import React from 'react';
import '../../css/TextsMain.css'
import {Button, Select, Spinner, TextField, Card} from "@shopify/polaris";

const StickyAtcTexts = ({ stickyAtc, onTextChange, onLocaleChange, save }) =>
    <div className="sticky-atc-texts">
        {stickyAtc.locales ? <div>

                <Select options={stickyAtc.locales} onChange={onLocaleChange} value={stickyAtc.locale}
                        disabled={stickyAtc.savingSettings}/>
            <Card>
                <TextField label="Add To Cart Button (Use {{price}} to show price):" onChange={onTextChange('atc_button')}
                           value={stickyAtc.settings.texts[stickyAtc.locale].atc_button}
                           disabled={stickyAtc.savingSettings} autoComplete={false} />
                <TextField label="Confirmation:" onChange={onTextChange('confirmation')}
                           value={stickyAtc.settings.texts[stickyAtc.locale].confirmation}
                           disabled={stickyAtc.savingSettings} autoComplete={false} />
                <TextField label="Out Of Stock:" onChange={onTextChange('out_of_stock')}
                           value={stickyAtc.settings.texts[stickyAtc.locale].out_of_stock}
                           disabled={stickyAtc.savingSettings} autoComplete={false} />
                <div className="save-button">
                    <Button onClick={save} loading={stickyAtc.savingSettings} primary>Save</Button>
                </div>
            </Card>
        </div> : <div>
            <Spinner />
        </div>}

</div>;

export default StickyAtcTexts;
