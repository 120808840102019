import fetch from "cross-fetch";
import {host} from "./data";
import {refreshSyncStatus} from "./status";

export const LOAD_TRANSLATIONS_FAILED = 'LOAD_TRANSLATIONS_FAILED';
export const SAVING_TRANSLATIONS = 'SAVING_TRANSLATIONS';
export const SYNCING_PRODUCT_TRANSLATIONS = 'SYNCING_PRODUCT_TRANSLATIONS';
export const SYNC_PRODUCT_TRANSLATIONS_SUCCESS = 'SYNC_PRODUCT_TRANSLATIONS_SUCCESS';
export const SYNC_PRODUCT_TRANS_STATUS = 'SYNC_PRODUCT_TRANS_STATUS';
export const SYNC_PRODUCT_TRANSLATIONS_FAILED = 'SYNC_PRODUCT_TRANSLATIONS_FAILED';
export const REMOVING_LOCALE = 'REMOVING_LOCALE';
export const ADDING_LOCALE = 'ADDING_LOCALE';
export const SAVING_TRANSLATIONS_SUCCESS = 'SAVING_TRANSLATIONS_SUCCESS';
export const SAVING_TRANSLATIONS_FAILURE = 'SAVING_TRANSLATIONS_FAILURE';
export const ADD_LOCALE_FAILURE = 'ADD_LOCALE_FAILURE';
export const REMOVE_LOCALE_FAILURE = 'REMOVE_LOCALE_FAILURE';
export const DISMISS_TRANSLATIONS_SAVED_TOAST = 'DISMISS_TRANSLATIONS_SAVED_TOAST';
export const RESTORE_SLIDER_TEXTS_DEFAULTS = 'RESTORE_SLIDER_TEXTS_DEFAULTS';
export const RESTORE_BUNDLE_TEXTS_DEFAULTS = 'RESTORE_BUNDLE_TEXTS_DEFAULTS';
export const RESTORE_WARRANTY_TEXTS_DEFAULTS = 'RESTORE_WARRANTY_TEXTS_DEFAULTS';
export const RESTORE_MINICART_TEXTS_DEFAULTS = 'RESTORE_MINICART_TEXTS_DEFAULTS';
export const RESTORE_VOLUME_TEXTS_DEFAULTS = 'RESTORE_VOLUME_TEXTS_DEFAULTS';
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';
export const ADD_LOCALE_SUCCESS = 'ADD_LOCALE_SUCCESS';
export const REMOVE_LOCALE_SUCCESS = 'REMOVE_LOCALE_SUCCESS';
export const LOCALE_CHANGE = 'LOCALE_CHANGE';
export const NEW_LOCALE_CHANGE = 'NEW_LOCALE_CHANGE';
export const COPY_FROM_CHANGE = 'COPY_FROM_CHANGE';
export const OPEN_ADD_LOCALE_MODAL = 'OPEN_ADD_LOCALE_MODAL';
export const OPEN_REMOVE_LOCALE_MODAL = 'OPEN_REMOVE_LOCALE_MODAL';
export const CLOSE_ADD_LOCALE_MODAL = 'CLOSE_ADD_LOCALE_MODAL';
export const CLOSE_REMOVE_LOCALE_MODAL = 'CLOSE_REMOVE_LOCALE_MODAL';
export const SLIDER_TITLE_CHANGE = 'SLIDER_TITLE_CHANGE';
export const SLIDER_SUB_TITLE_CHANGE = 'SLIDER_SUB_TITLE_CHANGE';
export const SLIDER_ADD_TO_CART_BUTTON_TITLE_CHANGE = 'SLIDER_ADD_TO_CART_BUTTON_TITLE_CHANGE';
export const SLIDER_ADD_TO_CART_BUTTON_ADDED_TEXT_CHANGE = 'SLIDER_ADD_TO_CART_BUTTON_ADDED_TEXT_CHANGE';
export const SLIDER_QUICK_VIEW_TEXT_CHANGE = 'SLIDER_QUICK_VIEW_TEXT_CHANGE';
export const SLIDER_FULL_PRODUCT_DETAILS_CHANGE = 'SLIDER_FULL_PRODUCT_DETAILS_CHANGE';
export const SLIDER_CONTINUE_SHOPPING_TEXT_CHANGE = 'SLIDER_CONTINUE_SHOPPING_TEXT_CHANGE';
export const SLIDER_POPUP_JUST_ADDED_TEXT_CHANGE = 'SLIDER_POPUP_JUST_ADDED_TEXT_CHANGE';
export const SLIDER_POPUP_VIEW_CART_TEXT_CHANGE = 'SLIDER_POPUP_VIEW_CART_TEXT_CHANGE';
export const SLIDER_TITLE_CART_SLIDER_CHANGE = 'SLIDER_TITLE_CART_SLIDER_CHANGE';
export const SLIDER_TITLE_BOTTOM_SLIDER_CHANGE = 'SLIDER_TITLE_BOTTOM_SLIDER_CHANGE';
export const SLIDER_TITLE_MAIN_SLIDER_CHANGE = 'SLIDER_TITLE_MAIN_SLIDER_CHANGE';
export const SLIDER_NOTIF_POPUP_QTY_TEXT_CHANGE = 'SLIDER_NOTIF_POPUP_QTY_TEXT_CHANGE';
export const TEXTS_CHANGE_TEXT = 'TEXTS_CHANGE_TEXT';
export const BUNDLE_ADD_SELECTED_TO_CART_TEXT_CHANGE = 'BUNDLE_ADD_SELECTED_TO_CART_TEXT_CHANGE';
export const BUNDLE_TOTAL_PRICE_TEXT_CHANGE = 'BUNDLE_TOTAL_PRICE_TEXT_CHANGE';
export const BUNDLE_ADD_TO_CART_TEXT_CHANGE = 'BUNDLE_ADD_TO_CART_TEXT_CHANGE';
export const BUNDLE_JUST_ADDED_TEXT_CHANGE = 'BUNDLE_JUST_ADDED_TEXT_CHANGE';
export const BUNDLE_DISCOUNT_GRANTED_TEXT_CHANGE = 'BUNDLE_DISCOUNT_GRANTED_TEXT_CHANGE';
export const BUNDLE_CONTINUE_SHOPPING_TEXT_CHANGE = 'BUNDLE_CONTINUE_SHOPPING_TEXT_CHANGE';
export const BUNDLE_QUICK_VIEW_FULL_DETAILS_TEXT_CHANGE = 'BUNDLE_QUICK_VIEW_FULL_DETAILS_TEXT_CHANGE';
export const BUNDLE_POPUP_VIEW_CART_TEXT_CHANGE = 'BUNDLE_POPUP_VIEW_CART_TEXT_CHANGE';
export const BUNDLE_NOTIF_POPUP_QTY_TEXT_CHANGE = 'BUNDLE_NOTIF_POPUP_QTY_TEXT_CHANGE';
export const BUNDLE_SAVE_TEXT_CHANGE = 'BUNDLE_SAVE_TEXT_CHANGE';
export const BUNDLE_OUT_OF_STOCK_TEXT_CHANGE = 'BUNDLE_OUT_OF_STOCK_TEXT_CHANGE';
export const WARRANTY_TITLE_CHANGE = 'WARRANTY_TITLE_CHANGE';
export const WARRANTY_CALL_TO_ACTION_CHANGE = 'WARRANTY_CALL_TO_ACTION_CHANGE';
export const WARRANTY_TOOLTIP_TEMPLATE_CHANGE = 'WARRANTY_TOOLTIP_TEMPLATE_CHANGE';
export const MINICART_FREE_DISCOUNT_TITLE_CHANGE = 'MINICART_FREE_DISCOUNT_TITLE_CHANGE';
export const MINICART_EMPTY_CART_TEXT_CHANGE = 'MINICART_EMPTY_CART_TEXT_CHANGE';
export const MINICART_CART_TOTAL_TEXT_CHANGE = 'MINICART_CART_TOTAL_TEXT_CHANGE';
export const MINICART_CHECKOUT_TEXT_CHANGE = 'MINICART_CHECKOUT_TEXT_CHANGE';
export const MINICART_UNLOCK_DISCOUNT_TEXT_CHANGE = 'MINICART_UNLOCK_DISCOUNT_TEXT_CHANGE';
export const MINICART_FREE_DISCOUNT_UNLOCKED_TEXT_CHANGE = 'MINICART_FREE_DISCOUNT_UNLOCKED_TEXT_CHANGE';
export const MINICART_CART_TITLE_CHANGE = 'MINICART_CART_TITLE_CHANGE';
export const MINICART_FREE_SHIPPING_TITLE_CHANGE = 'MINICART_FREE_SHIPPING_TITLE_CHANGE';
export const MINICART_UNLOCK_SHIPPING_TEXT_CHANGE = 'MINICART_UNLOCK_SHIPPING_TEXT_CHANGE';
export const MINICART_FREE_SHIPPING_UNLOCKED_TEXT_CHANGE = 'MINICART_FREE_SHIPPING_UNLOCKED_TEXT_CHANGE';
export const MINICART_VIEW_CART_TEXT_CHANGE = 'MINICART_VIEW_CART_TEXT_CHANGE';
export const MINICART_ADD_CART_NOTE_TEXT_CHANGE = 'MINICART_ADD_CART_NOTE_TEXT_CHANGE';
export const MINICART_CART_NOTE_PLACEHOLDER_TEXT_CHANGE = 'MINICART_CART_NOTE_PLACEHOLDER_TEXT_CHANGE';
export const MINICART_OFFER_CTA_TEXT_CHANGE = 'MINICART_OFFER_CTA_TEXT_CHANGE';
export const VOLUME_ADD_TO_CART_TEXT_CHANGE = 'VOLUME_ADD_TO_CART_TEXT_CHANGE';
export const VOLUME_POPULAR_TEXT_CHANGE = 'VOLUME_POPULAR_TEXT_CHANGE';
export const VOLUME_TOTAL_TEXT_CHANGE = 'VOLUME_TOTAL_TEXT_CHANGE';
export const VOLUME_SAVE_TEXT_CHANGE = 'VOLUME_SAVE_TEXT_CHANGE';
export const VOLUME_EACH_ITEM_TEXT_CHANGE = 'VOLUME_EACH_ITEM_TEXT_CHANGE';
export const VOLUME_JUST_ADDED_TEXT_CHANGE = 'VOLUME_JUST_ADDED_TEXT_CHANGE';
export const VOLUME_QTY_TEXT_CHANGE = 'VOLUME_QTY_TEXT_CHANGE';
export const VOLUME_SAVE_PER_ITEM_CHANGE = 'VOLUME_SAVE_PER_ITEM_CHANGE';
export const VOLUME_OUT_OF_STOCK_TEXT_CHANGE = 'VOLUME_OUT_OF_STOCK_TEXT_CHANGE';
export const VOLUME_TIERS_TABLE_QTY_TEXT_CHANGE = 'VOLUME_TIERS_TABLE_QTY_TEXT_CHANGE';
export const VOLUME_TIERS_TABLE_DISCOUNT_TEXT_CHANGE = 'VOLUME_TIERS_TABLE_DISCOUNT_TEXT_CHANGE';

export const loadTranslationsFailed = () => ({
    type: LOAD_TRANSLATIONS_FAILED,
});

export const savingTranslations = () => ({
    type: SAVING_TRANSLATIONS,
});

export const syncingProductTranslations = () => ({
    type: SYNCING_PRODUCT_TRANSLATIONS,
});

export const syncProductTranslationsSuccess = () => ({
    type: SYNC_PRODUCT_TRANSLATIONS_SUCCESS,
});

export const syncProductTransStatus = data => ({
    type: SYNC_PRODUCT_TRANS_STATUS,
    data,
});

export const syncProductTranslationsFailed = () => ({
    type: SYNC_PRODUCT_TRANSLATIONS_FAILED,
});

export const removingLocale = () => ({
    type: REMOVING_LOCALE,
});

export const addingLocale = () => ({
    type: ADDING_LOCALE,
});

export const savingTranslationsSuccess = () => ({
    type: SAVING_TRANSLATIONS_SUCCESS,
});

export const savingTranslationsFailure = () => ({
    type: SAVING_TRANSLATIONS_FAILURE,
});

export const addLocaleFailure = () => ({
    type: ADD_LOCALE_FAILURE,
});

export const removeLocaleFailure = () => ({
    type: REMOVE_LOCALE_FAILURE,
});

export const dismissTranslationsSavedToast = () => ({
    type: DISMISS_TRANSLATIONS_SAVED_TOAST,
});

export const restoreSliderTextsDefaults = () => ({
    type: RESTORE_SLIDER_TEXTS_DEFAULTS,
});

export const restoreBundleTextsDefaults = () => ({
    type: RESTORE_BUNDLE_TEXTS_DEFAULTS,
});

export const restoreWarrantyTextsDefaults = () => ({
    type: RESTORE_WARRANTY_TEXTS_DEFAULTS,
});

export const restoreMinicartTextsDefaults = () => ({
    type: RESTORE_MINICART_TEXTS_DEFAULTS,
});

export const restoreVolumeTextsDefaults = () => ({
    type: RESTORE_VOLUME_TEXTS_DEFAULTS,
});

export const openAddLocaleModal = () => ({
    type: OPEN_ADD_LOCALE_MODAL,
});

export const openRemoveLocaleModal = () => ({
    type: OPEN_REMOVE_LOCALE_MODAL,
});

export const closeAddLocaleModal = () => ({
    type: CLOSE_ADD_LOCALE_MODAL,
});

export const closeRemoveLocaleModal = () => ({
    type: CLOSE_REMOVE_LOCALE_MODAL,
});

export const setTranslations = json => ({
    type: SET_TRANSLATIONS,
    translations: json.translations.translations,
    manuallyAddedLocales: json.translations.manually_added_locales,
    availableLocales: json.available_locales,
    defaults: json.defaults,
});

export const addLocaleSuccess = json => ({
    type: ADD_LOCALE_SUCCESS,
    translations: json.translations,
    manuallyAddedLocales: json.manually_added_locales,
});

export const removeLocaleSuccess = json => ({
    type: REMOVE_LOCALE_SUCCESS,
    translations: json.translations,
    manuallyAddedLocales: json.manually_added_locales,
});

export const localeChange = text => ({
    type: LOCALE_CHANGE,
    text,
});

export const newLocaleChange = text => ({
    type: NEW_LOCALE_CHANGE,
    text,
});

export const copyFromChange = text => ({
    type: COPY_FROM_CHANGE,
    text,
});

export const sliderTitleChange = text => ({
    type: SLIDER_TITLE_CHANGE,
    text,
});

export const sliderSubTitleChange = text => ({
    type: SLIDER_SUB_TITLE_CHANGE,
    text,
});

export const sliderAddToCartButtonTitleChange = text => ({
    type: SLIDER_ADD_TO_CART_BUTTON_TITLE_CHANGE,
    text,
});

export const sliderAddToCartButtonAddedTextChange = text => ({
    type: SLIDER_ADD_TO_CART_BUTTON_ADDED_TEXT_CHANGE,
    text,
});

export const sliderQuickViewTextChange = text => ({
    type: SLIDER_QUICK_VIEW_TEXT_CHANGE,
    text,
});

export const sliderFullProductDetailsChange = text => ({
    type: SLIDER_FULL_PRODUCT_DETAILS_CHANGE,
    text,
});

export const sliderContinueShoppingTextChange = text => ({
    type: SLIDER_CONTINUE_SHOPPING_TEXT_CHANGE,
    text,
});

export const sliderPopupJustAddedTextChange = text => ({
    type: SLIDER_POPUP_JUST_ADDED_TEXT_CHANGE,
    text,
});

export const sliderPopupViewCartTextChange = text => ({
    type: SLIDER_POPUP_VIEW_CART_TEXT_CHANGE,
    text,
});

export const sliderTitleCartSliderChange = text => ({
    type: SLIDER_TITLE_CART_SLIDER_CHANGE,
    text,
});

export const sliderTitleBottomSliderChange = text => ({
    type: SLIDER_TITLE_BOTTOM_SLIDER_CHANGE,
    text,
});

export const sliderTitleMainSliderChange = text => ({
    type: SLIDER_TITLE_MAIN_SLIDER_CHANGE,
    text,
});

export const sliderNotifPopupQtyTextChange = text => ({
    type: SLIDER_NOTIF_POPUP_QTY_TEXT_CHANGE,
    text,
});
export const textsChangeText = (widget, attr, text) => ({
    type: TEXTS_CHANGE_TEXT,
    widget,
    attr,
    text,
});

export const bundleAddSelectedToCartTextChange = text => ({
    type: BUNDLE_ADD_SELECTED_TO_CART_TEXT_CHANGE,
    text,
});

export const bundleTotalPriceTextChange = text => ({
    type: BUNDLE_TOTAL_PRICE_TEXT_CHANGE,
    text,
});

export const bundleAddToCartTextChange = text => ({
    type: BUNDLE_ADD_TO_CART_TEXT_CHANGE,
    text,
});

export const bundleJustAddedTextChange = text => ({
    type: BUNDLE_JUST_ADDED_TEXT_CHANGE,
    text,
});

export const bundleDiscountGrantedTextChange = text => ({
    type: BUNDLE_DISCOUNT_GRANTED_TEXT_CHANGE,
    text,
});

export const bundleContinueShoppingTextChange = text => ({
    type: BUNDLE_CONTINUE_SHOPPING_TEXT_CHANGE,
    text,
});

export const bundleQuickViewFullDetailsTextChange = text => ({
    type: BUNDLE_QUICK_VIEW_FULL_DETAILS_TEXT_CHANGE,
    text,
});

export const bundlePopupViewCartTextChange = text => ({
    type: BUNDLE_POPUP_VIEW_CART_TEXT_CHANGE,
    text,
});

export const bundleNotifPopupQtyTextChange = text => ({
    type: BUNDLE_NOTIF_POPUP_QTY_TEXT_CHANGE,
    text,
});

export const bundleSaveTextChange = text => ({
    type: BUNDLE_SAVE_TEXT_CHANGE,
    text,
});

export const bundleOutOfStockTextChange = text => ({
    type: BUNDLE_OUT_OF_STOCK_TEXT_CHANGE,
    text,
});

export const warrantyTitleChange = text => ({
    type: WARRANTY_TITLE_CHANGE,
    text,
});

export const warrantyCallToActionChange = text => ({
    type: WARRANTY_CALL_TO_ACTION_CHANGE,
    text,
});

export const warrantyTooltipTemplateChange = text => ({
    type: WARRANTY_TOOLTIP_TEMPLATE_CHANGE,
    text,
});

export const minicartFreeDiscountTitleChange = text => ({
    type: MINICART_FREE_DISCOUNT_TITLE_CHANGE,
    text,
});

export const minicartEmptyCartTextChange = text => ({
    type: MINICART_EMPTY_CART_TEXT_CHANGE,
    text,
});

export const minicartCartTotalTextChange = text => ({
    type: MINICART_CART_TOTAL_TEXT_CHANGE,
    text,
});

export const minicartCheckoutTextChange = text => ({
    type: MINICART_CHECKOUT_TEXT_CHANGE,
    text,
});

export const minicartUnlockDiscountTextChange = text => ({
    type: MINICART_UNLOCK_DISCOUNT_TEXT_CHANGE,
    text,
});

export const minicartFreeDiscountUnlockedTextChange = text => ({
    type: MINICART_FREE_DISCOUNT_UNLOCKED_TEXT_CHANGE,
    text,
});

export const minicartCartTitleChange = text => ({
    type: MINICART_CART_TITLE_CHANGE,
    text,
});

export const minicartFreeShippingTitleChange = text => ({
    type: MINICART_FREE_SHIPPING_TITLE_CHANGE,
    text,
});

export const minicartUnlockShippingTextChange = text => ({
    type: MINICART_UNLOCK_SHIPPING_TEXT_CHANGE,
    text,
});

export const minicartFreeShippingUnlockedTextChange = text => ({
    type: MINICART_FREE_SHIPPING_UNLOCKED_TEXT_CHANGE,
    text,
});

export const minicartViewCartTextChange = text => ({
    type: MINICART_VIEW_CART_TEXT_CHANGE,
    text,
});

export const minicartAddCartNoteTextChange = text => ({
    type: MINICART_ADD_CART_NOTE_TEXT_CHANGE,
    text,
});

export const minicartCartNotePlaceholderTextChange = text => ({
    type: MINICART_CART_NOTE_PLACEHOLDER_TEXT_CHANGE,
    text,
});

export const minicartOfferCtaTextChange = text => ({
    type: MINICART_OFFER_CTA_TEXT_CHANGE,
    text,
});

export const volumeAddToCartTextChange = text => ({
    type: VOLUME_ADD_TO_CART_TEXT_CHANGE,
    text,
});

export const volumePopularTextChange = text => ({
    type: VOLUME_POPULAR_TEXT_CHANGE,
    text,
});

export const volumeTotalTextChange = text => ({
    type: VOLUME_TOTAL_TEXT_CHANGE,
    text,
});

export const volumeSaveTextChange = text => ({
    type: VOLUME_SAVE_TEXT_CHANGE,
    text,
});

export const volumeEachItemTextChange = text => ({
    type: VOLUME_EACH_ITEM_TEXT_CHANGE,
    text,
});

export const volumeJustAddedTextChange = text => ({
    type: VOLUME_JUST_ADDED_TEXT_CHANGE,
    text,
});

export const volumeQtyTextChange = text => ({
    type: VOLUME_QTY_TEXT_CHANGE,
    text,
});

export const volumeSavePerItemChange = text => ({
    type: VOLUME_SAVE_PER_ITEM_CHANGE,
    text,
});

export const volumeOutOfStockTextChange = text => ({
    type: VOLUME_OUT_OF_STOCK_TEXT_CHANGE,
    text,
});

export const volumeTiersTableQtyTextChange = text => ({
    type: VOLUME_TIERS_TABLE_QTY_TEXT_CHANGE,
    text,
});

export const volumeTiersTableDiscountTextChange = text => ({
    type: VOLUME_TIERS_TABLE_DISCOUNT_TEXT_CHANGE,
    text,
});

function setTimeoutSetTranslations(dispatch) {
    setTimeout(() => dispatch(loadTranslations()), 5_000);
}

export function loadTranslations() {
    return (dispatch, getState) => {
        const state = getState();
        if (!state.texts.translations) {
            fetch(`https://${host}/translations/get?shop_name=${state.data.shopName}&token=${state.data.token}${state.data.appType ? `&app_type=${state.data.appType}` : ''}`)
                .then((response) => {
                    if (response.ok) {
                        response.json().then(json => {
                            if (json.translation_status === 'done') {
                                dispatch(setTranslations(json));
                            } else {
                                setTimeoutSetTranslations(dispatch);
                            }
                        });
                    } else {
                        dispatch(loadTranslationsFailed());
                    }
                })
        }
    }
}

export function syncProductTranslations() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(syncingProductTranslations());
        fetch(`https://${host}/translations/sync_product_translations?shop_name=${state.data.shopName}&token=${state.data.token}${state.data.appType ? `&app_type=${state.data.appType}` : ''}`)
            .then((response) => {
                if (response.ok) {
                    dispatch(syncProductTranslationsSuccess());
                } else {
                    dispatch(syncProductTranslationsFailed());
                }
            })

    }
}

export function saveTranslations() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(savingTranslations());
        return fetch(`https://${host}/translations/save`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    app_type: state.data.appType,
                    translations: state.texts.translations,
                    manually_added_locales: state.texts.manuallyAddedLocales,
                })
            })
            .then((response) => {
                if (response.ok) {
                    dispatch(savingTranslationsSuccess());
                    dispatch(refreshSyncStatus());
                } else {
                    dispatch(savingTranslationsFailure());
                }
            });
    }
}

export function removeLocale() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(removingLocale());
        return fetch(`https://${host}/translations/delete_locale`,
            {
                method: 'delete',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    app_type: state.data.appType,
                    locale: state.texts.locale,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(removeLocaleSuccess(json));
                    });
                    dispatch(refreshSyncStatus());
                } else {
                    dispatch(removeLocaleFailure());
                }
            });
    }
}

export function addLocale() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(addingLocale());
        return fetch(`https://${host}/translations/add_locale`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    app_type: state.data.appType,
                    new_locale: state.texts.desiredNewLocale,
                    copy_from: state.texts.copyFromLocale,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(addLocaleSuccess(json));
                    });
                    dispatch(refreshSyncStatus());
                } else {
                    dispatch(addLocaleFailure());
                }
            });
    }
}

