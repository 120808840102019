import * as _ from "lodash";
import {INSTALL_SHOP_DOMAIN_CHANGE, INSTALLING_ZOORIX} from "../actions/install";

const install = (state = { }, action) => {
    let newState = null;
    switch (action.type) {
        case INSTALL_SHOP_DOMAIN_CHANGE:
            newState = _.cloneDeep(state);
            newState.shopDomain = action.text;
            break;
        case INSTALLING_ZOORIX:
            newState = _.cloneDeep(state);
            newState.installing = true;
            break;
        default:
            return state;
    }
    return newState;
};

export default install;
