import React from 'react';
import {Button, Card, Frame, TextStyle, Toast, Form, FormLayout, TextField, Checkbox, Select} from '@shopify/polaris';
import '../../css/Settings.css';
import ImageUpload from "../ImageUpload";
import {host} from "../../actions/data";

const templatesTitles = {
    purchase: 'Purchase Email & SMS',
    accept: 'Accept Email & SMS',
    reject: 'Reject Email & SMS',
}

const showOnCartOptions = [
    { label: 'None', value: 'none' },
    { label: 'Top', value: 'top' },
    { label: 'Bottom', value: 'bottom' },
    { label: 'Both', value: 'both' },
];

const SettingsWarranty = ({ toastIsActive, onDismissToast, save, saveInProgress, saveError, settings, restoreToDefaults,
    tokenId, upload, restoreZoorixDefault, isUploading, cancelUpload, shopName, token,
    uploadSuccess, uploadFailure, uploadError, emailOrPhone, onEmailOrPhoneChange,
    sendTestEmail, sendTestSMS, sendTestEmailProcessing, sendTestEmailError,
    sendTestEmailToast, sendTestSMSProcessing, sendTestSMSError, sendTestSMSToast,
    onSendNotificationChange, onEmailChange, onPercentageChange, onEmailSubjectChange,
    onSmsSubjectChange,
    onEmailTemplateChange, onSmsTemplateChange, onStoreNameChange,
    onWarrantyInCartsChange, planName, onSendPurchaseNotificationChange,
    onSendAcceptNotificationChange, onSendRejectNotificationChange, onShowOnCartChange }) => (
    <Frame>
        <Form>
            <FormLayout>
                {saveError && <TextStyle variation="negative">There was an error sending your request. Please try
                    again in a few minutes</TextStyle>}
                <div className="zrx-tab-wrapper-settings">
                    <div className="zrx-tab-title">Warranty Settings</div>
                    <div className="zrx-tab-subtitle">Configuration and customizations of the warranty offers</div>
                </div>
                <div className="form-card">
                    <Card title={'Notifications'}>
                        <Checkbox label="Send me notification on every warranty redemption request"
                            checked={settings.send_notification} onChange={onSendNotificationChange}
                            disabled={saveInProgress} />
                        <TextField label={`${settings.send_notification ? '* ' : ''}Email:`} value={settings.email}
                            onChange={onEmailChange} disabled={saveInProgress} />
                        <div style={{ marginTop: '7px' }}><Checkbox label="Send Email/SMS to buyer after a warranty was purchased"
                            checked={settings.send_purchase_notification} onChange={onSendPurchaseNotificationChange}
                            disabled={saveInProgress} /></div>
                        <div><Checkbox label="Send Email/SMS to buyer after a warranty redemption request was accepted"
                            checked={settings.send_accept_notification} onChange={onSendAcceptNotificationChange}
                            disabled={saveInProgress} /></div>
                        <div><Checkbox label="Send Email/SMS to buyer after a warranty redemption request was rejected"
                            checked={settings.send_reject_notification} onChange={onSendRejectNotificationChange}
                            disabled={saveInProgress} /></div>
                    </Card>
                </div>
                <div className="form-card">
                    <Card title={'Store'}>
                        <TextField label="* Store name" value={settings.store_name}
                            onChange={onStoreNameChange} disabled={saveInProgress} />
                    </Card>
                </div>
                <div className="form-card">
                    <Card title={'Redemption Discount'}>
                        <TextField label="* Default discount in %:" type="number"
                            value={settings.percentage && settings.percentage.toString()}
                            onChange={onPercentageChange} disabled={saveInProgress} />
                    </Card>
                </div>
                <div className="form-card">
                    <Card title={'Cart'}>
                        <Checkbox label="Show warranty on cart slider"
                            checked={settings.warranty_in_cart}
                            onChange={onWarrantyInCartsChange} disabled={saveInProgress} />
                        <Select label="Show warranty on cart page:" options={showOnCartOptions}
                            onChange={onShowOnCartChange}
                            value={settings.show_on_cart_top ?
                                (settings.show_on_cart_bottom ? 'both' : 'top') :
                                (settings.show_on_cart_bottom ? 'bottom' : 'none')} disabled={saveInProgress} />
                    </Card>
                </div>
                <div className="form-card">
                    <Card title={'Default Image'}>
                        {settings.is_zoorix_default_warranty_image ?
                            <div>
                                <img src="https://public.zoorix.com/images/warranty.png" alt="zoorix default"
                                    height={'200px'} width={'200px'} />
                            </div>
                            :
                            <div>

                                    <img src={`https://public.zoorix.com/shops/${tokenId}/images/default_warranty?${Date.now()}`}
                                         alt="store default" height={'200px'} width={'200px'}/>

                                    <Button onClick={restoreZoorixDefault}>Restore Zoorix default</Button>
                                </div>
                            }
                            { isUploading ?
                                <div>
                                    {uploadError &&
                                    <TextStyle variation="negative">There was an error uploading your file. Please try
                                        again in a few minutes</TextStyle>}
                                    <ImageUpload onCancel={cancelUpload}
                                                 presignedUrl={`https://${host}/image/get_url_for_default_warranty?shop_name=${shopName}&token=${token}`}
                                                 uploadSuccess={uploadSuccess}
                                                 uploadFailure={uploadFailure}

                                    />
                                </div>
                                :
                                <Button onClick={upload}>Upload</Button>
                            }

                        </Card>
                    </div>
                    {['purchase', 'accept', 'reject'].map(email_type => (
                        <div className="form-card warranty-settings" key={email_type}>
                            <Card title={templatesTitles[email_type]}>
                                <TextField label="* Email subject" value={settings[`${email_type}_email_subject`]}
                                           onChange={onEmailSubjectChange(email_type)} disabled={saveInProgress} />
                                <TextField label="* Email template (contents of body tag)" value={settings[`${email_type}_email_template`]}
                                           onChange={onEmailTemplateChange(email_type)} disabled={saveInProgress}
                                           multiline={5} />
                                <TextField label="* SMS subject (3-11 alphanumeric characters)" value={settings[`${email_type}_sms_subject`]}
                                           onChange={onSmsSubjectChange(email_type)} disabled={saveInProgress} />
                                <TextField label="* SMS template" value={settings[`${email_type}_sms_template`]}
                                           onChange={onSmsTemplateChange(email_type)} disabled={saveInProgress} multiline={3} />
                                {sendTestEmailError[email_type] && <TextStyle variation="negative">There was an error sending the email.
                                    Please try again in a few minutes<br/></TextStyle>}
                                {sendTestSMSError[email_type] && <TextStyle variation="negative">There was an error sending the SMS.
                                    Please try again in a few minutes<br/></TextStyle>}
                                <TextField label="Email or Phone:" value={emailOrPhone[email_type]}
                                           onChange={onEmailOrPhoneChange(email_type)} disabled={saveInProgress} />
                                <Button onClick={sendTestEmail(email_type)} loading={sendTestEmailProcessing[email_type]}
                                        disabled={!emailOrPhone[email_type] || !settings[`${email_type}_email_subject`]}>Send test email</Button>
                                <Button onClick={sendTestSMS(email_type)} loading={sendTestSMSProcessing[email_type]}
                                        disabled={!emailOrPhone[email_type] || !settings[`${email_type}_sms_subject`] ||
                                        settings[`${email_type}_sms_subject`].length < 3 || settings[`${email_type}_sms_subject`].length > 11}
                                >Send test SMS</Button>
                            </Card>
                        </div>
                    ))}

                    <span className={"save-button " + (planName === 'trial' ? 'trial' : '')}>
                        <Button id="save-settings" onClick={save} primary loading={saveInProgress}
                                disabled={(settings.send_notification && !settings.email) || !settings.percentage ||
                                (!settings.purchase_sms_subject || settings.purchase_sms_subject.length < 3 || settings.purchase_sms_subject.length > 11) ||
                                (!settings.accept_sms_subject || settings.accept_sms_subject.length < 3 || settings.accept_sms_subject.length > 11) ||
                                (!settings.reject_sms_subject || settings.reject_sms_subject.length < 3 || settings.reject_sms_subject.length > 11) ||
                                !settings.purchase_email_subject || !settings.accept_email_subject || !settings.reject_email_subject ||
                                !settings.store_name || !settings.tooltip_template}
                        >Save</Button>
                    </span>
                    <Button onClick={restoreToDefaults}>Restore defaults</Button>
                    {toastIsActive && <Toast content="Settings saved" onDismiss={onDismissToast} />}
                    {sendTestEmailToast && <Toast content="Email sent" onDismiss={onDismissToast} />}
                    {sendTestSMSToast && <Toast content="SMS sent" onDismiss={onDismissToast} />}
                </FormLayout>
            </Form>
        </Frame>
    );

export default SettingsWarranty;
