import { connect } from 'react-redux';
import SettingsBundle from "../../components/settings/SettingsBundle";
import {
    addSelectedToCartButtonColorChange,
    addSelectedToCartTextColorChange,
    bundleBottomEnabledChange,
    bundleComparePriceColorChange,
    bundleComparePriceSizeChange,
    bundleCurrencyPositionChange, bundleCutZerosChange, bundleDeselectBundleCheckboxesChange,
    bundleFontFamilyChange,
    bundleItemNameColorChange,
    bundleItemPriceColorChange,
    BundleMainEnabledChange,
    bundleNavigateToChange,
    bundlePriceColorChange,
    bundlePriceSizeChange,
    bundleProductDescOnQuickViewChange,
    bundleQuickViewEnabledChange, bundleRefreshOnCartChange, bundleShowSaveChange,
    bundleTitleAlign,
    bundleTitleColorChange,
    bundleTitleFontSizeChange,
    bundleTitleFontWeightChange,
    dismissToast,
    hideBundleComparePriceChange,
    infoPopupBundleChange, regenerateAutoBundlesChange,
    restoreBundleToDefaults,
    saveBundleSettings,
    showBundleItemCompareChange,
    sumComparePricesChange,
    totalPriceTextColorChange
} from "../../actions/settingsBundle";
import { addAlphaToColor } from "./VisibleSettingsMain";

const mapStateToProps = state => ({
    toastIsActive: state.settingsBundle.toastIsActive,
    saveInProgress: state.settingsBundle.saveInProgress,
    saveError: state.settingsBundle.saveError,
    settings: state.settingsBundle.settings,
    planName: state.settingsBundle.planName,
});

const mapDispatchToProps = (dispatch) => ({
    onDismissToast: () => dispatch(dismissToast()),
    save: () => dispatch(saveBundleSettings()),
    restoreToDefaults: () => dispatch(restoreBundleToDefaults()),
    onTitleAlignChange: text => dispatch(bundleTitleAlign(text)),
    onBundleComparePriceSizeChange: text => dispatch(bundleComparePriceSizeChange(text)),
    onBundlePriceSizeChange: text => dispatch(bundlePriceSizeChange(text)),
    onBundleTitleFontSizeChange: text => dispatch(bundleTitleFontSizeChange(text)),
    onFontFamilyChange: text => dispatch(bundleFontFamilyChange(text)),
    onNavigateToChange: text => dispatch(bundleNavigateToChange(text)),
    onCurrencyPositionChange: text => dispatch(bundleCurrencyPositionChange(text)),
    onShowSaveChange: text => dispatch(bundleShowSaveChange(text)),
    onDeselectBundleCheckboxesChange: text => dispatch(bundleDeselectBundleCheckboxesChange(text)),
    onTitleFontWeightChange: weight => dispatch(bundleTitleFontWeightChange(weight)),
    onAddSelectedToCartTextColorChange: color => dispatch(addSelectedToCartTextColorChange(addAlphaToColor(color))),
    onAddSelectedToCartButtonColorChange: color => dispatch(addSelectedToCartButtonColorChange(addAlphaToColor(color))),
    onTotalPriceTextColorChange: color => dispatch(totalPriceTextColorChange(addAlphaToColor(color))),
    onBundleComparePriceColorChange: color => dispatch(bundleComparePriceColorChange(addAlphaToColor(color))),
    onBundlePriceColorChange: color => dispatch(bundlePriceColorChange(addAlphaToColor(color))),
    onBundleTitleColorChange: color => dispatch(bundleTitleColorChange(addAlphaToColor(color))),
    onBundleItemPriceColorChange: color => dispatch(bundleItemPriceColorChange(addAlphaToColor(color))),
    onBundleItemNameColorChange: color => dispatch(bundleItemNameColorChange(addAlphaToColor(color))),
    onHideBundleComparePriceChange: bool => dispatch(hideBundleComparePriceChange(bool)),
    onSumComparePricesChange: bool => dispatch(sumComparePricesChange(bool)),
    onShowBundleItemCompareChange: bool => dispatch(showBundleItemCompareChange(bool)),
    onBundleMainEnabledChange: bool => dispatch(BundleMainEnabledChange(bool)),
    onBundleBottomEnabledChange: bool => dispatch(bundleBottomEnabledChange(bool)),
    onInfoPopupBundleChange: bool => dispatch(infoPopupBundleChange(bool)),
    onQuickViewEnabledChange: bool => dispatch(bundleQuickViewEnabledChange(bool)),
    onProductDescOnQuickViewChange: bool => dispatch(bundleProductDescOnQuickViewChange(bool)),
    onCutZerosChange: bool => dispatch(bundleCutZerosChange(bool)),
    onRefreshOnCartChange: bool => dispatch(bundleRefreshOnCartChange(bool)),
    onRegenerateAutoBundlesChange: bool => dispatch(regenerateAutoBundlesChange(bool)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettingsBundle);
