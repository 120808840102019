import * as _ from 'lodash';
import {SUBMITTED, SUBMIT_ERROR, TEXT_CHANGE} from "../actions/support";

const textError = 'Text must be at least 10 characters long';

const support = (state = { textError: null }, action) => {
    let newState = null;
    switch (action.type) {
        case TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.text = action.text;
            newState.textError = null;
            break;
        case SUBMITTED:
            newState = _.cloneDeep(state);
            newState.submitted = true;
            newState.error = false;
            break;
        case SUBMIT_ERROR:
            newState = _.cloneDeep(state);
            if (action.connectionError) {
                newState.error = true;
            } else if (action.textLengthError) {
                newState.textError = textError;
            }
            break;
        default:
            return state;
    }
    return newState;
};

export default support;
