import fetch from "cross-fetch";
import {host} from "./data";
import {refreshSyncStatus} from "./status";

export const APP_SETTINGS_INIT = 'APP_SETTINGS_INIT';
export const SHOW_OUT_OF_STOCK = 'SHOW_OUT_OF_STOCK';
export const PRICE_ORDER_COMPARE_TO_LEFT_CHANGE = 'PRICE_ORDER_COMPARE_TO_LEFT_CHANGE';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SHOW_ONLY_FROM_LOCATIONS = 'SHOW_ONLY_FROM_LOCATIONS';
export const SELECT_LOCATION = 'SELECT_LOCATION';
export const WIDGET_ORDER_CHANGE = 'WIDGET_ORDER_CHANGE';
export const ACTIVATE_TOAST = 'ACTIVATE_TOAST';
export const DISMISS_TOAST = 'DISMISS_TOAST';
export const APP_SETTINGS_RESTORE_TO_DEFAULTS = 'APP_SETTINGS_RESTORE_TO_DEFAULTS';
export const APP_SETTINGS_SAVE_SETTINGS_INIT = 'APP_SETTINGS_SAVE_SETTINGS_INIT';
export const APP_SETTINGS_SAVE_SETTINGS_ERROR = 'APP_SETTINGS_SAVE_SETTINGS_ERROR';
export const APP_SETTINGS_SAVE_SETTINGS_SUCCESS = 'APP_SETTINGS_SAVE_SETTINGS_SUCCESS';

export const appSettingsInit = json => ({
    type: APP_SETTINGS_INIT,
    upsell: json.upsell,
    hasReadInventory: json.hasReadInventory,
    locations: json.locations,
    planName: json.plan_name,
    generalSetting: json.general_setting,
    jsSelectors: json.js_selectors,
});

export const appSettingsRestoreToDefaults = () => ({
    type: APP_SETTINGS_RESTORE_TO_DEFAULTS,
});

export const showOutOfStock = bool => ({
    type: SHOW_OUT_OF_STOCK,
    bool,
});

export const priceOrderCompareToLeftChange = bool => ({
    type: PRICE_ORDER_COMPARE_TO_LEFT_CHANGE,
    bool,
});

export const showOnlyFromLocations = bool => ({
    type: SHOW_ONLY_FROM_LOCATIONS,
    bool,
});

export const selectLocation = (id, bool) => ({
    type: SELECT_LOCATION,
    id,
    bool,
});

export const widgetOrderChange = (id, text) => ({
    type: WIDGET_ORDER_CHANGE,
    id,
    text,
});

export const activateToast = () => ({
    type: ACTIVATE_TOAST,
});

export const dismissToast = () => ({
    type: DISMISS_TOAST,
});

export const appSettingsSaveSettingsInit = () => ({
    type: APP_SETTINGS_SAVE_SETTINGS_INIT,
});

export const appSettingsSaveSettingsError = () => ({
    type: APP_SETTINGS_SAVE_SETTINGS_ERROR,
});

export const appSettingsSaveSettingsSuccess = json => ({
    type: APP_SETTINGS_SAVE_SETTINGS_SUCCESS,
    json,
});

export const setLocations = json => ({
    type: SET_LOCATIONS,
    locations: json.locations,
});

export function submit() {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(appSettingsSaveSettingsInit());
        const response = await fetch(`https://${host}/app_settings/save`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    app_type: state.data.appType,
                    show_out_of_stock: state.settingsApp.showOutOfStock,
                    show_only_from_locations: state.settingsApp.showOnlyFromLocations,
                    locations: state.settingsApp.isLocationSelected,
                    widget_order: state.settingsApp.widgetOrder,
                    settings: state.settingsApp.generalSetting.values,
                })
            });
        if (response.ok) {
            const json = await response.json();
            dispatch(appSettingsSaveSettingsSuccess(json));
            dispatch(activateToast());
            dispatch(refreshSyncStatus());
        } else {
            dispatch(appSettingsSaveSettingsError());
        }
    }
}


