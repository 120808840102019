import fetch from "cross-fetch";
import {host, postPayload} from "./data";

export const INIT_ANALYTICS = 'INIT_ANALYTICS';
export const INIT_ANAL_MAPPINGS = 'INIT_ANAL_MAPPINGS';
export const INIT_ANAL_BUNDLES = 'INIT_ANAL_BUNDLES';
export const INIT_ANAL_VOLUME_DISCOUNTS = 'INIT_ANAL_VOLUME_DISCOUNTS';
export const TOGGLE_SELECT_DATE_ACTIVE = 'TOGGLE_SELECT_DATE_ACTIVE';
export const ANALYTICS_MINITAB_CLICKED = 'ANALYTICS_MINITAB_CLICKED';
export const START_APPLY_DATE_RANGE = 'START_APPLY_DATE_RANGE';
export const ANALYTICS_TOGGLE_EXPORT_MODAL = 'ANALYTICS_TOGGLE_EXPORT_MODAL';
export const ANALYTICS_DISMISS_TOAST = 'ANALYTICS_DISMISS_TOAST';
export const DATE_RANGE_PRESET_CHANGE = 'DATE_RANGE_PRESET_CHANGE';
export const ANALYTICS_EXPORTING = 'ANALYTICS_EXPORTING';
export const ANALYTICS_TOGGLE_MAPPING = 'ANALYTICS_TOGGLE_MAPPING';
export const ANALYTICS_TOGGLE_BUNDLE = 'ANALYTICS_TOGGLE_BUNDLE';
export const ANALYTICS_TOGGLE_VOLUME = 'ANALYTICS_TOGGLE_VOLUME';
export const OPEN_ORDER = 'OPEN_ORDER';
export const UPDATE_ANALYTICS = 'UPDATE_ANALYTICS';
export const DATE_RANGE_CHANGE = 'DATE_RANGE_CHANGE';
export const ANALYTICS_TOAST = 'ANALYTICS_TOAST';
export const DATE_RANGE_MONTH_CHANGE = 'DATE_RANGE_MONTH_CHANGE';
export const SORT_PRODUCTS = 'SORT_PRODUCTS';

export const initAnalytics = (json, appType) => ({
    type: INIT_ANALYTICS,
    currency: json.currency,
    multiCurrency: json.multi_currency,
    ordersGraph: json.orders_graph,
    revenueGraph: json.revenue_graph,
    products: json.products,
    totalSales: json.total_sales,
    totalZoorixSales: json.total_zoorix_sales,
    zoorixRevenue: json.zoorix_revenue,
    zoorixRevenueUsd: json.zoorix_revenue_usd,
    totalOrders: json.total_orders,
    payments: json.payments,
    ordersGenByZoorix: json.orders_gen_by_zoorix,
    productsGenByZoorix: json.products_gen_by_zoorix,
    appType,
});

export const initAnalMappings = json => ({
    type: INIT_ANAL_MAPPINGS,
    json,
});

export const initAnalBundles = (json, appType) => ({
    type: INIT_ANAL_BUNDLES,
    json,
    appType,
});

export const initAnalVolumeDiscounts = json => ({
    type: INIT_ANAL_VOLUME_DISCOUNTS,
    json,
});

export const toggleSelectDateActive = () => ({
   type: TOGGLE_SELECT_DATE_ACTIVE,
});

export const analyticsMinitabClicked = tabName => ({
   type: ANALYTICS_MINITAB_CLICKED,
    tabName,
});

export const startApplyDateRange = () => ({
   type: START_APPLY_DATE_RANGE,
});

export const analyticsToggleExportModal = () => ({
   type: ANALYTICS_TOGGLE_EXPORT_MODAL,
});

export const analyticsDismissToast = () => ({
   type: ANALYTICS_DISMISS_TOAST,
});

export const dateRangePresetChange = preset => ({
    type: DATE_RANGE_PRESET_CHANGE,
    preset,
});

export const analyticsExporting = bool => ({
    type: ANALYTICS_EXPORTING,
    bool,
});

export const analyticsToggleMapping = id => ({
    type: ANALYTICS_TOGGLE_MAPPING,
    id,
});

export const analyticsToggleBundle = id => ({
    type: ANALYTICS_TOGGLE_BUNDLE,
    id,
});

export const analyticsToggleVolume = id => ({
    type: ANALYTICS_TOGGLE_VOLUME,
    id,
});

export const openOrder = id => ({
    type: OPEN_ORDER,
    id,
});

export const updateAnalytics = json => ({
    type: UPDATE_ANALYTICS,
    currency: json.currency,
    multiCurrency: json.multi_currency,
    ordersGraph: json.orders_graph,
    revenueGraph: json.revenue_graph,
    products: json.products,
    totalSales: json.total_sales,
    totalZoorixSales: json.total_zoorix_sales,
    zoorixRevenue: json.zoorix_revenue,
    zoorixRevenueUsd: json.zoorix_revenue_usd,
    totalOrders: json.total_orders,
    payments: json.payments,
    ordersGenByZoorix: json.orders_gen_by_zoorix,
    productsGenByZoorix: json.products_gen_by_zoorix,
});

export const dateRangeChange = range => ({
    type: DATE_RANGE_CHANGE,
    range,
});

export const analyticsToast = text => ({
    type: ANALYTICS_TOAST,
    text,
});

export const dateRangeMonthChange = (month, year) => ({
    type: DATE_RANGE_MONTH_CHANGE,
    month,
    year,
});

export const sortProducts = (index, direction) => ({
    type: SORT_PRODUCTS,
    index,
    direction,
});

export function applyDateRange() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(startApplyDateRange());
        fetch(`https://${host}/data/get_anal_data_v2?shop_name=${state.data.shopName}&token=${state.data.token}&start_date=${state.analytics.dateRangeSelectedDates.start.toISOString()}&end_date=${state.analytics.dateRangeSelectedDates.end.toISOString()}${state.data.appType ? `&app_type=${state.data.appType}` : ''}`)
            .then(response => response.json())
            .then(json => dispatch(updateAnalytics(json)));
        fetch(`https://${host}/data/get_anal_mappings?shop_name=${state.data.shopName}&token=${state.data.token}&start_date=${state.analytics.dateRangeSelectedDates.start.toISOString()}&end_date=${state.analytics.dateRangeSelectedDates.end.toISOString()}${state.data.appType ? `&app_type=${state.data.appType}` : ''}`)
            .then(resp => resp.json())
            .then(json => dispatch(initAnalMappings(json)));
        fetch(`https://${host}/data/get_anal_bundles?shop_name=${state.data.shopName}&token=${state.data.token}&start_date=${state.analytics.dateRangeSelectedDates.start.toISOString()}&end_date=${state.analytics.dateRangeSelectedDates.end.toISOString()}${state.data.appType ? `&app_type=${state.data.appType}` : ''}`)
            .then(resp => resp.json())
            .then(json => dispatch(initAnalBundles(json, state.data.appType)));
        fetch(`https://${host}/data/get_anal_volume_discounts?shop_name=${state.data.shopName}&token=${state.data.token}&start_date=${state.analytics.dateRangeSelectedDates.start.toISOString()}&end_date=${state.analytics.dateRangeSelectedDates.end.toISOString()}${state.data.appType ? `&app_type=${state.data.appType}` : ''}`)
            .then(resp => resp.json())
            .then(json => dispatch(initAnalVolumeDiscounts(json)));
    }
}

export const analyticsExport = () => async (dispatch, getState) => {
    const state = getState();
    dispatch(analyticsExporting(true));
    const res = await postPayload('data/anal_export', {
        start_date: state.analytics.dateRangeSelectedDates.start.toISOString(),
        end_date: state.analytics.dateRangeSelectedDates.end.toISOString(),
        email: state.data.ownerEmail,
        displayed_values: state.analytics.displayedValues,
    }, state);
    if (res.ok) {
        dispatch(analyticsToast(`Export data will be sent to ${state.data.ownerEmail} in a few minutes`));
    } else {
        dispatch(analyticsToast(`Export failed`));
    }
    dispatch(analyticsExporting(false));
    dispatch(analyticsToggleExportModal());
}
