import * as _ from 'lodash';
import {
    CART_DRAWER_ADD_OFFER,
    CART_DRAWER_CLOSE_CHOOSE_PRODUCT,
    CART_DRAWER_DELETING_OFFER,
    CART_DRAWER_PRODUCT_SEARCH_SUCCESS,
    CART_DRAWER_SELECTING_OFFER_PRODUCT,
    CART_DRAWER_UPDATE_PRODUCT_SEARCH_TEXT,
    INIT_PRODUCTS_CART_DRAWER, MOVE_CART_DRAWER_ITEM
} from "../actions/productsCartDrawer";
//import {MOVE_MAPPING_ITEM} from "../actions/products";

const productsCartDrawer = (state = {}, action) => {
    let newState = null;
    switch (action.type) {
        case INIT_PRODUCTS_CART_DRAWER:
            newState = _.cloneDeep(state);
            newState.ready = true;
            newState.deletingOffer = false;
            newState.selectingOfferProduct = false;
            newState.offers = _.cloneDeep(action.json.minicart_offers);
            break;
        case CART_DRAWER_DELETING_OFFER:
            newState = _.cloneDeep(state);
            newState.deletingOffer = true;
            break;
        case CART_DRAWER_ADD_OFFER:
            newState = _.cloneDeep(state);
            newState.chooseProductOpen = true;
            break;
        case CART_DRAWER_CLOSE_CHOOSE_PRODUCT:
            newState = _.cloneDeep(state);
            newState.chooseProductOpen = false;
            break;
        case CART_DRAWER_SELECTING_OFFER_PRODUCT:
            newState = _.cloneDeep(state);
            newState.selectingOfferProduct = true;
            break;
        case CART_DRAWER_UPDATE_PRODUCT_SEARCH_TEXT:
            newState = _.cloneDeep(state);
            newState.searchBoxText = action.text;
            newState.showSearchSpinner = true;
            newState.searchedProducts = [];
            break;
        case CART_DRAWER_PRODUCT_SEARCH_SUCCESS:
            newState = _.cloneDeep(state);
            if (newState.searchBoxText === action.title) {
                newState.searchedProducts = _.cloneDeep(action.products);
                newState.showSearchSpinner = false;
            }
            break;
        case MOVE_CART_DRAWER_ITEM:
            newState = _.cloneDeep(state);
            if (action.direction === 'up') {
                [newState.offers[action.index - 1], newState.offers[action.index]] =
                    [newState.offers[action.index], newState.offers[action.index - 1]];
            } else if (action.direction === 'down') {
                [newState.offers[action.index + 1], newState.offers[action.index]] =
                    [newState.offers[action.index], newState.offers[action.index + 1]];
            }
            break;
        default:
            return state;
    }
    return newState;
};

export default productsCartDrawer;
