import React from 'react';
import '../../css/sticky-atc/StickyAtc.scss';
import {Button, Spinner, Card, Banner} from "@shopify/polaris";

const StickyAtcDashboard = ({stickyAtc, dismissUpdatePopup}) => {
    return <div className="sticky-add-to-cart-dashboard">
        {stickyAtc.updateAppPopupActive && <div className={'update-popup'}>
            <Banner onDismiss={dismissUpdatePopup} status={'warning'}>
                App is outdated. Please <Button onClick={() => window.open(stickyAtc.installUrl, '_parent')} plain={true}>click</Button> to update.
            </Banner>
        </div>}
        <Card.Section>
            <Card>
                <div className="card-wrapper">
                    <div className="embedded-app-link-wrapper">
                        <div className="embedded-app-link-title">App Status</div>
                        <div className="embedded-app-buttons-wrapper">
                            <Button plain
                                    onClick={() => window.open(`https://${stickyAtc.shop}/admin/themes/current/editor?context=apps`,
                                        '_blank')}>Enable / Disable Zoorix on the Shopify Editor</Button></div>
                    </div>
                </div>
            </Card>
        </Card.Section>

        <div className="sticky-add-to-cart-stats-wrapper">
            <div className="sticky-add-to-cart-stats-title">
                <span>Click Statistics</span>
            </div>
            <div className="sticky-add-to-cart-stats-subtitle">See how many store visitors clicked your bar.</div>
            <div className="sticky-add-to-cart-stats">
                <div className="sticky-add-to-cart-stats-item"><span className="sticky-add-to-cart-stats-item-label">Total:</span> {stickyAtc.dashboardData && stickyAtc.dashboardData.clicksTotal ?
                    <span className="sticky-add-to-cart-stats-item-count">{stickyAtc.dashboardData.clicksTotal}</span> :
                    <div className="sticky-add-to-cart-stats-spinner">

                        <Spinner size="small"/></div> }
                </div>
                <div className="sticky-add-to-cart-stats-item"><span className="sticky-add-to-cart-stats-item-label">Today:</span> {stickyAtc.dashboardData && stickyAtc.dashboardData.clicksToday ?
                    <span className="sticky-add-to-cart-stats-item-count">{stickyAtc.dashboardData.clicksToday}</span> :
                    <div className="sticky-add-to-cart-stats-spinner">
                        <Spinner size="small"/>
                    </div> }
                </div>
                <div className="sticky-add-to-cart-stats-item"><span className="sticky-add-to-cart-stats-item-label">Yesterday:</span>{stickyAtc.dashboardData && stickyAtc.dashboardData.clicksYesterday ?
                    <span
                        className="sticky-add-to-cart-stats-item-count">{stickyAtc.dashboardData.clicksYesterday}</span> :
                    <div className="sticky-add-to-cart-stats-spinner">

                        <Spinner size="small"/></div> }

                </div>
                <div className="sticky-add-to-cart-stats-item"><span className="sticky-add-to-cart-stats-item-label">Last 7 days:</span>{stickyAtc.dashboardData && stickyAtc.dashboardData.clicksLast7Days ?
                    <span
                        className="sticky-add-to-cart-stats-item-count">{stickyAtc.dashboardData.clicksLast7Days}</span> :
                    <div className="sticky-add-to-cart-stats-spinner">

                        <Spinner size="small"/></div> }
                </div>
                <div className="sticky-add-to-cart-stats-item"><span className="sticky-add-to-cart-stats-item-label">Last 30 days:</span>{stickyAtc.dashboardData && stickyAtc.dashboardData.clicksLast30Days ?
                    <span
                        className="sticky-add-to-cart-stats-item-count">{stickyAtc.dashboardData.clicksLast30Days}</span> :
                    <div className="sticky-add-to-cart-stats-spinner">

                        <Spinner size="small"/></div> }
                </div>
            </div>

            <div className="sticky-add-to-cart-partner">
                <div className="sticky-add-to-cart-partner-section-title">
                    <span>Partner Spotlight</span>
                </div>
                <div className="sticky-add-to-cart-partner-section-subtitle">Want to get more sales? check this app<span aria-label='yahh' role="img">👇</span></div>
                <div className="sticky-add-to-cart-zoorix-banner">

                    <div className="sticky-add-to-cart-zoorix-banner-inner">

                    <div className="sticky-add-to-cart-zoorix-banner-icon">
                        <figure
                            className="tw-rounded-sm tw-overflow-hidden tw-shrink-0 tw-relative after:tw-content-[''] after:tw-absolute after:tw-inset-0 after:tw-rounded-sm tw-transition-transform group-hover:tw-scale-[1.025] tw-w-appIcon--sm lg:tw-w-appIcon--lg after:tw-shadow-[inset_0_0_0_1px_rgba(0,0,0,0.1)]">

                            <img alt=""
                                srcSet="https://cdn.shopify.com/app-store/listing_images/878ee40e7b3300a1eea9cbb74df480fb/icon/CNDVz4mXhvYCEAE=.png?height=72&amp;width=72 1x, https://cdn.shopify.com/app-store/listing_images/878ee40e7b3300a1eea9cbb74df480fb/icon/CNDVz4mXhvYCEAE=.png?height=144&amp;width=144 2x"
                                className="tw-block tw-relative tw-shrink-0 tw-w-appIcon--sm lg:tw-w-appIcon--lg"
                                src="https://cdn.shopify.com/app-store/listing_images/878ee40e7b3300a1eea9cbb74df480fb/icon/CNDVz4mXhvYCEAE=.png"/>

                        </figure>
                    </div>

                    <div className="sticky-add-to-cart-zoorix-banner-text-wrapper">

                        <div className="sticky-add-to-cart-zoorix-app-title">Zoorix: Bundles & Cross Sell</div>
                            <div className="sticky-add-to-cart-zoorix-app-desc">
                                <div>
                                    5.0
                                    <span>
                                        <svg className="dashboard-status-partner-star" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none"><path d="M5.063 1.005c.324-.866 1.55-.866 1.874 0l.806 2.157a1 1 0 0 0 .893.649l2.3.1c.925.04 1.303 1.206.58 1.782L9.713 7.126a1 1 0 0 0-.34 1.05l.615 2.219c.247.891-.745 1.612-1.516 1.101l-1.92-1.27a1 1 0 0 0-1.104 0l-1.92 1.27c-.771.51-1.763-.21-1.516-1.101l.616-2.219a1 1 0 0 0-.341-1.05L.484 5.693c-.723-.576-.345-1.742.58-1.782l2.3-.1a1 1 0 0 0 .893-.65l.806-2.156Z" fill="#78726D"></path></svg>
                                    </span>
                                    (574) . Free plan available<br/>
                                </div>
                                <div className="sticky-add-to-cart-zoorix-app-features">Bundles, Upsell, Cross-Sell, BOGO, Frequently Brought Together</div>
                            </div>
                            <div className="dashboard-status-partner-cta" onClick={() => { window.open('https://apps.shopify.com/zoorix') }}>View</div>

                        </div>

                    </div>

                </div>
            </div>

        </div>
    </div>
};

export default StickyAtcDashboard;
