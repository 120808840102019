import { connect } from 'react-redux';
import SettingsCartDrawer from "../../components/settings/SettingsCartDrawer";
import {
    cartDrawerSettingsBoolChange,
    checkoutButtonColorChange,
    dismissToast,
    floatingIconBackgroundColorChange,
    floatingIconColorChange,
    freeDiscountAmountChange,
    freeDiscountThresholdChange,
    freeShippingThresholdChange,
    incentiveChange,
    invokeFromHeaderChange,
    leftToSpendColorChange,
    miniCartDisplayOnHomePageChange,
    miniCartNoteChange, miniCartOneClickOfferOnEmptyCartChange,
    miniCartOpenAfterAdcChange,
    miniCartViewCartColorChange,
    promoBarColorChange,
    restoreCartDrawerToDefaults,
    save,
    showButtonsChange,
} from "../../actions/settingsCartDrawer";
import {addAlphaToColor} from "./VisibleSettingsMain";

const mapStateToProps = state => ({
    settings: state.settingsCartDrawer.settings,
    toastIsActive: state.settingsCartDrawer.toastIsActive,
    saveInProgress: state.settingsCartDrawer.saveInProgress,
    saveError: state.settingsCartDrawer.saveError,
    currencySymbol: state.settingsCartDrawer.currencySymbol,
    planName: state.settingsCartDrawer.planName,
    incentive: state.settingsCartDrawer.incentive,
    buttonOption: state.settingsCartDrawer.buttonOption,
});

const mapDispatchToProps = (dispatch) => ({
    onInvokeFromHeaderChange: bool => dispatch(invokeFromHeaderChange(bool)),
    onBoolChange: attr => text => dispatch(cartDrawerSettingsBoolChange(attr, text)),
    onFreeDiscountThresholdChange: num => dispatch(freeDiscountThresholdChange(num)),
    onFreeShippingThresholdChange: num => dispatch(freeShippingThresholdChange(num)),
    onFreeDiscountAmountChange: num => dispatch(freeDiscountAmountChange(num)),
    onIncentiveChange: text => dispatch(incentiveChange(text)),
    onShowButtonsChange: text => dispatch(showButtonsChange(text)),
    onDisplayOnHomePageChange: text => dispatch(miniCartDisplayOnHomePageChange(text)),
    onOpenAfterAdcChange: text => dispatch(miniCartOpenAfterAdcChange(text)),
    onNoteChange: text => dispatch(miniCartNoteChange(text)),
    onOneClickOfferOnEmptyCartChange: text => dispatch(miniCartOneClickOfferOnEmptyCartChange(text)),
    onPromoBarColorChange: color => dispatch(promoBarColorChange(addAlphaToColor(color))),
    onLeftToSpendColorChange: color => dispatch(leftToSpendColorChange(addAlphaToColor(color))),
    onCheckoutButtonColorChange: color => dispatch(checkoutButtonColorChange(addAlphaToColor(color))),
    onFloatingIconBackgroundColorChange: color => dispatch(floatingIconBackgroundColorChange(addAlphaToColor(color))),
    onFloatingIconColorChange: color => dispatch(floatingIconColorChange(addAlphaToColor(color))),
    onMiniCartViewCartColorChange: color => dispatch(miniCartViewCartColorChange(addAlphaToColor(color))),
    onDismissToast: () => dispatch(dismissToast()),
    save: () => dispatch(save()),
    restoreToDefaults: () => dispatch(restoreCartDrawerToDefaults()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettingsCartDrawer);
