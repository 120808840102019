import {connect} from 'react-redux'
import Upgrade from "../components/Upgrade";
import {upgradeToZoorixM} from "../actions/nav";

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    upgrade: () => dispatch(upgradeToZoorixM()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Upgrade);
