import fetch from "cross-fetch";
import {host} from "./data";
import * as _ from "lodash";

export const INIT_WARRANTY_MODERATION = 'INIT_WARRANTY_MODERATION';
export const WARRANTY_MODERATION_TYPE_START = 'WARRANTY_MODERATION_TYPE_START';
export const ON_WARRANTY_MODERATION_VIEW_REQUEST = 'ON_WARRANTY_MODERATION_VIEW_REQUEST';
export const REJECT_WARRANTY_REDEMPTION_REQUEST = 'REJECT_WARRANTY_REDEMPTION_REQUEST';
export const ACCEPT_WARRANTY_REDEMPTION_REQUEST = 'ACCEPT_WARRANTY_REDEMPTION_REQUEST';
export const POPULATE_WARRANTY_MODERATION_ITEMS = 'POPULATE_WARRANTY_MODERATION_ITEMS';
export const POPULATE_WARRANTY_MODERATION_ORDER_VARIANTS = 'POPULATE_WARRANTY_MODERATION_ORDER_VARIANTS';
export const WARRANTY_MODERATION_VIEW_ORDER = 'WARRANTY_MODERATION_VIEW_ORDER';
export const WARRANTY_MODERATION_FILTER_ERROR = 'WARRANTY_MODERATION_FILTER_ERROR';
export const WARRANTY_MODERATION_SELECT_VARIANT_ERROR = 'WARRANTY_MODERATION_SELECT_VARIANT_ERROR';
export const WARRANTY_MODERATION_CREATE_MANUAL_ERROR = 'WARRANTY_MODERATION_CREATE_MANUAL_ERROR';
export const WARRANTY_MODERATION_REJECT_ERROR = 'WARRANTY_MODERATION_REJECT_ERROR';
export const WARRANTY_MODERATION_ACCEPT_ERROR = 'WARRANTY_MODERATION_ACCEPT_ERROR';
export const WARRANTY_MODERATION_REJECT_SUCCESS = 'WARRANTY_MODERATION_REJECT_SUCCESS';
export const WARRANTY_MODERATION_ACCEPT_SUCCESS = 'WARRANTY_MODERATION_ACCEPT_SUCCESS';
export const WARRANTY_MODERATION_VIEW_ORDER_START = 'WARRANTY_MODERATION_VIEW_ORDER_START';
export const WARRANTY_MODERATION_PREVIOUS_PAGE = 'WARRANTY_MODERATION_PREVIOUS_PAGE';
export const WARRANTY_MODERATION_NEXT_PAGE = 'WARRANTY_MODERATION_NEXT_PAGE';
export const WARRANTY_MODERATION_FILTER_START = 'WARRANTY_MODERATION_FILTER_START';
export const WARRANTY_MODERATION_SELECT_VARIANT_START = 'WARRANTY_MODERATION_SELECT_VARIANT_START';
export const WARRANTY_MODERATION_CREATE_MANUAL_START = 'WARRANTY_MODERATION_CREATE_MANUAL_START';
export const WARRANTY_MODERATION_REJECT_START = 'WARRANTY_MODERATION_REJECT_START';
export const WARRANTY_MODERATION_ACCEPT_START = 'WARRANTY_MODERATION_ACCEPT_START';
export const WARRANTY_MODERATION_CLOSE_ORDER_MODAL = 'WARRANTY_MODERATION_CLOSE_ORDER_MODAL';
export const WARRANTY_MODERATION_CLOSE_REQUEST_MODAL = 'WARRANTY_MODERATION_CLOSE_REQUEST_MODAL';
export const WARRANTY_MODERATION_CLOSE_REJECT_MODAL = 'WARRANTY_MODERATION_CLOSE_REJECT_MODAL';
export const WARRANTY_MODERATION_CLOSE_ACCEPT_MODAL = 'WARRANTY_MODERATION_CLOSE_ACCEPT_MODAL';
export const WARRANTY_MODERATION_DISMISS_TOAST = 'WARRANTY_MODERATION_DISMISS_TOAST';
export const WARRANTY_MODERATION_CREATE_MANUAL = 'WARRANTY_MODERATION_CREATE_MANUAL';
export const WARRANTY_MODERATION_CLOSE_CREATE_MANUAL_MODAL = 'WARRANTY_MODERATION_CLOSE_CREATE_MANUAL_MODAL';
export const WARRANTY_MODERATION_CREATE_MANUAL_SUCCESS = 'WARRANTY_MODERATION_CREATE_MANUAL_SUCCESS';
export const WARRANTY_MODERATION_REJECT_REASON_CHANGE = 'WARRANTY_MODERATION_REJECT_REASON_CHANGE';
export const WARRANTY_MODERATION_DISCOUNT_AMOUNT_CHANGE = 'WARRANTY_MODERATION_DISCOUNT_AMOUNT_CHANGE';
export const WARRANTY_MODERATION_ORDER_NAME_CHANGE = 'WARRANTY_MODERATION_ORDER_NAME_CHANGE';
export const WARRANTY_MODERATION_ORDER_EMAIL_CHANGE = 'WARRANTY_MODERATION_ORDER_EMAIL_CHANGE';
export const WARRANTY_MODERATION_SELECTED_VARIANT_CHANGE = 'WARRANTY_MODERATION_SELECTED_VARIANT_CHANGE';

export const initWarrantyModeration = json => ({
    type: INIT_WARRANTY_MODERATION,
    currencySymbol: json.currency_symbol,
    defaultDiscount: json.warranty_settings.values.percentage,
    settings: json.warranty_settings,
});

export const warrantyModerationTypeStart = itemType => ({
    type: WARRANTY_MODERATION_TYPE_START,
    itemType,
});

export const onWarrantyModerationViewRequest = id => ({
    type: ON_WARRANTY_MODERATION_VIEW_REQUEST,
    id,
});

export const rejectWarrantyRedemptionRequest = id => ({
    type: REJECT_WARRANTY_REDEMPTION_REQUEST,
    id,
});

export const acceptWarrantyRedemptionRequest = id => ({
    type: ACCEPT_WARRANTY_REDEMPTION_REQUEST,
    id,
});

export const populateWarrantyModerationItems = json => ({
    type: POPULATE_WARRANTY_MODERATION_ITEMS,
    items: json.items,
    maxPage: json.max_page,
    itemType: json.type,
    page: json.page,
});

export const populateWarrantyModerationOrderVariants = json => ({
    type: POPULATE_WARRANTY_MODERATION_ORDER_VARIANTS,
    variants: json.variants,
    orderId: json.order_id,
    email: json.email,
});

export const warrantyModerationViewOrder = (json, id) => ({
    type: WARRANTY_MODERATION_VIEW_ORDER,
    json,
    id,
});

export const warrantyModerationFilterError = id => ({
    type: WARRANTY_MODERATION_FILTER_ERROR,
    id,
});

export const warrantyModerationSelectVariantError = () => ({
    type: WARRANTY_MODERATION_SELECT_VARIANT_ERROR,
});

export const warrantyModerationCreateManualError = () => ({
    type: WARRANTY_MODERATION_CREATE_MANUAL_ERROR,
});

export const warrantyModerationRejectError = () => ({
    type: WARRANTY_MODERATION_REJECT_ERROR,
});

export const warrantyModerationAcceptError = () => ({
    type: WARRANTY_MODERATION_ACCEPT_ERROR,
});

export const warrantyModerationRejectSuccess = () => ({
    type: WARRANTY_MODERATION_REJECT_SUCCESS,
});

export const warrantyModerationAcceptSuccess = () => ({
    type: WARRANTY_MODERATION_ACCEPT_SUCCESS,
});

export const warrantyModerationViewOrderStart = id => ({
    type: WARRANTY_MODERATION_VIEW_ORDER_START,
    id,
});

export const warrantyModerationPreviousPage = () => ({
    type: WARRANTY_MODERATION_PREVIOUS_PAGE,
});

export const warrantyModerationNextPage = () => ({
    type: WARRANTY_MODERATION_NEXT_PAGE,
});

export const warrantyModerationFilterStart = () => ({
    type: WARRANTY_MODERATION_FILTER_START,
});

export const warrantyModerationSelectVariantStart = () => ({
    type: WARRANTY_MODERATION_SELECT_VARIANT_START,
});

export const warrantyModerationCreateManualStart = () => ({
    type: WARRANTY_MODERATION_CREATE_MANUAL_START,
});

export const warrantyModerationRejectStart = () => ({
    type: WARRANTY_MODERATION_REJECT_START,
});

export const warrantyModerationAcceptStart = () => ({
    type: WARRANTY_MODERATION_ACCEPT_START,
});

export const warrantyModerationCloseOrderModal = () => ({
    type: WARRANTY_MODERATION_CLOSE_ORDER_MODAL,
});

export const warrantyModerationCloseRequestModal = () => ({
    type: WARRANTY_MODERATION_CLOSE_REQUEST_MODAL,
});

export const warrantyModerationCloseRejectModal = () => ({
    type: WARRANTY_MODERATION_CLOSE_REJECT_MODAL,
});

export const warrantyModerationCloseAcceptModal = () => ({
    type: WARRANTY_MODERATION_CLOSE_ACCEPT_MODAL,
});

export const warrantyModerationDismissToast = () => ({
    type: WARRANTY_MODERATION_DISMISS_TOAST,
});

export const warrantyModerationCreateManual = () => ({
    type: WARRANTY_MODERATION_CREATE_MANUAL,
});

export const warrantyModerationCloseCreateManualModal = () => ({
    type: WARRANTY_MODERATION_CLOSE_CREATE_MANUAL_MODAL,
});

export const warrantyModerationCreateManualSuccess = () => ({
    type: WARRANTY_MODERATION_CREATE_MANUAL_SUCCESS,
});

export const warrantyModerationRejectReasonChange = text => ({
    type: WARRANTY_MODERATION_REJECT_REASON_CHANGE,
    text,
});

export const warrantyModerationDiscountAmountChange = text => ({
    type: WARRANTY_MODERATION_DISCOUNT_AMOUNT_CHANGE,
    text,
});

export const warrantyModerationOrderNameChange = text => ({
    type: WARRANTY_MODERATION_ORDER_NAME_CHANGE,
    text,
});

export const warrantyModerationOrderEmailChange = text => ({
    type: WARRANTY_MODERATION_ORDER_EMAIL_CHANGE,
    text,
});

export const warrantyModerationSelectedVariantChange = choice => ({
    type: WARRANTY_MODERATION_SELECTED_VARIANT_CHANGE,
    choice,
});

export function onWarrantyModerationPreviousPage() {
    return dispatch => {
        dispatch(warrantyModerationPreviousPage());
        dispatch(warrantyModerationFilter());
    }
}

export function onWarrantyModerationNextPage() {
    return dispatch => {
        dispatch(warrantyModerationNextPage());
        dispatch(warrantyModerationFilter());
    }
}

export function onWarrantyModerationTypeChange(type) {
    return dispatch => {
        dispatch(warrantyModerationTypeStart(type));
        dispatch(warrantyModerationFilter());
    }
}

function warrantyModerationFilter() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(warrantyModerationFilterStart());
        return fetch(`https://${host}/warranty_moderation/fetch_items`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    type: state.warrantyModeration.type,
                    page: state.warrantyModeration.page,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then(json => dispatch(populateWarrantyModerationItems(json)));
                } else {
                    dispatch(warrantyModerationFilterError());
                }
            });
    }
}

export function warrantyModerationSelectVariant() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(warrantyModerationSelectVariantStart());
        return fetch(`https://${host}/warranty_moderation/fetch_order_variants`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    order_name: state.warrantyModeration.orderName,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then(json => dispatch(populateWarrantyModerationOrderVariants(json)));
                } else {
                    dispatch(warrantyModerationSelectVariantError());
                }
            });
    }
}

export function warrantyModerationCreateManualAction() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(warrantyModerationCreateManualStart());
        const chosenItem = _.find(state.warrantyModeration.variants || [],
            {value: state.warrantyModeration.selectedVariants && state.warrantyModeration.selectedVariants[0]}) || {};

        return fetch(`https://${host}/warranty_moderation/create_manual`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    order_id: state.warrantyModeration.orderId,
                    email: state.warrantyModeration.orderEmail,
                    discount_amount: state.warrantyModeration.discountAmount,
                    item_name: `${chosenItem.product_title || ''} - ${chosenItem.variant_title || ''}`,
                    item_price: chosenItem.price,
                })
            })
            .then((response) => {
                if (response.ok) {
                    dispatch(warrantyModerationCloseCreateManualModal());
                    dispatch(warrantyModerationCreateManualSuccess());
                    dispatch(warrantyModerationFilter());
                } else {
                    dispatch(warrantyModerationCreateManualError());
                }
            });
    }
}

export function warrantyModerationRejectAction() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(warrantyModerationRejectStart());
        return fetch(`https://${host}/warranty_moderation/reject`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    reason: state.warrantyModeration.rejectReason,
                    item_id: state.warrantyModeration.rejectedItemId,
                })
            })
            .then((response) => {
                if (response.ok) {
                    dispatch(warrantyModerationFilter());
                    dispatch(warrantyModerationRejectSuccess());
                    dispatch(warrantyModerationCloseRejectModal());
                } else {
                    dispatch(warrantyModerationRejectError());
                }
            });
    }
}

export function warrantyModerationAcceptAction() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(warrantyModerationAcceptStart());
        return fetch(`https://${host}/warranty_moderation/accept`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    amount: state.warrantyModeration.discountAmount,
                    item_id: state.warrantyModeration.acceptedItemId,
                })
            })
            .then((response) => {
                if (response.ok) {
                    dispatch(warrantyModerationFilter());
                    dispatch(warrantyModerationAcceptSuccess());
                    dispatch(warrantyModerationCloseAcceptModal());
                } else {
                    dispatch(warrantyModerationAcceptError());
                }
            });
    }
}

export function onWarrantyModerationViewOrder(id) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(warrantyModerationViewOrderStart(id));
        return fetch(`https://${host}/warranty_moderation/view_order`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    item_id: id,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then(json => dispatch(warrantyModerationViewOrder(json, id)));
                } else {
                    dispatch(warrantyModerationFilterError(id));
                }
            });
    }
}
