import React from 'react';
import {
    Button,
    Frame,
    Form,
    FormLayout,
    Toast, Card, TextField, Select,
} from "@shopify/polaris";
import '../../css/MixAndMatch.scss';
import {ChromePicker} from "react-color";

const color = (mixAndMatch, changeColor, title, attr) => <div>
    <div className="mm-color-field-title">{title}</div>
    <ChromePicker color={mixAndMatch.settings.values[attr]}
                  onChangeComplete={changeColor(attr)} />
</div>

const openCollectionsToggleOnLoad = [
    { label: 'Open', value: 'yes' },
    { label: 'Close', value: 'no' },
];

const SettingsMixAndMatch = ({ mixAndMatch, onDismissToast, textChange, changeColor, save, numOfSearchedProductsChange,
                                 savePromoPageTitle, boolChange }) =>
        <Frame>
            <Form>
                <FormLayout>
                    <div className="zrx-tab-wrapper-settings">
                        <div className="zrx-tab-title">Mix & Match Settings</div>
                        <div className="zrx-tab-subtitle">Configuration and customizations of the mix & match offers</div>
                    </div>
                    <div className="form-card">
                        <Card title={'Offer widget'}>
                            <TextField label="Offer title font size:" value={mixAndMatch.settings.values.title_font_size}
                                       onChange={textChange('title_font_size')}
                                       disabled={mixAndMatch.saveInProgress} autoComplete={false} />
                            <TextField label="Number of collection items shown:" min={1} max={12}
                                       value={mixAndMatch.settings.values.num_of_searched_products.toString()}
                                       onChange={numOfSearchedProductsChange} type={'number'}
                                       disabled={mixAndMatch.saveInProgress} autoComplete={false} />
                            {color(mixAndMatch, changeColor, 'Offer title Color:', 'title_color')}
                            <TextField label="Offer subtitle font size:" value={mixAndMatch.settings.values.subtitle_font_size}
                                       onChange={textChange('subtitle_font_size')}
                                       disabled={mixAndMatch.saveInProgress} autoComplete={false} />
                            {color(mixAndMatch, changeColor, 'Offer subtitle color:', 'subtitle_color')}
                            {color(mixAndMatch, changeColor, 'Go to bundle builder background color:', 'go_to_bundle_builder_background_color')}
                            {color(mixAndMatch, changeColor, 'Go to bundle builder text color:', 'go_to_bundle_builder_text_color')}
                        </Card>
                        <Card title={'Bundle builder'}>
                            <div>
                                <TextField label={'Promo page title'} value={mixAndMatch.settings.values.mix_and_match_promo_page_title}
                                           onChange={textChange('mix_and_match_promo_page_title')} disabled={mixAndMatch.savingPromoPageTitle}
                                           autoComplete={false} />
                                <Button onClick={savePromoPageTitle} loading={mixAndMatch.savingPromoPageTitle}>Save title</Button>
                            </div>
                            <Select label="Open collections dropdown on load:" options={openCollectionsToggleOnLoad}
                                    onChange={boolChange('open_collections_toggle_on_load')}
                                    value={mixAndMatch.settings.values.open_collections_toggle_on_load ? 'yes' : 'no'}
                                    disabled={mixAndMatch.savingPromoPageTitle} />
                            {color(mixAndMatch, changeColor, 'Collection item add-to-cart button background color:', 'item_add_to_cart_button_background_color')}
                            {color(mixAndMatch, changeColor, 'Collection item add-to-cart button text color:', 'item_add_to_cart_button_text_color')}
                            {color(mixAndMatch, changeColor, 'Bundle add-to-cart button background color:', 'bundle_add_to_cart_button_background_color')}
                            {color(mixAndMatch, changeColor, 'Bundle add-to-cart button text color:', 'bundle_add_to_cart_button_text_color')}
                            {color(mixAndMatch, changeColor, 'Bundle builder save text color:', 'bundle_builder_save_text_color')}
                            {color(mixAndMatch, changeColor, 'Bundle builder save background color:', 'bundle_builder_save_bg_color')}
                        </Card>
                        <Card title={'Discount banner'}>
                            {color(mixAndMatch, changeColor, 'Discount banner background color:', 'discount_banner_background_color')}
                            {color(mixAndMatch, changeColor, 'Discount banner text color:', 'discount_banner_text_color')}
                        </Card>
                        <Card title={'Prices'}>
                            {color(mixAndMatch, changeColor, 'Original price text color:', 'original_price_text_color')}
                            {color(mixAndMatch, changeColor, 'Discounted price text color:', 'discounted_price_text_color')}
                        </Card>

                    </div>
                    <span className={"save-button"}>
                        <Button id="save-settings" onClick={save} primary loading={mixAndMatch.saveInProgress}>Save</Button>
                    </span>
                    {mixAndMatch.toastIsActive && <Toast content={mixAndMatch.toastContent} onDismiss={onDismissToast} />}
                </FormLayout>
            </Form>
        </Frame>;

export default SettingsMixAndMatch;
