import {closeWizard, postPayload} from "../data";
import * as _ from "lodash";
import uuid4 from "uuid4";
import {postResync} from "../status";

export const INITIALIZE_APP_V2_WIZARD = 'INITIALIZE_APP_V2_WIZARD';
export const APP_V2_WIZARD_NEXT = 'APP_V2_WIZARD_NEXT';
export const WIZARD_DONE_LOADING = 'WIZARD_DONE_LOADING';
export const APP_V2_WIZARD_BACK = 'APP_V2_WIZARD_BACK';
export const WIZARD_CLOSE_ENABLE_ZOORIX_MODAL = 'WIZARD_CLOSE_ENABLE_ZOORIX_MODAL';
export const TOGGLE_CONTACT_US_MODAL = 'TOGGLE_CONTACT_US_MODAL';
export const TOGGLE_SELECT_PRODUCTS_MODAL = 'TOGGLE_SELECT_PRODUCTS_MODAL';
export const TOGGLE_CROSS_SELL_SELECT_PRODUCTS_MODAL = 'TOGGLE_CROSS_SELL_SELECT_PRODUCTS_MODAL';
export const TOGGLE_SELECT_COLLECTIONS_MODAL = 'TOGGLE_SELECT_COLLECTIONS_MODAL';
export const TOGGLE_CROSS_SELL_SELECT_COLLECTIONS_MODAL = 'TOGGLE_CROSS_SELL_SELECT_COLLECTIONS_MODAL';
export const TOGGLE_MNM_SELECT_COLLECTIONS_MODAL = 'TOGGLE_MNM_SELECT_COLLECTIONS_MODAL';
export const TOGGLE_SELECT_TAGS_MODAL = 'TOGGLE_SELECT_TAGS_MODAL';
export const TOGGLE_CROSS_SELL_SELECT_TAGS_MODAL = 'TOGGLE_CROSS_SELL_SELECT_TAGS_MODAL';
export const APPROVE_SELECTED_PRODUCTS = 'APPROVE_SELECTED_PRODUCTS';
export const APPROVE_CROSS_SELL_SELECTED_PRODUCTS = 'APPROVE_CROSS_SELL_SELECTED_PRODUCTS';
export const APPROVE_SELECTED_COLLECTIONS = 'APPROVE_SELECTED_COLLECTIONS';
export const APPROVE_CROSS_SELL_SELECTED_COLLECTIONS = 'APPROVE_CROSS_SELL_SELECTED_COLLECTIONS';
export const APPROVE_MNM_SELECTED_COLLECTIONS = 'APPROVE_MNM_SELECTED_COLLECTIONS';
export const APPROVE_SELECTED_TAGS = 'APPROVE_SELECTED_TAGS';
export const APPROVE_CROSS_SELL_SELECTED_TAGS = 'APPROVE_CROSS_SELL_SELECTED_TAGS';
export const WIZARD_VOLUME_ADD_TIER = 'WIZARD_VOLUME_ADD_TIER';
export const SELECT_OFFER_TYPE = 'SELECT_OFFER_TYPE';
export const SELECT_BUNDLE_TYPE = 'SELECT_BUNDLE_TYPE';
export const WIZARD_SELECT_MNM_DISCOUNT_TYPE = 'WIZARD_SELECT_MNM_DISCOUNT_TYPE';
export const SELECT_PAGE_TYPE = 'SELECT_PAGE_TYPE';
export const SELECT_TRIGGER = 'SELECT_TRIGGER';
export const SELECT_CONTENT_TYPE = 'SELECT_CONTENT_TYPE';
export const UPDATE_PRODUCT_SEARCH_TEXT = 'UPDATE_PRODUCT_SEARCH_TEXT';
export const UPDATE_COLLECTION_SEARCH_TEXT = 'UPDATE_COLLECTION_SEARCH_TEXT';
export const UPDATE_TAG_SEARCH_TEXT = 'UPDATE_TAG_SEARCH_TEXT';
export const WIZARD_BUNDLE_AMOUNT_CHANGE = 'WIZARD_BUNDLE_AMOUNT_CHANGE';
export const WIZARD_MNM_DISCOUNT_AMOUNT_CHANGE = 'WIZARD_MNM_DISCOUNT_AMOUNT_CHANGE';
export const WIZARD_MNM_MIN_QTY_CHANGE = 'WIZARD_MNM_MIN_QTY_CHANGE';
export const WIZARD_MNM_TRASH_PRODUCT_GROUP = 'WIZARD_MNM_TRASH_PRODUCT_GROUP';
export const WIZARD_VOLUME_QTY_CHANGE = 'WIZARD_VOLUME_QTY_CHANGE';
export const WIZARD_VOLUME_DISCOUNT_TYPE_CHANGE = 'WIZARD_VOLUME_DISCOUNT_TYPE_CHANGE';
export const WIZARD_VOLUME_DISCOUNT_CHANGE = 'WIZARD_VOLUME_DISCOUNT_CHANGE';
export const WIZARD_VOLUME_POPULAR_CHANGE = 'WIZARD_VOLUME_POPULAR_CHANGE';
export const WIZARD_VOLUME_TRASH_TIER = 'WIZARD_VOLUME_TRASH_TIER';
export const SET_SEARCHED_PRODUCTS = 'SET_SEARCHED_PRODUCTS';
export const WIZARD_SET_TRIGGER_STAGE_UUID = 'WIZARD_SET_TRIGGER_STAGE_UUID';
export const WIZARD_SET_OFFER_STAGE_UUID = 'WIZARD_SET_OFFER_STAGE_UUID';
export const WIZARD_SET_TRIGGER_STAGE_READY = 'WIZARD_SET_TRIGGER_STAGE_READY';
export const WIZARD_SET_OFFER_STAGE_READY = 'WIZARD_SET_OFFER_STAGE_READY';
export const SET_BUNDLE_SEARCHED_PRODUCTS = 'SET_BUNDLE_SEARCHED_PRODUCTS';
export const SET_SEARCHED_COLLECTIONS = 'SET_SEARCHED_COLLECTIONS';
export const SET_ALL_TAGS = 'SET_ALL_TAGS';
export const SET_SEARCHED_TAGS = 'SET_SEARCHED_TAGS';
export const TOGGLE_SELECT_PRODUCT = 'TOGGLE_SELECT_PRODUCT';
export const TOGGLE_SELECT_COLLECTION = 'TOGGLE_SELECT_COLLECTION';
export const TOGGLE_SELECT_TAG = 'TOGGLE_SELECT_TAG';
export const REMOVE_SELECTED_PRODUCT = 'REMOVE_SELECTED_PRODUCT';
export const REMOVE_SELECTED_COLLECTION = 'REMOVE_SELECTED_COLLECTION';
export const REMOVE_SELECTED_TAG = 'REMOVE_SELECTED_TAG';
export const WIZARD_BUNDLE_SELECT_PRODUCT = 'WIZARD_BUNDLE_SELECT_PRODUCT';
export const WIZARD_CHOOSE_BUNDLE_PRODUCT = 'WIZARD_CHOOSE_BUNDLE_PRODUCT';
export const WIZARD_BUNDLE_REMOVE_PRODUCT = 'WIZARD_BUNDLE_REMOVE_PRODUCT';
export const SENDING_SUPPORT_REQUEST = 'SENDING_SUPPORT_REQUEST';
export const WIZARD_SET_INVALID_SELECT_PAGE = 'WIZARD_SET_INVALID_SELECT_PAGE';
export const WIZARD_SET_INVALID_SELECT_TRIGGER = 'WIZARD_SET_INVALID_SELECT_TRIGGER';
export const WIZARD_SET_INVALID_SELECT_OFFER = 'WIZARD_SET_INVALID_SELECT_OFFER';
export const WIZARD_BUNDLE_CLOSE_SELECT_PRODUCTS_MODAL = 'WIZARD_BUNDLE_CLOSE_SELECT_PRODUCTS_MODAL';
export const WIZARD_BUNDLE_ADD_PRODUCT = 'WIZARD_BUNDLE_ADD_PRODUCT';
export const WIZARD_STATUS = 'WIZARD_STATUS';
export const WIZARD_PREVIEW_READY = 'WIZARD_PREVIEW_READY';
export const WIZARD_CREATE_ANOTHER_OFFER = 'WIZARD_CREATE_ANOTHER_OFFER';
export const WIZARD_COLLECTIONS_ARE_UPLOADED = 'WIZARD_COLLECTIONS_ARE_UPLOADED';
export const WIZARD_SET_CREATE_STORE_JS_UUID = 'WIZARD_SET_CREATE_STORE_JS_UUID';
export const WIZARD_PREVIEW_PROGRESS = 'WIZARD_PREVIEW_PROGRESS';
export const FETCH_PRODUCTS_COMPLETE = 'FETCH_PRODUCTS_COMPLETE';

export const initializeAppV2Wizard = json => ({
    type: INITIALIZE_APP_V2_WIZARD,
    json,
});

export const appV2WizardNext = () => ({
    type: APP_V2_WIZARD_NEXT,
});

export const wizardDoneLoading = () => ({
    type: WIZARD_DONE_LOADING,
});

export const appV2WizardBack = () => ({
    type: APP_V2_WIZARD_BACK,
});

export const wizardCloseEnableZoorixModal = () => ({
    type: WIZARD_CLOSE_ENABLE_ZOORIX_MODAL,
});

export const toggleContactUsModal = () => ({
    type: TOGGLE_CONTACT_US_MODAL,
});

export const toggleSelectProductsModal = () => ({
    type: TOGGLE_SELECT_PRODUCTS_MODAL,
});

export const toggleCrossSellSelectProductsModal = () => ({
    type: TOGGLE_CROSS_SELL_SELECT_PRODUCTS_MODAL,
});

export const toggleSelectCollectionsModal = () => ({
    type: TOGGLE_SELECT_COLLECTIONS_MODAL,
});

export const toggleCrossSellSelectCollectionsModal = () => ({
    type: TOGGLE_CROSS_SELL_SELECT_COLLECTIONS_MODAL,
});

export const toggleMnmSelectCollectionsModal = () => ({
    type: TOGGLE_MNM_SELECT_COLLECTIONS_MODAL,
});

export const toggleSelectTagsModal = () => ({
    type: TOGGLE_SELECT_TAGS_MODAL,
});

export const toggleCrossSellSelectTagsModal = () => ({
    type: TOGGLE_CROSS_SELL_SELECT_TAGS_MODAL,
});

export const approveSelectedProducts = () => ({
    type: APPROVE_SELECTED_PRODUCTS,
});

export const approveCrossSellSelectedProducts = () => ({
    type: APPROVE_CROSS_SELL_SELECTED_PRODUCTS,
});

export const approveSelectedCollections = () => ({
    type: APPROVE_SELECTED_COLLECTIONS,
});

export const approveCrossSellSelectedCollections = () => ({
    type: APPROVE_CROSS_SELL_SELECTED_COLLECTIONS,
});

export const approveMnmSelectedCollections = () => ({
    type: APPROVE_MNM_SELECTED_COLLECTIONS,
});

export const approveSelectedTags = () => ({
    type: APPROVE_SELECTED_TAGS,
});

export const approveCrossSellSelectedTags = () => ({
    type: APPROVE_CROSS_SELL_SELECTED_TAGS,
});

export const wizardVolumeAddTier = () => ({
    type: WIZARD_VOLUME_ADD_TIER,
});

export const selectOfferType = offerType => ({
    type: SELECT_OFFER_TYPE,
    offerType,
});

export const selectBundleType = bundleType => ({
    type: SELECT_BUNDLE_TYPE,
    bundleType,
});

export const wizardSelectMnmDiscountType = _type => ({
    type: WIZARD_SELECT_MNM_DISCOUNT_TYPE,
    _type,
});

export const selectPageType = pageType => ({
    type: SELECT_PAGE_TYPE,
    pageType,
});

export const selectTrigger = triggerType => ({
    type: SELECT_TRIGGER,
    triggerType,
});

export const selectContentType = contentType => ({
    type: SELECT_CONTENT_TYPE,
    contentType,
});

export const updateProductSearchText = text => ({
    type: UPDATE_PRODUCT_SEARCH_TEXT,
    text,
});

export const updateCollectionSearchText = text => ({
    type: UPDATE_COLLECTION_SEARCH_TEXT,
    text,
});

export const updateTagSearchText = text => ({
    type: UPDATE_TAG_SEARCH_TEXT,
    text,
});

export const wizardBundleAmountChange = text => ({
    type: WIZARD_BUNDLE_AMOUNT_CHANGE,
    text,
});

export const wizardMnmDiscountAmountChange = text => ({
    type: WIZARD_MNM_DISCOUNT_AMOUNT_CHANGE,
    text,
});

export const wizardMnmMinQtyChange = (idx, text) => ({
    type: WIZARD_MNM_MIN_QTY_CHANGE,
    idx,
    text,
});

export const wizardMnmTrashProductGroup = (idx) => ({
    type: WIZARD_MNM_TRASH_PRODUCT_GROUP,
    idx,
});

export const wizardVolumeQtyChange = (idx, qty) => ({
    type: WIZARD_VOLUME_QTY_CHANGE,
    idx,
    qty,
});

export const wizardVolumeDiscountTypeChange = (idx, text) => ({
    type: WIZARD_VOLUME_DISCOUNT_TYPE_CHANGE,
    idx,
    text,
});

export const wizardVolumeDiscountChange = (idx, text) => ({
    type: WIZARD_VOLUME_DISCOUNT_CHANGE,
    idx,
    text,
});

export const wizardVolumePopularChange = (idx, bool) => ({
    type: WIZARD_VOLUME_POPULAR_CHANGE,
    idx,
    bool,
});

export const wizardVolumeTrashTier = idx => ({
    type: WIZARD_VOLUME_TRASH_TIER,
    idx,
});

export const setSearchedProducts = json => ({
    type: SET_SEARCHED_PRODUCTS,
    json,
});

export const wizardSetTriggerStageUuid = uuid => ({
    type: WIZARD_SET_TRIGGER_STAGE_UUID,
    uuid,
});

export const wizardSetOfferStageUuid = uuid => ({
    type: WIZARD_SET_OFFER_STAGE_UUID,
    uuid,
});

export const wizardSetTriggerStageReady = (uuid, handle) => ({
    type: WIZARD_SET_TRIGGER_STAGE_READY,
    uuid,
    handle,
});

export const wizardSetOfferStageReady = (uuid, handle) => ({
    type: WIZARD_SET_OFFER_STAGE_READY,
    uuid,
    handle,
});

export const setBundleSearchedProducts = json => ({
    type: SET_BUNDLE_SEARCHED_PRODUCTS,
    json,
});

export const setSearchedCollections = json => ({
    type: SET_SEARCHED_COLLECTIONS,
    json,
});

export const setSearchedTags = edges => ({
    type: SET_SEARCHED_TAGS,
    edges,
});

export const setAllTags = json => ({
    type: SET_ALL_TAGS,
    json,
});

export const toggleSelectProduct = id => ({
    type: TOGGLE_SELECT_PRODUCT,
    id,
});

export const toggleSelectCollection = id => ({
    type: TOGGLE_SELECT_COLLECTION,
    id,
});

export const toggleSelectTag = id => ({
    type: TOGGLE_SELECT_TAG,
    id,
});

export const removeSelectedProduct = id => ({
    type: REMOVE_SELECTED_PRODUCT,
    id,
});

export const removeSelectedCollection = id => ({
    type: REMOVE_SELECTED_COLLECTION,
    id,
});

export const removeSelectedTag = id => ({
    type: REMOVE_SELECTED_TAG,
    id,
});

export const wizardBundleSelectProduct = id => ({
    type: WIZARD_BUNDLE_SELECT_PRODUCT,
    id,
});

export const wizardChooseBundleProduct = idx => ({
    type: WIZARD_CHOOSE_BUNDLE_PRODUCT,
    idx,
});

export const wizardBundleRemoveProduct = idx => ({
    type: WIZARD_BUNDLE_REMOVE_PRODUCT,
    idx,
});

export const sendingSupportRequest = bool => ({
    type: SENDING_SUPPORT_REQUEST,
    bool,
});

export const wizardSetInvalidSelectPage = bool => ({
    type: WIZARD_SET_INVALID_SELECT_PAGE,
    bool,
});

export const wizardSetInvalidSelectTrigger = bool => ({
    type: WIZARD_SET_INVALID_SELECT_TRIGGER,
    bool,
});

export const wizardSetInvalidSelectOffer = bool => ({
    type: WIZARD_SET_INVALID_SELECT_OFFER,
    bool,
});

export const wizardBundleCloseSelectProductsModal = () => ({
    type: WIZARD_BUNDLE_CLOSE_SELECT_PRODUCTS_MODAL,
});

export const wizardBundleAddProduct = () => ({
    type: WIZARD_BUNDLE_ADD_PRODUCT,
});

export const wizardStatus = text => ({
    type: WIZARD_STATUS,
    text,
});

export const wizardPreviewReady = () => ({
    type: WIZARD_PREVIEW_READY,
});

export const wizardCreateAnotherOffer = () => ({
    type: WIZARD_CREATE_ANOTHER_OFFER,
});

export const wizardCollectionsAreUploaded = () => ({
    type: WIZARD_COLLECTIONS_ARE_UPLOADED,
});

export const wizardSetCreateStoreJsUuid = uuid => ({
    type: WIZARD_SET_CREATE_STORE_JS_UUID,
    uuid,
});

export const wizardPreviewProgress = progress => ({
    type: WIZARD_PREVIEW_PROGRESS,
    progress,
});

const textChangeInnerFunction = _.debounce(dispatch => dispatch(selectProductsApi()), 1000);
export function onProductSearchTextChange(text) {
    return (dispatch, getState) => {
        dispatch(updateProductSearchText(text));
        textChangeInnerFunction(dispatch);
    }
}

const bundleTextChangeInnerFunction = _.debounce(dispatch => dispatch(searchBundleProductsApi()), 1000);
export function wizardBundleSelectProductTextChange(text) {
    return (dispatch, getState) => {
        dispatch(updateProductSearchText(text));
        bundleTextChangeInnerFunction(dispatch);
    }
}

const collectionTextChangeInnerFunction = _.debounce(dispatch => dispatch(searchCollectionsApi()), 1000);
export function collectionSearchTextChange(text) {
    return (dispatch, getState) => {
        dispatch(updateCollectionSearchText(text));
        collectionTextChangeInnerFunction(dispatch);
    }
}

export function tagSearchTextChange(text) {
    return (dispatch, getState) => {
        dispatch(updateTagSearchText(text));
        dispatch(searchTags());
    }
}

export function selectTriggerAndSearch(triggerType) {
    return (dispatch, getState) => {
        dispatch(selectTrigger(triggerType));
        const state = getState();
        switch (triggerType) {
            case 'specific_products':
                dispatch(updateProductSearchText(state.appV2Wizard.searchText || ''));
                textChangeInnerFunction(dispatch);
                break;
            case 'collections':
                dispatch(updateCollectionSearchText(state.appV2Wizard.searchText || ''));
                collectionTextChangeInnerFunction(dispatch);
                break;
            case 'tags':
                dispatch(updateTagSearchText(state.appV2Wizard.searchText || ''));
                dispatch(searchTags());
                break;
            default:
                break;
        }
    }
}

export function selectCrossSellContentAndSearch(contentType) {
    return (dispatch, getState) => {
        dispatch(selectContentType(contentType));
        const state = getState();
        switch (contentType) {
            case 'specific_products':
                dispatch(updateProductSearchText(state.appV2Wizard.searchText || ''));
                textChangeInnerFunction(dispatch);
                break;
            case 'collections':
                dispatch(updateCollectionSearchText(state.appV2Wizard.searchText || ''));
                collectionTextChangeInnerFunction(dispatch);
                break;
            case 'tags':
                dispatch(updateTagSearchText(state.appV2Wizard.searchText || ''));
                dispatch(searchTags());
                break;
            default:
                break;
        }
    }
}

export function selectProductsApi() {
    return async (dispatch, getState) => {
        const state = getState();
        const response = await postPayload('products/search', {
            text: state.appV2Wizard.searchText,
        }, state)
        if (response.ok) {
            const json = await response.json();
            dispatch(setSearchedProducts(json));
        } else {
            console.log('selectProductsApi failure');
        }
    }
}

export const wizardCheckIfReadyToCreateOffer = () => async (dispatch, getState) => {
    const state = getState();
    dispatch(wizardStatus(
        state.appV2Wizard.stage4FetchComplete ?
            (state.appV2Wizard.stage5FetchComplete ? 'Fetching products complete' : 'Fetching offer products')
            : (state.appV2Wizard.stage5FetchComplete ? 'Fetching trigger products' : 'Fetching trigger and offer products')));
    const ready = state.appV2Wizard.stage4FetchComplete && state.appV2Wizard.stage5FetchComplete;
    if (ready) {
        dispatch(wizardStatus('Creating offer'));
        if (state.appV2Wizard.offerType === 'cross_sell') {
            dispatch(wizardCreateCrossSell());
        } else if (state.appV2Wizard.offerType === 'volume_discount') {
            dispatch(wizardCreateVolumeDiscount());
        } else if (state.appV2Wizard.offerType === 'bundle') {
            dispatch(wizardCreateBundle());
        } else if (state.appV2Wizard.offerType === 'mix_and_match') {
            if (state.appV2Wizard.collectionsAreUploaded) {
                dispatch(wizardCreateMixAndMatch());
            } else {
                dispatch(wizardStatus('Fetching collections'));
                setTimeout(() => dispatch(wizardCheckIfReadyToCreateOffer()), 500);
            }
        }
    } else {
        setTimeout(() => dispatch(wizardCheckIfReadyToCreateOffer()), 500);
    }
}

export const adminTrack = (event, props, callback) => async (dispatch, getState) => {
    const state = getState();
    await postPayload('segment/track', {
        event,
        props,
    }, state)
    if (callback) callback();
}

export const wizardCreateStoreJsDone = uuid => async (dispatch, getState) => {
    const state = getState();
    if (state.appV2Wizard.createStoreJsUuid === uuid) {
        dispatch(wizardPreviewReady());
    }
}

export const wizardBundleAddProductAndChoose = () => async (dispatch, getState) => {
    await dispatch(wizardBundleAddProduct());
    const state = getState();
    dispatch(wizardChooseBundleProduct(state.appV2Wizard.bundle.products.length - 1));
    dispatch(searchBundleProductsApi());
}

export const wizardCreateStoreJsProgress = data => async (dispatch, getState) => {
    const state = getState();
    if (state.appV2Wizard.createStoreJsUuid === data.uuid) {
        dispatch(wizardPreviewProgress(data.progress));
    }
}

export const appV2WizardSelectOfferType = () => async (dispatch, getState) => {
    const state = getState();
    if (state.appV2Wizard.offerType === 'cross_sell' || state.appV2Wizard.offerType === 'volume_discount') {
        if (!['specific_products', 'collections', 'tags'].includes(state.appV2Wizard.triggerType)) {
            dispatch(selectTrigger('specific_products'));
        }
    } else if (state.appV2Wizard.offerType === 'bundle') {
        if (!['bundle_items', 'collections', 'tags'].includes(state.appV2Wizard.triggerType)) {
            dispatch(selectTrigger('bundle_items'));
        }
    } else if (state.appV2Wizard.offerType === 'mix_and_match') {
        if (!['mnm_items', 'collections'].includes(state.appV2Wizard.triggerType)) {
            dispatch(selectTrigger('mnm_items'));
        }
        dispatch(checkIfCollectionsAreUploaded(true));
    }
    dispatch(appV2WizardNext());
    dispatch(adminTrack('onboarding_wizard_offer_selected', {
        offer_type: state.appV2Wizard.offerType,
        trigger_type: state.appV2Wizard.triggerType,
    }));
}

export const checkIfCollectionsAreUploaded = (invokeJob) => async (dispatch, getState) => {
    const state = getState();
    let res = await postPayload('data/are_collections_uploaded', {}, state);
    if (res.ok) {
        const json = await res.json();
        if (json.collections_uploaded) {
            dispatch(wizardCollectionsAreUploaded());
        } else {
            if (invokeJob) {
                res = await postPayload('data/save_and_upload_collections', {}, state);
                console.log(`checkIfCollectionsAreUploaded save_and_upload_collections ok:${res.ok}`)
            }
            setTimeout(() => dispatch(checkIfCollectionsAreUploaded(false)), 1000)
        }
    } else {
        console.log('checkIfCollectionsAreUploaded fail')
    }
}

export const wizardValidateSelectPage = () => async (dispatch, getState) => {
    const state = getState();
    if (state.appV2Wizard.offerType === 'cross_sell') {
        if (state.appV2Wizard.pageType['product'] || state.appV2Wizard.pageType['cart']) {
            dispatch(adminTrack('onboarding_wizard_page_selected', {
                offer_type: state.appV2Wizard.offerType,
                page_type_product: state.appV2Wizard.pageType['product'],
                page_type_cart: state.appV2Wizard.pageType['cart'],
            }));
            dispatch(appV2WizardNext());
        } else {
            dispatch(wizardSetInvalidSelectPage(true));
        }
    } else if (state.appV2Wizard.offerType === 'volume_discount' || state.appV2Wizard.offerType === 'bundle' ||
        state.appV2Wizard.offerType === 'mix_and_match') {

        if (state.appV2Wizard.pageType['product-main'] || state.appV2Wizard.pageType['product-bottom'] ||
            state.appV2Wizard.pageType['cart-top'] || state.appV2Wizard.pageType['cart-bottom']) {
            dispatch(adminTrack('onboarding_wizard_page_selected', {
                offer_type: state.appV2Wizard.offerType,
                page_type_product_main: state.appV2Wizard.pageType['product-main'],
                page_type_product_bottom: state.appV2Wizard.pageType['product-bottom'],
                page_type_cart_top: state.appV2Wizard.pageType['cart-top'],
                page_type_cart_bottom: state.appV2Wizard.pageType['cart-bottom'],
            }));
            dispatch(appV2WizardNext());
        } else {
            dispatch(wizardSetInvalidSelectPage(true));
        }
    }

}

export const wizardCreateCrossSell = () => async (dispatch, getState) => {
    const state = getState();
    const triggerIds = getConfirmedIds(state, 'triggerType', 'confirmedSelectedProducts',
        'confirmedSelectedCollections', 'confirmedSelectedTags');
    const contentIds = getConfirmedIds(state, 'contentType', 'confirmedCrossSellSelectedProducts',
        'confirmedCrossSellSelectedCollections', 'confirmedCrossSellSelectedTags')
    const res = await postPayload('mappings/save_new_from_wizard', {
        on_pages: state.appV2Wizard.pageType,
        trigger_type: state.appV2Wizard.triggerType,
        trigger_ids: triggerIds,
        content_type: state.appV2Wizard.contentType,
        content_ids: contentIds,
    }, state)
    if (res.ok) {
        dispatch(wizardCreateStoreJs());
    }
}

export const wizardCreateVolumeDiscount = () => async (dispatch, getState) => {
    const state = getState();
    const triggerIds = getConfirmedIds(state, 'triggerType', 'confirmedSelectedProducts',
        'confirmedSelectedCollections', 'confirmedSelectedTags');
    const res = await postPayload('volume_discount/create_from_wizard', {
        on_pages: state.appV2Wizard.pageType,
        trigger_type: state.appV2Wizard.triggerType,
        trigger_id: triggerIds[0],
        vd: state.appV2Wizard.vd,
    }, state)
    if (res.ok) {
        dispatch(wizardCreateStoreJs());
    }
}

export const wizardCreateBundle = () => async (dispatch, getState) => {
    const state = getState();
    let triggerIds;
    if (state.appV2Wizard.triggerType === 'bundle_items') {
        // Do nothing
    } else {
        triggerIds = getConfirmedIds(state, 'triggerType', 'confirmedSelectedProducts',
            'confirmedSelectedCollections', 'confirmedSelectedTags');
    }
    const res = await postPayload('bundle/create_from_wizard', {
        on_pages: state.appV2Wizard.pageType,
        trigger_type: state.appV2Wizard.triggerType,
        bundle: state.appV2Wizard.bundle,
        bundle_type: state.appV2Wizard.bundleType,
        trigger_ids: triggerIds,
    }, state)
    if (res.ok) {
        dispatch(wizardCreateStoreJs());
    }
}

export const wizardCreateMixAndMatch = () => async (dispatch, getState) => {
    const state = getState();
    const res = await postPayload('mix_and_match/create_from_wizard', {
        on_pages: state.appV2Wizard.pageType,
        trigger_type: state.appV2Wizard.triggerType,
        mnm: state.appV2Wizard.mnm,
        trigger_collections: state.appV2Wizard.confirmedSelectedCollections,
    }, state)
    if (res.ok) {
        dispatch(wizardCreateStoreJs());
    }
}

export const wizardCreateStoreJs = () => async (dispatch, getState) => {
    const state = getState();
    dispatch(wizardStatus('Creating store files'));
    const res = await postPayload('admin_v2/create_store_js', {}, state);
    if (res.ok) {
        const json = await res.json();
        dispatch(wizardSetCreateStoreJsUuid(json.uuid));
    }
}

function getConfirmedIds(state, typeField, productsField, collectionsField, tagsField) {
    let ids = [];
    if (state.appV2Wizard[typeField] === 'specific_products') {
        ids = state.appV2Wizard[productsField].map(p => p.id);
    } else if (state.appV2Wizard[typeField] === 'collections') {
        ids = state.appV2Wizard[collectionsField].map(c => c.id);
    } else if (state.appV2Wizard[typeField] === 'tags') {
        ids = state.appV2Wizard[tagsField];
    }
    return ids;
}

export function wizardFetchStage4Products() {
    return async (dispatch, getState) => {
        const stage4Uuid = uuid4();
        const state = getState();
        await dispatch(wizardSetTriggerStageUuid(stage4Uuid));
        if (state.appV2Wizard.triggerType === 'bundle_items' || state.appV2Wizard.triggerType === 'mnm_items') {
            dispatch(wizardSetTriggerStageReady(stage4Uuid));
            dispatch(adminTrack('onboarding_wizard_trigger_type_selected', {
                trigger_type: state.appV2Wizard.triggerType,
            }));
            dispatch(appV2WizardNext());
            return;
        }
        const ids = getConfirmedIds(state, 'triggerType', 'confirmedSelectedProducts',
            'confirmedSelectedCollections', 'confirmedSelectedTags');
        if (ids.length === 0) {
            dispatch(wizardSetInvalidSelectTrigger(true));
            return;
        }
        dispatch(adminTrack('onboarding_wizard_trigger_type_selected', {
            trigger_type: state.appV2Wizard.triggerType,
        }));
        dispatch(appV2WizardNext());
        const response = await postPayload('admin_v2/fetch_products_for_wizard', {
            uuid: stage4Uuid,
            ids_type: state.appV2Wizard.triggerType,
            ids,
            stage: 'trigger',
        }, state)
        if (response.ok) {
            console.log('wizardFetchStage4Products success');
        } else {
            console.log('wizardFetchStage4Products failure');
        }
    }
}

export function wizardFetchStage5Products() {
    return async (dispatch, getState) => {
        const stage5Uuid = uuid4();
        const state = getState();
        await dispatch(wizardSetOfferStageUuid(stage5Uuid));

        if (state.appV2Wizard.offerType === 'volume_discount') {
            dispatch(wizardSetOfferStageReady(stage5Uuid));
            dispatch(wizardCheckIfReadyToCreateOffer());
            dispatch(adminTrack('onboarding_wizard_finalize_offer', {
                offer_type: state.appV2Wizard.offerType,
            }));
            dispatch(appV2WizardNext());
            return;
        }

        let ids;
        let ids_type;
        if (state.appV2Wizard.offerType === 'cross_sell') {
            ids_type = state.appV2Wizard.contentType;
            ids = getConfirmedIds(state, 'contentType', 'confirmedCrossSellSelectedProducts',
                'confirmedCrossSellSelectedCollections', 'confirmedCrossSellSelectedTags');
        } else if (state.appV2Wizard.offerType === 'bundle') {
            ids_type = 'specific_products';
            ids = state.appV2Wizard.bundle.products.filter(p => !p.empty).map(p => p.id);
        } else if (state.appV2Wizard.offerType === 'mix_and_match') {
            ids_type = 'collections';
            ids = state.appV2Wizard.mnm.product_groups.filter(pg => pg.group_type === 'collection').map(p => p.id);
        }

        if (ids.length === 0) {
            dispatch(wizardSetInvalidSelectOffer(true));
            return;
        }
        dispatch(wizardCheckIfReadyToCreateOffer());
        dispatch(adminTrack('onboarding_wizard_finalize_offer', {
            offer_type: state.appV2Wizard.offerType,
        }));
        dispatch(appV2WizardNext());
        const response = await postPayload('admin_v2/fetch_products_for_wizard', {
            uuid: stage5Uuid,
            ids_type,
            ids,
            stage: 'config_offer',
        }, state)
        if (response.ok) {
            console.log('wizardFetchStage5Products success');
        } else {
            console.log('wizardFetchStage5Products failure');
        }
    }
}

export function wizardHideEnableZoorix() {
    return async (dispatch, getState) => {
        dispatch(wizardCloseEnableZoorixModal());
        const state = getState();
        const response = await postPayload('admin_settings/toggle_hide_wizard_enable_zoorix', {
            bool: true,
        }, state)
        if (response.ok) {
            console.log('wizardHideEnableZoorix success');
        } else {
            console.log('wizardHideEnableZoorix failure');
        }
    }
}

export const wizardPreviewOffer = () => async (dispatch, getState) => {
    const state = getState();
    dispatch(adminTrack('onboarding_wizard_preview', {}));
    const res = await postPayload('admin_v2/preview_offer', {}, state);
    if (res.ok) {
        const json = await res.json();
        const one_time_token_id = json.one_time_token_id;
        window.open(`https://${state.data.shopName}/products/${state.appV2Wizard.previewProductHandle}?shown_ott=${one_time_token_id}`, '_blank')
    } else {
        dispatch(adminTrack('onboarding_wizard_preview_failed_to_gen_ott', {}));
        window.open(`https://${state.data.shopName}/products/${state.appV2Wizard.previewProductHandle}`, '_blank')
    }
}

export const wizardDone = () => async (dispatch, getState) => {
    dispatch(wizardDoneLoading());
    dispatch(adminTrack('onboarding_wizard_done', {}, () => {
        dispatch(closeWizard(() => {
            dispatch(postResync(() => window.location.reload()));
        }));
    }));
}

export const wizardAbort = () => async (dispatch, getState) => {
    dispatch(wizardDoneLoading());
    dispatch(adminTrack('onboarding_wizard_abort', {}, () => {
        dispatch(closeWizard(() => {
            dispatch(postResync(() => window.location.reload()));
        }));
    }));
}

export function searchBundleProductsApi() {
    return async (dispatch, getState) => {
        const state = getState();
        const response = await postPayload('products/search', {
            text: state.appV2Wizard.searchText || '',
        }, state)
        if (response.ok) {
            const json = await response.json();
            dispatch(setBundleSearchedProducts(json));
        } else {
            console.log('searchBundleProductsApi failure');
        }
    }
}

export function searchCollectionsApi() {
    return async (dispatch, getState) => {
        const state = getState();
        const res = await postPayload('collections/search', {
            text: state.appV2Wizard.searchText,
        }, state);
        if (res.ok) {
            const json = await res.json();
            dispatch(setSearchedCollections(json));
        } else {
            console.log('searchCollectionsApi failure');
        }
    }
}

export function searchTags() {
    return async (dispatch, getState) => {
        const state = getState();
        const text = state.appV2Wizard.searchText.toLowerCase();
        const edges = state.appV2Wizard.allTags.filter(edge => edge.node.toLowerCase().includes(text)).slice(0, 10);
        if (!edges.find(edge => edge.node.toLowerCase() === text)) {
            edges.push({node: text});
        }
        dispatch(setSearchedTags(edges));
    }
}

export function getAllTags(shopName, token) {
    return async (dispatch, getState) => {
        const res = await postPayload('tags/get_all', {
            shop_name: shopName,
            token,
        });
        if (res.ok) {
            const json = await res.json();
            dispatch(setAllTags(json));
        } else {
            console.log('getAllTags failure');
        }
    }
}

