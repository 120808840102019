import {
    DISMISS_OUT_OF_DATE_TOAST,
    EMAIL_CHANGE,
    INIT_DATA,
    NAME_CHANGE,
    RECEIVE_DATA, SET_WIZARD_BOOL, SET_ZOORIX_M_INSTALLED,
    UPDATE_ZOORIX_TOAST
} from "../actions/data";
import * as _ from "lodash";
import {PLANS_SET_DISCOUNT} from "../actions/plans";

const data = (state = { isFetched: false, isFetching: false, ownerName: '', ownerEmail: '', shopName: ''}, action) => {
    let newState = null;
    switch (action.type) {
        case INIT_DATA:
            newState = {
                isFetching: true,
                appType: action.appType,
            };
            break;
        case RECEIVE_DATA:
            newState = {
                zoorixMEnabledLoading: true,
                isFetched: true,
                isFetching: false,
                appType: action.appType,
                ownerName: action.ownerName,
                ownerEmail: action.ownerEmail,
                shopName: action.shopName,
                token: action.token,
                passwordEnabled: action.passwordEnabled,
                showAdminWizard: action.showAdminWizard,
                token_id: action.token_id,
                upgradeUrl: action.upgradeUrl,
                referralOfferingDiscount: action.referralOfferingDiscount,
            };
            break;
        case NAME_CHANGE:
            newState = _.cloneDeep(state);
            newState.ownerName = action.text;
            break;
        case PLANS_SET_DISCOUNT:
            newState = _.cloneDeep(state);
            newState.referralOfferingDiscount = action.json.ref_discount;
            break;
        case SET_WIZARD_BOOL:
            newState = _.cloneDeep(state);
            newState.showAdminWizard = action.bool;
            break;
        case SET_ZOORIX_M_INSTALLED:
            newState = _.cloneDeep(state);
            newState.zoorixMEnabled = action.bool;
            newState.zoorixMEnabledLoading = false;
            break;
        case EMAIL_CHANGE:
            newState = _.cloneDeep(state);
            newState.ownerEmail = action.text;
            break;
        case UPDATE_ZOORIX_TOAST:
            newState = _.cloneDeep(state);
            newState.installUrl = action.installUrl;
            newState.outOfDateToastActive = true;
            break;
        case DISMISS_OUT_OF_DATE_TOAST:
            newState = _.cloneDeep(state);
            newState.outOfDateToastActive = false;
            break;
        default:
            return state;
    }
    return newState;
};

export default data;
