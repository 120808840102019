import { connect } from 'react-redux'
import SettingsMain from "../../components/settings/SettingsMain";
import {
    bundleClicked,
    sliderClicked,
    warrantyClicked,
    appClicked,
    cartDrawerClicked,
    volumeDiscountClicked, mixAndMatchClicked
} from "../../actions/nav";

const mapStateToProps = state => ({
    isSliderVisible: state.nav.isSliderVisible,
    isBundleVisible: state.nav.isBundleVisible,
    isWarrantyVisible: state.nav.isWarrantyVisible,
    isAppVisible: state.nav.isAppVisible,
    isCartDrawerVisible: state.nav.isCartDrawerVisible,
    isVolumeVisible: state.nav.isVolumeVisible,
    isMixAndMatchVisible: state.nav.isMixAndMatchVisible,

    appType: state.data.appType,
});

const mapDispatchToProps = (dispatch) => ({
    onSliderClicked: () => dispatch(sliderClicked()),
    onBundleClicked: () => dispatch(bundleClicked()),
    onWarrantyClicked: () => dispatch(warrantyClicked()),
    onAppClicked: () => dispatch(appClicked()),
    onCartDrawerClicked: () => dispatch(cartDrawerClicked()),
    onVolumeClicked: () => dispatch(volumeDiscountClicked()),
    onMixAndMatchClicked: () => dispatch(mixAndMatchClicked()),
});

export function addAlphaToColor(color) {
    return color.hex + parseInt(color.rgb.a * 255).toString(16).padStart(2, '0');
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettingsMain);
