import { connect } from 'react-redux'
import SettingsMixAndMatch from "../../components/settings/SettingsMixAndMatch";
import {
    mixAndMatchDismissToast, MixAndMatchNumOfSearchedProductsChange, mixAndMatchSavePromoPageTitle,
    mixAndMatchSaveSettings,
    mixAndMatchSettingColorChange, mixAndMatchSettingsBoolChange,
    mixAndMatchSettingsTextChange
} from "../../actions/mixAndMatch";
import {addAlphaToColor} from "./VisibleSettingsMain";

const mapStateToProps = state => ({
    mixAndMatch: state.mixAndMatch,
});

const mapDispatchToProps = dispatch => ({
    textChange: attr => text => dispatch(mixAndMatchSettingsTextChange(attr, text)),
    boolChange: attr => bool => dispatch(mixAndMatchSettingsBoolChange(attr, bool)),
    changeColor: attr => color => dispatch(mixAndMatchSettingColorChange(attr, addAlphaToColor(color))),
    save: () => dispatch(mixAndMatchSaveSettings()),
    savePromoPageTitle: () => dispatch(mixAndMatchSavePromoPageTitle()),
    onDismissToast: () => dispatch(mixAndMatchDismissToast()),
    numOfSearchedProductsChange: text => dispatch(MixAndMatchNumOfSearchedProductsChange(text)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettingsMixAndMatch);
