import { connect } from 'react-redux'
import StickyAtcSettings from "../../components/sticky-atc/StickyAtcSettings";
import {
    stickyAtcChangeSaveSettings,
    stickyAtcChangeSettingBool,
    stickyAtcChangeSettingColor,
    stickyAtcChangeSettingText, stickyAtcChangeShowOn
} from "../../actions/sticky-atc/stickyAtc";
import {addAlphaToColor} from "../settings/VisibleSettingsMain";

const mapStateToProps = state => ({
    stickyAtc: state.stickyAtc,
});

const mapDispatchToProps = (dispatch) => ({
    save: () => dispatch(stickyAtcChangeSaveSettings()),
    changeText: attribute => text => dispatch(stickyAtcChangeSettingText(attribute, text)),
    changeBool: attribute => text => dispatch(stickyAtcChangeSettingBool(attribute, text)),
    changeColor: attribute => color => dispatch(stickyAtcChangeSettingColor(attribute, addAlphaToColor(color))),
    changeShowOn: text => dispatch(stickyAtcChangeShowOn(text)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StickyAtcSettings);
