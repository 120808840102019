import * as _ from "lodash";
import {
    ACTIVATE_TOAST, CART_DRAWER_SETTINGS_BOOL_CHANGE,
    CHECKOUT_BUTTON_COLOR_CHANGE,
    DISMISS_TOAST,
    FLOATING_ICON_BACKGROUND_COLOR_CHANGE,
    FLOATING_ICON_COLOR_CHANGE,
    FREE_DISCOUNT_AMOUNT_CHANGE,
    FREE_DISCOUNT_THRESHOLD_CHANGE,
    FREE_SHIPPING_THRESHOLD_CHANGE,
    INCENTIVE_CHANGE,
    INIT_CART_DRAWER_SETTINGS,
    INVOKE_FROM_HEADER_CHANGE,
    LEFT_TO_SPEND_COLOR_CHANGE,
    MINI_CART_DISPLAY_ON_HOME_PAGE_CHANGE, MINI_CART_NOTE_CHANGE, MINI_CART_ONE_CLICK_OFFER_ON_EMPTY_CART_CHANGE,
    MINI_CART_OPEN_AFTER_ADC_CHANGE,
    MINI_CART_VIEW_CART_COLOR_CHANGE,
    PROMO_BAR_COLOR_CHANGE,
    RESTORE_CART_DRAWER_TO_DEFAULTS,
    SAVE_CART_DRAWER_SETTINGS_ERROR,
    SAVE_CART_DRAWER_SETTINGS_PROCESSING,
    SAVE_CART_DRAWER_SETTINGS_SUCCESS,
    SHOW_BUTTONS_CHANGE,
} from "../actions/settingsCartDrawer";

const settingsCartDrawer = (state = {}, action) => {
    let newState = null;
    switch (action.type) {
        case INIT_CART_DRAWER_SETTINGS:
            newState = _.cloneDeep(state);
            newState.settings = _.cloneDeep(action.settings.values);
            newState.defaultSettings = _.cloneDeep(action.settings.defaults);
            newState.currencySymbol = action.currencySymbol;
            newState.planName = action.planName;
            newState.incentive = newState.settings.free_discount_enabled ?
                'Free Discount' : (newState.settings.free_shipping_enabled ? 'Free Shipping' : 'None');
            newState.buttonOption = newState.settings.show_view_cart_button ?
                (newState.settings.show_checkout_button ? 'cart_and_checkout' : 'cart') : 'checkout';
            break;
        case ACTIVATE_TOAST:
            newState = _.cloneDeep(state);
            newState.toastIsActive = true;
            break;
        case SAVE_CART_DRAWER_SETTINGS_SUCCESS:
            newState = _.cloneDeep(state);
            newState.saveInProgress = false;
            break;
        case DISMISS_TOAST:
            newState = _.cloneDeep(state);
            newState.toastIsActive = false;
            break;
        case RESTORE_CART_DRAWER_TO_DEFAULTS:
            newState = _.cloneDeep(state);
            newState.settings = _.cloneDeep(newState.defaultSettings);
            newState.incentive = newState.settings.free_discount_enabled ?
                'Free Discount' : (newState.settings.free_shipping_enabled ? 'Free Shipping' : 'None');
            newState.buttonOption = newState.settings.show_view_cart_button ?
                (newState.settings.show_checkout_button ? 'cart_and_checkout' : 'cart') : 'checkout';
            break;
        case SAVE_CART_DRAWER_SETTINGS_PROCESSING:
            newState = _.cloneDeep(state);
            newState.saveInProgress = true;
            newState.saveError = false;
            break;
        case SAVE_CART_DRAWER_SETTINGS_ERROR:
            newState = _.cloneDeep(state);
            newState.saveInProgress = false;
            newState.saveError = true;
            break;
        case INVOKE_FROM_HEADER_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.invoke_from_header = action.bool;
            break;
        case CART_DRAWER_SETTINGS_BOOL_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings[action.attr] = action.text === 'yes';
            break;
        case INCENTIVE_CHANGE:
            newState = _.cloneDeep(state);
            newState.incentive = action.text;
            newState.settings.free_discount_enabled = newState.incentive === 'Free Discount';
            newState.settings.free_shipping_enabled = newState.incentive === 'Free Shipping';
            break;
        case SHOW_BUTTONS_CHANGE:
            newState = _.cloneDeep(state);
            newState.buttonOption = action.text;
            newState.settings.show_view_cart_button = action.text === 'cart_and_checkout' || action.text === 'cart';
            newState.settings.show_checkout_button = action.text === 'cart_and_checkout' || action.text === 'checkout';
            break;
        case MINI_CART_DISPLAY_ON_HOME_PAGE_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.display_on_home_page = action.text === 'display_on_hp';
            break;
        case MINI_CART_OPEN_AFTER_ADC_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.open_after_adc = action.text === 'open';
            break;
        case MINI_CART_NOTE_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.cart_note = action.text === 'enable';
            break;
        case MINI_CART_ONE_CLICK_OFFER_ON_EMPTY_CART_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.show_one_click_offer_on_empty_cart = action.text === 'show';
            break;
        case PROMO_BAR_COLOR_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.promo_bar_color = action.hex;
            break;
        case LEFT_TO_SPEND_COLOR_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.left_to_spend_color = action.hex;
            break;
        case CHECKOUT_BUTTON_COLOR_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.checkout_button_color = action.hex;
            break;
        case FLOATING_ICON_BACKGROUND_COLOR_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.floating_icon_background_color = action.hex;
            break;
        case FLOATING_ICON_COLOR_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.floating_icon_color = action.hex;
            break;
        case MINI_CART_VIEW_CART_COLOR_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.mini_cart_view_cart_color = action.hex;
            break;
        case FREE_DISCOUNT_THRESHOLD_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.free_discount_threshold = action.num;
            if (action.num !== '' && action.num < 0) {
                newState.settings.free_discount_threshold = '0';
            }
            break;
        case FREE_SHIPPING_THRESHOLD_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.free_shipping_threshold = action.num;
            if (action.num !== '' && action.num < 0) {
                newState.settings.free_shipping_threshold = '0';
            }
            break;
        case FREE_DISCOUNT_AMOUNT_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.free_discount_amount = action.num;
            if (action.num !== '' && action.num < 0) {
                newState.settings.free_discount_amount = '0';
            }
            break;
        default:
            return state;
    }
    return newState;
};

export default settingsCartDrawer;
