import fetch from 'cross-fetch';
import { initStatus, setTimeoutRefreshSyncStatus } from "./status";
import { initSettings } from "./settings";
import { initAnalBundles, initAnalMappings, initAnalVolumeDiscounts, initAnalytics } from "./analytics";
import { bundleClicked, changeTab, initNav } from "./nav";
import { initProductsBundle } from "./productsBundle";
import { initBundleSettings } from "./settingsBundle";
import { initWarrantySettings } from "./settingsWarranty";
import { initCssEditor } from "./cssEditor";
import { initWarrantyModeration, onWarrantyModerationTypeChange } from "./warrantyModeration";
import { appSettingsInit } from "./settingsApp";
import { initCartDrawerSettings } from "./settingsCartDrawer";
import { subscribeToInstallationStatus } from "../installationStatusMiddleware";
import { volumeSettingsInit } from "./settingsVolume";
import { initPlans } from "./plans";
import { ZOORIX_REF_CODE } from "./partners";
import { getAllTags, initializeAppV2Wizard, wizardCloseEnableZoorixModal } from "./v2/appV2Wizard";
import { initMixAndMatch } from "./mixAndMatch";

export const INIT_DATA = 'INIT_DATA';
export const RECEIVE_DATA = 'RECEIVE_DATA';
export const SET_ZOORIX_M_INSTALLED = 'SET_ZOORIX_M_INSTALLED';
export const SET_WIZARD_BOOL = 'SET_WIZARD_BOOL';
export const NAME_CHANGE = 'NAME_CHANGE';
export const EMAIL_CHANGE = 'EMAIL_CHANGE';
export const UPDATE_ZOORIX_TOAST = 'UPDATE_ZOORIX_TOAST';
export const DISMISS_OUT_OF_DATE_TOAST = 'DISMISS_OUT_OF_DATE_TOAST';
export const ZOORIX_SHOPIFY_DOMAIN = 'zoorix_shopify_domain';

export const initData = (appType) => ({
    type: INIT_DATA,
    appType,
});

export const receiveData = (json, appType) => ({
    type: RECEIVE_DATA,
    ownerName: json.ownerName,
    ownerEmail: json.ownerEmail,
    shopName: json.shopName,
    token: json.token,
    token_id: json.token_id,
    passwordEnabled: json.password_enabled,
    showAdminWizard: json.show_admin_wizard,
    referralOfferingDiscount: json.referral_offering_discount,
    upgradeUrl: json.upgrade_url,
    appType,
});

export const setZoorixMInstalled = bool => ({
    type: SET_ZOORIX_M_INSTALLED,
    bool,
});

export const setWizardBool = bool => ({
    type: SET_WIZARD_BOOL,
    bool,
});

export const devHost = '65eb-188-64-207-189.ngrok.io';
// export const host = process.env.NODE_ENV === 'development' ? devHost : 'api.zoorix.com';
// export const host = process.env.NODE_ENV === 'development' ? 'zoorix.loca.lt' : 'api.zoorix.com';
export const host = 'sticky-atc-api.zoorix.com';
export const zoorixMHost = 'api.zoorix.com';

export const getInitDataUrl = (shopName, token, refCode, appType) => `https://${host}/get_init_data?shop_name=${shopName}&token=${token}&version=2${refCode ? `&ref_code=${refCode}` : ''}${appType ? `&app_type=${appType}` : ''}`

function redirectToInstallUrl(json) {
    let disable_install_redirect = false;
    try {
        disable_install_redirect = localStorage.getItem('disable_install_redirect');
    } catch (e) {
        console.log('unable to fetch disable_install_redirect', e);
    }
    if (json.install_url && !disable_install_redirect) {
        window.open(json.install_url, '_parent');
    }
}

function fetchData(shopName, token, appType) {
    if (appType === 'bundles') {
        return dispatch => {
            dispatch(initData(appType));
            dispatch(subscribeToInstallationStatus(shopName, token, appType));
            const lastMonth = new Date();
            lastMonth.setDate(lastMonth.getDate() - 30);
            fetch(`https://${host}/data/get_anal_data_v2?shop_name=${shopName}&token=${token}&start_date=${lastMonth.toISOString()}&end_date=${new Date().toISOString()}&app_type=${appType}`)
                .then(response => response.json())
                .then(json => dispatch(initAnalytics(json, appType)));
            fetch(`https://${host}/data/get_anal_bundles?shop_name=${shopName}&token=${token}&start_date=${lastMonth.toISOString()}&end_date=${new Date().toISOString()}&app_type=${appType}`)
                .then(resp => resp.json())
                .then(json => dispatch(initAnalBundles(json, appType)));
            fetch(getInitDataUrl(shopName, token, null, appType))
                .then(response => response.json())
                .then(json => {
                    redirectToInstallUrl(json);
                    dispatch(receiveData(json, appType));
                    dispatch(initNav(json, appType));
                    dispatch(initStatus(json));
                    dispatch(appSettingsInit(json));
                    dispatch(initProductsBundle(json));
                    dispatch(initBundleSettings(json));
                    dispatch(initCssEditor(json));
                    dispatch(changeTab('status'));
                    dispatch(bundleClicked());
                    setTimeoutRefreshSyncStatus(dispatch);
                })
        }
    }
    return dispatch => {
        window.setInterval(() => {
            dispatch(checkIfClientIsUpToDate(shopName, token));
        }, 60_000);
        dispatch(initData());
        dispatch(initNav({ installation: {} }));
        dispatch(subscribeToInstallationStatus(shopName, token));
        const lastMonth = new Date();
        lastMonth.setDate(lastMonth.getDate() - 30);
        fetch(`https://${host}/data/get_anal_data_v2?shop_name=${shopName}&token=${token}&start_date=${lastMonth.toISOString()}&end_date=${new Date().toISOString()}`)
            .then(response => response.json())
            .then(json => dispatch(initAnalytics(json)));
        fetch(`https://${host}/data/get_anal_mappings?shop_name=${shopName}&token=${token}&start_date=${lastMonth.toISOString()}&end_date=${new Date().toISOString()}`)
            .then(resp => resp.json())
            .then(json => dispatch(initAnalMappings(json)));
        fetch(`https://${host}/data/get_anal_bundles?shop_name=${shopName}&token=${token}&start_date=${lastMonth.toISOString()}&end_date=${new Date().toISOString()}`)
            .then(resp => resp.json())
            .then(json => dispatch(initAnalBundles(json)));
        fetch(`https://${host}/data/get_anal_volume_discounts?shop_name=${shopName}&token=${token}&start_date=${lastMonth.toISOString()}&end_date=${new Date().toISOString()}`)
            .then(resp => resp.json())
            .then(json => dispatch(initAnalVolumeDiscounts(json)));
        let refCode = null;
        try {
            refCode = localStorage.getItem(ZOORIX_REF_CODE);
        } catch (e) {
            console.log('unable to get refCode', e)
        }
        return fetch(getInitDataUrl(shopName, token, refCode))
            .then(response => response.json())
            .then(json => {
                // if (json.plan_name === 'trial') {
                //     adaptHeightForTrail();
                // }

                redirectToInstallUrl(json);
                if (json.password_enabled) dispatch(pollForZoorixMInstalled(json.shopName));
                dispatch(initializeAppV2Wizard(json));
                dispatch(getAllTags(shopName, token));
                dispatch(receiveData(json));
                dispatch(initNav(json));
                dispatch(initStatus(json));
                dispatch(initSettings(json));
                dispatch(appSettingsInit(json));
                dispatch(initProductsBundle(json));
                dispatch(initBundleSettings(json));
                dispatch(initWarrantySettings(json));
                dispatch(initCartDrawerSettings(json));
                dispatch(volumeSettingsInit(json));
                dispatch(initCssEditor(json));
                dispatch(initWarrantyModeration(json));
                dispatch(onWarrantyModerationTypeChange('pending'));
                dispatch(initPlans());
                dispatch(initMixAndMatch(json));
                setTimeoutRefreshSyncStatus(dispatch);
            })
    }
}

// function adaptHeightForTrail(){
//     let root = document.querySelector('#root');
//     root.style.height = 'calc(100% - 70px)';
//     root.style.overflowX = 'scroll';
// }

function shouldFetchPostsData(state) {
    return !state.data.isFetched && !state.data.isFetching;
}

export function fetchDataIfNeeded(shopName, token, appType) {
    return (dispatch, getState) => {
        if (shouldFetchPostsData(getState())) {
            try {
                localStorage.setItem(ZOORIX_SHOPIFY_DOMAIN, shopName);
            } catch (e) {
                console.log('unable to set shop name', e);
            }
            return dispatch(fetchData(shopName, token, appType))
        }
    }
}

export function pollForZoorixMInstalled(shopName) {
    return async (dispatch, getState) => {
        if (document.hasFocus()) {
            const res = await fetch(`https://is-m-enabled.zoorix.com/is-zoorix-M-app-blocks-enabled?sn=${shopName}`);
            if (res.ok) {
                const json = await res.json();
                if (getState().data.zoorixMEnabled !== json.a) {
                    dispatch(setZoorixMInstalled(json.a));
                    if (json.a) dispatch(wizardCloseEnableZoorixModal());
                }
            } else {
                console.log('pollForZoorixMInstalled failed')
            }
        }
        setTimeout(() => dispatch(pollForZoorixMInstalled(shopName)), 5000);
    }
}

export const closeWizard = (callback) => async (dispatch, getState) => {
    const res = await postPayload('data/hide_wizard', {}, getState());
    if (res.ok) {
        if (callback) callback();
    } else {
        console.log('closeWizard failed')
    }
};

export const nameChange = text => ({
    type: NAME_CHANGE,
    text
});

export const emailChange = text => ({
    type: EMAIL_CHANGE,
    text
});

export const updateZoorixToast = installUrl => ({
    type: UPDATE_ZOORIX_TOAST,
    installUrl,
});

export const dismissOutOfDateToast = () => ({
    type: DISMISS_OUT_OF_DATE_TOAST,
});


export const postPayload = async (path, payload, state) => {
    if (state) {
        payload.shop_name = state.data.shopName;
        payload.token = state.data.token;
    }
    return fetch(`https://${host}/${path}`, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
    });
}

export const postDevPayload = async (path, payload, state) => {
    if (state) {
        payload.shop_name = 'woki-dev-store.myshopify.com';
        payload.token = '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';
    }
    return fetch(`https://${devHost}/${path}`, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
    });
}

export function checkIfClientIsUpToDate(shopName, token) {
    return (dispatch, getState) => {
        return fetch(`https://${host}/data/client_up_to_date`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token,
                    shop_name: shopName,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        let disable_install_redirect = false;
                        try {
                            disable_install_redirect = localStorage.getItem('disable_install_redirect');
                        } catch (e) {
                            console.log('unable to fetch disable_install_redirect', e);
                        }

                        if (json.install_url && !disable_install_redirect) {
                            dispatch(updateZoorixToast(json.install_url));
                        }
                    });
                } else {
                    console.log('checkIfClientIsUpToDate failed');
                }
            });

    }
}

export function updateZoorix() {
    return (dispatch, getState) => {
        const state = getState();
        window.open(state.data.installUrl, '_parent');
    }
}

