import * as _ from 'lodash';
import {
    AMOUNT2_CHANGE,
    AMOUNT_CHANGE,
    AUTO_BUNDLE_REMOVE_FAILURE,
    AUTO_BUNDLES,
    AUTO_BUNDLES_LOADED,
    AUTO_BUNDLES_LOADING,
    AUTO_BUNDLES_STATUS,
    BACK,
    BUNDLE_ADD_GROUP_FAILURE,
    BUNDLE_ADD_GROUP_SUCCESS,
    BUNDLE_ALWAYS_SHOWN_ON_CART_CHANGE,
    BUNDLE_AUTO_GEN_CHANGE,
    BUNDLE_CART_BOTTOM_CHANGE,
    BUNDLE_CART_TOP_CHANGE,
    BUNDLE_CHOOSE_GROUP_FAILURE,
    BUNDLE_CHOOSE_GROUP_SUCCESS,
    BUNDLE_DISCOUNT_TYPE_CHANGED,
    BUNDLE_LOAD_AUTO_BUNDLES_FOR_SEARCH,
    BUNDLE_LOAD_AUTO_BUNDLES_JSON_FOR_SEARCH,
    BUNDLE_LOCALE_CHANGE,
    BUNDLE_NAME_CHANGE,
    BUNDLE_QTY_MODIFIERS_CHANGE,
    BUNDLE_REMOVE_FAILURE,
    BUNDLE_SEARCH_FAILURE,
    BUNDLE_SEARCH_SUCCESS,
    BUNDLE_SELECTED,
    BUNDLE_SEMANTIC_NAME_CHANGE,
    BUNDLE_START_ADDING_GROUP,
    BUNDLE_START_CHOOSING_GROUP,
    BUNDLE_SUBTITLE_CHANGE, BUNDLES_IMPORT_EXPORT,
    CHOOSE_BUNDLE_ITEM,
    CHOOSE_PRODUCT,
    CHOOSE_VARIANTS,
    CHOOSE_VARIANTS_ACTION,
    CLOSE_CHOOSE_PRODUCT,
    CLOSE_CHOOSE_VARIANTS,
    CLOSE_NO_AUTO_BUNDLES_MODAL,
    CLOSE_QUANTITATIVE_MODAL,
    CLOSE_REMOVE_AUTO_BUNDLE_MODAL,
    CLOSE_REMOVE_BUNDLE_MODAL,
    CLOSE_SHORT_CODE_MODAL,
    CREATE_BUNDLE,
    CREATE_GROUPED_BUNDLE,
    CREATE_PAGE,
    CREATING_AUTO_BUNDLE,
    CREATING_AUTO_BUNDLE_FAILED,
    CREATING_BUNDLE,
    CREATING_BUNDLE_FAILED,
    CREATING_GROUPED_BUNDLE,
    CREATING_GROUPED_BUNDLE_FAILED,
    DEL_AUTO_BUNDLES,
    DISMISS_DUPLICATE_BUNDLE_TOAST,
    DISMISS_TOAST,
    DISPLAY_CHANGE,
    DUPLICATE_BUNDLE_FAILURE,
    DUPLICATE_BUNDLE_STARTED,
    DUPLICATE_BUNDLE_SUCCESS,
    EDIT_BUNDLE,
    EDIT_PAGE,
    EDITING_BUNDLE,
    EDITING_BUNDLE_FAILED,
    GEN_AUTO_BUNDLES,
    GEN_AUTO_BUNDLES_SUCCESS,
    GROUP_TYPE_CHANGE,
    GROUP_VALUE_CHANGE, IMPORT_BUNDLE_STATE, IMPORTED_BUNDLES, IMPORTED_BUNDLES_LOADED, IMPORTED_BUNDLES_LOADING,
    IN_BUNDLE_CHANGED,
    INIT_PRODUCTS_BUNDLE,
    LOAD_PAGES_SUCCESS,
    MANAGE_PAGES,
    MOVE_BUNDLE_DOWN,
    MOVE_BUNDLE_UP,
    OPEN_NO_AUTO_BUNDLES_MODAL,
    OPEN_QUANTITATIVE_MODAL, OVERRIDE_EXISTING_BUNDLES_ON_IMPORT, OVERRIDE_EXISTING_BUNDLES_ON_IMPORT_CHANGE,
    PAGE_DELETE_FAILURE,
    PAGE_DELETE_SUCCESS,
    PAGE_DELETING,
    PAGE_SAVE_FAILURE,
    PAGE_SAVE_SUCCESS,
    PAGE_SAVING,
    PAGE_TITLE_CHANGE, PREVIEW_BUNDLE_LOADING,
    REMOVE_BUNDLE,
    REMOVE_BUNDLE_FROM_PAGE,
    REMOVE_PRODUCT,
    REMOVE_SOURCE_GROUP,
    REMOVE_SOURCE_PRODUCT,
    SAVE_ERROR,
    SAVING,
    SETTINGS_SAVED,
    SHOW_BUNDLES_SEMANTIC_NAMES_FAILURE,
    SHOW_BUNDLES_SEMANTIC_NAMES_SUCCESS,
    SHOW_ON_PRODUCT_PAGE_CHANGE,
    SHOW_SHORT_CODE,
    SHOWING_BUNDLES_SEMANTIC_NAMES,
    SINGLE_BUNDLE_NAVIGATE_TO_CHANGE,
    START_AUTO_BUNDLE_REMOVE,
    START_BUNDLE_REMOVE,
    START_BUNDLE_SEARCH,
    SWITCH_ENABLE_FAILURE,
    SWITCH_ENABLE_LOADING,
    SWITCH_ENABLE_SUCCESS,
    TOGGLE_ADD_GROUP_ACTIVE, TOGGLE_OVERRIDE_EXISTING_BUNDLES_MODAL,
    TYPE_CHANGE
} from "../actions/productsBundle";
import {addByFilterOptions} from "../components/products/ProductsSlider";
import {calcLocales} from "./texts";

export function handleFilter(newState, groupAddition) {
    if (!newState[groupAddition].groupType || newState[groupAddition].groupType === 'product-type') {
        newState[groupAddition].groupType = 'product-type';
        newState[groupAddition].groupValues = _.cloneDeep(newState.productTypes);
    } else if (newState[groupAddition].groupType === 'vendor') {
        newState[groupAddition].groupValues = _.cloneDeep(newState.vendors);
    } else if (newState[groupAddition].groupType === 'tag') {
        newState[groupAddition].groupValues = _.cloneDeep(newState.tags);
    } else if (newState[groupAddition].groupType === 'automated-collection') {
        newState[groupAddition].groupValues = _.cloneDeep(newState.smartCollections);
    } else if (newState[groupAddition].groupType === 'manual-collection') {
        newState[groupAddition].groupValues = _.cloneDeep(newState.customCollections);
    }
    newState[groupAddition].groupValue = (newState[groupAddition].groupValues[0] && newState[groupAddition].groupValues[0].value) ||
        newState[groupAddition].groupValues[0];
}

export function calcBundleName(bundle) {
    return bundle.bundle_type === 'grouped' ?
        `${addByFilterOptions.filter(o => o.value === bundle.filter1_type)[0].label}: ${bundle.filter1_value}` :
        bundle.products.map(p => p.title).join(', ');
}

function calcPageBundles(state) {
    state.pageBundles = state.bundles.map(b => ({key: b.id, value: `${b.name} (${calcBundleName(b)})`}));
    if (state.autoBundlesForSearch) {
        state.pageBundles = state.pageBundles.concat(state.autoBundlesForSearch.map(b => ({key: b.id, value: b.semantic_name})));
    }
}

const productsBundle = (state = {}, action) => {
    let newState = null;
    switch (action.type) {
        case INIT_PRODUCTS_BUNDLE:
            newState = _.cloneDeep(state);
            newState.autoBundles = newState.autoBundles || {
                page: 0,
                loading: true,
            };
            newState.importedBundles = newState.importedBundles || {
                page: 0,
                loading: true,
            };
            newState.previewBundleLoading = {};
            newState.showBundlesSemanticNames = action.adminSettings ?
                action.adminSettings.show_bundles_semantic_names : newState.showBundlesSemanticNames;
            newState.bundles = _.cloneDeep(action.bundles);
            newState.importedBundlesCount = action.importedBundlesCount;
            calcPageBundles(newState);
            newState.switchEnableLoading = {};
            newState.duplicateBundleStarted = {};
            newState.editingBundle = {};
            newState.availableLocales = [];
            newState.currencySymbol = action.currencySymbol || newState.currencySymbol;
            newState.isShowBundles = true;
            newState.isShowEdit = false;
            newState.isShowGroupedEdit = false;
            newState.isGenAutoBundle = false;
            newState.isSaving = false;
            newState.saveError = false;
            newState.groupAddition = {};
            newState.tags = action.tags ? _.cloneDeep(action.tags) : newState.tags;
            newState.vendors = action.vendors ? _.cloneDeep(action.vendors) : newState.vendors;
            newState.productTypes = action.productTypes ? _.cloneDeep(action.productTypes) : newState.productTypes;
            newState.customCollections = action.customCollections ? _.cloneDeep(action.customCollections) : newState.customCollections;
            newState.smartCollections = action.smartCollections ? _.cloneDeep(action.smartCollections) : newState.smartCollections;
            handleFilter(newState, 'groupAddition');
            break;
        case AUTO_BUNDLES_STATUS:
            newState = _.cloneDeep(state);
            newState.bundles = _.cloneDeep(action.bundles);
            calcPageBundles(newState);
            break;
        case EDITING_BUNDLE:
            newState = _.cloneDeep(state);
            newState.editingBundle[action.id] = true;
            break;
        case EDITING_BUNDLE_FAILED:
            newState = _.cloneDeep(state);
            newState.editingBundle[action.id] = false;
            break;
        case CREATE_BUNDLE:
            newState = _.cloneDeep(state);
            newState.isShowBundles = false;
            newState.creatingBundle = false;
            newState.isShowEdit = true;
            newState.isGenAutoBundle = false;
            newState.bundle = {
                is_enabled: true,
                semantic_name: action.json.semantic_name,
                type: 'fixed',
                products: [],
                source_products: [],
                display: 'bundle',
                navigate_to: 'default',
                source_groups: [],
                show_on_product_page: 'default',
                qty_modifiers: true,
                amount: 0,
                amount2: 0,
                amount3: 0,
                amount4: 0,
                amount5: 0,
                amount6: 0,
                amount7: 0,
                amount8: 0,
                discount_types: Array.apply(null, {length: 8}).map(() => 'percentage'),
            };
            newState.bundle.bundleTranslations = {};
            action.json.locales.forEach(locale => {
                newState.bundle.bundleTranslations[locale] = {
                    name: '',
                    subtitle: '',
                }
            })
            if (action.json.available_locales) {
                newState.availableLocales = _.cloneDeep(action.json.available_locales);
            }
            calcLocales(newState, newState.bundle.bundleTranslations);
            break;
        case CREATE_PAGE:
            newState = _.cloneDeep(state);
            newState.isShowBundles = false;
            newState.isShowPages = false;
            newState.isShowEditPage = true;
            newState.page = {
                title: '',
                bundles: [],
            };
            break;
        case BUNDLES_IMPORT_EXPORT:
            newState = _.cloneDeep(state);
            newState.isShowBundles = false;
            newState.isShowImportExport = true;
            break;
        case EDIT_PAGE:
            newState = _.cloneDeep(state);
            newState.isShowBundles = false;
            newState.isShowPages = false;
            newState.isShowEditPage = true;
            newState.page = _.cloneDeep(_.find(newState.pages, {id: action.id}));
            break;
        case MANAGE_PAGES:
            newState = _.cloneDeep(state);
            newState.isShowBundles = false;
            newState.isShowPages = true;
            break;
        case AUTO_BUNDLES_LOADED:
            newState = _.cloneDeep(state);
            newState.autoBundlesLoaded = true;
            break;
        case IMPORTED_BUNDLES_LOADED:
            newState = _.cloneDeep(state);
            newState.importedBundlesLoaded = true;
            break;
        case CREATE_GROUPED_BUNDLE:
            newState = _.cloneDeep(state);
            newState.isShowBundles = false;
            newState.isShowGroupedEdit = true;
            newState.creatingGroupedBundle = false;
            newState.bundle = {
                is_enabled: true,
                semantic_name: action.json.semantic_name,
                type: 'fixed',
                products: [{}],
                navigate_to: 'default',
                show_on_product_page: 'default',
                qty_modifiers: true,
                amount: 0,
                amount2: 0,
                amount3: 0,
                amount4: 0,
                amount5: 0,
                amount6: 0,
                amount7: 0,
                amount8: 0,
                discount_types: Array.apply(null, {length: 8}).map(() => 'percentage'),
            };
            newState.bundle.bundleTranslations = {};
            action.json.locales.forEach(locale => {
                newState.bundle.bundleTranslations[locale] = {
                    name: '',
                    subtitle: '',
                }
            })
            if (action.json.available_locales) {
                newState.availableLocales = _.cloneDeep(action.json.available_locales);
            }
            calcLocales(newState, newState.bundle.bundleTranslations);
            break;
        case GEN_AUTO_BUNDLES:
            newState = _.cloneDeep(state);
            newState.isShowBundles = false;
            newState.isShowEdit = true;
            newState.isGenAutoBundle = true;
            newState.creatingAutoBundle = false;
            newState.bundle = {
                type: 'percentage',
                navigate_to: 'default',
                auto_type: 'orders',
                discount_types: [],
            };
            newState.bundle.bundleTranslations = {};
            action.json.locales.forEach(locale => {
                newState.bundle.bundleTranslations[locale] = {
                    name: '',
                    subtitle: '',
                }
            })
            if (action.json.available_locales) {
                newState.availableLocales = _.cloneDeep(action.json.available_locales);
            }
            calcLocales(newState, newState.bundle.bundleTranslations);
            break;
        case EDIT_BUNDLE:
            newState = _.cloneDeep(state);
            newState.bundle = _.cloneDeep(_.find(newState.bundles, {id: action.id}));
            newState.bundle.discount_types = newState.bundle.discount_types || Array.apply(null, {length: 8}).map(() => 'percentage');
            newState.bundle.amount = parseFloat(newState.bundle.amount);
            [2,3,4,5,6,7,8].forEach(i => newState.bundle[`amount${i}`] = parseFloat(newState.bundle[`amount${i}`]));
            newState.editingBundle[action.id] = false;
            newState.isShowBundles = false;
            if (newState.bundle.bundle_type === 'grouped') {
                newState.isShowGroupedEdit = true;
            } else {
                newState.isShowEdit = true;
                newState.isGenAutoBundle = false;
            }
            if (newState.bundle.is_fixed) {
                newState.bundle.type = 'fixed';
            } else if (newState.bundle.is_percentage) {
                newState.bundle.type = 'percentage';
            } else if (newState.bundle.is_quantitative) {
                newState.bundle.type = 'quantitative';
            }
            newState.bundle.display =
                (newState.bundle.source_products.length > 0 || newState.bundle.source_groups.length > 0) ?
                    'following' : 'bundle';
            newState.bundle.bundleTranslations = _.cloneDeep(action.json.bundle_translations);
            if (action.json.available_locales) {
                newState.availableLocales = _.cloneDeep(action.json.available_locales);
            }
            calcLocales(newState, newState.bundle.bundleTranslations);
            break;
        case REMOVE_BUNDLE:
            newState = _.cloneDeep(state);
            newState.removeBundleModalOpen = true;
            newState.bundleName = _.find(newState.bundles, {id: action.id}).name;
            newState.destBundle = action.id;
            break;
        case BUNDLE_LOCALE_CHANGE:
            newState = _.cloneDeep(state);
            newState.locale = action.text;
            break;
        case BUNDLE_QTY_MODIFIERS_CHANGE:
            newState = _.cloneDeep(state);
            newState.bundle.qty_modifiers = action.text === 'qty';
            break;
        case BUNDLE_SEMANTIC_NAME_CHANGE:
            newState = _.cloneDeep(state);
            newState.bundle.semantic_name = action.text;
            break;
        case AUTO_BUNDLES_LOADING:
            newState = _.cloneDeep(state);
            newState.autoBundles.loading = true;
            break;
        case IMPORTED_BUNDLES_LOADING:
            newState = _.cloneDeep(state);
            newState.importedBundles.loading = true;
            break;
        case AUTO_BUNDLES:
            newState = _.cloneDeep(state);
            newState.autoBundles = {
                count: action.json.count,
                page: action.json.page,
                maxPage: action.json.max_page,
                loading: false,
            };
            _.remove(newState.bundles, {bundle_type: 'auto'});
            newState.bundles = newState.bundles.concat(_.cloneDeep(action.json.auto_bundles));
            break;
        case IMPORTED_BUNDLES:
            newState = _.cloneDeep(state);
            newState.importedBundles = {
                count: action.json.count,
                page: action.json.page,
                maxPage: action.json.max_page,
                loading: false,
            };
            _.remove(newState.bundles, {bundle_type: 'imported'});
            newState.bundles = newState.bundles.concat(_.cloneDeep(action.json.imported_bundles));
            break;
        case CREATING_BUNDLE:
            newState = _.cloneDeep(state);
            newState.creatingBundle = true;
            break;
        case CREATING_AUTO_BUNDLE:
            newState = _.cloneDeep(state);
            newState.creatingAutoBundle = true;
            break;
        case CREATING_GROUPED_BUNDLE:
            newState = _.cloneDeep(state);
            newState.creatingGroupedBundle = true;
            break;
        case CREATING_GROUPED_BUNDLE_FAILED:
            newState = _.cloneDeep(state);
            newState.creatingGroupedBundle = false;
            break;
        case CREATING_AUTO_BUNDLE_FAILED:
            newState = _.cloneDeep(state);
            newState.creatingAutoBundle = false;
            break;
        case CREATING_BUNDLE_FAILED:
            newState = _.cloneDeep(state);
            newState.creatingBundle = false;
            break;
        case DEL_AUTO_BUNDLES:
            newState = _.cloneDeep(state);
            newState.removeAutoBundleModalOpen = true;
            break;
        case TOGGLE_ADD_GROUP_ACTIVE:
            newState = _.cloneDeep(state);
            newState.groupAddition.addGroupActive = !newState.groupAddition.addGroupActive;
            break;
        case SHOWING_BUNDLES_SEMANTIC_NAMES:
            newState = _.cloneDeep(state);
            newState.isShowBundlesSemanticNamesSaving = true;
            newState.showBundlesSemanticNames = action.text === 'name';
            break;
        case SHOW_BUNDLES_SEMANTIC_NAMES_FAILURE:
            newState = _.cloneDeep(state);
            newState.isShowBundlesSemanticNamesSaving = false;
            newState.showBundlesSemanticNames = action.text === 'name';
            break;
        case SHOW_BUNDLES_SEMANTIC_NAMES_SUCCESS:
            newState = _.cloneDeep(state);
            newState.isShowBundlesSemanticNamesSaving = false;
            newState.showBundlesSemanticNames = action.text === 'name';
            break;
        case GROUP_TYPE_CHANGE:
            newState = _.cloneDeep(state);
            newState.groupAddition.groupType = action.text;
            handleFilter(newState, 'groupAddition');
            break;
        case GROUP_VALUE_CHANGE:
            newState = _.cloneDeep(state);
            newState.groupAddition.groupValue = action.text;
            break;
        case BUNDLE_START_ADDING_GROUP:
            newState = _.cloneDeep(state);
            newState.groupAddition.isAddingGroup = true;
            break;
        case BUNDLE_START_CHOOSING_GROUP:
            newState = _.cloneDeep(state);
            newState.groupAddition.isChoosingGroup = true;
            break;
        case BUNDLE_ADD_GROUP_FAILURE:
            newState = _.cloneDeep(state);
            newState.groupAddition.isAddingGroup = false;
            break;
        case BUNDLE_CHOOSE_GROUP_FAILURE:
            newState = _.cloneDeep(state);
            newState.groupAddition.isChoosingGroup = false;
            break;
        case REMOVE_SOURCE_GROUP:
            newState = _.cloneDeep(state);
            _.remove(newState.bundle.source_groups, {id: action.id});
            break;
        case BUNDLE_ADD_GROUP_SUCCESS:
            newState = _.cloneDeep(state);
            newState.groupAddition.isAddingGroup = false;
            newState.groupAddition.addGroupActive = false;
            newState.bundle.source_groups.push({
                id: action.id,
                filter1_type: action.filter1_type,
                filter1_value: action.filter1_value,
                count: action.count,
            });
            break;
        case BUNDLE_CHOOSE_GROUP_SUCCESS:
            newState = _.cloneDeep(state);
            newState.groupAddition.isChoosingGroup = false;
            newState.groupAddition.addGroupActive = false;
            newState.bundle.filter1_type = action.filter1_type;
            newState.bundle.filter1_value = action.filter1_value;
            break;
        case CLOSE_REMOVE_AUTO_BUNDLE_MODAL:
            newState = _.cloneDeep(state);
            newState.removeAutoBundleModalOpen = false;
            newState.removeAutoBundlesSpinner = false;
            newState.removeAutoBundlesError = false;
            break;
        case SWITCH_ENABLE_LOADING:
            newState = _.cloneDeep(state);
            newState.switchEnableLoading[action.id] = true;
            break;
        case LOAD_PAGES_SUCCESS:
            newState = _.cloneDeep(state);
            newState.pages = _.cloneDeep(action.pages);
            break;
        case DUPLICATE_BUNDLE_STARTED:
            newState = _.cloneDeep(state);
            newState.duplicateBundleStarted[action.id] = true;
            break;
        case DUPLICATE_BUNDLE_SUCCESS:
            newState = _.cloneDeep(state);
            newState.duplicateBundleStarted[action.id] = false;
            newState.bundles = _.cloneDeep(action.bundles);
            calcPageBundles(newState);
            break;
        case DUPLICATE_BUNDLE_FAILURE:
            newState = _.cloneDeep(state);
            newState.duplicateBundleStarted[action.id] = false;
            newState.duplicateBundleFailure = true;
            break;
        case PREVIEW_BUNDLE_LOADING:
            newState = _.cloneDeep(state);
            newState.previewBundleLoading[action.id] = action.bool;
            break;
        case DISMISS_DUPLICATE_BUNDLE_TOAST:
            newState = _.cloneDeep(state);
            newState.duplicateBundleFailure = false;
            break;
        case SWITCH_ENABLE_FAILURE:
            newState = _.cloneDeep(state);
            newState.switchEnableLoading[action.id] = false;
            newState.switchEnableError = true;
            break;
        case SWITCH_ENABLE_SUCCESS:
            newState = _.cloneDeep(state);
            newState.switchEnableLoading[action.id] = false;
            newState.switchEnableError = false;
            newState.bundles = _.cloneDeep(action.bundles);
            calcPageBundles(newState);
            break;
        case SHOW_SHORT_CODE:
            newState = _.cloneDeep(state);
            newState.showShortCodeModalOpen = true;
            {
                const b = newState.bundles.find(b => b.id === action.id);
                if (b.bundle_type === 'grouped') {
                    newState.shortCodeSingleId = false;
                    newState.shortCodeBundleIds = b.sub_ids;
                } else {
                    newState.shortCodeSingleId = true;
                    newState.shortCodeBundleId = b.id;
                }
            }
            break;
        case CLOSE_SHORT_CODE_MODAL:
            newState = _.cloneDeep(state);
            newState.showShortCodeModalOpen = false;
            newState.shortCodeBundleId = null;
            break;
        case CLOSE_NO_AUTO_BUNDLES_MODAL:
            newState = _.cloneDeep(state);
            newState.noAutoBundlesModalOpen = false;
            break;
        case OPEN_NO_AUTO_BUNDLES_MODAL:
            newState = _.cloneDeep(state);
            newState.noAutoBundlesModalOpen = true;
            break;
        case CLOSE_REMOVE_BUNDLE_MODAL:
            newState = _.cloneDeep(state);
            newState.removeBundleModalOpen = false;
            newState.removeBundleSpinner = false;
            newState.removeBundleError = false;
            break;
        case BUNDLE_REMOVE_FAILURE:
            newState = _.cloneDeep(state);
            newState.removeBundleSpinner = false;
            newState.removeBundleError = true;
            break;
        case AUTO_BUNDLE_REMOVE_FAILURE:
            newState = _.cloneDeep(state);
            newState.removeAutoBundlesSpinner = false;
            newState.removeAutoBundlesError = true;
            break;
        case START_BUNDLE_REMOVE:
            newState = _.cloneDeep(state);
            newState.removeBundleSpinner = true;
            newState.removeBundleError = false;
            break;
        case START_AUTO_BUNDLE_REMOVE:
            newState = _.cloneDeep(state);
            newState.removeAutoBundlesSpinner = true;
            newState.removeAutoBundlesError = false;
            break;
        case BACK:
            newState = _.cloneDeep(state);
            newState.isShowBundles = true;
            newState.isShowEdit = false;
            newState.isGenAutoBundle = false;
            newState.isShowGroupedEdit = false;
            newState.isShowEditPage = false;
            newState.isShowPages = false;
            newState.isShowImportExport = false;
            newState.bundle = null;
            break;
        case SAVING:
            newState = _.cloneDeep(state);
            newState.isSaving = true;
            newState.saveError = false;
            break;
        case SAVE_ERROR:
            newState = _.cloneDeep(state);
            newState.saveError = true;
            newState.isSaving = false;
            break;
        case SETTINGS_SAVED:
            newState = _.cloneDeep(state);
            newState.toastIsActive = true;
            break;
        case GEN_AUTO_BUNDLES_SUCCESS:
            newState = _.cloneDeep(state);
            newState.isSaving = false;
            break;
        case BUNDLE_NAME_CHANGE:
            newState = _.cloneDeep(state);
            newState.bundle.bundleTranslations[newState.locale].name = action.name;
            break;
        case SHOW_ON_PRODUCT_PAGE_CHANGE:
            newState = _.cloneDeep(state);
            newState.bundle.show_on_product_page = action.text;
            break;
        case BUNDLE_SUBTITLE_CHANGE:
            newState = _.cloneDeep(state);
            newState.bundle.bundleTranslations[newState.locale].subtitle = action.text;
            break;
        case BUNDLE_AUTO_GEN_CHANGE:
            newState = _.cloneDeep(state);
            newState.bundle.auto_type = action.text;
            break;
        case AMOUNT_CHANGE:
            newState = _.cloneDeep(state);
            newState.bundle.amount = parseFloat(action.amount);
            if (newState.bundle.amount < 0) {
                newState.bundle.amount = 0;
            }
            break;
        case AMOUNT2_CHANGE:
            newState = _.cloneDeep(state);
            newState.bundle[`amount${action.i + 1}`] = parseFloat(action.amount);
            if (newState.bundle[`amount${action.i + 1}`] < 0) {
                newState.bundle[`amount${action.i + 1}`] = 0;
            }
            break;
        case TYPE_CHANGE:
            newState = _.cloneDeep(state);
            newState.bundle.type = action.text;
            break;
        case CHOOSE_PRODUCT:
            newState = _.cloneDeep(state);
            newState.destinationProductId = action.id;
            newState.chooseProductOpen = true;
            break;
        case CLOSE_CHOOSE_PRODUCT:
            newState = _.cloneDeep(state);
            newState.destinationProductId = null;
            newState.chooseProductOpen = false;
            break;
        case DISMISS_TOAST:
            newState = _.cloneDeep(state);
            newState.toastIsActive = false;
            break;
        case START_BUNDLE_SEARCH:
            newState = _.cloneDeep(state);
            newState.showSearchSpinner = true;
            newState.searchBoxText = action.text;
            newState.searchedProducts = [];
            break;
        case MOVE_BUNDLE_UP:
            newState = _.cloneDeep(state);
            [newState.page.bundles[action.index - 1], newState.page.bundles[action.index]] =
                [newState.page.bundles[action.index], newState.page.bundles[action.index - 1]];
            break;
        case MOVE_BUNDLE_DOWN:
            newState = _.cloneDeep(state);
            [newState.page.bundles[action.index + 1], newState.page.bundles[action.index]] =
                [newState.page.bundles[action.index], newState.page.bundles[action.index + 1]];
            break;
        case REMOVE_BUNDLE_FROM_PAGE:
            newState = _.cloneDeep(state);
            newState.page.bundles.splice(action.index, 1);
            break;
        case PAGE_TITLE_CHANGE:
            newState = _.cloneDeep(state);
            newState.page.title = action.text;
            break;
        case BUNDLE_SELECTED:
            newState = _.cloneDeep(state);
            if (newState.autoBundlesForSearch) {
                const autoBundle = newState.autoBundlesForSearch.find(b => b.id === action.id);
                if (autoBundle) {
                    newState.page.bundles.push(
                        {
                            bundle_type: 'auto',
                            id: autoBundle.id,
                            name: autoBundle.semantic_name,
                            products: [],
                        }
                    )
                } else {
                    newState.page.bundles.push(_.cloneDeep(_.find(newState.bundles, {id: action.id})));
                }
            } else {
                newState.page.bundles.push(_.cloneDeep(_.find(newState.bundles, {id: action.id})));
            }
            break;
        case PAGE_SAVING:
            newState = _.cloneDeep(state);
            newState.pageSaving = true;
            break;
        case PAGE_DELETING:
            newState = _.cloneDeep(state);
            newState.pageDeleting = true;
            break;
        case PAGE_SAVE_FAILURE:
            newState = _.cloneDeep(state);
            newState.pageSaving = false;
            newState.saveError = true;
            break;
        case BUNDLE_LOAD_AUTO_BUNDLES_JSON_FOR_SEARCH:
            newState = _.cloneDeep(state);
            newState.autoBundlesForSearch = _.cloneDeep(action.json.auto_bundles);
            calcPageBundles(newState);
            break;
        case PAGE_SAVE_SUCCESS:
            newState = _.cloneDeep(state);
            newState.pageSaving = false;
            newState.saveError = false;
            newState.isShowEditPage = false;
            newState.isShowPages = true;
            newState.pages = _.cloneDeep(action.json.pages);
            break;
        case PAGE_DELETE_SUCCESS:
            newState = _.cloneDeep(state);
            newState.pageDeleting = false;
            newState.pages = _.cloneDeep(action.json.pages);
            break;
        case PAGE_DELETE_FAILURE:
            newState = _.cloneDeep(state);
            newState.pageDeleting = false;
            break;
        case BUNDLE_SEARCH_SUCCESS:
            newState = _.cloneDeep(state);
            if (newState.searchBoxText !== action.title) {
                // do nothing
            } else {
                newState.searchedProducts = _.cloneDeep(action.products);
                newState.showSearchSpinner = false;
                newState.searchError = false;
            }
            break;
        case BUNDLE_SEARCH_FAILURE:
            newState = _.cloneDeep(state);
            newState.searchError = true;
            newState.showSearchSpinner = false;
            break;
        case REMOVE_SOURCE_PRODUCT:
            newState = _.cloneDeep(state);
            _.remove(newState.bundle.source_products, {shopify_product_id: action.id});
            break;
        case REMOVE_PRODUCT:
            newState = _.cloneDeep(state);
            newState.bundle.products[action.id] = null;
            break;
        case CHOOSE_VARIANTS:
            newState = _.cloneDeep(state);
            newState.variants = _.cloneDeep(newState.bundle.products[action.id].variants);
            newState.variantsId = action.id;
            newState.chooseVariantsOpen = true;
            break;
        case CLOSE_CHOOSE_VARIANTS:
            newState = _.cloneDeep(state);
            newState.variants = null;
            newState.variantsId = null;
            newState.chooseVariantsOpen = false;
            break;
        case IN_BUNDLE_CHANGED:
            newState = _.cloneDeep(state);
            _.find(newState.variants, {shopify_id: action.id}).in_bundle = action.bool;
            break;
        case BUNDLE_DISCOUNT_TYPE_CHANGED:
            newState = _.cloneDeep(state);
            newState.bundle.discount_types[action.id] = action.text;
            break;
        case CHOOSE_VARIANTS_ACTION:
            newState = _.cloneDeep(state);
            newState.bundle.products[newState.variantsId].variants = _.cloneDeep(newState.variants);
            newState.variants = null;
            newState.variantsId = null;
            newState.chooseVariantsOpen = false;
            break;
        case OPEN_QUANTITATIVE_MODAL:
            newState = _.cloneDeep(state);
            newState.quantitativeModalOpen = true;
            break;
        case CLOSE_QUANTITATIVE_MODAL:
            newState = _.cloneDeep(state);
            newState.quantitativeModalOpen = false;
            break;
        case DISPLAY_CHANGE:
            newState = _.cloneDeep(state);
            newState.bundle.display = action.text;
            break;
        case SINGLE_BUNDLE_NAVIGATE_TO_CHANGE:
            newState = _.cloneDeep(state);
            newState.bundle.navigate_to = action.text;
            break;
        case BUNDLE_CART_TOP_CHANGE:
            newState = _.cloneDeep(state);
            newState.bundle.show_on_cart_top = action.bool;
            break;
        case OVERRIDE_EXISTING_BUNDLES_ON_IMPORT_CHANGE:
            newState = _.cloneDeep(state);
            if (action.bool) {
                newState.overrideExisingBundlesOnImportModalOpen = true;
            } else {
                newState.overrideExistingBundlesOnImport = false;
            }
            break;
        case TOGGLE_OVERRIDE_EXISTING_BUNDLES_MODAL:
            newState = _.cloneDeep(state);
            newState.overrideExisingBundlesOnImportModalOpen = action.bool;
            break;
        case OVERRIDE_EXISTING_BUNDLES_ON_IMPORT:
            newState = _.cloneDeep(state);
            newState.overrideExisingBundlesOnImportModalOpen = false;
            newState.overrideExistingBundlesOnImport = true;
            break;
        case IMPORT_BUNDLE_STATE:
            newState = _.cloneDeep(state);
            newState.importState = _.cloneDeep(action.json);
            break;
        case BUNDLE_CART_BOTTOM_CHANGE:
            newState = _.cloneDeep(state);
            newState.bundle.show_on_cart_bottom = action.bool;
            break;
        case BUNDLE_ALWAYS_SHOWN_ON_CART_CHANGE:
            newState = _.cloneDeep(state);
            newState.bundle.always_shown_on_cart = action.bool;
            break;
        case BUNDLE_LOAD_AUTO_BUNDLES_FOR_SEARCH:
            newState = _.cloneDeep(state);
            newState.loadingAutoBundlesForSearch = action.bool;
            break;
        case CHOOSE_BUNDLE_ITEM:
            newState = _.cloneDeep(state);
            const selectedItem = _.find(newState.searchedProducts, {id: action.id});
            const newProduct = {
                image_src: selectedItem.image_src,
                shopify_product_id: selectedItem.id,
                title: selectedItem.title,
                variants: selectedItem.variants,
            };
            if (newState.destinationProductId === 'source') {
                const existingItem = _.find(newState.bundle.source_products,
                    {shopify_product_id: newProduct.shopify_product_id});
                if (!existingItem) {
                    newState.bundle.source_products.push(newProduct);
                }
            } else {
                newState.bundle.products[newState.destinationProductId] = newProduct;
            }
            newState.destinationProductId = null;
            newState.chooseProductOpen = false;
            break;
        default:
            return state;
    }
    return newState;
};

export default productsBundle;
