import * as _ from "lodash";
import {APP_V2_CLOSE_WIZARD, INITIALIZE_APP_V2, SET_APP_V2_INITIAL_STATE} from "../../actions/v2/appV2";

const appV2 = (state = { }, action) => {
    let newState = null;
    switch (action.type) {
        case INITIALIZE_APP_V2:
            newState = _.cloneDeep(state);
            newState.initialized = true;
            newState.shop = action.params.shop;
            newState.token = action.params.token;
            break;
        case SET_APP_V2_INITIAL_STATE:
            newState = _.cloneDeep(state);
            newState.showWizard = action.json.funnels_count === 0;
            newState.showDashboard = action.json.funnels_count > 0;
            break;
        case APP_V2_CLOSE_WIZARD:
            newState = _.cloneDeep(state);
            newState.showWizard = false;
            newState.showDashboard = true;
            break;
        default:
            return state;
    }
    return newState;
};

export default appV2;
