import * as _ from "lodash";
import {
    CSS_CHANGE,
    CSS_EDITOR_REMOVE_VERSION_LOCK_SUCCESS,
    CSS_EDITOR_REMOVING_VERSION_LOCK,
    CSS_EDITOR_TOGGLE_REMOVE_VERSION_LOCK_MODAL,
    CSS_SAVE_FAILURE,
    CSS_SAVE_SUCCESS,
    DISMISS_TOAST,
    INIT_CSS_EDITOR,
    JS_CHANGE,
    START_CSS_SAVE
} from "../actions/cssEditor";

const cssEditor = (state = { }, action) => {
    let newState = null;
    switch (action.type) {
        case INIT_CSS_EDITOR:
            newState = _.cloneDeep(state);
            newState.customCss = _.cloneDeep(action.customCss);
            newState.css = newState.customCss.latest_css;
            newState.js = newState.customCss.custom_js;
            newState.widgetVersionLock = action.widgetVersionLock;
            newState.latestMajor = action.latestMajor;
            newState.isSaving = false;
            newState.error = false;
            break;
        case START_CSS_SAVE:
            newState = _.cloneDeep(state);
            newState.isSaving = true;
            break;
        case CSS_SAVE_FAILURE:
            newState = _.cloneDeep(state);
            newState.isSaving = false;
            newState.error = true;
            break;
        case CSS_CHANGE:
            newState = _.cloneDeep(state);
            newState.css = action.text;
            break;
        case JS_CHANGE:
            newState = _.cloneDeep(state);
            newState.js = action.text;
            break;
        case CSS_EDITOR_TOGGLE_REMOVE_VERSION_LOCK_MODAL:
            newState = _.cloneDeep(state);
            newState.removeVersionLockModalOpen = action.bool;
            break;
        case CSS_EDITOR_REMOVING_VERSION_LOCK:
            newState = _.cloneDeep(state);
            newState.removingWidgetVersionLock = action.bool;
            break;
        case CSS_SAVE_SUCCESS:
            newState = _.cloneDeep(state);
            newState.toastIsActive = true;
            break;
        case CSS_EDITOR_REMOVE_VERSION_LOCK_SUCCESS:
            newState = _.cloneDeep(state);
            newState.widgetVersionLock = null;
            break;
        case DISMISS_TOAST:
            newState = _.cloneDeep(state);
            newState.toastIsActive = false;
            break;
        default:
            return state;
    }
    return newState;
};

export default cssEditor;
