import * as _ from "lodash";
import {
    ACTIVATE_TOAST,
    CANCEL_UPLOAD_WARRANTY_DEFAULT,
    DISMISS_TOAST,
    EMAIL_SUBJECT_CHANGE,
    EMAIL_TEMPLATE_CHANGE,
    INIT_WARRANTY_SETTINGS,
    NOTIFICATION_EMAIL_CHANGE,
    ON_DISCOUNT_PERCENTAGE_CHANGE,
    ON_TEST_EMAIL_OR_PHONE_CHANGE,
    RESTORE_WARRANTY_TO_DEFAULTS,
    RESTORE_ZOORIX_WARRANTY_DEFAULT,
    SAVE_WARRANTY_SETTINGS_ERROR,
    SAVE_WARRANTY_SETTINGS_PROCESSING,
    SAVE_WARRANTY_SETTINGS_SUCCESS, SEND_ACCEPT_NOTIFICATION_CHANGE,
    SEND_NOTIFICATION_CHANGE, SEND_PURCHASE_NOTIFICATION_CHANGE, SEND_REJECT_NOTIFICATION_CHANGE,
    SEND_TEST_EMAIL_ERROR,
    SEND_TEST_EMAIL_PROCESSING,
    SEND_TEST_EMAIL_SUCCESS,
    SEND_TEST_SMS_ERROR,
    SEND_TEST_SMS_PROCESSING,
    SEND_TEST_SMS_SUCCESS,
    SMS_SUBJECT_CHANGE,
    SMS_TEMPLATE_CHANGE,
    UPLOAD_WARRANTY_DEFAULT,
    UPLOAD_WARRANTY_DEFAULT_FAILURE,
    UPLOAD_WARRANTY_DEFAULT_SUCCESS,
    WARRANTY_IN_CARTS_CHANGE, WARRANTY_SHOW_ON_CART_CHANGE,
    WARRANTY_STORE_NAME_CHANGE,
} from "../actions/settingsWarranty";

const settingsWarranty = (state = {}, action) => {
    let newState = null;
    switch (action.type) {
        case INIT_WARRANTY_SETTINGS:
            newState = _.cloneDeep(state);
            newState.settings = _.cloneDeep(action.settings.values);
            newState.defaultSettings = _.cloneDeep(action.settings.defaults);
            newState.tokenId = action.tokenId;
            newState.sendTestEmailError = {};
            newState.sendTestSMSError = {};
            newState.emailOrPhone = {};
            newState.sendTestEmailProcessing = {};
            newState.sendTestSMSProcessing = {};
            newState.planName = action.planName;
            break;
        case ACTIVATE_TOAST:
            newState = _.cloneDeep(state);
            newState.toastIsActive = true;
            break;
        case SAVE_WARRANTY_SETTINGS_SUCCESS:
            newState = _.cloneDeep(state);
            newState.saveInProgress = false;
            break;
        case DISMISS_TOAST:
            newState = _.cloneDeep(state);
            newState.toastIsActive = false;
            newState.sendTestEmailToast = false;
            newState.sendTestSMSToast = false;
            break;
        case RESTORE_WARRANTY_TO_DEFAULTS:
            newState = _.cloneDeep(state);
            newState.settings = _.cloneDeep(newState.defaultSettings);
            break;
        case SAVE_WARRANTY_SETTINGS_PROCESSING:
            newState = _.cloneDeep(state);
            newState.saveInProgress = true;
            newState.saveError = false;
            break;
        case SAVE_WARRANTY_SETTINGS_ERROR:
            newState = _.cloneDeep(state);
            newState.saveInProgress = false;
            newState.saveError = true;
            break;
        case UPLOAD_WARRANTY_DEFAULT:
            newState = _.cloneDeep(state);
            newState.isUploading = true;
            break;
        case CANCEL_UPLOAD_WARRANTY_DEFAULT:
            newState = _.cloneDeep(state);
            newState.isUploading = false;
            break;
        case UPLOAD_WARRANTY_DEFAULT_SUCCESS:
            newState = _.cloneDeep(state);
            newState.settings.is_zoorix_default_warranty_image = false;
            newState.isUploading = false;
            newState.uploadError = false;
            break;
        case RESTORE_ZOORIX_WARRANTY_DEFAULT:
            newState = _.cloneDeep(state);
            newState.settings.is_zoorix_default_warranty_image = true;
            break;
        case SEND_NOTIFICATION_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.send_notification = action.bool;
            break;
        case WARRANTY_IN_CARTS_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.warranty_in_cart = action.bool;
            break;
        case SEND_PURCHASE_NOTIFICATION_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.send_purchase_notification = action.bool;
            break;
        case SEND_ACCEPT_NOTIFICATION_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.send_accept_notification = action.bool;
            break;
        case SEND_REJECT_NOTIFICATION_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.send_reject_notification = action.bool;
            break;
        case NOTIFICATION_EMAIL_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.email = action.text;
            break;
        case ON_DISCOUNT_PERCENTAGE_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.percentage = action.text;
            if (action.text < 0) {
                newState.settings.percentage = 0;
            } else if (action.text > 100) {
                newState.settings.percentage = 100;
            }
            break;
        case EMAIL_SUBJECT_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings[`${action.email_type}_email_subject`] = action.text;
            break;
        case EMAIL_TEMPLATE_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings[`${action.email_type}_email_template`] = action.text;
            break;
        case WARRANTY_STORE_NAME_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.store_name = action.text;
            break;
        case WARRANTY_SHOW_ON_CART_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.show_on_cart_top = action.text === 'both' || action.text === 'top';
            newState.settings.show_on_cart_bottom = action.text === 'both' || action.text === 'bottom';
            break;
        case SMS_SUBJECT_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings[`${action.email_type}_sms_subject`] = action.text;
            if (action.text.length > 11) {
                newState.settings[`${action.email_type}_sms_subject`] = action.text.substring(0, 11);
            }
            newState.settings[`${action.email_type}_sms_subject`] =
                newState.settings[`${action.email_type}_sms_subject`].replace(/[^a-z0-9]/gi,'');
            break;
        case SMS_TEMPLATE_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings[`${action.email_type}_sms_template`] = action.text;
            break;
        case UPLOAD_WARRANTY_DEFAULT_FAILURE:
            newState = _.cloneDeep(state);
            newState.uploadError = true;
            break;
        case ON_TEST_EMAIL_OR_PHONE_CHANGE:
            newState = _.cloneDeep(state);
            newState.emailOrPhone[action.email_type] = action.text;
            break;
        case SEND_TEST_EMAIL_PROCESSING:
            newState = _.cloneDeep(state);
            newState.sendTestEmailProcessing[action.email_type] = true;
            newState.sendTestEmailError[action.email_type] = false;
            break;
        case SEND_TEST_SMS_PROCESSING:
            newState = _.cloneDeep(state);
            newState.sendTestSMSProcessing[action.email_type] = true;
            newState.sendTestSMSError[action.email_type] = false;
            break;
        case SEND_TEST_EMAIL_SUCCESS:
            newState = _.cloneDeep(state);
            newState.sendTestEmailProcessing[action.email_type] = false;
            newState.sendTestEmailError[action.email_type] = false;
            newState.sendTestEmailToast = true;
            break;
        case SEND_TEST_SMS_SUCCESS:
            newState = _.cloneDeep(state);
            newState.sendTestSMSProcessing[action.email_type] = false;
            newState.sendTestSMSError[action.email_type] = false;
            newState.sendTestSMSToast = true;
            break;
        case SEND_TEST_EMAIL_ERROR:
            newState = _.cloneDeep(state);
            newState.sendTestEmailProcessing[action.email_type] = false;
            newState.sendTestEmailError[action.email_type] = true;
            break;
        case SEND_TEST_SMS_ERROR:
            newState = _.cloneDeep(state);
            newState.sendTestSMSProcessing[action.email_type] = false;
            newState.sendTestSMSError[action.email_type] = true;
            break;
        default:
            return state;
    }
    return newState;
};

export default settingsWarranty;
