import {
    ENABLE_ZOORIX,
    DISABLE_ZOORIX,
    RECEIVED_ENABLE,
    RECEIVED_DISABLE,
    INIT_STATUS,
    RESYNC,
    RESYNC_SUCCESS,
    RESYNC_ERROR,
    RESYNC_STATUS,
    TOGGLE_CART,
    RECEIVED_DISABLE_CART,
    RECEIVED_ENABLE_CART,
    ACTIVATE_FEEDBACK_POPUP,
    DISMISS_FEEDBACK_POPUP,
    CLOSE_REVIEW_CARD,
    TOGGLE_PRODUCT_BOTTOM_PROCESSING,
    RECEIVED_PRODUCT_BOTTOM_CART,
    WAITING_FOR_PRODUCT_PAGE_WIDGET,
    PRODUCT_INSTALLATION_COMPLETE,
    WAITING_FOR_CART_PAGE_WIDGET,
    CART_INSTALLATION_COMPLETE,
    TOGGLE_BUNDLE_PROCESSING,
    TOGGLE_BUNDLE_DONE,
    TOGGLE_WARRANTY_PROCESSING,
    TOGGLE_WARRANTY_DONE,
    TOGGLE_CART_DRAWER_PROCESSING,
    TOGGLE_CART_DRAWER_DONE,
    CLOSE_PAYMENT_MODAL,
    INSTALLATION_STATUS_UPDATE,
    TOGGLE_VOLUME_DISCOUNT_PROCESSING,
    TOGGLE_VOLUME_DISCOUNT_DONE,
    ONLINE_STORE2,
    HIDE_DEEP_LINK_UI, SHOW_CLOSE_DEEP_LINK_CARD
} from "../actions/status";
import * as _ from "lodash";

const enabledState = {
    enabled: true,
    buttonText: 'Disable',
    content: 'Enabled',
    isPosting: false,
};

const disabledState = {
    enabled: false,
    buttonText: 'Enable',
    content: 'Disabled',
    isPosting: false,
};

const status = (state = {}, action) => {
    let newState = null;
    switch (action.type) {
        case INIT_STATUS:
            newState = _.cloneDeep(action.enabled ? enabledState : disabledState);
            newState.onlineStore2 = action.onlineStore2;
            newState.appEmbeds = action.appEmbeds;
            newState.showInstallZoorixHead = action.showInstallZoorixHead;
            newState.installationStatusTime = 0;
            newState.installationStatusText = '';
            newState.cartEnabled = action.cartEnabled;
            if (newState.cartEnabled) {
                newState.cartButtonText = 'Disable';
                newState.cartContent = 'Enabled';
            } else {
                newState.cartButtonText = 'Enable';
                newState.cartContent = 'Disabled';
            }
            newState.bundleEnabled = action.bundleEnabled;
            if (newState.bundleEnabled) {
                newState.bundleButtonText = 'Disable';
                newState.bundleContent = 'Enabled';
            } else {
                newState.bundleButtonText = 'Enable';
                newState.bundleContent = 'Disabled';
            }
            newState.lastSync = action.lastSync;
            newState.productCount = action.productCount;
            newState.collectionCount = action.customCollectionsCount + action.smartCollectionsCount;
            newState.syncStarted = action.syncStarted;
            newState.userLeftReview = action.leftReview;
            newState.installingTheme = action.installation.product_main === null ||
                action.installation.product_bottom === null ||
                action.installation.cart_top === null ||
                action.installation.cart_bottom === null ||
                action.installation.cart_items === null;
            newState.installation = _.cloneDeep(action.installation);
            newState.trialDaysLeft = action.trialDaysLeft;
            newState.trialDays = action.trialDays;
            newState.customPackagePrice = action.customPackagePrice;
            newState.packageName = action.packageName;
            newState.chargeActive = action.chargeActive;
            newState.warrantyButtonText = action.warrantySettings.values.is_enabled ? 'Disable' : 'Enable';
            newState.warrantyEnabled = action.warrantySettings.values.is_enabled;
            newState.warrantyContent = action.warrantySettings.values.is_enabled ? 'Enabled' : 'Disabled';
            newState.cartDrawerButtonText = action.cartDrawerSettings.values.is_enabled ? 'Disable' : 'Enable';
            newState.cartDrawerEnabled = action.cartDrawerSettings.values.is_enabled;
            newState.cartDrawerContent = action.cartDrawerSettings.values.is_enabled ? 'Enabled' : 'Disabled';
            newState.volumeDiscountButtonText = action.volumeSettings.values.is_enabled ? 'Disable' : 'Enable';
            newState.volumeDiscountEnabled = action.volumeSettings.values.is_enabled;
            newState.volumeDiscountContent = action.volumeSettings.values.is_enabled ? 'Enabled' : 'Disabled';
            newState.planName = action.planName;
            newState.devPlans = action.devPlans;
            newState.paymentModalOpen = true;
            newState.numOfOrders = action.numOfOrders;
            break;
        case RESYNC_STATUS:
            newState = _.cloneDeep(state);
            newState.lastSync = action.lastSync;
            newState.productCount = action.productCount;
            newState.collectionCount = action.customCollectionsCount + action.smartCollectionsCount;
            newState.syncStarted = action.syncStarted;
            newState.installingTheme = action.installation.product_main === null ||
                action.installation.product_bottom === null ||
                action.installation.cart_top === null ||
                action.installation.cart_bottom === null ||
                action.installation.cart_items === null;
            newState.installation = _.cloneDeep(action.installation);
            newState.warrantyButtonText = action.warrantySettings.values.is_enabled ? 'Disable' : 'Enable';
            newState.planName = action.planName;
            newState.numOfOrders = action.numOfOrders;
            break;
        case ACTIVATE_FEEDBACK_POPUP:
            newState = _.cloneDeep(state);
            newState.feedbackPopupActive = true;
            break;
        case HIDE_DEEP_LINK_UI:
            newState = _.cloneDeep(state);
            newState.showInstallZoorixHead = false;
            break;
        case ONLINE_STORE2:
            newState = _.cloneDeep(state);
            newState.onlineStore2 = action.onlineStore2;
            break;
        case DISMISS_FEEDBACK_POPUP:
            newState = _.cloneDeep(state);
            newState.feedbackPopupActive = false;
            break;
        case CLOSE_REVIEW_CARD:
            newState = _.cloneDeep(state);
            newState.userLeftReview = true;
            break;
        case CLOSE_PAYMENT_MODAL:
            newState = _.cloneDeep(state);
            newState.paymentModalOpen = false;
            break;
        case WAITING_FOR_PRODUCT_PAGE_WIDGET:
            newState = _.cloneDeep(state);
            newState.waitingForProductPageWidget = true;
            break;
        case PRODUCT_INSTALLATION_COMPLETE:
            newState = _.cloneDeep(state);
            newState.waitingForProductPageWidget = false;
            newState.installation.product_main = action.productMain;
            newState.installation.product_bottom = action.productBottom;
            break;
        case WAITING_FOR_CART_PAGE_WIDGET:
            newState = _.cloneDeep(state);
            newState.waitingForCartPageWidget = true;
            break;
        case SHOW_CLOSE_DEEP_LINK_CARD:
            newState = _.cloneDeep(state);
            newState.showCloseDeepLink = true;
            break;
        case INSTALLATION_STATUS_UPDATE:
            newState = _.cloneDeep(state);
            if (action.data.time > newState.installationStatusTime) {
                newState.installationStatusTime = action.data.time;
                newState.installationProgress = action.data.progress;
                newState.installationStatusText = action.data.status_text;
                newState.syncStarted = action.data.sync_started;
                if (action.data.status_text) {
                    newState.syncStartedText = null;
                } else {
                    newState.syncStartedText = 'Finishing';
                }
            }
            break;
        case CART_INSTALLATION_COMPLETE:
            newState = _.cloneDeep(state);
            newState.waitingForCartPageWidget = false;
            newState.installation.cart_top = action.cartTop;
            newState.installation.cart_bottom = action.cartBottom;
            newState.installation.cart_items = action.cartItems;
            break;
        case ENABLE_ZOORIX:
            newState = _.cloneDeep(state);
            newState.isPosting = true;
            break;
        case TOGGLE_CART:
            newState = _.cloneDeep(state);
            newState.cartProcessing = true;
            break;
        case TOGGLE_PRODUCT_BOTTOM_PROCESSING:
            newState = _.cloneDeep(state);
            newState.isPosting = true;
            break;
        case RECEIVED_PRODUCT_BOTTOM_CART:
            newState = _.cloneDeep(state);
            newState.isPosting = false;
            newState.productBottomEnabled = action.bool;
            if (newState.productBottomEnabled) {
                newState.productBottomButtonText = 'Disable';
                newState.productBottomContent = 'Enabled';
            } else {
                newState.productBottomButtonText = 'Enable';
                newState.productBottomContent = 'Disabled';
            }
            break;
        case DISABLE_ZOORIX:
            newState = _.cloneDeep(state);
            newState.isPosting = true;
            break;
        case RECEIVED_ENABLE:
            newState = _.assign({}, state, enabledState);
            break;
        case RECEIVED_DISABLE_CART:
            newState = _.cloneDeep(state);
            newState.cartEnabled = false;
            newState.cartButtonText = 'Enable';
            newState.cartContent = 'Disabled';
            newState.cartProcessing = false;
            break;
        case RECEIVED_ENABLE_CART:
            newState = _.cloneDeep(state);
            newState.cartEnabled = true;
            newState.cartButtonText = 'Disable';
            newState.cartContent = 'Enabled';
            newState.cartProcessing = false;
            break;
        case RECEIVED_DISABLE:
            newState = _.assign({}, state, disabledState);
            break;
        case RESYNC:
            newState = _.cloneDeep(state);
            newState.isPostingResync = true;
            newState.resyncError = false;
            break;
        case TOGGLE_BUNDLE_PROCESSING:
            newState = _.cloneDeep(state);
            newState.bundleProcessing = true;
            break;
        case TOGGLE_WARRANTY_PROCESSING:
            newState = _.cloneDeep(state);
            newState.warrantyProcessing = true;
            break;
        case TOGGLE_CART_DRAWER_PROCESSING:
            newState = _.cloneDeep(state);
            newState.cartDrawerProcessing = true;
            break;
        case TOGGLE_VOLUME_DISCOUNT_PROCESSING:
            newState = _.cloneDeep(state);
            newState.volumeDiscountProcessing = true;
            break;
        case TOGGLE_BUNDLE_DONE:
            newState = _.cloneDeep(state);
            newState.bundleProcessing = false;
            newState.bundleEnabled = !newState.bundleEnabled;
            if (newState.bundleEnabled) {
                newState.bundleButtonText = 'Disable';
                newState.bundleContent = 'Enabled';
            } else {
                newState.bundleButtonText = 'Enable';
                newState.bundleContent = 'Disabled';
            }
            break;
        case TOGGLE_WARRANTY_DONE:
            newState = _.cloneDeep(state);
            newState.warrantyProcessing = false;
            newState.warrantyEnabled = !newState.warrantyEnabled;
            if (newState.warrantyEnabled) {
                newState.warrantyButtonText = 'Disable';
                newState.warrantyContent = 'Enabled';
            } else {
                newState.warrantyButtonText = 'Enable';
                newState.warrantyContent = 'Disabled';
            }
            break;
        case TOGGLE_CART_DRAWER_DONE:
            newState = _.cloneDeep(state);
            newState.cartDrawerProcessing = false;
            newState.cartDrawerEnabled = !newState.cartDrawerEnabled;
            if (newState.cartDrawerEnabled) {
                newState.cartDrawerButtonText = 'Disable';
                newState.cartDrawerContent = 'Enabled';
            } else {
                newState.cartDrawerButtonText = 'Enable';
                newState.cartDrawerContent = 'Disabled';
            }
            break;
        case TOGGLE_VOLUME_DISCOUNT_DONE:
            newState = _.cloneDeep(state);
            newState.volumeDiscountProcessing = false;
            newState.volumeDiscountEnabled = !newState.volumeDiscountEnabled;
            if (newState.volumeDiscountEnabled) {
                newState.volumeDiscountButtonText = 'Disable';
                newState.volumeDiscountContent = 'Enabled';
            } else {
                newState.volumeDiscountButtonText = 'Enable';
                newState.volumeDiscountContent = 'Disabled';
            }
            break;
        case RESYNC_SUCCESS:
            newState = _.cloneDeep(state);
            newState.isPostingResync = false;
            newState.resyncError = false;
            newState.syncStarted = action.syncStarted;
            newState.syncStartedText = 'Starting';
            break;
        case RESYNC_ERROR:
            newState = _.cloneDeep(state);
            newState.isPostingResync = false;
            newState.resyncError = true;
            break;
        default:
            return state;
    }
    return newState;
};

export default status;
