import * as _ from "lodash";
import {
    APP_V2_WIZARD_BACK,
    APP_V2_WIZARD_NEXT,
    APPROVE_CROSS_SELL_SELECTED_COLLECTIONS,
    APPROVE_CROSS_SELL_SELECTED_PRODUCTS,
    APPROVE_CROSS_SELL_SELECTED_TAGS,
    APPROVE_MNM_SELECTED_COLLECTIONS,
    APPROVE_SELECTED_COLLECTIONS,
    APPROVE_SELECTED_PRODUCTS,
    APPROVE_SELECTED_TAGS,
    INITIALIZE_APP_V2_WIZARD,
    REMOVE_SELECTED_COLLECTION,
    REMOVE_SELECTED_PRODUCT,
    REMOVE_SELECTED_TAG,
    SELECT_BUNDLE_TYPE,
    SELECT_CONTENT_TYPE,
    SELECT_OFFER_TYPE,
    SELECT_PAGE_TYPE,
    SELECT_TRIGGER,
    SENDING_SUPPORT_REQUEST,
    SET_ALL_TAGS,
    SET_BUNDLE_SEARCHED_PRODUCTS,
    SET_SEARCHED_COLLECTIONS,
    SET_SEARCHED_PRODUCTS,
    SET_SEARCHED_TAGS,
    TOGGLE_CONTACT_US_MODAL,
    TOGGLE_CROSS_SELL_SELECT_COLLECTIONS_MODAL,
    TOGGLE_CROSS_SELL_SELECT_PRODUCTS_MODAL,
    TOGGLE_CROSS_SELL_SELECT_TAGS_MODAL,
    TOGGLE_MNM_SELECT_COLLECTIONS_MODAL,
    TOGGLE_SELECT_COLLECTION,
    TOGGLE_SELECT_COLLECTIONS_MODAL,
    TOGGLE_SELECT_PRODUCT,
    TOGGLE_SELECT_PRODUCTS_MODAL,
    TOGGLE_SELECT_TAG,
    TOGGLE_SELECT_TAGS_MODAL,
    UPDATE_COLLECTION_SEARCH_TEXT,
    UPDATE_PRODUCT_SEARCH_TEXT,
    UPDATE_TAG_SEARCH_TEXT,
    WIZARD_BUNDLE_ADD_PRODUCT,
    WIZARD_BUNDLE_AMOUNT_CHANGE,
    WIZARD_BUNDLE_CLOSE_SELECT_PRODUCTS_MODAL,
    WIZARD_BUNDLE_REMOVE_PRODUCT,
    WIZARD_BUNDLE_SELECT_PRODUCT,
    WIZARD_CHOOSE_BUNDLE_PRODUCT,
    WIZARD_CLOSE_ENABLE_ZOORIX_MODAL,
    WIZARD_COLLECTIONS_ARE_UPLOADED,
    WIZARD_CREATE_ANOTHER_OFFER,
    WIZARD_DONE_LOADING,
    WIZARD_MNM_DISCOUNT_AMOUNT_CHANGE,
    WIZARD_MNM_MIN_QTY_CHANGE,
    WIZARD_MNM_TRASH_PRODUCT_GROUP,
    WIZARD_PREVIEW_PROGRESS,
    WIZARD_PREVIEW_READY,
    WIZARD_SELECT_MNM_DISCOUNT_TYPE,
    WIZARD_SET_CREATE_STORE_JS_UUID,
    WIZARD_SET_INVALID_SELECT_OFFER,
    WIZARD_SET_INVALID_SELECT_PAGE,
    WIZARD_SET_INVALID_SELECT_TRIGGER,
    WIZARD_SET_OFFER_STAGE_READY,
    WIZARD_SET_OFFER_STAGE_UUID,
    WIZARD_SET_TRIGGER_STAGE_READY,
    WIZARD_SET_TRIGGER_STAGE_UUID,
    WIZARD_STATUS,
    WIZARD_VOLUME_ADD_TIER,
    WIZARD_VOLUME_DISCOUNT_CHANGE,
    WIZARD_VOLUME_DISCOUNT_TYPE_CHANGE,
    WIZARD_VOLUME_POPULAR_CHANGE,
    WIZARD_VOLUME_QTY_CHANGE,
    WIZARD_VOLUME_TRASH_TIER
} from "../../actions/v2/appV2Wizard";

function colorSearchedProducts(state) {
    if (state.searchedProducts) {
        state.searchedProducts.forEach(p => p.selected = !!state.selectedProducts.find(sp => sp.id === p.node.id));
    }
}

function colorSearchedCollections(state) {
    if (state.searchedCollections) {
        state.searchedCollections.forEach(c => c.selected = !!state.selectedCollections.find(sc => sc.id === c.node.id));
    }
}

function colorSearchedTags(state) {
    if (state.searchedTags) {
        state.searchedTags.forEach(edge => edge.selected = !!state.selectedTags.find(st => st === edge.node));
    }
}

function initState(newState) {
    newState.contentType = 'specific_products';
    newState.selectedProducts = [];
    newState.selectedCollections = [];
    newState.selectedTags = [];
    newState.confirmedSelectedProducts = [];
    newState.confirmedCrossSellSelectedProducts = [];
    newState.confirmedSelectedCollections = [];
    newState.confirmedCrossSellSelectedCollections = [];
    newState.confirmedSelectedTags = [];
    newState.confirmedCrossSellSelectedTags = [];
    newState.bundle = {
        amount: 0,
        products: [
            { empty: true },
        ],
    };
    newState.vd = {
        tiers: [{
            qty: 2,
            discount: 10,
            discountType: 'percentage',
            popular: false,
        }],
    };
    newState.mnm = {
        discount: {
            type: 'percentage',
            amount: 0,
        },
        product_groups: [],
    };
}

const appV2Wizard = (state = { }, action) => {
    let newState = null;
    switch (action.type) {
        case INITIALIZE_APP_V2_WIZARD:
            newState = _.cloneDeep(state);
            newState.stage = 1;
            newState.offerType = 'cross_sell';
            newState.bundleType = 'fixed';
            newState.currencySymbol = action.json.currency_symbol;
            newState.enableZoorixModalOpen = !action.json.admin_settings.hide_wizard_enable_zoorix;
            newState.triggerType = null;
            newState.pageType = {
                product: true,
                cart: true,
                'product-main': true,
            };
            initState(newState);
            break;
        case APP_V2_WIZARD_NEXT:
            newState = _.cloneDeep(state);
            newState.stage += 1;
            break;
        case WIZARD_DONE_LOADING:
            newState = _.cloneDeep(state);
            newState.doneLoading = true;
            break;
        case APP_V2_WIZARD_BACK:
            newState = _.cloneDeep(state);
            newState.stage -= 1;
            break;
        case WIZARD_CREATE_ANOTHER_OFFER:
            newState = _.cloneDeep(state);
            newState.stage = 2;
            newState.previewReady = false;
            initState(newState);
            break;
        case WIZARD_COLLECTIONS_ARE_UPLOADED:
            newState = _.cloneDeep(state);
            newState.collectionsAreUploaded = true;
            break;
        case WIZARD_CLOSE_ENABLE_ZOORIX_MODAL:
            newState = _.cloneDeep(state);
            newState.enableZoorixModalOpen = false;
            break;
        case TOGGLE_CONTACT_US_MODAL:
            newState = _.cloneDeep(state);
            newState.contactUsModalOpen = !newState.contactUsModalOpen;
            break;
        case TOGGLE_SELECT_PRODUCTS_MODAL:
            newState = _.cloneDeep(state);
            newState.selectProductsModalOpen = !newState.selectProductsModalOpen;
            if (newState.selectProductsModalOpen) {
                newState.selectedProducts = _.cloneDeep(newState.confirmedSelectedProducts);
                colorSearchedProducts(newState);
            }
            break;
        case TOGGLE_CROSS_SELL_SELECT_PRODUCTS_MODAL:
            newState = _.cloneDeep(state);
            newState.selectProductsModalOpen = !newState.selectProductsModalOpen;
            if (newState.selectProductsModalOpen) {
                newState.selectedProducts = _.cloneDeep(newState.confirmedCrossSellSelectedProducts);
                colorSearchedProducts(newState);
            }
            break;
        case TOGGLE_SELECT_COLLECTIONS_MODAL:
            newState = _.cloneDeep(state);
            newState.selectCollectionsModalOpen = !newState.selectCollectionsModalOpen;
            if (newState.selectCollectionsModalOpen) {
                newState.selectedCollections = _.cloneDeep(newState.confirmedSelectedCollections);
                colorSearchedCollections(newState);
            }
            break;
        case TOGGLE_CROSS_SELL_SELECT_COLLECTIONS_MODAL:
            newState = _.cloneDeep(state);
            newState.selectCollectionsModalOpen = !newState.selectCollectionsModalOpen;
            if (newState.selectCollectionsModalOpen) {
                newState.selectedCollections = _.cloneDeep(newState.confirmedCrossSellSelectedCollections);
                colorSearchedCollections(newState);
            }
            break;
        case TOGGLE_MNM_SELECT_COLLECTIONS_MODAL:
            newState = _.cloneDeep(state);
            newState.selectCollectionsModalOpen = !newState.selectCollectionsModalOpen;
            break;
        case TOGGLE_SELECT_TAGS_MODAL:
            newState = _.cloneDeep(state);
            newState.selectTagsModalOpen = !newState.selectTagsModalOpen;
            if (newState.selectTagsModalOpen) {
                newState.selectedTags = _.cloneDeep(newState.confirmedSelectedTags);
                colorSearchedTags(newState);
            }
            break;
        case TOGGLE_CROSS_SELL_SELECT_TAGS_MODAL:
            newState = _.cloneDeep(state);
            newState.selectTagsModalOpen = !newState.selectTagsModalOpen;
            if (newState.selectTagsModalOpen) {
                newState.selectedTags = _.cloneDeep(newState.confirmedCrossSellSelectedTags);
                colorSearchedTags(newState);
            }
            break;
        case APPROVE_SELECTED_PRODUCTS:
            newState = _.cloneDeep(state);
            newState.selectProductsModalOpen = false;
            newState.confirmedSelectedProducts = _.cloneDeep(newState.selectedProducts);
            break;
        case APPROVE_CROSS_SELL_SELECTED_PRODUCTS:
            newState = _.cloneDeep(state);
            newState.selectProductsModalOpen = false;
            newState.confirmedCrossSellSelectedProducts = _.cloneDeep(newState.selectedProducts);
            break;
        case APPROVE_SELECTED_COLLECTIONS:
            newState = _.cloneDeep(state);
            newState.selectCollectionsModalOpen = false;
            newState.confirmedSelectedCollections = _.cloneDeep(newState.selectedCollections);
            break;
        case APPROVE_CROSS_SELL_SELECTED_COLLECTIONS:
            newState = _.cloneDeep(state);
            newState.selectCollectionsModalOpen = false;
            newState.confirmedCrossSellSelectedCollections = _.cloneDeep(newState.selectedCollections);
            break;
        case APPROVE_MNM_SELECTED_COLLECTIONS:
            newState = _.cloneDeep(state);
            if (newState.selectedCollections.length > 0) {
                newState.selectCollectionsModalOpen = false;
                newState.mnm.product_groups.push({
                    ...newState.selectedCollections[0],
                    min_qty: 1,
                    group_type: 'collection',
                })
            }
            break;
        case APPROVE_SELECTED_TAGS:
            newState = _.cloneDeep(state);
            newState.selectTagsModalOpen = false;
            newState.confirmedSelectedTags = _.cloneDeep(newState.selectedTags);
            break;
        case APPROVE_CROSS_SELL_SELECTED_TAGS:
            newState = _.cloneDeep(state);
            newState.selectTagsModalOpen = false;
            newState.confirmedCrossSellSelectedTags = _.cloneDeep(newState.selectedTags);
            break;
        case SELECT_OFFER_TYPE:
            newState = _.cloneDeep(state);
            newState.offerType = action.offerType;
            break;
        case SELECT_BUNDLE_TYPE:
            newState = _.cloneDeep(state);
            newState.bundleType = action.bundleType;
            break;
        case WIZARD_SELECT_MNM_DISCOUNT_TYPE:
            newState = _.cloneDeep(state);
            newState.mnm.discount.type = action._type;
            break;
        case SELECT_TRIGGER:
            newState = _.cloneDeep(state);
            newState.triggerType = action.triggerType;
            break;
        case SELECT_CONTENT_TYPE:
            newState = _.cloneDeep(state);
            newState.contentType = action.contentType;
            break;
        case UPDATE_PRODUCT_SEARCH_TEXT:
            newState = _.cloneDeep(state);
            newState.searchText = action.text;
            newState.searchedProductsSpinner = true;
            newState.searchedProducts = [];
            break;
        case UPDATE_COLLECTION_SEARCH_TEXT:
            newState = _.cloneDeep(state);
            newState.searchText = action.text;
            newState.searchedCollectionsSpinner = true;
            newState.searchedCollections = [];
            break;
        case UPDATE_TAG_SEARCH_TEXT:
            newState = _.cloneDeep(state);
            newState.searchText = action.text;
            newState.searchedTags = [];
            break;
        case WIZARD_BUNDLE_AMOUNT_CHANGE:
            newState = _.cloneDeep(state);
            newState.bundle.amount = action.text || 0;
            break;
        case WIZARD_MNM_DISCOUNT_AMOUNT_CHANGE:
            newState = _.cloneDeep(state);
            newState.mnm.discount.amount = action.text || 0;
            break;
        case WIZARD_MNM_MIN_QTY_CHANGE:
            newState = _.cloneDeep(state);
            newState.mnm.product_groups[action.idx].min_qty = parseInt(action.text || 0);
            break;
        case WIZARD_VOLUME_QTY_CHANGE:
            newState = _.cloneDeep(state);
            newState.vd.tiers[action.idx].qty = action.qty || 0;
            break;
        case WIZARD_VOLUME_DISCOUNT_TYPE_CHANGE:
            newState = _.cloneDeep(state);
            newState.vd.tiers[action.idx].discountType = action.text;
            break;
        case WIZARD_VOLUME_DISCOUNT_CHANGE:
            newState = _.cloneDeep(state);
            newState.vd.tiers[action.idx].discount = action.text || 0;
            break;
        case WIZARD_VOLUME_POPULAR_CHANGE:
            newState = _.cloneDeep(state);
            for (let i = 0; i < newState.vd.tiers.length; i += 1) {
                newState.vd.tiers[i].popular = action.idx === i ? action.bool : false;
            }
            break;
        case WIZARD_VOLUME_TRASH_TIER:
            newState = _.cloneDeep(state);
            newState.vd.tiers.splice(action.idx, 1);
            break;
        case WIZARD_MNM_TRASH_PRODUCT_GROUP:
            newState = _.cloneDeep(state);
            newState.mnm.product_groups.splice(action.idx, 1);
            break;
        case WIZARD_SET_TRIGGER_STAGE_UUID:
            newState = _.cloneDeep(state);
            newState.stage4Uuid = action.uuid;
            newState.stage4FetchComplete = false;
            break;
        case WIZARD_SET_OFFER_STAGE_UUID:
            newState = _.cloneDeep(state);
            newState.stage5Uuid = action.uuid;
            newState.stage5FetchComplete = false;
            break;
        case WIZARD_SET_TRIGGER_STAGE_READY:
            newState = _.cloneDeep(state);
            if (newState.stage4Uuid === action.uuid) {
                newState.stage4FetchComplete = true;
                if (newState.triggerType !== 'bundle_items' && newState.triggerType !== 'mnm_items') newState.previewProductHandle = action.handle;
            }
            break;
        case WIZARD_SET_OFFER_STAGE_READY:
            newState = _.cloneDeep(state);
            if (newState.stage5Uuid === action.uuid) {
                newState.stage5FetchComplete = true;
                if (newState.triggerType === 'bundle_items' || newState.triggerType === 'mnm_items') newState.previewProductHandle = action.handle;
            }
            break;
        case WIZARD_VOLUME_ADD_TIER:
            newState = _.cloneDeep(state);
            const lastTier = newState.vd.tiers[newState.vd.tiers.length - 1];
            newState.vd.tiers.push({
                qty: lastTier.qty + 1,
                discount: lastTier.discount + 10,
                discountType: lastTier.discountType,
                popular: false,
            });
            break;
        case SET_SEARCHED_PRODUCTS:
            newState = _.cloneDeep(state);
            newState.searchedProducts = _.cloneDeep(action.json.result.data.data.products.edges);
            newState.searchedProductsSpinner = false;
            colorSearchedProducts(newState);
            break;
        case SET_BUNDLE_SEARCHED_PRODUCTS:
            newState = _.cloneDeep(state);
            newState.searchedProducts = _.cloneDeep(action.json.result.data.data.products.edges);
            newState.searchedProductsSpinner = false;
            break;
        case SET_SEARCHED_COLLECTIONS:
            newState = _.cloneDeep(state);
            newState.searchedCollections = _.cloneDeep(action.json.result.data.data.collections.edges);
            newState.searchedCollectionsSpinner = false;
            colorSearchedCollections(newState);
            break;
        case SET_SEARCHED_TAGS:
            newState = _.cloneDeep(state);
            newState.searchedTags = _.cloneDeep(action.edges);
            colorSearchedTags(newState);
            break;
        case SET_ALL_TAGS:
            newState = _.cloneDeep(state);
            newState.allTags = _.cloneDeep(action.json.result.data.data.shop.productTags.edges);
            break;
        case TOGGLE_SELECT_PRODUCT:
            newState = _.cloneDeep(state);
            if (newState.selectedProducts.find(p => p.id === action.id)) {
                _.remove(newState.selectedProducts, p => p.id === action.id);
            } else {
                const p = newState.searchedProducts.find(p => p.node.id === action.id);
                if (newState.offerType === 'volume_discount') newState.selectedProducts = [];
                newState.selectedProducts.push({
                    id: p.node.id,
                    title: p.node.title,
                })
            }
            colorSearchedProducts(newState);
            break;
        case TOGGLE_SELECT_COLLECTION:
            newState = _.cloneDeep(state);
            if (newState.selectedCollections.find(c => c.id === action.id)) {
                _.remove(newState.selectedCollections, c => c.id === action.id);
            } else {
                const c = newState.searchedCollections.find(c => c.node.id === action.id);
                if (newState.offerType === 'volume_discount' ||
                    (newState.offerType === 'mix_and_match' && newState.stage === 5)) newState.selectedCollections = [];
                newState.selectedCollections.push({
                    id: c.node.id,
                    title: c.node.title,
                })
            }
            colorSearchedCollections(newState);
            break;
        case TOGGLE_SELECT_TAG:
            newState = _.cloneDeep(state);
            if (newState.selectedTags.find(tag => tag === action.id)) {
                _.remove(newState.selectedTags, tag => tag === action.id);
            } else {
                const edge = newState.searchedTags.find(edge => edge.node === action.id);
                if (newState.offerType === 'volume_discount') newState.selectedTags = [];
                newState.selectedTags.push(edge.node)
            }
            colorSearchedTags(newState);
            break;
        case SELECT_PAGE_TYPE:
            newState = _.cloneDeep(state);
            newState.pageType[action.pageType] = !newState.pageType[action.pageType];
            if (newState.pageType[action.pageType]) {
                newState.invalidSelectPage = false;
            }
            break;
        case REMOVE_SELECTED_PRODUCT:
            newState = _.cloneDeep(state);
            _.remove(newState.selectedProducts, p => p.id === action.id);
            colorSearchedProducts(newState);
            break;
        case REMOVE_SELECTED_COLLECTION:
            newState = _.cloneDeep(state);
            _.remove(newState.selectedCollections, c => c.id === action.id);
            colorSearchedCollections(newState);
            break;
        case REMOVE_SELECTED_TAG:
            newState = _.cloneDeep(state);
            _.remove(newState.selectedTags, tag => tag === action.id);
            colorSearchedTags(newState);
            break;
        case WIZARD_CHOOSE_BUNDLE_PRODUCT:
            newState = _.cloneDeep(state);
            newState.bundle.choosingProductIndex = action.idx;
            newState.selectProductsModalOpen = true;
            newState.searchedProductsSpinner = true;
            break;
        case WIZARD_BUNDLE_REMOVE_PRODUCT:
            newState = _.cloneDeep(state);
            newState.bundle.products.splice(action.idx, 1);
            if (newState.bundle.products.length === 0) {
                newState.bundle.products.push({ empty: true });
            }
            break;
        case WIZARD_BUNDLE_SELECT_PRODUCT:
            newState = _.cloneDeep(state);
            const bundleProduct = newState.searchedProducts.find(p => p.node.id === action.id);
            newState.bundle.products[newState.bundle.choosingProductIndex] = {
                id: bundleProduct.node.id,
                title: bundleProduct.node.title,
                imageSrc: bundleProduct.node.images.edges.length > 0 && bundleProduct.node.images.edges[0].node.originalSrc,
            };
            newState.selectProductsModalOpen = false;
            break;
        case SENDING_SUPPORT_REQUEST:
            newState = _.cloneDeep(state);
            newState.sendingSupportRequest = action.bool;
            break;
        case WIZARD_SET_INVALID_SELECT_PAGE:
            newState = _.cloneDeep(state);
            newState.invalidSelectPage = action.bool;
            break;
        case WIZARD_SET_INVALID_SELECT_TRIGGER:
            newState = _.cloneDeep(state);
            newState.invalidSelectTrigger = action.bool;
            break;
        case WIZARD_SET_INVALID_SELECT_OFFER:
            newState = _.cloneDeep(state);
            newState.invalidSelectOffer = action.bool;
            break;
        case WIZARD_BUNDLE_CLOSE_SELECT_PRODUCTS_MODAL:
            newState = _.cloneDeep(state);
            newState.selectProductsModalOpen = false;
            break;
        case WIZARD_BUNDLE_ADD_PRODUCT:
            newState = _.cloneDeep(state);
            newState.bundle.products.push({ empty: true });
            break;
        case WIZARD_STATUS:
            newState = _.cloneDeep(state);
            newState.statusText = action.text;
            break;
        case WIZARD_PREVIEW_READY:
            newState = _.cloneDeep(state);
            newState.previewReady = true;
            break;
        case WIZARD_PREVIEW_PROGRESS:
            newState = _.cloneDeep(state);
            newState.previewProgress = action.progress;
            break;
        case WIZARD_SET_CREATE_STORE_JS_UUID:
            newState = _.cloneDeep(state);
            newState.createStoreJsUuid = action.uuid;
            break;
        default:
            return state;
    }
    return newState;
};

export default appV2Wizard;
