import * as _ from 'lodash';
import {
    CREATE_VOLUME_DISCOUNT,
    CREATE_VOLUME_DISCOUNT_FAILED,
    CREATING_VOLUME_DISCOUNT,
    INIT_PRODUCTS_VOLUME,
    INIT_PRODUCTS_VOLUME_COLLECTIONS,
    INIT_PRODUCTS_VOLUME_FAILED,
    ON_VOLUME_LOCALE_CHANGE,
    START_VOLUME_DISCOUNT_SEARCH,
    VOLUME_ADD_VARIANT,
    VOLUME_BACK,
    VOLUME_CLOSE_REMOVE_DISCOUNT_MODAL,
    VOLUME_DISCOUNT_ADD_GROUP,
    VOLUME_DISCOUNT_ADD_PRODUCT,
    VOLUME_DISCOUNT_ADD_TIER,
    VOLUME_DISCOUNT_ADD_VARIANT_TEXT_CHANGE,
    VOLUME_DISCOUNT_CART_DISPLAY_ON_CHANGE,
    VOLUME_DISCOUNT_CHOOSE_DYNAMIC_GROUP,
    VOLUME_DISCOUNT_CHOOSE_PRODUCT,
    VOLUME_DISCOUNT_CHOOSE_PRODUCT_ITEM,
    VOLUME_DISCOUNT_CLOSE_CHOOSE_PRODUCT,
    VOLUME_DISCOUNT_DISCOUNT_CHANGE,
    VOLUME_DISCOUNT_DISCOUNT_TYPE_CHANGE,
    VOLUME_DISCOUNT_DISPLAY_ON_CHANGE,
    VOLUME_DISCOUNT_DYNAMIC_GROUP_TYPE_CHANGE,
    VOLUME_DISCOUNT_DYNAMIC_GROUP_VALUE_CHANGE,
    VOLUME_DISCOUNT_GROUP_TYPE_CHANGE,
    VOLUME_DISCOUNT_GROUP_VALUE_CHANGE,
    VOLUME_DISCOUNT_MARK_AS_POPULAR,
    VOLUME_DISCOUNT_NAME_CHANGE,
    VOLUME_DISCOUNT_NAVIGATE_TO_CHANGE,
    VOLUME_DISCOUNT_OFFER_TYPE_CHANGE,
    VOLUME_DISCOUNT_PRODUCT_DISPLAY_ON_CHANGE,
    VOLUME_DISCOUNT_QTY_CHANGE,
    VOLUME_DISCOUNT_REMOVE_FAILURE,
    VOLUME_DISCOUNT_REMOVE_SOURCE_GROUP,
    VOLUME_DISCOUNT_REMOVE_SOURCE_PRODUCT,
    VOLUME_DISCOUNT_REMOVE_SUCCESS,
    VOLUME_DISCOUNT_REMOVE_TIER,
    VOLUME_DISCOUNT_REMOVING,
    VOLUME_DISCOUNT_SAVE_FAILURE,
    VOLUME_DISCOUNT_SAVE_SUCCESS,
    VOLUME_DISCOUNT_SAVING,
    VOLUME_DISCOUNT_SEARCH_FAILURE,
    VOLUME_DISCOUNT_SEARCH_SUCCESS,
    VOLUME_DISCOUNT_SUBTITLE_CHANGE,
    VOLUME_DISCOUNT_TITLE_CHANGE,
    VOLUME_DISCOUNT_TOGGLE_ADD_GROUP_ACTIVE,
    VOLUME_DISCOUNT_TOGGLE_DYNAMIC_ADD_GROUP_ACTIVE, VOLUME_DISMISS_GENERAL_TOAST,
    VOLUME_EDIT_DISCOUNT_FAILED,
    VOLUME_EDIT_DISCOUNT_SUCCESS,
    VOLUME_EDITING_DISCOUNT, VOLUME_GENERAL_TOAST,
    VOLUME_OPEN_REMOVE_DISCOUNT_MODAL,
    VOLUME_PREDEFINED_VARIANTS_CHANGE,
    VOLUME_PREVIEW_DISCOUNT_LOADING,
    VOLUME_REMOVE_VARIANT, VOLUME_SHOW_SHORT_CODE,
    VOLUME_SWITCH_DISCOUNT_ENABLED_FAILED,
    VOLUME_SWITCH_DISCOUNT_ENABLED_SUCCESS,
    VOLUME_SWITCHING_DISCOUNT_ENABLED,
    VOLUME_VARIANT_CHANGE,
    VOLUME_VARIANT_QTY_CHANGE
} from "../actions/productsVolume";
import {calcLocales} from "./texts";
import {handleFilter} from "./productsBundle";
import uuid4 from "uuid4";

function calcTierQty(tier) {
    tier.qty = _.sum(_.map(tier.predefined_variants, t => parseFloat(t.qty)));
}

const productsVolume = (state = {}, action) => {
    let newState = null;
    let tier = null;
    let num = null;
    switch (action.type) {
        case INIT_PRODUCTS_VOLUME:
            newState = _.cloneDeep(state);
            newState.ready = true;
            newState.isShowDiscounts = true;
            newState.discounts = _.cloneDeep(action.json.discounts);
            newState.availableLocales = [];
            newState.editingDiscount = {};
            newState.switchEnableLoading = {};
            newState.previewDiscountLoading = {};
            break;
        case INIT_PRODUCTS_VOLUME_COLLECTIONS:
            newState = _.cloneDeep(state);
            newState.collectionsReady = true;
            newState.groupAddition = {};
            newState.dynamicGroupAddition = {};
            newState.customCollections = _.cloneDeep(action.json.customCollections);
            newState.smartCollections = _.cloneDeep(action.json.smartCollections);
            newState.tags = _.cloneDeep(action.json.tags);
            newState.vendors = _.cloneDeep(action.json.vendors);
            newState.productTypes = _.cloneDeep(action.json.product_types);
            handleFilter(newState, 'groupAddition');
            handleFilter(newState, 'dynamicGroupAddition');
            break;
        case VOLUME_DISCOUNT_SAVE_SUCCESS:
            newState = _.cloneDeep(state);
            newState.isShowDiscounts = true;
            newState.isShowEdit = false;
            newState.isSaving = false;
            newState.discounts = _.cloneDeep(action.json.discounts);
            break;
        case CREATE_VOLUME_DISCOUNT:
            newState = _.cloneDeep(state);
            newState.creatingDiscount = false;
            newState.isShowDiscounts = false;
            newState.isShowEdit = true;
            if (action.json.available_locales) {
                newState.availableLocales = _.cloneDeep(action.json.available_locales);
            }
            newState.discount = {
                name: `Volume discount #${newState.discounts.length + 1}`,
                translations: _.cloneDeep(action.json.translations),
                nav_after_add: 'checkout',
                discount_type: 'percentage',
                display_on_products: 'on_product',
                display_on_cart: 'none',
                display_on_cart_bottom: false,
                display_on_cart_top: false,
                display_on_product: 'main',
                display_on_product_main: true,
                display_on_product_bottom: false,
                source_display_on: [],
                tiers: [],
            }
            calcLocales(newState, newState.discount.translations);
            break;
        case VOLUME_EDIT_DISCOUNT_SUCCESS:
            newState = _.cloneDeep(state);
            newState.editingDiscount[action.json.discount.id] = false;
            newState.isShowDiscounts = false;
            newState.isShowEdit = true;
            if (action.json.available_locales) {
                newState.availableLocales = _.cloneDeep(action.json.available_locales);
            }
            newState.discount = _.cloneDeep(action.json.discount);
            if (newState.discount.display_on_cart_bottom) {
                newState.discount.display_on_cart = newState.discount.display_on_cart_top ? 'both' : 'bottom';
            } else {
                newState.discount.display_on_cart = newState.discount.display_on_cart_top ? 'top' : 'none';
            }
            if (newState.discount.display_on_product_bottom) {
                newState.discount.display_on_product = newState.discount.display_on_product_main ? 'both' : 'bottom';
            } else {
                newState.discount.display_on_product = newState.discount.display_on_product_main ? 'main' : 'none';
            }
            calcLocales(newState, newState.discount.translations);
            break;
        case INIT_PRODUCTS_VOLUME_FAILED:
            newState = _.cloneDeep(state);
            newState.loadError = true;
            break;
        case VOLUME_DISMISS_GENERAL_TOAST:
            newState = _.cloneDeep(state);
            newState.generalToastIsActive = false;
            break;
        case VOLUME_DISCOUNT_TOGGLE_ADD_GROUP_ACTIVE:
            newState = _.cloneDeep(state);
            newState.groupAddition.addGroupActive = !newState.groupAddition.addGroupActive;
            break;
        case VOLUME_DISCOUNT_TOGGLE_DYNAMIC_ADD_GROUP_ACTIVE:
            newState = _.cloneDeep(state);
            newState.dynamicGroupAddition.addGroupActive = !newState.dynamicGroupAddition.addGroupActive;
            break;
        case VOLUME_DISCOUNT_GROUP_TYPE_CHANGE:
            newState = _.cloneDeep(state);
            newState.groupAddition.groupType = action.text;
            handleFilter(newState, 'groupAddition');
            break;
        case VOLUME_DISCOUNT_DYNAMIC_GROUP_TYPE_CHANGE:
            newState = _.cloneDeep(state);
            newState.dynamicGroupAddition.groupType = action.text;
            handleFilter(newState, 'dynamicGroupAddition');
            break;
        case VOLUME_DISCOUNT_GROUP_VALUE_CHANGE:
            newState = _.cloneDeep(state);
            newState.groupAddition.groupValue = action.text;
            break;
        case VOLUME_DISCOUNT_DYNAMIC_GROUP_VALUE_CHANGE:
            newState = _.cloneDeep(state);
            newState.dynamicGroupAddition.groupValue = action.text;
            break;
        case VOLUME_DISCOUNT_CART_DISPLAY_ON_CHANGE:
            newState = _.cloneDeep(state);
            newState.discount.display_on_cart = action.text;
            newState.discount.display_on_cart_bottom = action.text === 'bottom' || action.text === 'both';
            newState.discount.display_on_cart_top = action.text === 'top' || action.text === 'both';
            break;
        case VOLUME_DISCOUNT_PRODUCT_DISPLAY_ON_CHANGE:
            newState = _.cloneDeep(state);
            newState.discount.display_on_product = action.text;
            newState.discount.display_on_product_main = action.text === 'main' || action.text === 'both';
            newState.discount.display_on_product_bottom = action.text === 'bottom' || action.text === 'both';
            break;
        case VOLUME_DISCOUNT_OFFER_TYPE_CHANGE:
            newState = _.cloneDeep(state);
            newState.discount.dynamic = action.text === 'group';
            break;
        case VOLUME_DISCOUNT_CHOOSE_DYNAMIC_GROUP:
            newState = _.cloneDeep(state);
            newState.discount.filter1_type = newState.dynamicGroupAddition.groupType;
            newState.discount.filter1_value = newState.dynamicGroupAddition.groupValue;
            newState.dynamicGroupAddition.addGroupActive = false;
            break;
        case VOLUME_DISCOUNT_ADD_GROUP:
            newState = _.cloneDeep(state);
            newState.discount.source_display_on.push({
                id: uuid4(),
                source_type: 'product_group',
                filter1_type: newState.groupAddition.groupType,
                filter1_value: newState.groupAddition.groupValue,
            });
            newState.groupAddition.addGroupActive = false;
            break;
        case VOLUME_DISCOUNT_SAVING:
            newState = _.cloneDeep(state);
            newState.isSaving = true;
            newState.saveError = false;
            break;
        case VOLUME_DISCOUNT_REMOVING:
            newState = _.cloneDeep(state);
            newState.removeDiscountSpinner = true;
            newState.removeDiscountError = false;
            break;
        case VOLUME_DISCOUNT_SAVE_FAILURE:
            newState = _.cloneDeep(state);
            newState.isSaving = false;
            newState.saveError = true;
            break;
        case VOLUME_DISCOUNT_REMOVE_FAILURE:
            newState = _.cloneDeep(state);
            newState.removeDiscountSpinner = false;
            newState.removeDiscountError = true;
            break;
        case VOLUME_DISCOUNT_REMOVE_SUCCESS:
            newState = _.cloneDeep(state);
            newState.removeDiscountSpinner = false;
            newState.removeDiscountError = false;
            newState.discounts = _.cloneDeep(action.json.discounts);
            break;
        case VOLUME_SWITCH_DISCOUNT_ENABLED_SUCCESS:
            newState = _.cloneDeep(state);
            newState.switchEnableLoading[action.id] = false;
            newState.discounts = _.cloneDeep(action.json.discounts);
            break;
        case VOLUME_DISCOUNT_QTY_CHANGE:
            newState = _.cloneDeep(state);
            tier = newState.discount.tiers[action.id];
            if (action.text === '') {
                num = '';
            } else {
                num = parseInt(action.text);
                if (num < 0) {
                    num = 0;
                }
            }
            tier.qty = num;
            break;
        case VOLUME_DISCOUNT_DISCOUNT_TYPE_CHANGE:
            newState = _.cloneDeep(state);
            tier = newState.discount.tiers[action.id];
            tier.discount_type = action.text;
            break;
        case VOLUME_PREVIEW_DISCOUNT_LOADING:
            newState = _.cloneDeep(state);
            newState.previewDiscountLoading[action.id] = action.bool;
            break;
        case VOLUME_DISCOUNT_DISCOUNT_CHANGE:
            newState = _.cloneDeep(state);
            tier = newState.discount.tiers[action.id];
            if (action.text === '') {
                num = '';
            } else {
                num = parseFloat(action.text);
                if (num < 0) {
                    num = 0;
                }
            }
            tier.discount = num;
            break;
        case VOLUME_DISCOUNT_MARK_AS_POPULAR:
            newState = _.cloneDeep(state);
            newState.discount.tiers.forEach((tier, index) => {
               if (index === action.id) {
                   tier.popular = action.bool;
               } else {
                   tier.popular = false;
               }
            });
            break;
        case VOLUME_DISCOUNT_REMOVE_TIER:
            newState = _.cloneDeep(state);
            newState.discount.tiers.splice(action.id, 1);
            break;
        case VOLUME_DISCOUNT_REMOVE_SOURCE_PRODUCT:
            newState = _.cloneDeep(state);
            _.remove(newState.discount.source_display_on, {id: action.id});
            break;
        case VOLUME_DISCOUNT_REMOVE_SOURCE_GROUP:
            newState = _.cloneDeep(state);
            _.remove(newState.discount.source_display_on, {id: action.id});
            break;
        case VOLUME_VARIANT_QTY_CHANGE:
            newState = _.cloneDeep(state);
            tier = newState.discount.tiers[action.id];
            tier.predefined_variants[action.id2].qty = parseFloat(action.num);
            calcTierQty(tier);
            break;
        case VOLUME_VARIANT_CHANGE:
            newState = _.cloneDeep(state);
            tier = newState.discount.tiers[action.id];
            tier.predefined_variants[action.id2].id = parseFloat(action.text);
            break;
        case VOLUME_REMOVE_VARIANT:
            newState = _.cloneDeep(state);
            tier = newState.discount.tiers[action.id];
            tier.predefined_variants.splice(action.id2, 1);
            calcTierQty(tier);
            break;
        case VOLUME_ADD_VARIANT:
            newState = _.cloneDeep(state);
            tier = newState.discount.tiers[action.id];
            tier.predefined_variants.push({
                id: newState.discount.product.variants[0].shopify_id,
                qty: 1,
            });
            calcTierQty(tier);
            break;
        case VOLUME_PREDEFINED_VARIANTS_CHANGE:
            newState = _.cloneDeep(state);
            tier = newState.discount.tiers[action.id];
            if (tier.predefined_variants) {
                tier.predefined_variants = null;
            } else {
                tier.predefined_variants = [
                    {
                        id: newState.discount.product.variants[0].shopify_id,
                        qty: tier.qty || 0,
                    }
                ];
            }
            break;
        case VOLUME_OPEN_REMOVE_DISCOUNT_MODAL:
            newState = _.cloneDeep(state);
            newState.removeDiscountModalOpen = true;
            newState.discountIdToDelete = action.id;
            break;
        case VOLUME_SHOW_SHORT_CODE:
            newState = _.cloneDeep(state);
            newState.shortCodeModalOpen = action.bool;
            newState.shortCode = `<span zoorix-widget="v-discount" zoorix-offer-id="${action.id}"></span>`;
            break;
        case VOLUME_EDITING_DISCOUNT:
            newState = _.cloneDeep(state);
            newState.editingDiscount[action.id] = true;
            break;
        case VOLUME_SWITCHING_DISCOUNT_ENABLED:
            newState = _.cloneDeep(state);
            newState.switchEnableLoading[action.id] = true;
            break;
        case VOLUME_EDIT_DISCOUNT_FAILED:
            newState = _.cloneDeep(state);
            newState.editingDiscount[action.id] = false;
            break;
        case VOLUME_SWITCH_DISCOUNT_ENABLED_FAILED:
            newState = _.cloneDeep(state);
            newState.switchEnableLoading[action.id] = false;
            break;
        case VOLUME_CLOSE_REMOVE_DISCOUNT_MODAL:
            newState = _.cloneDeep(state);
            newState.removeDiscountModalOpen = false;
            newState.discountIdToDelete = null;
            break;
        case VOLUME_DISCOUNT_ADD_TIER:
            newState = _.cloneDeep(state);
            newState.discount.tiers.unshift({
                qty: 0,
                discount: 0,
                popular: false,
            });
            break;
        case START_VOLUME_DISCOUNT_SEARCH:
            newState = _.cloneDeep(state);
            newState.showSearchSpinner = true;
            newState.searchBoxText = action.text;
            newState.searchedProducts = [];
            break;
        case VOLUME_DISCOUNT_NAME_CHANGE:
            newState = _.cloneDeep(state);
            newState.discount.name = action.text;
            break;
        case VOLUME_DISCOUNT_DISPLAY_ON_CHANGE:
            newState = _.cloneDeep(state);
            newState.discount.display_on_products = action.text;
            break;
        case VOLUME_DISCOUNT_SEARCH_SUCCESS:
            newState = _.cloneDeep(state);
            if (newState.searchBoxText !== action.title) {
                // do nothing
            } else {
                newState.searchedProducts = _.cloneDeep(action.products);
                newState.showSearchSpinner = false;
                newState.searchError = false;
            }
            break;
        case VOLUME_DISCOUNT_CHOOSE_PRODUCT_ITEM:
            newState = _.cloneDeep(state);
            if (newState.destinationProduct === 'base') {
                newState.discount.product = _.cloneDeep(_.find(newState.searchedProducts, {id: action.id}));
                newState.discount.tiers && newState.discount.tiers.forEach(tier => {
                    tier.predefined_variants = null;
                });
            } else if (newState.destinationProduct === 'source') {
                const chosenProduct = _.cloneDeep(_.find(newState.searchedProducts, {id: action.id}));
                if (!_.includes(newState.discount.source_display_on.map(s => s.id), chosenProduct.id)) {
                    chosenProduct.source_type = 'single_product';
                    newState.discount.source_display_on.push(chosenProduct);
                }
            }
            newState.chooseProductOpen = false;
            break;
        case VOLUME_DISCOUNT_SEARCH_FAILURE:
            newState = _.cloneDeep(state);
            newState.searchError = true;
            newState.showSearchSpinner = false;
            break;
        case VOLUME_DISCOUNT_CHOOSE_PRODUCT:
            newState = _.cloneDeep(state);
            newState.destinationProduct = 'base';
            newState.chooseProductOpen = true;
            break;
        case VOLUME_DISCOUNT_ADD_PRODUCT:
            newState = _.cloneDeep(state);
            newState.chooseProductOpen = true;
            newState.destinationProduct = 'source';
            break;
        case VOLUME_DISCOUNT_CLOSE_CHOOSE_PRODUCT:
            newState = _.cloneDeep(state);
            newState.chooseProductOpen = false;
            break;
        case VOLUME_DISCOUNT_NAVIGATE_TO_CHANGE:
            newState = _.cloneDeep(state);
            newState.discount.nav_after_add = action.text;
            break;
        case ON_VOLUME_LOCALE_CHANGE:
            newState = _.cloneDeep(state);
            newState.locale = action.text;
            break;
        case VOLUME_GENERAL_TOAST:
            newState = _.cloneDeep(state);
            newState.generalToastCotent = action.text;
            newState.generalToastIsActive = true;
            break;
        case VOLUME_BACK:
            newState = _.cloneDeep(state);
            newState.isShowDiscounts = true;
            newState.isShowEdit = false;
            break;
        case CREATING_VOLUME_DISCOUNT:
            newState = _.cloneDeep(state);
            newState.creatingDiscount = true;
            break;
        case CREATE_VOLUME_DISCOUNT_FAILED:
            newState = _.cloneDeep(state);
            newState.creatingDiscount = false;
            break;
        case VOLUME_DISCOUNT_TITLE_CHANGE:
            newState = _.cloneDeep(state);
            newState.discount.translations[newState.locale].title = action.text;
            break;
        case VOLUME_DISCOUNT_SUBTITLE_CHANGE:
            newState = _.cloneDeep(state);
            newState.discount.translations[newState.locale].subtitle = action.text;
            break;
        case VOLUME_DISCOUNT_ADD_VARIANT_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.discount.translations[newState.locale].add_variant_text = action.text;
            break;
        default:
            return state;
    }
    return newState;
};

export default productsVolume;
