import * as _ from "lodash";
import {
    ACTIVATE_GENERIC_TOAST,
    APP_CLICKED,
    BUNDLE_CLICKED, CART_DRAWER_CLICKED, CHANGE_INTEGRATIONS_TAB, DISMISS_GENERIC_TOAST,
    HANDLE_TAB_CHANGE,
    INIT_NAV, MIX_AND_MATCH_CLICKED, SLIDER_CLICKED,
    tabs, VOLUME_DISCOUNT_CLICKED, WARRANTY_CLICKED
} from "../actions/nav";

const nav = (state = { }, action) => {
    let newState = null;
    switch (action.type) {
        case INIT_NAV:
            newState = _.cloneDeep(state);
            let newTabs = _.cloneDeep(tabs);
            if (action.installation.product_main &&
                action.installation.product_bottom &&
                action.installation.cart_top &&
                action.installation.cart_bottom &&
                action.installation.cart_items) {

                _.remove(newTabs, {id: 'manual-installation'});
            }

            if (!action.installation.installed_warranty) {
                _.remove(newTabs, { id: 'warranty-moderation' });
            }

            if (action.packageName === 'beta') {
                _.remove(newTabs, { id: 'plans' });
            }

            if (action.appType === 'bundles') {
                _.remove(newTabs, { id: 'plans' });
                _.remove(newTabs, { id: 'warranty-moderation' });
            }

            if (!action.appType) {
                _.remove(newTabs, { id: 'upgrade' });
            }

            newState.tabs = newTabs;
            newState.appType = action.appType;
            newState.integrationsTab = 0;
            break;
        case HANDLE_TAB_CHANGE:
            newState = _.cloneDeep(state);
            newState.selected = action.tab;
            if (newState.appType === 'bundles') {
                newState.isBundleVisible = true;
                newState.isAppVisible = false;
            }
            break;
        case CHANGE_INTEGRATIONS_TAB:
            newState = _.cloneDeep(state);
            newState.integrationsTab = action.tab;
            break;
        case ACTIVATE_GENERIC_TOAST:
            newState = _.cloneDeep(state);
            newState.genericToastActive = true;
            newState.genericToastContent = action.content;
            break;
        case DISMISS_GENERIC_TOAST:
            newState = _.cloneDeep(state);
            newState.genericToastActive = false;
            break;
        case SLIDER_CLICKED:
            newState = _.cloneDeep(state);
            newState.isSliderVisible = true;
            newState.isBundleVisible = false;
            newState.isWarrantyVisible = false;
            newState.isAppVisible = false;
            newState.isCartDrawerVisible = false;
            newState.isVolumeVisible = false;
            newState.isMixAndMatchVisible = false;
            break;
        case BUNDLE_CLICKED:
            newState = _.cloneDeep(state);
            newState.isSliderVisible = false;
            newState.isBundleVisible = true;
            newState.isWarrantyVisible = false;
            newState.isAppVisible = false;
            newState.isCartDrawerVisible = false;
            newState.isVolumeVisible = false;
            newState.isMixAndMatchVisible = false;
            break;
        case WARRANTY_CLICKED:
            newState = _.cloneDeep(state);
            newState.isSliderVisible = false;
            newState.isBundleVisible = false;
            newState.isWarrantyVisible = true;
            newState.isAppVisible = false;
            newState.isCartDrawerVisible = false;
            newState.isVolumeVisible = false;
            newState.isMixAndMatchVisible = false;
            break;
        case CART_DRAWER_CLICKED:
            newState = _.cloneDeep(state);
            newState.isSliderVisible = false;
            newState.isBundleVisible = false;
            newState.isWarrantyVisible = false;
            newState.isAppVisible = false;
            newState.isCartDrawerVisible = true;
            newState.isVolumeVisible = false;
            newState.isMixAndMatchVisible = false;
            break;
        case VOLUME_DISCOUNT_CLICKED:
            newState = _.cloneDeep(state);
            newState.isSliderVisible = false;
            newState.isBundleVisible = false;
            newState.isWarrantyVisible = false;
            newState.isAppVisible = false;
            newState.isCartDrawerVisible = false;
            newState.isVolumeVisible = true;
            newState.isMixAndMatchVisible = false;
            break;
        case MIX_AND_MATCH_CLICKED:
            newState = _.cloneDeep(state);
            newState.isSliderVisible = false;
            newState.isBundleVisible = false;
            newState.isWarrantyVisible = false;
            newState.isAppVisible = false;
            newState.isCartDrawerVisible = false;
            newState.isVolumeVisible = false;
            newState.isMixAndMatchVisible = true;
            break;
        case APP_CLICKED:
            newState = _.cloneDeep(state);
            newState.isSliderVisible = false;
            newState.isBundleVisible = false;
            newState.isWarrantyVisible = false;
            newState.isAppVisible = true;
            newState.isCartDrawerVisible = false;
            newState.isVolumeVisible = false;
            newState.isMixAndMatchVisible = false;
            break;
        default:
            return state;
    }
    return newState;
};

export default nav;
