import { connect } from 'react-redux'
import StickyAtcTexts from "../../components/sticky-atc/StickyAtcTexts";
import {
    stickyAtcChangeSaveTexts,
    stickyAtcLocaleChange,
    stickyAtcTextsChange
} from "../../actions/sticky-atc/stickyAtc";

const mapStateToProps = state => ({
    stickyAtc: state.stickyAtc,
});

const mapDispatchToProps = (dispatch) => ({
    onTextChange: attribute => text => dispatch(stickyAtcTextsChange(attribute, text)),
    onLocaleChange: locale => dispatch(stickyAtcLocaleChange(locale)),
    save: () => dispatch(stickyAtcChangeSaveTexts()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StickyAtcTexts);
