import { connect } from 'react-redux';
import SettingsWarranty from "../../components/settings/SettingsWarranty";
import {
    cancelUploadWarrantyDefault,
    dismissToast,
    emailSubjectChange, emailTemplateChange,
    notificationEmailChange,
    onDiscountPercentageChange,
    onTestEmailOrPhoneChange,
    restoreWarrantyToDefaults,
    restoreZoorixWarrantyDefault,
    saveWarrantySettings, sendAcceptNotificationChange,
    sendNotificationChange, sendPurchaseNotificationChange, sendRejectNotificationChange,
    sendTestEmail,
    sendTestSMS,
    smsSubjectChange, smsTemplateChange,
    uploadWarrantyDefault,
    uploadWarrantyDefaultFailure,
    uploadWarrantyDefaultSuccess,
    warrantyInCartsChange, warrantyShowOnCartChange, warrantyStoreNameChange,
} from "../../actions/settingsWarranty";

const mapStateToProps = state => ({
    toastIsActive: state.settingsWarranty.toastIsActive,
    saveInProgress: state.settingsWarranty.saveInProgress,
    saveError: state.settingsWarranty.saveError,
    settings: state.settingsWarranty.settings,
    tokenId: state.settingsWarranty.tokenId,
    isUploading: state.settingsWarranty.isUploading,
    uploadError: state.settingsWarranty.uploadError,
    emailOrPhone: state.settingsWarranty.emailOrPhone,
    sendTestEmailProcessing: state.settingsWarranty.sendTestEmailProcessing,
    sendTestEmailError: state.settingsWarranty.sendTestEmailError,
    sendTestEmailToast: state.settingsWarranty.sendTestEmailToast,
    sendTestSMSProcessing: state.settingsWarranty.sendTestSMSProcessing,
    sendTestSMSError: state.settingsWarranty.sendTestSMSError,
    sendTestSMSToast: state.settingsWarranty.sendTestSMSToast,
    planName: state.settingsWarranty.planName,

    shopName: state.data.shopName,
    token: state.data.token,
});

const mapDispatchToProps = (dispatch) => ({
    onDismissToast: () => dispatch(dismissToast()),
    save: () => dispatch(saveWarrantySettings()),
    restoreToDefaults: () => dispatch(restoreWarrantyToDefaults()),
    upload: () => dispatch(uploadWarrantyDefault()),
    cancelUpload: () => dispatch(cancelUploadWarrantyDefault()),
    uploadSuccess: () => dispatch(uploadWarrantyDefaultSuccess()),
    uploadFailure: () => dispatch(uploadWarrantyDefaultFailure()),
    restoreZoorixDefault: () => dispatch(restoreZoorixWarrantyDefault()),
    sendTestEmail: email_type => () => dispatch(sendTestEmail(email_type)),
    sendTestSMS: email_type => () => dispatch(sendTestSMS(email_type)),
    onEmailOrPhoneChange: email_type => text => dispatch(onTestEmailOrPhoneChange(email_type, text)),
    onEmailChange: text => dispatch(notificationEmailChange(text)),
    onPercentageChange: text => dispatch(onDiscountPercentageChange(text)),
    onEmailSubjectChange: email_type => text => dispatch(emailSubjectChange(email_type, text)),
    onEmailTemplateChange: email_type => text => dispatch(emailTemplateChange(email_type, text)),
    onSmsSubjectChange: email_type => text => dispatch(smsSubjectChange(email_type, text)),
    onSmsTemplateChange: email_type => text => dispatch(smsTemplateChange(email_type, text)),
    onStoreNameChange: text => dispatch(warrantyStoreNameChange(text)),
    onShowOnCartChange: text => dispatch(warrantyShowOnCartChange(text)),
    onSendNotificationChange: bool => dispatch(sendNotificationChange(bool)),
    onWarrantyInCartsChange: bool => dispatch(warrantyInCartsChange(bool)),
    onSendPurchaseNotificationChange: bool => dispatch(sendPurchaseNotificationChange(bool)),
    onSendAcceptNotificationChange: bool => dispatch(sendAcceptNotificationChange(bool)),
    onSendRejectNotificationChange: bool => dispatch(sendRejectNotificationChange(bool)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettingsWarranty);
