import * as _ from "lodash";
import {
    INIT_MIX_AND_MATCH,
    MIX_AND_MATCH_BOOL_CHANGE,
    MIX_AND_MATCH_CREATE_OFFER,
    MIX_AND_MATCH_CREATING_OFFER,
    MIX_AND_MATCH_DELETE_PRODUCT_GROUP,
    MIX_AND_MATCH_DELETE_TRIGGER_PRODUCT_GROUP, MIX_AND_MATCH_DELETING_OFFER,
    MIX_AND_MATCH_DISCOUNT_AMOUNT_CHANGE,
    MIX_AND_MATCH_DISCOUNT_TYPE_CHANGE,
    MIX_AND_MATCH_DISMISS_TOAST,
    MIX_AND_MATCH_EDIT_OFFER,
    MIX_AND_MATCH_LOAD_OFFERS,
    MIX_AND_MATCH_LOAD_OFFERS_ERROR,
    MIX_AND_MATCH_LOCALE_CHANGE,
    MIX_AND_MATCH_MIN_QTY_CHANGE,
    MIX_AND_MATCH_NAV_TO_CHANGE, MIX_AND_MATCH_NUM_OF_SEARCHED_PRODUCTS_CHANGE,
    MIX_AND_MATCH_OFFER_NAME_CHANGE,
    MIX_AND_MATCH_OFFER_TEXT_CHANGE, MIX_AND_MATCH_OPEN_DELETE_OFFER_MODAL,
    MIX_AND_MATCH_OPEN_SEARCH_COLLECTIONS_MODAL,
    MIX_AND_MATCH_OPEN_SHORT_CODE_MODAL,
    MIX_AND_MATCH_PREVIEW_OFFER_LOADING,
    MIX_AND_MATCH_SAVING_OFFER, MIX_AND_MATCH_SAVING_PROMO_TITLE,
    MIX_AND_MATCH_SAVING_SETTINGS,
    MIX_AND_MATCH_SEARCHING_COLLECTION,
    MIX_AND_MATCH_SELECT_COLLECTION,
    MIX_AND_MATCH_SET_SEARCH_COLLECTION_TITLE,
    MIX_AND_MATCH_SET_SEARCHED_COLLECTIONS,
    MIX_AND_MATCH_SET_STATE,
    MIX_AND_MATCH_SETTING_COLOR_CHANGE, MIX_AND_MATCH_SETTINGS_BOOL_CHANGE,
    MIX_AND_MATCH_SETTINGS_TEXT_CHANGE,
    MIX_AND_MATCH_TOAST,
    MIX_AND_MATCH_TOGGLE_SEARCH_COLLECTIONS_MODAL,
    MIX_AND_MATCH_TRIGGER_DISPLAY_OPTIONS_CHANGE,
    MIX_AND_MATCH_TRIGGER_LOCATION_CHANGE
} from "../actions/mixAndMatch";
import {calcLocales} from "./texts";

function fillDefault(state, attr) {
    if (!state.settings.values[attr]) {
        state.settings.values[attr] = state.settings.defaults[attr];
    }
}

const mixAndMatch = (state = { }, action) => {
    let newState = null;
    switch (action.type) {
        case INIT_MIX_AND_MATCH:
            newState = _.cloneDeep(state);
            newState.settings = _.cloneDeep(action.json.mix_and_match_settings);
            ['go_to_bundle_builder_background_color', 'go_to_bundle_builder_text_color', 'bundle_builder_save_text_color',
                'bundle_builder_save_bg_color', 'original_price_text_color', 'discounted_price_text_color',
                'num_of_searched_products', 'mix_and_match_promo_page_title'].forEach(
                    attr => fillDefault(newState, attr));
            break;
        case MIX_AND_MATCH_LOAD_OFFERS:
            newState = _.cloneDeep(state);
            newState.offers = _.cloneDeep(action.json.offers);
            newState.promoPage = _.cloneDeep(action.json.promo_page);
            newState.loadOffersError = false;
            newState.offersReady = true;
            newState.previewOfferLoading = {};
            break;
        case MIX_AND_MATCH_SET_SEARCHED_COLLECTIONS:
            newState = _.cloneDeep(state);
            newState.searchedCollections = _.cloneDeep(action.json.result.data.data.collections.edges);
            break;
        case MIX_AND_MATCH_EDIT_OFFER:
            newState = _.cloneDeep(state);
            newState.offer = _.cloneDeep(newState.offers.find(o => o.id === action.id));
            newState.offersState = 'edit_offer';
            calcLocales(newState, newState.offer.texts);
            break;
        case MIX_AND_MATCH_OPEN_DELETE_OFFER_MODAL:
            newState = _.cloneDeep(state);
            newState.deleteOfferModalOpen = action.bool;
            newState.offerToDelete = action.id;
            break;
        case MIX_AND_MATCH_OPEN_SHORT_CODE_MODAL:
            newState = _.cloneDeep(state);
            newState.shortCodeModalOpen = action.bool;
            newState.shortCodeOfferId = action.id;
            newState.shortCode = `<span zoorix-widget="mix-match" zoorix-widget-type="collapsed" zoorix-offer-id="${action.id}"></span>`;
            break;
        case MIX_AND_MATCH_PREVIEW_OFFER_LOADING:
            newState = _.cloneDeep(state);
            newState.previewOfferLoading[action.id] = action.bool;
            break;
        case MIX_AND_MATCH_CREATE_OFFER:
            newState = _.cloneDeep(state);
            if (action.json.available_locales) newState.availableLocales = _.cloneDeep(action.json.available_locales);
            newState.offer = {
                enabled: true,
                name: action.json.name,
                texts: _.cloneDeep(action.json.translations),
                offer: {
                    discount: {
                        type: 'fixed',
                        amount: 0,
                    },
                    product_groups: [],
                },
                navigate_to: 'checkout',
                triggers: {
                    on_offer_items: true,
                    product_groups: [],
                },
                trigger_locations: {
                    product_main: true,
                    product_bottom: false,
                    cart_top: false,
                    cart_bottom: false,
                }
            }
            calcLocales(newState, newState.offer.texts);
            break;
        case MIX_AND_MATCH_SAVING_SETTINGS:
            newState = _.cloneDeep(state);
            newState.saveInProgress = action.bool;
            break;
        case MIX_AND_MATCH_SAVING_PROMO_TITLE:
            newState = _.cloneDeep(state);
            newState.savingPromoPageTitle = action.bool;
            break;
        case MIX_AND_MATCH_TOAST:
            newState = _.cloneDeep(state);
            newState.toastIsActive = true;
            newState.toastContent = action.text;
            break;
        case MIX_AND_MATCH_NUM_OF_SEARCHED_PRODUCTS_CHANGE:
            newState = _.cloneDeep(state);
            if (action.text === '') {
                newState.settings.values.num_of_searched_products = action.text;
            } else {
                const i = parseInt(action.text);
                if (i < 1) {
                    newState.settings.values.num_of_searched_products = 1;
                } else if (i > 12) {
                    newState.settings.values.num_of_searched_products = 12;
                } else {
                    newState.settings.values.num_of_searched_products = i;
                }
            }
            break;
        case MIX_AND_MATCH_SET_SEARCH_COLLECTION_TITLE:
            newState = _.cloneDeep(state);
            newState.searchCollectionTitle = action.text;
            break;
        case MIX_AND_MATCH_OFFER_NAME_CHANGE:
            newState = _.cloneDeep(state);
            newState.offer.name = action.text;
            break;
        case MIX_AND_MATCH_DISCOUNT_TYPE_CHANGE:
            newState = _.cloneDeep(state);
            newState.offer.offer.discount.type = action.text;
            break;
        case MIX_AND_MATCH_DISCOUNT_AMOUNT_CHANGE:
            newState = _.cloneDeep(state);
            if (action.text < 0) action.text = 0;
            newState.offer.offer.discount.amount = parseFloat(action.text);
            break;
        case MIX_AND_MATCH_NAV_TO_CHANGE:
            newState = _.cloneDeep(state);
            newState.offer.navigate_to = action.text;
            break;
        case MIX_AND_MATCH_TRIGGER_DISPLAY_OPTIONS_CHANGE:
            newState = _.cloneDeep(state);
            newState.offer.triggers.on_offer_items = action.text === 'offer_items';
            break;
        case MIX_AND_MATCH_LOCALE_CHANGE:
            newState = _.cloneDeep(state);
            newState.locale = action.locale;
            break;
        case MIX_AND_MATCH_DISMISS_TOAST:
            newState = _.cloneDeep(state);
            newState.toastIsActive = false;
            break;
        case MIX_AND_MATCH_TOGGLE_SEARCH_COLLECTIONS_MODAL:
            newState = _.cloneDeep(state);
            newState.selectCollectionsModalOpen = !newState.selectCollectionsModalOpen;
            break;
        case MIX_AND_MATCH_OPEN_SEARCH_COLLECTIONS_MODAL:
            newState = _.cloneDeep(state);
            newState.searchCollectionModalType = action.modalType;
            newState.selectCollectionsModalOpen = true;
            break;
        case MIX_AND_MATCH_CREATING_OFFER:
            newState = _.cloneDeep(state);
            newState.creatingOffer = action.bool;
            break;
        case MIX_AND_MATCH_SAVING_OFFER:
            newState = _.cloneDeep(state);
            newState.savingOffer = action.bool;
            break;
        case MIX_AND_MATCH_SEARCHING_COLLECTION:
            newState = _.cloneDeep(state);
            newState.searchedCollectionsSpinner = action.bool;
            break;
        case MIX_AND_MATCH_DELETING_OFFER:
            newState = _.cloneDeep(state);
            newState.deletingOffer = action.bool;
            break;
        case MIX_AND_MATCH_SELECT_COLLECTION:
            newState = _.cloneDeep(state);
            newState.selectCollectionsModalOpen = false;
            if (newState.searchCollectionModalType === 'offer') {
                newState.offer.offer.product_groups.push({
                    group_type: 'collection',
                    id: action.id,
                    title: action.title,
                    min_qty: 1,
                })
            } else if (newState.searchCollectionModalType === 'trigger') {
                newState.offer.triggers.product_groups.push({
                    group_type: 'collection',
                    id: action.id,
                    title: action.title,
                })
            }
            break;
        case MIX_AND_MATCH_DELETE_PRODUCT_GROUP:
            newState = _.cloneDeep(state);
            newState.offer.offer.product_groups.splice(action.id, 1);
            break;
        case MIX_AND_MATCH_DELETE_TRIGGER_PRODUCT_GROUP:
            newState = _.cloneDeep(state);
            newState.offer.triggers.product_groups.splice(action.id, 1);
            break;
        case MIX_AND_MATCH_MIN_QTY_CHANGE:
            newState = _.cloneDeep(state);
            if (action.num < 1) action.num = 1;
            newState.offer.offer.product_groups[action.idx].min_qty = parseInt(action.num);
            break;
        case MIX_AND_MATCH_BOOL_CHANGE:
            newState = _.cloneDeep(state);
            newState.offer[action.attr] = action.text === 'yes';
            break;
        case MIX_AND_MATCH_LOAD_OFFERS_ERROR:
            newState = _.cloneDeep(state);
            newState.loadOffersError = true;
            break;
        case MIX_AND_MATCH_SET_STATE:
            newState = _.cloneDeep(state);
            newState.offersState = action.state;
            break;
        case MIX_AND_MATCH_SETTINGS_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.values[action.attr] = action.text;
            break;
        case MIX_AND_MATCH_SETTINGS_BOOL_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.values[action.attr] = action.bool === 'yes';
            break;
        case MIX_AND_MATCH_OFFER_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.offer.texts[newState.locale][action.attr] = action.text;
            break;
        case MIX_AND_MATCH_TRIGGER_LOCATION_CHANGE:
            newState = _.cloneDeep(state);
            newState.offer.trigger_locations[action.attr] = action.bool;
            break;
        case MIX_AND_MATCH_SETTING_COLOR_CHANGE:
            newState = _.cloneDeep(state);
            newState.settings.values[action.attr] = action.hex;
            break;
        default:
            return state;
    }
    return newState;
};

export default mixAndMatch;
