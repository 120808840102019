import React from 'react';
import {
    Button,
    Frame,
    Form,
    FormLayout,
    TextField,
    TextStyle,
    Toast, Card, Select, Checkbox, Icon, Tooltip
} from "@shopify/polaris";
import {InfoMinor} from "@shopify/polaris-icons";
import { ChromePicker } from 'react-color';
import '../../css/Settings.css';

export const fontWeightOptions = [
    { label: 'Normal', value: 'normal' },
    { label: 'Bold', value: 'bold' },
    { label: 'Bolder', value: 'bolder' },
    { label: 'Lighter', value: 'lighter' },
    { label: '100', value: '100' },
    { label: '200', value: '200' },
    { label: '300', value: '300' },
    { label: '400', value: '400' },
    { label: '500', value: '500' },
    { label: '600', value: '600' },
    { label: '700', value: '700' },
    { label: '800', value: '800' },
    { label: '900', value: '900' },
];

const cartOptions = [
    { label: 'Bottom', value: 'bottom' },
    { label: 'Top', value: 'top' },
    { label: 'Top & Bottom', value: 'both' },
];

const imageBackgroundOptions = [
    { label: 'Contain', value: 'contain' },
    { label: 'Cover', value: 'cover' },
];

export const currencyPositionOptions = [
    { label: 'Left', value: 'left' },
    { label: 'Right', value: 'right' },
];

export const alignOptions = [
    { label: 'Left', value: 'left' },
    { label: 'Center', value: 'center' },
    { label: 'Right', value: 'right' },
];

const selectionShapeOptions = [
    { label: 'Circle', value: 'circle' },
    { label: 'Square', value: 'square' },
];

const showDiscountedPricesOptions = [
    { label: 'Show discounted prices', value: 'show' },
    { label: 'Do not show discounted prices', value: 'dont' },
];

const navigateOptions = [
    { label: 'Checkout', value: 'checkout' },
    { label: 'Cart', value: 'cart' },
    { label: 'None', value: 'none' },
];

const displayOptions = [
    { label: 'Default', value: 'default' },
    { label: 'Collapse', value: 'collapse' },
    { label: 'Plain list', value: 'plain_list' },
];

const cartPopupOptions = [
    { label: 'Enabled', value: 'cart_popup' },
    { label: 'Disabled', value: 'dont' },
];

const cartDrawerDiscountOptions = [
    { label: 'Enabled', value: 'enable' },
    { label: 'Disabled', value: 'disable' },
];

const showSliderVariantsOptions = [
    { label: 'Show slider variants', value: 'show' },
    { label: 'Hide slider variants', value: 'hide' },
];

const mappingUsageOptions = [
    { label: 'Use only first mapping by priority', value: 'first' },
    { label: 'Use multiple mappings', value: 'multi' },
];

const separateAtcAndVariantOptions = [
    { label: 'Separated Add to cart button and variant selector', value: 'yes' },
    { label: 'Integrated Add to cart button with variant selector', value: 'no' },
];

export const fontOptions = [
    'inherit',
    'Arial',
    'Bookman',
    'Calibri',
    'Cambria',
    'Copperplate Gothic',
    'Garamond',
    'Helvetica',
    'Impact',
    'Palatino',
    'Times New Roman',
    'Trebuchet MS',
    'Verdana',
]

const SettingsSlider = ({
    onDismissToast, onSubmit, onMaxProductsChange,
    maxProducts, error, isSubmitting, toastIsActive, onTitleColorChange, titleColor,
    titleFontSize, onTitleFontSizeChange, titleFontWeight, onTitleFontWeightChange,
    subTitleFontSize, onSubTitleFontSizeChange, subTitleFontWeight, onSubTitleFontWeightChange,
    subTitleColor, onSubTitleColorChange, priceFontSize, onPriceFontSizeChange,
    onPriceFontWeightChange, priceFontWeight, priceColor, onPriceColorChange,
    comparePriceFontSize, onComparePriceFontSizeChange, onComparePriceFontWeightChange,
    comparePriceFontWeight, comparePriceColor, onComparePriceColorChange, productLinkFontSize,
    onProductLinkFontSizeChange, onProductLinkFontWeightChange, productLinkFontWeight,
    productLinkUnderline, onProductLinkUnderlineChange, productLinkColor, onProductLinkColorChange,
    selectionColor, onSelectionColorChange, onRestoreToDefaults, showQuantityModifier,
    onShowQuantityModifierChange, onCartPositionChange, cartPosition, shuffleProducts,
    onShuffleProductsChange, onCurrencyPositionChange, currencyPosition, customCurrency,
    onCustomCurrencyChange, currencyCutZeros, onCurrencyCutZerosChange, autoPlay, onAutoPlayChange,
    autoPlayInterval, onAutoPlayIntervalChange,
    hideProductLink, onHideProductLinkChange,
    belowAddToCart, onBelowAddToCartChange,
    productBottomEnabled, onProductBottomEnabledChange, refreshAfterAddToCart, onRefreshAfterAddToCartChange,
    onTitleAlignChange, titleAlign, border, onBorderChange, navCircle,
    onNavCircleChange, navColor, onNavColorChange,
    addToCartButtonFontSize, onAddToCartButtonFontSizeChange,
    addToCartButtonColor, addToCartButtonTextColor, onAddToCartButtonColorChange,
    onAddToCartButtonTextColorChange, addToCartButtonBottomWidget, onAddToCartButtonBottomWidgetChange,
    addToCartButtonCartWidget, onAddToCartButtonCartWidgetChange,
    addToCartButtonMainWidget, onAddToCartButtonMainWidgetChange, quickView, onQuickViewChange,
    imageBackground, onImageBackgroundChange,
    productDescOnQuickView, onProductDescOnQuickViewChange,
    numOfSlidesMobile, onNumOfSlidesMobileChange, numOfSlidesDesktop, onNumOfSlidesDesktopChange,
    sliderItemBackground, onSliderItemBackgroundChange, titleOnTop, onTitleOnTopChange, hideCompareTo,
    onHideCompareToChange, quickViewOnImageClicked, onQuickViewOnImageClickedChange, onSelectionShapeChange,
    selectionShape, onFontFamilyChange, sliderFontFamily, showQuantityModal, onShowQuantityModalChange, infoPopupSlider,
    onInfoPopupSliderChange, onNavigateToChange, navigateTo, recommendWithOrders, onRecommendWithOrdersChange,
    showSliderVariants, onShowSliderVariantsChange,
    sliderDiscount, onSliderDiscountChange, collapseMain, collapseBackgroundColor,
    onCollapseBackgroundColorChange, planName, navigateToProductPage, onNavigateToProductPageChange,
    sliderShowAfterItemAdded, onSliderShowAfterItemAddedChange, takeOnlyFirstMapping, onTakeOnlyFirstMappingChange,
    onShowDiscountedPricesChange, showDiscountedPrices, onCartPopupChange, cartPopup, onDisplayOptionsChange, plainList,
    onCartDrawerDiscountChange, cartDrawerDiscount, cartDrawerNumOfSlides, onCartDrawerNumOfSlidesChange,
    onlyOnSourceProducts, onOnlyOnSourceProductsChange, showOthersAfterGlobals, onShowOthersAfterGlobalsChanged,
    settings, onBoolChange,
}) => {

    return (
        <Frame>
            <Form onSubmit={onSubmit}>
                <FormLayout>
                    {error && <TextStyle variation="negative">There was an error sending your request. Please
                        try again in a few minutes</TextStyle>}
                    <div className="zrx-tab-wrapper-settings">
                        <div className="zrx-tab-title">Slider Settings</div>
                        <div className="zrx-tab-subtitle">Configuration and customizations of the slider offers</div>
                    </div>
                    <div className="form-card">
                        <Card title="Location in product page">
                            <div>
                                <Checkbox label="Middle" checked={belowAddToCart} onChange={onBelowAddToCartChange} disabled={isSubmitting} />&nbsp;
                               </div>
                                <Checkbox label="Bottom" checked={productBottomEnabled} onChange={onProductBottomEnabledChange} disabled={isSubmitting} /><br />
                            </Card>
                        </div>

                        <div className="form-card">
                            <Card title="Title">
                                <Select label="Alignment:" options={alignOptions} onChange={onTitleAlignChange} value={titleAlign} disabled={isSubmitting} />
                                <TextField label="Font size:" value={titleFontSize} onChange={onTitleFontSizeChange} disabled={isSubmitting} />
                                <Select label="Font weight:" options={fontWeightOptions} onChange={onTitleFontWeightChange} value={titleFontWeight} disabled={isSubmitting} />
                                <div className="color-title">Color:</div> <ChromePicker color={titleColor} onChangeComplete={onTitleColorChange} />
                            </Card>
                        </div>

                        <div className="form-card">
                            <Card title="Subtitle">
                                <TextField label="Font size:" value={subTitleFontSize} onChange={onSubTitleFontSizeChange} disabled={isSubmitting} />
                                <Select label="Font weight:" options={fontWeightOptions} onChange={onSubTitleFontWeightChange} value={subTitleFontWeight} disabled={isSubmitting} />
                                <div className="color-title">Color:</div> <ChromePicker color={subTitleColor} onChangeComplete={onSubTitleColorChange} />
                            </Card>
                        </div>

                        <div className="form-card">
                            <Card title="Display options">
                                <Select label={'Main widget'} options={displayOptions} onChange={onDisplayOptionsChange}
                                        value={collapseMain ? 'collapse' : (plainList ? 'plain_list' : 'default')}
                                        disabled={isSubmitting} />
                                {collapseMain && <div>
                                    <div className="color-title">Background color:</div>
                                    <ChromePicker color={collapseBackgroundColor}
                                                  onChangeComplete={onCollapseBackgroundColorChange} />
                                </div>}
                            </Card>
                        </div>

                        <div className="form-card">
                            <Card title="Add To Cart Button">
                                <div>
                                    <div style={{ marginBottom: '3px' }}>Enabled on widgets:</div>
                                    <Checkbox label="Main" checked={addToCartButtonMainWidget} onChange={onAddToCartButtonMainWidgetChange} disabled={isSubmitting} /><br />
                                    <Checkbox label="Bottom" checked={addToCartButtonBottomWidget} onChange={onAddToCartButtonBottomWidgetChange} disabled={isSubmitting} /><br />
                                    <Checkbox label="Cart" checked={addToCartButtonCartWidget} onChange={onAddToCartButtonCartWidgetChange} disabled={isSubmitting} /><br />
                                </div>
                                <div>
                                    <Select label="Navigate to:" options={navigateOptions} onChange={onNavigateToChange} value={navigateTo} disabled={isSubmitting} />
                                    <Select label="Display Options:" options={separateAtcAndVariantOptions}
                                            onChange={onBoolChange('separate_atc_and_variant_selector')}
                                            value={settings.values.separate_atc_and_variant_selector ? 'yes' : 'no'}
                                            disabled={isSubmitting} />
                                </div>
                                <div style={{ 'marginTop': '15px' }}>

                                    <span style={{ 'display': 'none' }} >
                                        <TextField label="Font size:" value={addToCartButtonFontSize} onChange={onAddToCartButtonFontSizeChange} disabled={isSubmitting} />
                                    </span>
                                </div>
                                <div style={{ 'marginTop': '15px' }}>
                                    <div className="color-title">Background Color:</div><ChromePicker color={addToCartButtonColor} onChangeComplete={onAddToCartButtonColorChange} />
                                    <div className="color-title">Text Color:</div><ChromePicker color={addToCartButtonTextColor} onChangeComplete={onAddToCartButtonTextColorChange} />
                                </div>
                            </Card>
                        </div>

                        <div className="form-card">
                            <Card title="Discount">
                                {sliderDiscount > 0 && <div>
                                    <TextField label="Discount on all slider items in %:" type="number"
                                               value={sliderDiscount && sliderDiscount.toString()}
                                               onChange={onSliderDiscountChange} disabled={isSubmitting} />
                                    <div className="field-tip">* To apply the discount only if item is bought together with the main product, use the product mapping discount</div>
                                </div>}
                                <Select label="Display options:" options={showDiscountedPricesOptions}
                                        onChange={onShowDiscountedPricesChange}
                                        value={showDiscountedPrices ? 'show' : 'dont'} disabled={isSubmitting} />
                            </Card>
                        </div>

                        <div className="form-card">
                            <Card title="Price">
                                <TextField label="Font size:" value={priceFontSize} onChange={onPriceFontSizeChange} disabled={isSubmitting} />
                                <Select label="Font weight:" options={fontWeightOptions} onChange={onPriceFontWeightChange} value={priceFontWeight} disabled={isSubmitting} />
                                <div className="color-title">Color:</div> <ChromePicker color={priceColor} onChangeComplete={onPriceColorChange} />
                            </Card>
                        </div>

                        <div className="form-card">
                            <Card title="Compare price">
                                <TextField label="Font size:" value={comparePriceFontSize} onChange={onComparePriceFontSizeChange} disabled={isSubmitting} />
                                <Select label="Font weight:" options={fontWeightOptions} onChange={onComparePriceFontWeightChange} value={comparePriceFontWeight} disabled={isSubmitting} />
                                <div style={{marginTop: '10px'}}>
                                    <Checkbox label="Hide" checked={hideCompareTo} onChange={onHideCompareToChange} disabled={isSubmitting} /><br/>
                                </div>
                                <div className="color-title">Color:</div> <ChromePicker color={comparePriceColor} onChangeComplete={onComparePriceColorChange} />
                            </Card>
                        </div>

                        <div className="form-card">
                            <Card title="Quick View">
                                <Checkbox label="Enable" checked={quickView} onChange={onQuickViewChange} disabled={isSubmitting} /><br/>
                                <Checkbox label="Show full product description" checked={productDescOnQuickView} onChange={onProductDescOnQuickViewChange} disabled={isSubmitting} /><br/>
                                <Checkbox label="Show quantity modifier" checked={showQuantityModal} onChange={onShowQuantityModalChange} disabled={isSubmitting} />
                                <div>
                                    <div style={{ marginBottom: '3px', marginTop: '12px' }}>Quick View on image clicked:</div>
                                    <Checkbox label="Enabled" checked={quickViewOnImageClicked} onChange={onQuickViewOnImageClickedChange} disabled={isSubmitting} />
                                </div>
                            </Card>
                        </div>
                        <div className="form-card">
                            <Card title="Product Name">
                                <TextField label="Font size:" value={productLinkFontSize} onChange={onProductLinkFontSizeChange} disabled={isSubmitting} />
                                <Select label="Font weight:" options={fontWeightOptions} onChange={onProductLinkFontWeightChange} value={productLinkFontWeight} disabled={isSubmitting} />
                                <div style={{marginTop: '10px'}}>
                                    <Checkbox label="Underline" checked={productLinkUnderline} onChange={onProductLinkUnderlineChange} disabled={isSubmitting} /><br />
                                </div>
                                <Checkbox label="Hidden" checked={hideProductLink} onChange={onHideProductLinkChange} disabled={isSubmitting} />
                                <div>
                                    <Checkbox label="Product name on top of price" checked={titleOnTop} onChange={onTitleOnTopChange} disabled={isSubmitting} />
                                </div>
                                <div className="color-title">Color:</div><ChromePicker color={productLinkColor} onChangeComplete={onProductLinkColorChange} />

                            </Card>
                        </div>

                        <div className="form-card">
                            <Card title="Selection">
                                <Checkbox label="Show quantity modifier" checked={showQuantityModifier} onChange={onShowQuantityModifierChange} disabled={isSubmitting} />
                                <Select label="Shape:" options={selectionShapeOptions} onChange={onSelectionShapeChange} value={selectionShape} disabled={isSubmitting} />
                                <div className="color-title">Color:</div><ChromePicker color={selectionColor} onChangeComplete={onSelectionColorChange} />
                            </Card>
                        </div>
                        <div className="form-card">
                            <Card title="Cart slider">
                                <Select label="Position:" options={cartOptions} onChange={onCartPositionChange} value={cartPosition} disabled={isSubmitting} />
                                <span className="refresh-after-add">
                                    <Checkbox label="Refresh after add to cart" checked={refreshAfterAddToCart} onChange={onRefreshAfterAddToCartChange} disabled={isSubmitting} />
                                    <span className="field-tip refresh-after-add-tip">(on full page carts)</span>
                                </span>
                                <div>
                                    <Checkbox label="Keep items in slider even if in cart" checked={sliderShowAfterItemAdded}
                                              onChange={onSliderShowAfterItemAddedChange} disabled={isSubmitting} />
                                </div>
                                <Select label="Slider in Ajax cart drawer:" options={cartPopupOptions} onChange={onCartPopupChange}
                                        value={cartPopup ? 'cart_popup' : 'dont'} disabled={isSubmitting} />
                                <Select label="Show discount in Ajax cart drawer:" options={cartDrawerDiscountOptions}
                                        onChange={onCartDrawerDiscountChange}
                                        value={cartDrawerDiscount ? 'enable' : 'disable'} disabled={isSubmitting} />
                            </Card>
                        </div>

                        <div className="form-card">
                            <Card title="Slider">
                                <TextField label="Number of slides on mobile" type="number"
                                           value={numOfSlidesMobile && numOfSlidesMobile.toString()}
                                           autoComplete={false}
                                           onChange={onNumOfSlidesMobileChange} disabled={isSubmitting} />
                                <TextField label="Number of slides on desktop" type="number"
                                           value={numOfSlidesDesktop && numOfSlidesDesktop.toString()}
                                           autoComplete={false}
                                           onChange={onNumOfSlidesDesktopChange} disabled={isSubmitting} />
                                <TextField label="Number of slider on cart drawer" type="number"
                                           value={cartDrawerNumOfSlides && cartDrawerNumOfSlides.toString()}
                                           autoComplete={false}
                                           onChange={onCartDrawerNumOfSlidesChange} disabled={isSubmitting} />
                                <div style={{marginTop: '10px'}}>
                                    <Checkbox label="Auto play" checked={autoPlay} onChange={onAutoPlayChange} disabled={isSubmitting} />
                                </div>
                                <TextField label="Auto play interval (in seconds)" type="number"
                                           value={autoPlayInterval && autoPlayInterval.toString()}
                                           autoComplete={false}
                                    onChange={onAutoPlayIntervalChange} disabled={isSubmitting} />
                                <div style={{ marginTop: '5px' }}>
                                    <Select label="Image background:" options={imageBackgroundOptions} onChange={onImageBackgroundChange} value={imageBackground} disabled={isSubmitting} />
                                </div>
                                <div style={{ 'marginTop': '13px' }}>
                                    <Checkbox label="Show navigation circle" checked={navCircle} onChange={onNavCircleChange} disabled={isSubmitting} />
                                </div>
                                <div className="color-title">Navigation color:</div><ChromePicker color={navColor} onChangeComplete={onNavColorChange} />
                                <div className="color-title">Item background:</div><ChromePicker color={sliderItemBackground} onChangeComplete={onSliderItemBackgroundChange} />
                            </Card>
                        </div>
                        <div className="form-card">
                            <Card title="Notification Popup">
                                <Checkbox label="Enabled" checked={infoPopupSlider} onChange={onInfoPopupSliderChange} disabled={isSubmitting} /><br />
                            </Card>
                        </div>
                        <div className="form-card">
                            <Card title="Product recommendations">
                                <Checkbox label={'Show slider only on source products'} checked={onlyOnSourceProducts}
                                          onChange={onOnlyOnSourceProductsChange} disabled={isSubmitting} />
                                <span style={{marginLeft: '7px'}}>
                                    <Tooltip light content="When disabled, the app generates recommendations for items which are not mapped manually">
                                        <Icon source={InfoMinor} />
                                    </Tooltip>
                                </span>

                                <br/>
                                <Checkbox label="AI powered recommendations" checked={recommendWithOrders} onChange={onRecommendWithOrdersChange} disabled={isSubmitting} />
                                <span style={{marginLeft: '7px'}}>
                                    <Tooltip light content="Recommendations are generated based on analysing store orders">
                                        <Icon source={InfoMinor} />
                                    </Tooltip>
                                </span>

                                <br />
                                <Checkbox label="Show other products after sticky products" checked={showOthersAfterGlobals}
                                          onChange={onShowOthersAfterGlobalsChanged} disabled={isSubmitting} /> <br/>
                                <Select label="Mappings usage:" options={mappingUsageOptions} onChange={onTakeOnlyFirstMappingChange}
                                        value={takeOnlyFirstMapping ? 'first' : 'multi'}
                                        disabled={isSubmitting} />
                            </Card>
                        </div>
                        <div className="form-card">
                            <Card title="Advanced">
                                <Checkbox label="Shuffle products" checked={shuffleProducts} onChange={onShuffleProductsChange} disabled={isSubmitting} /><br />
                                <Checkbox label="Border" checked={border} onChange={onBorderChange} disabled={isSubmitting} /><br />
                                <Checkbox label="Navigate to product page" checked={navigateToProductPage} onChange={onNavigateToProductPageChange} disabled={isSubmitting} /><br />
                                <Select label="Widget font:" options={fontOptions} onChange={onFontFamilyChange} value={sliderFontFamily} disabled={isSubmitting} />
                                <Select label="Slider variants display:" options={showSliderVariantsOptions} onChange={onShowSliderVariantsChange}
                                        value={showSliderVariants ? 'show' : 'hide'}
                                        disabled={isSubmitting} />
                            </Card>
                        </div>
                        <TextField label="Maximum amount of products to display" type="number"
                                   value={maxProducts && maxProducts.toString()}
                                   onChange={onMaxProductsChange} autoComplete={false} disabled={isSubmitting} />
                        <Button onClick={onRestoreToDefaults}>Restore to defaults</Button>
                        <span className={"save-button " + (planName === 'trial' ? 'trial' : '')}>
                            <Button id="save-settings" submit primary loading={isSubmitting}>Save</Button>
                        </span>

                    </FormLayout>
                </Form>

            {toastIsActive && <Toast content="Settings saved" onDismiss={onDismissToast} />}
        </Frame>

    )
};

export default SettingsSlider;
