import 'babel-polyfill'

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Root from './containers/Root'
import configureStore from "./configureStore";
// import * as Sentry from "@sentry/react";

// if (process.env.NODE_ENV === 'production') {
//     Sentry.init({
//         dsn: "https://bf80a22489a84381892cbae9da4bac00:2f028451aa2b43f89465422eb196596c@sentry.io/1889974",
//         release: "my-project-name@" + process.env.npm_package_version,
//     });
// }

const store = configureStore();

ReactDOM.render(<Root store={store} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// window.addEventListener('message', event => console.log(event));
