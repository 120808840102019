import React from 'react';
import {Button, Checkbox, FormLayout, Icon, Popover, Select, Spinner, TextField, TextStyle} from "@shopify/polaris";
import {DeleteMajor} from "@shopify/polaris-icons";
import NoImage from "./NoImage";
import {addByFilterOptions} from "./ProductsSlider";

const Excluded = ({backToMain, onSaveExcluded, isSavingExcluded, saveError, searchBoxText, onChangeExcludedSearchText,
                      onFocusInExcludedSearchText, showSearchSpinner, searchError, searchedProducts, addExcludedItem,
                      excludedProducts, removeExcludedItem, addAllToExcludedByFilterActive,
                      toggleAddAllToExcludedByFilterActive, isDynamicGrouping, onDynamicGroupingChange,
                      onExcludedFilterChange, excludedFilter, onExcludedFilterOptionChange, excludedFilterOption,
                      excludedFilterOptions, onAddAnotherExcludedFilterChange, isAddAnotherExcludedFilter,
                      onExcludedFilterChange2, excludedFilter2, excludedFilterOptions2, excludedFilterOption2,
                      onExcludedFilter2OptionChange, isAddingAllToExcludedByFilter, onAddAllToExcludedByFilter }) => (
    <div className="mapping-table">
        <div className="navigation product-mapping">
            <span className="navigation-button">
                <Button onClick={backToMain}>Back</Button>
            </span>
            <span className="filter-wrapper">
                <Popover
                    active={addAllToExcludedByFilterActive}
                    activator={<Button onClick={toggleAddAllToExcludedByFilterActive} disclosure>Add by filter</Button>}
                    onClose={toggleAddAllToExcludedByFilterActive}
                    ariaHaspopup={false}
                    sectioned
                >
                    <FormLayout>
                        <Select label="Add by filter:" options={addByFilterOptions} onChange={onExcludedFilterChange} value={excludedFilter} />
                        <Select options={excludedFilterOptions} onChange={onExcludedFilterOptionChange} value={excludedFilterOption} />
                        <Checkbox label="Dynamic grouping" checked={isDynamicGrouping}
                                  onChange={onDynamicGroupingChange} />
                        <Checkbox label="Add another filter" checked={isAddAnotherExcludedFilter} onChange={onAddAnotherExcludedFilterChange} />
                        {isAddAnotherExcludedFilter &&
                        <FormLayout>
                            <Select label="Second filter:" options={addByFilterOptions} onChange={onExcludedFilterChange2} value={excludedFilter2} />
                            <Select options={excludedFilterOptions2} onChange={onExcludedFilter2OptionChange} value={excludedFilterOption2} />
                        </FormLayout>

                        }
                        <Button primary onClick={onAddAllToExcludedByFilter} loading={isAddingAllToExcludedByFilter}>Add</Button>
                    </FormLayout>
                </Popover>
            </span>
            <span className="navigation-button">
                <Button onClick={onSaveExcluded} loading={isSavingExcluded} primary>Save</Button>
            </span>
        </div>
        {saveError ? <div><TextStyle variation="negative">Something went wrong</TextStyle></div> : null}
        <div className="search-products">
            <TextField placeholder='Search phrase, e.g. Shirts for men' autoComplete={false}
                       label="Add products to be excluded:"
                       value={searchBoxText} onChange={onChangeExcludedSearchText}
                       onFocus={onFocusInExcludedSearchText}/>
            {showSearchSpinner && <div className="fetch-search-wrapper"><span
                className="searching">Searching...</span><span className="fetch-spinner"><Spinner
                size="small"/></span></div> }
            {searchError && <TextStyle variation="negative">Something went wrong</TextStyle> }
            {searchedProducts && searchedProducts.map((item) => (
                <div className={'product-container searchable'} key={item.id}
                     onClick={addExcludedItem(item.id)}>
                    <span className="image-wrapper">
                        { item.image_src ? (
                            <div className="item-image"
                                 style={{'backgroundImage': 'url(' + item.image_src + ')'}}/>
                        ) : (
                            <NoImage itemId={item.id} klassName={'item-image'}/>
                        )}
                    </span>
                    <span className="item-title-wrapper">
                        <span className="item-title searchable">{item.title}</span>
                    </span>
                </div>
            ))}
        </div>
        <div className="source-products">
            <div className="source-products-title">Excluded products:</div>
            {excludedProducts && excludedProducts.map((item) => (
                <div className={'product-container'} key={item.id}>
                    {item.dynamic ?
                    <div className="collection-item-wrapper">
                        <div className="collection-item-name">{addByFilterOptions.filter(o => o.value === item.filter1_type)[0].label}: {item.filter1_value}</div>
                        {item.filter2_type && <div>, {addByFilterOptions.filter(o => o.value === item.filter2_type)[0].label}: {item.filter2_value}</div>}
                        <span className="collection-item-products">({item.count} products)</span>
                    </div> :
                    <div className="source-item-wrapper">
                        <span className="image-wrapper">
                        { item.image_src ? (
                            <div className="item-image"
                                 style={{'backgroundImage': 'url(' + item.image_src + ')'}}/>
                        ) : (
                            <NoImage itemId={item.id} klassName={'item-image'}/>
                        )}
                        </span>
                            <span className="item-title-wrapper">
                            <span className="item-title product">{item.title}</span>
                        </span>
                    </div>}

                    <span onClick={removeExcludedItem(item.id)} className="remove-item">
                        <Icon source={DeleteMajor}/>
                    </span>
                </div>))}
        </div>
    </div>
);

export default Excluded;
