import React from 'react';
import '../../css/sticky-atc/StickyAtc.scss';
import {Spinner, Card, Select, TextField, Button} from "@shopify/polaris";
import {ChromePicker} from "react-color";

const followUpActionsOptions = [
    { label: 'Checkout', value: 'checkout' },
    { label: 'Cart', value: 'cart' },
    { label: 'Refresh', value: 'refresh' },
    { label: 'None', value: 'none' },
];

const showOnOptions = [
    { label: 'Desktop & Mobile', value: 'both' },
    { label: 'Only Desktop', value: 'desktop' },
    { label: 'Only Mobile', value: 'mobile' },
];

const borderRadiusOptions = [
    { label: 'None', value: '0' },
    { label: 'Small', value: '0.5em' },
    { label: 'Big', value: '1.9em' },
];

const barPositionOptions = [
    { label: 'Top', value: 'top' },
    { label: 'Bottom', value: 'bottom' },
];

// const barAlignOptions = [
//     { label: 'Spread', value: 'spread' },
//     { label: 'Compact', value: 'compact' },
// ];

const showProductImageOptions = [
    { label: 'Show product image', value: 'yes' },
    { label: "Don't show product image", value: 'no' },
];

const showProductNameOptions = [
    { label: 'Show product name', value: 'yes' },
    { label: "Don't show product name", value: 'no' },
];

const showVariantsOptions = [
    { label: 'Show variants', value: 'yes' },
    { label: "Don't show variants", value: 'no' },
];

const showPriceOptions = [
    { label: 'Show price', value: 'yes' },
    { label: "Don't show price", value: 'no' },
];

const showCompareAtPriceOptions = [
    { label: 'Show compare at price', value: 'yes' },
    { label: "Don't show compare at price", value: 'no' },
];

const showQuantityOptions = [
    { label: 'Show quantity', value: 'yes' },
    { label: "Don't show quantity", value: 'no' },
];

const mobileMinimalModeOptions = [
    { label: 'Use mobile compact mode', value: 'yes' },
    { label: "Don't use mobile compact mode", value: 'no' },
];

const buttonShakeOptions = [
    { label: 'No Shake', value: '' },
    { label: 'Shake', value: 'zrx-sticky-atc-shake' },
];

const StickyAtcSettings = ({ stickyAtc, changeText, changeBool, changeColor, save, changeShowOn }) => {
    return <div className="sticky-atc-settings">
        {stickyAtc.settings ? <div>
            <Card title="General Settings">
                <Select label="Button on click follow up action" disabled={stickyAtc.savingSettings}
                        onChange={changeText('follow_up_action')}
                        options={followUpActionsOptions} value={stickyAtc.settings.follow_up_action} />
                <TextField label="Show after % of scroll" value={stickyAtc.settings.show_after_percentage_scroll}
                           disabled={stickyAtc.savingSettings}
                           type="number" autoComplete={false} onChange={changeText('show_after_percentage_scroll')} />
            </Card>
            <Card title="Design Settings">
                <Select label="Bar Position" disabled={stickyAtc.savingSettings}
                        onChange={changeText('bar_position')}
                        options={barPositionOptions} value={stickyAtc.settings.bar_position} />
                {/*<Select label="Bar Alignment" disabled={stickyAtc.savingSettings}*/}
                {/*        onChange={changeText('bar_align')}*/}
                {/*        options={barAlignOptions} value={stickyAtc.settings.bar_align} />*/}
                <Select label="Show On" disabled={stickyAtc.savingSettings}
                        onChange={changeShowOn}
                        options={showOnOptions}
                        value={stickyAtc.settings.show_on_desktop ?
                            (stickyAtc.settings.show_on_mobile ? 'both' : 'desktop') :
                            (stickyAtc.settings.show_on_mobile ? 'mobile' : 'none')}
                />
                <Select label="Product Image" disabled={stickyAtc.savingSettings}
                        onChange={changeBool('show_product_image')}
                        options={showProductImageOptions} value={stickyAtc.settings.show_product_image ? 'yes' : 'no'} />
                <Select label="Product Name" disabled={stickyAtc.savingSettings}
                        onChange={changeBool('show_product_name')}
                        options={showProductNameOptions} value={stickyAtc.settings.show_product_name ? 'yes' : 'no'} />
                <Select label="Variants" disabled={stickyAtc.savingSettings}
                        onChange={changeBool('show_variants')}
                        options={showVariantsOptions} value={stickyAtc.settings.show_variants ? 'yes' : 'no'} />
                <Select label="Price" disabled={stickyAtc.savingSettings}
                        onChange={changeBool('show_price')}
                        options={showPriceOptions} value={stickyAtc.settings.show_price ? 'yes' : 'no'} />
                <Select label="Compare At Price" disabled={stickyAtc.savingSettings}
                        onChange={changeBool('show_compare_at_price')}
                        options={showCompareAtPriceOptions} value={stickyAtc.settings.show_compare_at_price ? 'yes' : 'no'} />
                <Select label="Quantity" disabled={stickyAtc.savingSettings}
                        onChange={changeBool('show_quantity')}
                        options={showQuantityOptions} value={stickyAtc.settings.show_quantity ? 'yes' : 'no'} />
                <Select label="Buttons Border Radius" disabled={stickyAtc.savingSettings}
                        onChange={changeText('border_radius')}
                        options={borderRadiusOptions} value={stickyAtc.settings.border_radius} />
                <Select label="Button Shake" disabled={stickyAtc.savingSettings}
                        onChange={changeText('button_shake_class')}
                        options={buttonShakeOptions} value={stickyAtc.settings.button_shake_class} />
                <div className="select-lable-wrapper">
                    <div className="select-label">Mobile Compact Mode</div>
                    <div className="info-top">Compact mode hides product name and qty modifier on mobile</div>
                </div>
                <Select disabled={stickyAtc.savingSettings}
                        onChange={changeBool('mobile_minimal_mode')}
                        options={mobileMinimalModeOptions} value={stickyAtc.settings.mobile_minimal_mode ? 'yes' : 'no'} />
                <div className="margin-bottom-20"></div>
                <div className="color-field-title">Bar Color:</div>
                <ChromePicker color={stickyAtc.settings.bar_bg_color} onChangeComplete={changeColor('bar_bg_color')} />
                <div className="color-field-title">Bar Text Color:</div>
                <ChromePicker color={stickyAtc.settings.bar_text_color} onChangeComplete={changeColor('bar_text_color')} />
                <div className="color-field-title">Button Color:</div>
                <ChromePicker color={stickyAtc.settings.button_bg_color} onChangeComplete={changeColor('button_bg_color')} />
                <div className="color-field-title">Button Text Color:</div>
                <ChromePicker color={stickyAtc.settings.button_text_color} onChangeComplete={changeColor('button_text_color')} />
            </Card>
            <Card title="Confirmation">
                <TextField label="Show Confirmation For X Seconds" value={stickyAtc.settings.show_confirmation_seconds}
                           disabled={stickyAtc.savingSettings}
                           type="number" autoComplete={false} onChange={changeText('show_confirmation_seconds')} />
                <div className="color-field-title">Bar Color:</div>
                <ChromePicker color={stickyAtc.settings.confirmation_bar_bg_color} onChangeComplete={changeColor('confirmation_bar_bg_color')} />
                <div className="color-field-title">Text Color:</div>
                <ChromePicker color={stickyAtc.settings.confirmation_bar_text_color} onChangeComplete={changeColor('confirmation_bar_text_color')} />
            </Card>
             {/*<<Card title="Advanced">
                <TextField label="Main Atc selector" value={stickyAtc.settings.main_atc_selector}
                           disabled={stickyAtc.savingSettings}
                           placeholder="Please enter a value ..." autoComplete={false} onChange={changeText('main_atc_selector')} />
                <TextField label="Trigger Element Selector" value={stickyAtc.settings.trigger_element_selector}
                           disabled={stickyAtc.savingSettings}
                           placeholder="Please enter a value ..." autoComplete={false} onChange={changeText('trigger_element_selector')} /> 
                <TextField label="Scroll to other element selector" value={stickyAtc.settings.scroll_to_other_element}
                           disabled={stickyAtc.savingSettings}
                           placeholder="Please enter a value ..." autoComplete={false} onChange={changeText('scroll_to_other_element')} /> 


            </Card>*/}
            <div className="save-button">
                <Button onClick={save} loading={stickyAtc.savingSettings} primary>Save</Button>
            </div>
        </div> : <div>
            <Spinner />
        </div>}
    </div>
};

export default StickyAtcSettings;
