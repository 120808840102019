import React from 'react';
import '../../css/ProductsMain.css'
import VisibleSettingsSlider from "../../containers/settings/VisibleSettingsSlider";
import VisibleSettingsBundle from "../../containers/settings/VisibleSettingsBundle";
import VisibleSettingsWarranty from "../../containers/settings/VisibleSettingsWarranty";
import VisibleSettingsApp from "../../containers/settings/VisibleSettingsApp";
import VisibleSettingsCartDrawer from "../../containers/settings/VisibleSettingsCartDrawer";
import VisibleSettingsVolume from "../../containers/settings/VisibleSettingsVolume";
import VisibleSettingsMixAndMatch from "../../containers/settings/VisibleSettingsMixAndMatch";

const SettingsMain = ({ onSliderClicked, onBundleClicked, isSliderVisible,
    isBundleVisible, isWarrantyVisible, onWarrantyClicked, isAppVisible, onAppClicked, onCartDrawerClicked,
    isCartDrawerVisible, isVolumeVisible, onVolumeClicked, appType, isMixAndMatchVisible, onMixAndMatchClicked }) => (
        <div className="products-wrapper">
            <div className="slider settings">
                {appType !== 'bundles' && <div className={'sidebar-item' + (isSliderVisible ? ' selected' : '')} onClick={onSliderClicked}>Slider</div>}
                <div className={'sidebar-item' + (isBundleVisible ? ' selected' : '')} onClick={onBundleClicked}>Bundle</div>
                {appType !== 'bundles' && <div className={'sidebar-item' + (isWarrantyVisible ? ' selected' : '')} onClick={onWarrantyClicked}>Warranty</div>}
                {appType !== 'bundles' && <div className={'sidebar-item' + (isCartDrawerVisible ? ' selected' : '')} onClick={onCartDrawerClicked}>Cart Drawer</div>}
                {appType !== 'bundles' && <div className={'sidebar-item' + (isVolumeVisible ? ' selected' : '')} onClick={onVolumeClicked}>Volume Discount</div>}
                <div className={'sidebar-item' + (isMixAndMatchVisible ? ' selected' : '')} onClick={onMixAndMatchClicked}>Mix & Match</div>
                <div className={'sidebar-item' + (isAppVisible ? ' selected' : '')} onClick={onAppClicked}>General</div>
            </div>
            {isSliderVisible && <VisibleSettingsSlider />}
            {isBundleVisible && <VisibleSettingsBundle />}
            {isWarrantyVisible && <VisibleSettingsWarranty />}
            {isAppVisible && <VisibleSettingsApp />}
            {isCartDrawerVisible && <VisibleSettingsCartDrawer />}
            {isVolumeVisible && <VisibleSettingsVolume />}
            {isMixAndMatchVisible && <VisibleSettingsMixAndMatch />}

        </div>
    );

export default SettingsMain;
