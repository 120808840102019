import React from 'react';
import {
    Button,
    Frame,
    Form,
    FormLayout,
    TextStyle,
    Toast, Card, Checkbox, Spinner, Modal, Select, TextField,
} from "@shopify/polaris";
import '../../css/Settings.css';

const widgetOptions = [
    { label: 'Slider', value: 'slider_position' },
    { label: 'Bundles', value: 'bundles_position' },
    { label: 'Warranty', value: 'warranty_position' },
    { label: 'Volume Discount', value: 'volume_position' },
];

const positionOptions = [
    { label: 'Above', value: 'above' },
    { label: 'Below', value: 'below' },
];

const klaviyoOptions = [
    { label: 'Enabled', value: 'enabled' },
    { label: 'Disabled', value: 'disabled' },
];

const appBlocksOptions = [
    { label: 'Enabled', value: 'enabled' },
    { label: 'Disabled', value: 'disabled' },
];

const selectLastOptions = [
    { label: 'Select First', value: 'first' },
    { label: 'Select Last', value: 'last' },
];

const roundUpOptions = [
    { label: 'Round up', value: 'round' },
    { label: "Don't round", value: 'dont' },
];

const imageSharpnessOptions = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
];

const SettingsApp = ({
    onDismissToast, onSubmit,
    submissionError, isSubmitting, toastIsActive, onRestoreToDefaults, showOutOfStock, onShowOutOfStockChange,
    hasReadInventory, locations,
    showOnlyFromLocations, onShowOnlyFromLocationsChange, isLocationSelected, onSelectLocation,
    fetchingLocations, inventoryModalOpen, closeInventoryModal, planName, onWidgetOrderChange, widgetOrder,
    generalSetting, onPriceOrderCompareToLeftChange, onAboveProductMainChange, onAboveProductBottomChange,
    onAboveCartTopChange, onAboveCartBottomChange, onProductMainSelectorChange, onProductBottomSelectorChange,
    onCartTopSelectorChange, onCartBottomSelectorChange, jsSelectors, onImageSharpnessChange, onFacebookPixelChange,
    onKlaviyoChange, onlineStore2, onAppBlocksChange, onCartTotalSelectorChange, onCartTopSelectLastChange,
    onCartBottomSelectLastChange, appEmbeds, appType, onRoundUpChange }) => {

    return (
        <Frame>
            <div>

                <Form onSubmit={onSubmit}>
                    <FormLayout>
                        {submissionError && <TextStyle variation="negative">There was an error sending your request.
                            Please try again in a few minutes</TextStyle>}
                        <div className="zrx-tab-wrapper-settings">
                            <div className="zrx-tab-title">General Settings</div>
                            <div className="zrx-tab-subtitle">General configurations and customizations of the app</div>
                        </div>
                        <div className="form-card">
                            <Card title="Inventory">
                                <Checkbox label="Show out of stock items" checked={showOutOfStock && !showOnlyFromLocations} onChange={onShowOutOfStockChange} disabled={isSubmitting || showOnlyFromLocations} />
                                {hasReadInventory && fetchingLocations ? (
                                    <div className="locations-spinner">
                                        <Spinner size="small" /> <span className="fetching-locations">Fetching locations...</span>
                                    </div>
                                ) : null}
                                {hasReadInventory && locations && locations.length > 0 ? (
                                    <div>
                                        <Checkbox label="Show items only from the selected locations" checked={showOnlyFromLocations} onChange={onShowOnlyFromLocationsChange} disabled={isSubmitting} />
                                        {locations.map((item, index) => (
                                            <div key={item.id}>
                                                <span className='location-checkbox'>
                                                    <Checkbox label={item.name} checked={isLocationSelected[item.id].selected} onChange={onSelectLocation(item.id)} disabled={isSubmitting || !showOnlyFromLocations} />
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                ) : null}
                            </Card>
                        </div>

                        {!appType && <div className="form-card">
                            <Card title="Widgets order">
                                {[0,1,2,3].map(i =>
                                    <div key={i}>
                                        <Select options={widgetOptions} onChange={onWidgetOrderChange(i)}
                                                value={widgetOrder[i]} disabled={isSubmitting} />
                                    </div>
                                )}
                            </Card>
                        </div>}

                        <div className="form-card">
                            <Card title="Images">
                                <Select label={'Sharpness:'} options={imageSharpnessOptions}
                                        onChange={onImageSharpnessChange} disabled={isSubmitting}
                                        value={parseInt(generalSetting.values.image_sharpness).toString()}  />
                            </Card>
                        </div>

                        <div className="form-card">
                            <Card title="Integrations">
                                <TextField placeholder="000000000000001"
                                           label={<div>Facebook Pixel <div className="fb-pixel-tooltip">Tracking Add to Cart events on Facebook Ads.</div></div>}
                                           value={generalSetting.values.facebook_pixel}
                                           onChange={onFacebookPixelChange} disabled={isSubmitting}
                                           autoComplete={false} />

                               <div style={{marginTop: '20px'}}>
                                <Select options={klaviyoOptions} onChange={onKlaviyoChange}
                                        label={<div>Klaviyo<div className="fb-pixel-tooltip">Tracking Add to Cart events on Klaviyo</div></div>}
                                        value={generalSetting.values.enable_klaviyo ? 'enabled' : 'disabled'}
                                        disabled={isSubmitting} />
                               </div>
                            </Card>
                        </div>

                        <div className="form-card">
                            <Card title="Markets">
                                <Select options={roundUpOptions} onChange={onRoundUpChange}
                                        label="Round up prices"
                                        value={generalSetting.values.round_up_prices ? 'round' : 'dont'}
                                        disabled={isSubmitting} />
                            </Card>
                        </div>

                        {false && onlineStore2 && <div className="form-card">
                            <Card title="App Blocks">
                                <Select options={appBlocksOptions} onChange={onAppBlocksChange}
                                        label={'App Blocks'}
                                        value={generalSetting.values.enable_app_blocks ? 'enabled' : 'disabled'}
                                        disabled={isSubmitting} />
                            </Card>
                        </div>}

                        {jsSelectors && <div className="form-card">
                            <Card title="Selectors">
                                <div className="selectors-wrapper">
                                    {!generalSetting.values.enable_app_blocks && <div>
                                    <div className="selector-card-title">Product page - middle</div>
                                    <div className="selector-card">
                                    <Select options={positionOptions} onChange={onAboveProductMainChange}
                                            label="Position"
                                            value={generalSetting.values.above_product_main ? 'above' : 'below'}
                                            disabled={isSubmitting} />
                                    <TextField label="Selector" value={generalSetting.values.product_main_selector}
                                               onChange={onProductMainSelectorChange}
                                               disabled={isSubmitting} autoComplete={false} />
                                    </div>
                                    <div className="selector-card-title">Product page - bottom</div>

                                    <div className="selector-card">

                                    <Select options={positionOptions} onChange={onAboveProductBottomChange}
                                            label="Position"
                                            value={generalSetting.values.above_product_bottom ? 'above' : 'below'}
                                            disabled={isSubmitting} />
                                    <TextField label="Selector" value={generalSetting.values.product_bottom_selector}
                                               onChange={onProductBottomSelectorChange}
                                               disabled={isSubmitting} autoComplete={false} />
                                    </div>
                                    </div>}
                                    <div className="selector-card-title">Cart page - top</div>
                                    <div className="selector-card">

                                    <Select options={positionOptions} onChange={onAboveCartTopChange}
                                            label="Position"
                                            value={generalSetting.values.above_cart_top ? 'above' : 'below'}
                                            disabled={isSubmitting} />
                                    <TextField label="Selector" value={generalSetting.values.cart_top_selector}
                                               onChange={onCartTopSelectorChange}
                                               disabled={isSubmitting} autoComplete={false} />
                                    <span className="selection-logic">
                                        <Select options={selectLastOptions} onChange={onCartTopSelectLastChange}
                                            label="Selection Logic"
                                            value={generalSetting.values.cart_top_selector_take_last ? 'last' : 'first'}
                                            disabled={isSubmitting} />
                                    </span>
                                    </div>
                                    <div className="selector-card-title">Cart page - bottom</div>

                                    <div className="selector-card">

                                    <Select options={positionOptions} onChange={onAboveCartBottomChange}
                                            label="Position"
                                            value={generalSetting.values.above_cart_bottom ? 'above' : 'below'}
                                            disabled={isSubmitting} />
                                    <TextField label="Selector" value={generalSetting.values.cart_bottom_selector}
                                               onChange={onCartBottomSelectorChange}
                                               disabled={isSubmitting} autoComplete={false} />
                                        <span className="selection-logic">
                                    <Select options={selectLastOptions} onChange={onCartBottomSelectLastChange}
                                            label="Selection Logic"
                                            value={generalSetting.values.cart_bottom_selector_take_last ? 'last' : 'first'}
                                            disabled={isSubmitting} />
                                            </span>
                                    </div>

                                    {(onlineStore2 || appEmbeds) && <div>
                                        <div className="selector-card-title">Cart page - total</div>
                                        <div className="selector-card cart-total-selector">

                                            <TextField label="Selector" value={generalSetting.values.cart_total_selector}
                                                       onChange={onCartTotalSelectorChange}
                                                       disabled={isSubmitting} autoComplete={false} />
                                        </div>
                                    </div>}

                                </div>
                            </Card>
                        </div>}

                        {false && <div className="form-card">
                            <Card title="Currency">
                                <Checkbox label="Original price left to discount price"
                                          checked={generalSetting.values.price_order_compare_to_left}
                                          onChange={onPriceOrderCompareToLeftChange} disabled={isSubmitting} />
                            </Card>
                        </div>}

                        <Button onClick={onRestoreToDefaults}>Restore to defaults</Button>
                        <span className={"save-button " + (planName === 'trial' ? 'trial' : '')}>
                            <Button id="save-settings" submit primary loading={isSubmitting}>Save</Button>
                        </span>

                    </FormLayout>
                </Form>
            </div>

            <Modal open={inventoryModalOpen} onClose={closeInventoryModal} title="Inventory settings change"
                   primaryAction={{
                        content: 'OK',
                        onAction: closeInventoryModal,
                   }}>
                <Modal.Section>
                    The widget might not be shown on some of your products.
                </Modal.Section>
            </Modal>

            {toastIsActive && <Toast content="Settings saved" onDismiss={onDismissToast} />}
        </Frame>

    )
};

export default SettingsApp;
