import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'
import { AppProvider } from "@shopify/polaris";
import enTranslations from '@shopify/polaris/locales/en.json';
import VisibleStickyAtc from "./sticky-atc/VisibleStickyAtc";
import {Provider as AppBridgeProvider} from '@shopify/app-bridge-react';
import '@shopify/polaris/dist/styles.css';
import '../css/Status.css';
import ExitIframe from "./ExitIframe";
import Test from "./Test";
// import VisibleStickyAtcDowntime from "./sticky-atc/VisibleStickyAtcDowntime";

const config = {
    apiKey: 'b4ffea2d78d05e4b4f01ca3f37c07fee',
    host: new URLSearchParams(window.location.search).get("host") || btoa('localhost'),
    forceRedirect: false
};

const Root = ({ store }) => (
    <AppBridgeProvider config={config}>
    <Provider store={store}>
        <Router>
            <AppProvider i18n={enTranslations}>
                <Switch>
                    <Route path="/sticky-atc/:shop/:token" component={VisibleStickyAtc} store={store}/>
                    <Route path="/home/index" component={VisibleStickyAtc} store={store}/>
                    <Route path="/front/exit_iframe" component={ExitIframe} />
                    <Route path="/test" component={Test} />
                    {/*<Route path="/sticky-atc/:shop/:token" component={VisibleStickyAtcDowntime} store={store}/>*/}
                </Switch>
            </AppProvider>
        </Router>
    </Provider>
    </AppBridgeProvider>
);

Root.propTypes = {
    store: PropTypes.object.isRequired
};

export default Root
