import { connect } from 'react-redux';
import SettingsApp from "../../components/settings/SettingsApp";
import {
    appSettingsRestoreToDefaults, dismissToast, selectLocation, submit, showOutOfStock,
    showOnlyFromLocations, widgetOrderChange, priceOrderCompareToLeftChange,
} from "../../actions/settingsApp";
import {
    aboveCartBottomChange,
    aboveCartTopChange,
    aboveProductBottomChange,
    aboveProductMainChange, appBlocksChange, cartBottomSelectLastChange,
    cartBottomSelectorChange, cartTopSelectLastChange,
    cartTopSelectorChange, cartTotalSelectorChange,
    closeSliderInventoryModal,
    facebookPixelChange,
    imageSharpnessChange, klaviyoChange,
    productBottomSelectorChange,
    productMainSelectorChange, roundUpChange
} from "../../actions/settings";

const mapStateToProps = state => ({
    showOutOfStock: state.settingsApp.showOutOfStock,
    showOnlyFromLocations: state.settingsApp.showOnlyFromLocations,
    isLocationSelected: state.settingsApp.isLocationSelected,
    hasReadInventory: state.settingsApp.hasReadInventory,
    locations: state.settingsApp.locations,
    fetchingLocations: state.settingsApp.fetchingLocations,
    inventoryModalOpen: state.settingsApp.inventoryModalOpen,
    submissionError: state.settingsApp.submissionError,
    isSubmitting: state.settingsApp.isSubmitting,
    toastIsActive: state.settingsApp.toastIsActive,
    planName: state.settingsApp.planName,
    widgetOrder: state.settingsApp.widgetOrder,
    generalSetting: state.settingsApp.generalSetting,
    jsSelectors: state.settingsApp.jsSelectors,

    onlineStore2: state.status.onlineStore2,
    appEmbeds: state.status.appEmbeds,

    appType: state.data.appType,
});

const mapDispatchToProps = (dispatch) => ({
    onDismissToast: () => dispatch(dismissToast()),
    onSubmit: () => dispatch(submit()),
    onRestoreToDefaults: () => dispatch(appSettingsRestoreToDefaults()),
    onSelectLocation: id => bool => dispatch(selectLocation(id, bool)),
    onWidgetOrderChange: id => text => dispatch(widgetOrderChange(id, text)),
    onShowOutOfStockChange: bool => dispatch(showOutOfStock(bool)),
    onPriceOrderCompareToLeftChange: bool => dispatch(priceOrderCompareToLeftChange(bool)),
    onShowOnlyFromLocationsChange: bool => dispatch(showOnlyFromLocations(bool)),
    closeInventoryModal: () => dispatch(closeSliderInventoryModal()),
    onAboveProductMainChange: text => dispatch(aboveProductMainChange(text)),
    onAboveProductBottomChange: text => dispatch(aboveProductBottomChange(text)),
    onAboveCartTopChange: text => dispatch(aboveCartTopChange(text)),
    onAboveCartBottomChange: text => dispatch(aboveCartBottomChange(text)),
    onProductMainSelectorChange: text => dispatch(productMainSelectorChange(text)),
    onProductBottomSelectorChange: text => dispatch(productBottomSelectorChange(text)),
    onCartTopSelectorChange: text => dispatch(cartTopSelectorChange(text)),
    onCartBottomSelectorChange: text => dispatch(cartBottomSelectorChange(text)),
    onImageSharpnessChange: text => dispatch(imageSharpnessChange(text)),
    onFacebookPixelChange: text => dispatch(facebookPixelChange(text)),
    onKlaviyoChange: text => dispatch(klaviyoChange(text)),
    onAppBlocksChange: text => dispatch(appBlocksChange(text)),
    onCartTotalSelectorChange: text => dispatch(cartTotalSelectorChange(text)),
    onCartTopSelectLastChange: text => dispatch(cartTopSelectLastChange(text)),
    onCartBottomSelectLastChange: text => dispatch(cartBottomSelectLastChange(text)),
    onRoundUpChange: text => dispatch(roundUpChange(text)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettingsApp);
