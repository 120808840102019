import * as _ from 'lodash';
import {
    INIT_FEEDBACK, INIT_FEEDBACK_TOKEN,
    POST_SUBMIT_RATING,
    RATING_SUBMIT_ERROR,
    RATING_SUBMIT_SUCCESS,
    RATING_TEXT_CHANGE
} from "../actions/feedback";

const feedback = (state = {}, action) => {
    let newState = null;
    switch (action.type) {
        case INIT_FEEDBACK:
            newState = _.cloneDeep(state);
            newState.token = action.token;
            newState.rating = action.rating;
            newState.gifSrc = '/dance' + Math.floor(Math.random() * 3) + '.gif';
            break;
        case INIT_FEEDBACK_TOKEN:
            newState = _.cloneDeep(state);
            newState.token = action.token;
            break;
        case RATING_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.ratingText = action.text;
            break;
        case POST_SUBMIT_RATING:
            newState = _.cloneDeep(state);
            newState.isRatingSubmitting = true;
            newState.isRatingSubmitError = false;
            break;
        case RATING_SUBMIT_ERROR:
            newState = _.cloneDeep(state);
            newState.isRatingSubmitting = false;
            newState.isRatingSubmitError = true;
            break;
        case RATING_SUBMIT_SUCCESS:
            newState = _.cloneDeep(state);
            newState.isShowThankYou = true;
            newState.isRatingSubmitError = false;
            break;

        default:
            return state;
    }
    return newState;
};

export default feedback;
