import React, {useState, useEffect} from 'react';
import '../../css/sticky-atc/StickyAtc.scss';
import { Frame, Spinner, Tabs, Toast } from "@shopify/polaris";
import { stickyTabs } from "../../actions/sticky-atc/stickyAtc";
import VisibleStickyAtcDashboard from "../../containers/sticky-atc/VisibleStickyAtcDashboard";
import VisibleStickyAtcSettings from "../../containers/sticky-atc/VisibleStickyAtcSettings";
import VisibleStickyAtcCssEditor from "../../containers/sticky-atc/VisibleStickyAtcCssEditor";
import VisibleStickyAtcSupport from "../../containers/sticky-atc/VisibleStickyAtcSupport";
import VisibleStickyAtcFaq from "../../containers/sticky-atc/VisibleStickyAtcFaq";
import VisibleStickyAtcTexts from "../../containers/sticky-atc/VisibleStickyAtcTexts";
import VisibleUpgrade from "../../containers/VisibleUpgrade";



const StickyAtc = ({ match: { params }, init, stickyAtc, handleTabSelect, onDismissToast,  onLoadCreateStoreJS,
                   fetchUpdate}) => {
    init(params);

    const [storeJsCreated, setStoreJsCreated] = useState(false);
    const [updateFetched, setUpdateFetched] = useState(false);

    useEffect(() => {
        if (!storeJsCreated) {
          onLoadCreateStoreJS(params);
          setStoreJsCreated(true);
        }
    }, [storeJsCreated, onLoadCreateStoreJS, params]);

    useEffect(() => {
        if (!updateFetched) {
            fetchUpdate(params);
            setUpdateFetched(true);
        }
    }, [updateFetched, fetchUpdate, params]);

    return <Frame>
        {
            !stickyAtc.initialized ?
                <div><Spinner /></div> :
                <div>
                    <Tabs selected={stickyAtc.selectedTab} tabs={stickyTabs(stickyAtc)} onSelect={handleTabSelect}>
                        {stickyTabs(stickyAtc)[stickyAtc.selectedTab].id === 'dashboard' && <VisibleStickyAtcDashboard />}
                        {stickyTabs(stickyAtc)[stickyAtc.selectedTab].id === 'settings' && <VisibleStickyAtcSettings />}
                        {stickyTabs(stickyAtc)[stickyAtc.selectedTab].id === 'css-editor' && <VisibleStickyAtcCssEditor />}
                        {stickyTabs(stickyAtc)[stickyAtc.selectedTab].id === 'support' && <VisibleStickyAtcSupport />}
                        {stickyTabs(stickyAtc)[stickyAtc.selectedTab].id === 'faq' && <VisibleStickyAtcFaq />}
                        {stickyTabs(stickyAtc)[stickyAtc.selectedTab].id === 'texts' && <VisibleStickyAtcTexts />}
                        {stickyTabs(stickyAtc)[stickyAtc.selectedTab].id === 'zoorix-m' && <VisibleUpgrade />}
                    </Tabs>
                </div>
        }
        {stickyAtc.toastActive && <Toast content={stickyAtc.toastContent} onDismiss={onDismissToast} />}
    </Frame>
};

export default StickyAtc;
