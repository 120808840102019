import React from 'react';
import {
    Button, Frame,
} from "@shopify/polaris";
import '../css/BundleUpgrade.scss'
// import VisiblePricingPlatinum from "../containers/plans/VisiblePricingPlatinum";


const Upgrade = ({ upgrade }) =>
    <div id="upgrade-container">
        <Frame>

            <div className="upgrade-top-bar">Get started for FREE <span aria-label='party' role="img">🥳</span></div>
            <div className="upgrade-app-head">
                <img className="upgrade-app-icon" srcSet="https://cdn.shopify.com/app-store/listing_images/878ee40e7b3300a1eea9cbb74df480fb/icon/CO6E69j0lu8CEAE=.png?height=168&amp;width=168 2x, https://cdn.shopify.com/app-store/listing_images/878ee40e7b3300a1eea9cbb74df480fb/icon/CO6E69j0lu8CEAE=.png?height=84&amp;width=84 1x" height="84" width="84" alt="Zoorix: Cross Sell Cart Upsell" src="https://cdn.shopify.com/app-store/listing_images/878ee40e7b3300a1eea9cbb74df480fb/icon/CO6E69j0lu8CEAE=.png?height=84&amp;width=84"></img>
                <div className="upgrade-details-wrapper">
                    <div className="upgrade-app-title">Zoorix: Cross Sell & Bundles</div>
                    <div className="upgrade-partner-title">by Zoorix</div>
                    <div className="upgrade-app-desc">Frequently Bought Together, Bundle Products, Quantity Breaks</div>
                    <div className="upgrade-app-reviews">5.0 (+574 reviews)</div>
                    <div className="upgrade-install-btn">
                        <Button onClick={upgrade} primary>Add app</Button>
                        <Button onClick={() => { window.open('https://zoorix-store.myshopify.com/') }}>View Demo Store</Button>
                    </div>
                    <div className="upgrade-installation-notes">Cancel any time. 30 trial days. Free Up To 50 Orders/Month</div>
                </div>
                <div className="upgrade-video-container">
                    <iframe title="Zoorix Video" width="101%" height="101%"
                        src="https://www.youtube.com/embed/CZ-xDMmQGBA">
                    </iframe>


                </div>

            </div>
            <div className="upgrade-key-benefit-images-container">
                <div className="upgrade-key-benefit-image-wrapper">
                    <img className="upgrade-key-benefit-image" alt="Benefit" srcSet="https://cdn.shopify.com/app-store/listing_images/878ee40e7b3300a1eea9cbb74df480fb/key_benefit/CJefvsLXuPICEAE=.jpeg?height=480&amp;quality=90&amp;width=640 2x, https://cdn.shopify.com/app-store/listing_images/878ee40e7b3300a1eea9cbb74df480fb/key_benefit/CJefvsLXuPICEAE=.jpeg?height=240&amp;quality=90&amp;width=320 1x" src="https://cdn.shopify.com/app-store/listing_images/878ee40e7b3300a1eea9cbb74df480fb/key_benefit/CJefvsLXuPICEAE=.jpeg?height=240&amp;quality=90&amp;width=320" />
                    <div className="upgrade-key-benefit-title">Get More Sales (!)</div>
                    <div className="upgrade-key-benefit-desc">Boost sales (!) with 1-click recommendations of frequently bought together, product bundles upsell, volume discounts & sticky cart drawer</div>
                </div>
                <div className="upgrade-key-benefit-image-wrapper">
                    <img className="upgrade-key-benefit-image" alt="Benefit" srcSet="https://cdn.shopify.com/app-store/listing_images/878ee40e7b3300a1eea9cbb74df480fb/key_benefit/COqnx8LXuPICEAE=.jpeg?height=480&amp;quality=90&amp;width=640 2x, https://cdn.shopify.com/app-store/listing_images/878ee40e7b3300a1eea9cbb74df480fb/key_benefit/COqnx8LXuPICEAE=.jpeg?height=240&amp;quality=90&amp;width=320 1x" src="https://cdn.shopify.com/app-store/listing_images/878ee40e7b3300a1eea9cbb74df480fb/key_benefit/COqnx8LXuPICEAE=.jpeg?height=240&amp;quality=90&amp;width=320" />
                    <div className="upgrade-key-benefit-title">In Cart Sweet Upsell<span className='upgrade-cart' aria-label='cart' role="img"> 🛒</span></div>
                    <div className="upgrade-key-benefit-desc">Sell more (!!) by offering cart cross sell & upsell with manual/AI product recommendations, in cart slider, quantity discounts & slide cart

                    </div>
                </div>
                <div className="upgrade-key-benefit-image-wrapper">
                    <img className="upgrade-key-benefit-image" alt="Benefit" srcSet="https://cdn.shopify.com/app-store/listing_images/878ee40e7b3300a1eea9cbb74df480fb/key_benefit/CLaf0cLXuPICEAE=.jpeg?height=480&amp;quality=90&amp;width=640 2x, https://cdn.shopify.com/app-store/listing_images/878ee40e7b3300a1eea9cbb74df480fb/key_benefit/CLaf0cLXuPICEAE=.jpeg?height=240&amp;quality=90&amp;width=320 1x" src="https://cdn.shopify.com/app-store/listing_images/878ee40e7b3300a1eea9cbb74df480fb/key_benefit/CLaf0cLXuPICEAE=.jpeg?height=240&amp;quality=90&amp;width=320" />
                    <div className="upgrade-key-benefit-title">Same Traffic, Increased AOV</div>
                    <div className="upgrade-key-benefit-desc">Increase AOV (!!!) using upsell system & cross sell features /w quantity breaks, also bought together, slider cart & bundle promotion page</div>
                </div>


            </div>

            <div className="bundle-upgrade">
                <div className="bundle-upgrade-compare-title">Features <span aria-label="" role="img">🤗</span></div>
                <table>
                    <thead>
                        <tr>
                            <th>Feature</th>
                            <th className="bg-green">Zoorix: Cross Sell & Upsell</th>

                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td>Bundle</td>
                            <td><span className="tick">&#10004;</span></td>
                        </tr>
                        <tr>
                            <td>Multi Currencies</td>
                            <td><span className="tick">&#10004;</span></td>
                        </tr>
                        <tr>
                            <td>Shopify Locations</td>
                            <td><span className="tick">&#10004;</span></td>
                        </tr>
                        <tr>
                            <td>Multi Languages</td>
                            <td><span className="tick">&#10004;</span></td>
                        </tr>
                        <tr>
                            <td>Online Store 2.0</td>
                            <td><span className="tick">&#10004;</span></td>
                        </tr>
                        <tr>
                            <td>Cross Sell</td>
                            <td><span className="tick">&#10004;</span></td>
                        </tr>
                        <tr>
                            <td>Volume Discount</td>
                            <td><span className="tick">&#10004;</span></td>
                        </tr>

                        <tr>
                            <td>Sticky Cart Drawer</td>
                            <td><span className="tick">&#10004;</span></td>
                        </tr>


                        <tr>
                            <td>Warranty Upsell</td>
                            <td><span className="tick">&#10004;</span></td>
                        </tr>

                        <tr>
                            <td>Product Recommendations</td>
                            <td><span className="tick">&#10004;</span></td>
                        </tr>

                        <tr>
                            <td>Ajax Cart Cross Sell</td>
                            <td><span className="tick">&#10004;</span></td>
                        </tr>
                    </tbody>
                </table>
            </div >

            <div className="upgrade-install-btn-wrapper">
                <div className="upgrade-install-call-to-action">Start <span className="upgrade-install-call-to-action-free">FREE</span> with Zoorix: Upsell & Cross Sell</div>
                <div className="upgrade-install-btn">
                    <Button onClick={upgrade} primary>Add app</Button>
                    <Button onClick={() => { window.open('https://zoorix-store.myshopify.com/') }}>View Demo Store</Button>
                </div>
            </div>

            {/*<div className="upgrade-pricing-table-wrapper">*/}
            {/*    <div className="upgrade-pricing-table-title-wrapper">*/}
            {/*        <span className="upgrade-pricing-table-title">Pricing</span>*/}
            {/*        <span className="upgrade-pricing-table-trial">30-days free trial</span>*/}
            {/*        <a rel="noopener noreferrer" target="_blank" href="https://www.zoorix.com/#pricing" className="upgrade-pricing-table-full-pricing">See all pricing options</a>*/}

            {/*    </div>*/}


            {/*     <VisiblePricingPlatinum />*/}
            {/*</div>*/}

            <div className="upgrade-faq">
                <div className="upgrade-faq-title">FAQ</div>
                <div className="upgrade-faq-grid">
                    <div className="upgrade-faq-block">
                        <div className="upgrade-faq-question">Would I should I install Zoorix: Cross Sell & Bundles?</div>
                        <div className="upgrade-faq-answer">It's a top rated app with +441 reviews with 5.0 avarage. The app offers multiple offers type and risk free with 30 free trials days</div>
                    </div>
                    <div className="upgrade-faq-block">
                        <div className="upgrade-faq-question">What is the ROI from the app?</div>
                        <div className="upgrade-faq-answer">Usually around 10K% ROI. See more info about the ROI dashboard - <a target="_blank" rel="noopener noreferrer" href='https://help.zoorix.com/en/article/understanding-the-roi-section-1y0bzyd/'>Learn More</a></div>
                    </div>
                    <div className="upgrade-faq-block">
                        <div className="upgrade-faq-question">What type of offers the app has?</div>
                        <div className="upgrade-faq-answer">Cross Sell (Slider), Bundle, Volume Discounts, Warranty Upsell, Smart Cart Drawer design for upsells. See more info about what the app has to offer - <a target="_blank" rel="noopener noreferrer" href='https://help.zoorix.com/en/article/understanding-the-roi-section-1y0bzyd/'>Learn More</a></div>
                    </div>
                    <div className="upgrade-faq-block">
                        <div className="upgrade-faq-question">Does the app leave any left overs on the theme?</div>
                        <div className="upgrade-faq-answer">No. The app is enabled and disabled on the App Embeds section and when after uninstalling the app there are no leftovers on the theme.</div>
                    </div>
                    <div className="upgrade-faq-block">
                        <div className="upgrade-faq-question">How many merchants are using the app?</div>
                        <div className="upgrade-faq-answer">6000+ merchants are using the app</div>
                    </div>
                    <div className="upgrade-faq-block">
                        <div className="upgrade-faq-question">Is the app completly free for small stores?</div>
                        <div className="upgrade-faq-answer">Yes, the app is totally fee for stores with less than 50 orders per month</div>
                    </div>
                </div>

                <div className="upgrade-install-btn-wrapper grey-wrapper">
                    <div className="upgrade-install-call-to-action">Start <span className="upgrade-install-call-to-action-free">FREE</span> with Zoorix: Upsell & Cross Sell</div>
                    <div className="upgrade-install-btn">
                        <Button onClick={upgrade} primary>Add app</Button>
                        <Button onClick={() => { window.open('https://zoorix-store.myshopify.com/') }}>View Demo Store</Button>
                    </div>
                </div>

                <div className="upgrade-reviews">
                    <div className="upgrade-reivews-wrapper">
                        <div className="upgrade-w-layout-grid upgrade-grid-review">
                            <div className="rating-left">
                                <div className="overall-rating">
                                    <h3 className="overall-rating-text">Overall Rating</h3>
                                    <h3 className="rating-star-text no-margin"><span className="start-review"></span>5.0</h3>
                                </div>
                                <div className="upgrade-based-reviews-text">Based on +441 reviews</div>
                            </div>
                            <div className="rating-right">
                                <h2>Top Reviews</h2>
                                <div className="review-blocks">
                                    <div className="review-block">
                                        <div className="review-stars">
                                            <div className="start-review"></div>
                                            <div className="start-review"></div>
                                            <div className="start-review"></div>
                                            <div className="start-review"></div>
                                            <div className="start-review"></div>
                                        </div>
                                        <p>Absolute game changer. I personally love this app, your customers will love this app. This stops them from being all over the place, put the bundle and upsells right there to keep everything simple for user experience</p>
                                        <div className="review-author">LETS-E-BIKE</div>
                                    </div>
                                    <div className="review-block">
                                        <div className="review-stars">
                                            <div className="start-review"></div>
                                            <div className="start-review"></div>
                                            <div className="start-review"></div>
                                            <div className="start-review"></div>
                                            <div className="start-review"></div>
                                        </div>
                                        <p>App is simply amazing. We received an immediate boost in sales and our customers love the upsell options we've provided them. It was exactly what we were looking for! Thank you so much. This app is highly recommended and the customer support is tremendous!!</p>
                                        <div className="review-author">Planet Tone</div>
                                    </div>
                                    <div className="review-block">
                                        <div className="review-stars">
                                            <div className="start-review"></div>
                                            <div className="start-review"></div>
                                            <div className="start-review"></div>
                                            <div className="start-review"></div>
                                            <div className="start-review"></div>
                                        </div>
                                        <p>I can't say enough about this app, 10/10. First, our previous in cart upsell was only converting around 18-20%, now we are on average hitting 25-30%. The biggest reason I like this app was how clean, responsive and fast it is on Mobile. You will not regret installing this app. @Zoorix, please continue to keep up the great support, app speed and having high add to cart % :)</p>
                                        <div className="review-author">Hooked Coolers</div>
                                    </div>
                                    <div className="review-block">
                                        <div className="review-stars">
                                            <div className="start-review"></div>
                                            <div className="start-review"></div>
                                            <div className="start-review"></div>
                                            <div className="start-review"></div>
                                            <div className="start-review"></div>
                                        </div>
                                        <p>"Excellent app! Easy to use, mostly intuitive. Once I learned to use it I was quickly able to add product to pages and the cart to upsell similar or matching products. When I needed support it was quick and the customization I asked for was done perfectly."</p>
                                        <div className="review-author">Carol &amp; Company</div>
                                    </div>
                                    <div className="review-block last">
                                        <div className="review-stars">
                                            <div className="start-review"></div>
                                            <div className="start-review"></div>
                                            <div className="start-review"></div>
                                            <div className="start-review"></div>
                                            <div className="start-review"></div>
                                        </div>
                                        <p>"I was looking for an app like this for like a half year. The cross sell is very well performing and brings a high conversion. The warranty Upsell is just an absolutely genius widget, I don't know any app that can handle that. This warranty upsell performs very well!! Absolutely like that app, awesome support as well."</p>
                                        <div className="review-author">backguard-de just</div>
                                    </div>
                                </div>
                                <div className="see-more-reviews-wrapper">
                                    <a rel="noopener noreferrer" className="see-more-reviews" href="https://apps.shopify.com/zoorix" target="_blank">See more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Frame >
    </div >

export default Upgrade;
