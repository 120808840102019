import * as _ from "lodash";
import {
    DIAMOND_CHANGE_RANGE,
    INIT_PLANS, INIT_PLANS_DATA, PLANS_PROMO_CODE_CHANGE, PLANS_SET_SUBMITTING_PROMO_CODE,
    PLAT_PLUS_BETA_CHANGE_RANGE,
    PLATINUM_PLUS_CHANGE_RANGE, SET_LOADING_PLANS, SET_LOADING_WHITE_LABEL
} from "../actions/plans";

const plans = (state = { }, action) => {
    let newState = null;
    switch (action.type) {
        case INIT_PLANS:
            newState = _.cloneDeep(state);
            newState.platinumPlusRange = 0;
            newState.diamondRange = 0;
            newState.platPlusBetaRange = 0;
            newState.refCodeLock = true;
            break;
        case SET_LOADING_PLANS:
            newState = _.cloneDeep(state);
            newState.loading = true;
            break;
        case SET_LOADING_WHITE_LABEL:
            newState = _.cloneDeep(state);
            newState.whiteLabelLoading = action.bool;
            break;
        case PLANS_SET_SUBMITTING_PROMO_CODE:
            newState = _.cloneDeep(state);
            newState.submittingPromoCode = action.bool;
            break;
        case INIT_PLANS_DATA:
            newState = _.cloneDeep(state);
            newState.currentCharge = _.cloneDeep(action.json.current_charge);
            newState.isFreeTier = action.json.is_free_tier;
            newState.poweredByZoorixWhiteLabel = action.json.powered_by_zoorix_white_label;
            newState.poweredByZoorixMonthlyCounter = action.json.powered_by_zoorix_monthly_counter;
            newState.refCodeLock = action.json.ref_code_lock;
            break;
        case PLANS_PROMO_CODE_CHANGE:
            newState = _.cloneDeep(state);
            newState.promoCode = action.text;
            break;
        case PLATINUM_PLUS_CHANGE_RANGE:
            newState = _.cloneDeep(state);
            newState.platinumPlusRange = action.value;
            break;
        case DIAMOND_CHANGE_RANGE:
            newState = _.cloneDeep(state);
            newState.diamondRange = action.value;
            break;
        case PLAT_PLUS_BETA_CHANGE_RANGE:
            newState = _.cloneDeep(state);
            newState.platPlusBetaRange = action.value;
            break;
        default:
            return state;
    }
    return newState;
};

export default plans;
