import React from 'react';
import {Button, Card, Frame, TextStyle, Toast, Form, FormLayout, TextField, Checkbox, Select} from '@shopify/polaris';
import '../../css/Settings.css';
import {ChromePicker} from "react-color";

const incentiveOptions = ['None', 'Free Discount', 'Free Shipping'];

const buttonOptions = [
    { label: 'Cart and Checkout', value: 'cart_and_checkout' },
    { label: 'Cart', value: 'cart' },
    { label: 'Checkout', value: 'checkout' },
]

const displayOnHomePageOptions = [
    { label: 'Display on home page', value: 'display_on_hp' },
    { label: 'Do not display on home page', value: 'dont_display_on_hp' },
]

const oneClickOfferOnEmptyCartOptions = [
    { label: 'Don\'t show on empty cart', value: 'dont_show' },
    { label: 'Show on empty cart', value: 'show' },
]

const openAfterAdcOptions = [
    { label: 'Open minicart after add to cart', value: 'open' },
    { label: 'Do not open minicart after add to cart', value: 'dont' },
]

const noteOptions = [
    { label: 'Show', value: 'enable' },
    { label: 'Don\'t show', value: 'disable' },
]

const freeTextEnabledOptions = [
    { label: 'Enabled', value: 'yes' },
    { label: 'Disabled', value: 'no' },
];

const termsEnabledOptions = [
    { label: 'Enabled', value: 'yes' },
    { label: 'Disabled', value: 'no' },
];

const showPaymentButtonsOptions = [
    { label: 'Show', value: 'yes' },
    { label: 'Hide', value: 'no' },
];

const showContinueShoppingOptions = [
    { label: 'Show', value: 'yes' },
    { label: 'Hide', value: 'no' },
];

const SettingsCartDrawer= ({ toastIsActive, onDismissToast, save, saveInProgress, saveError, settings, restoreToDefaults,
                               currencySymbol, onFreeDiscountThresholdChange,
                               onPromoBarColorChange, onLeftToSpendColorChange,
                               onCheckoutButtonColorChange,
                               onFreeDiscountAmountChange, planName, onFloatingIconBackgroundColorChange,
                               onFloatingIconColorChange, onInvokeFromHeaderChange, onIncentiveChange, incentive,
                               onFreeShippingThresholdChange,
                               onShowButtonsChange, buttonOption,
                               onMiniCartViewCartColorChange, onDisplayOnHomePageChange, onOpenAfterAdcChange,
                               onNoteChange, onOneClickOfferOnEmptyCartChange, onBoolChange
}) => (
    <Frame>
        <Form>
            <FormLayout>
                {saveError && <TextStyle variation="negative">There was an error sending your request. Please try
                    again in a few minutes</TextStyle>}
                <div className="zrx-tab-wrapper-settings">
                    <div className="zrx-tab-title">Cart Drawer Settings</div>
                    <div className="zrx-tab-subtitle">Configuration and customizations of the cart drawer widget</div>
                </div>
                <div className="form-card">
                    <div className="incentive-select-wrapper">
                        <Select label="Incentive" options={incentiveOptions} onChange={onIncentiveChange}
                            value={incentive} disabled={saveInProgress} />
                    </div>
                    {settings.free_shipping_enabled && <Card title={'Free Shipping'}>
                        <TextField label={`Threshold in ${currencySymbol}:`} type="number"
                            value={settings.free_shipping_threshold && settings.free_shipping_threshold.toString()}
                            autoComplete={false}
                            onChange={onFreeShippingThresholdChange} disabled={saveInProgress} />

                        </Card>}
                        {settings.free_discount_enabled && <Card title={'Free Discount'}>
                            <TextField label={`Threshold in ${currencySymbol}:`} type="number"
                                       value={settings.free_discount_threshold && settings.free_discount_threshold.toString()}
                                       autoComplete={false}
                                       onChange={onFreeDiscountThresholdChange} disabled={saveInProgress} />
                            <TextField label={`Discount in ${currencySymbol}:`} type="number"
                                       value={settings.free_discount_amount && settings.free_discount_amount.toString()}
                                       autoComplete={false}
                                       onChange={onFreeDiscountAmountChange} disabled={saveInProgress} />

                        </Card>}
                    </div>
                    <div className="form-card">
                        <Card title={'General'}>
                            <div className="cart-note-field">
                                <Select label="Cart Note:" options={noteOptions} onChange={onNoteChange}
                                    value={settings.cart_note ? 'enable' : 'disable'} disabled={saveInProgress} />
                            </div>
                            <Checkbox label="Invoke from header" checked={settings.invoke_from_header}
                                      onChange={onInvokeFromHeaderChange}
                                      disabled={saveInProgress} />
                            <Select label="Show buttons:" options={buttonOptions} onChange={onShowButtonsChange}
                                    value={buttonOption} disabled={saveInProgress} />
                            <Select label="Open after add to cart:" options={openAfterAdcOptions}
                                    onChange={onOpenAfterAdcChange}
                                    value={settings.open_after_adc ? 'open' : 'dont'} disabled={saveInProgress} />
                            <Select label="Free Text:" options={freeTextEnabledOptions}
                                    onChange={onBoolChange('free_text_enabled')}
                                    value={settings.free_text_enabled ? 'yes' : 'no'} disabled={saveInProgress} />
                            <Select label="Terms and Conditions:" options={termsEnabledOptions}
                                    onChange={onBoolChange('terms_and_conditions_enabled')}
                                    value={settings.terms_and_conditions_enabled ? 'yes' : 'no'} disabled={saveInProgress} />
                            <Select label="Payment Buttons:" options={showPaymentButtonsOptions}
                                    onChange={onBoolChange('show_payment_buttons')}
                                    value={settings.show_payment_buttons ? 'yes' : 'no'} disabled={saveInProgress} />
                            <Select label="Continue Shopping Text:" options={showContinueShoppingOptions}
                                    onChange={onBoolChange('show_continue_shopping')}
                                    value={settings.show_continue_shopping ? 'yes' : 'no'} disabled={saveInProgress} />
                            <div className="color-title">Checkout button color:</div>
                            <ChromePicker color={settings.checkout_button_color}
                                          onChangeComplete={onCheckoutButtonColorChange} />
                            <div className="color-title">View cart button color:</div>
                            <ChromePicker color={settings.mini_cart_view_cart_color}
                                          onChangeComplete={onMiniCartViewCartColorChange} />
                            <div className="color-title">Floating icon color:</div>
                            <ChromePicker color={settings.floating_icon_color}
                                          onChangeComplete={onFloatingIconColorChange} />
                            <div className="color-title">Floating icon background color:</div>
                            <ChromePicker color={settings.floating_icon_background_color}
                                          onChangeComplete={onFloatingIconBackgroundColorChange} />
                            <div className="color-title">Bar color:</div>
                            <ChromePicker color={settings.promo_bar_color}
                                          onChangeComplete={onPromoBarColorChange} />
                            <div className="color-title">Left to spend color:</div>
                            <ChromePicker color={settings.left_to_spend_color}
                                          onChangeComplete={onLeftToSpendColorChange} />
                        </Card>
                    </div>
                    <div className="form-card">
                        <Card title={'Display options'}>
                            <Select options={displayOnHomePageOptions}
                                    onChange={onDisplayOnHomePageChange}
                                    value={settings.display_on_home_page ? 'display_on_hp' : 'dont_display_on_hp'}
                                    disabled={saveInProgress} />
                        </Card>
                    </div>

                    <div className="form-card">
                        <Card title={'1-Click Offer'}>
                            <Select label="Display Options" options={oneClickOfferOnEmptyCartOptions}
                                    onChange={onOneClickOfferOnEmptyCartChange}
                                    value={settings.show_one_click_offer_on_empty_cart ? 'show' : 'dont_show'}
                                    disabled={saveInProgress} />
                        </Card>
                    </div>

                    <span className={"save-button " + (planName === 'trial' ? 'trial' : '')}>
                        <Button id="save-settings" onClick={save} primary loading={saveInProgress}
                                disabled={(settings.free_discount_enabled &&
                                (!settings.free_discount_threshold || !settings.free_discount_amount )) ||
                                (settings.free_shipping_enabled && !settings.free_shipping_threshold)}
                        >Save</Button>
                    </span>
                    <Button onClick={restoreToDefaults}>Restore defaults</Button>
                    {toastIsActive && <Toast content="Settings saved" onDismiss={onDismissToast} />}
                </FormLayout>
            </Form>
        </Frame>
    );

export default SettingsCartDrawer;
