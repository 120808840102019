import fetch from "cross-fetch";
import {host, postPayload} from "./data";
import * as _ from "lodash";

export const INIT_PRODUCTS_CART_DRAWER = 'INIT_PRODUCTS_CART_DRAWER';
export const CART_DRAWER_DELETING_OFFER = 'CART_DRAWER_DELETING_OFFER';
export const CART_DRAWER_ADD_OFFER = 'CART_DRAWER_ADD_OFFER';
export const CART_DRAWER_CLOSE_CHOOSE_PRODUCT = 'CART_DRAWER_CLOSE_CHOOSE_PRODUCT';
export const CART_DRAWER_SELECTING_OFFER_PRODUCT = 'CART_DRAWER_SELECTING_OFFER_PRODUCT';
export const CART_DRAWER_UPDATE_PRODUCT_SEARCH_TEXT = 'CART_DRAWER_UPDATE_PRODUCT_SEARCH_TEXT';
export const MOVE_CART_DRAWER_ITEM = 'MOVE_CART_DRAWER_ITEM';
export const CART_DRAWER_PRODUCT_SEARCH_SUCCESS = 'CART_DRAWER_PRODUCT_SEARCH_SUCCESS';

export const initProductsCartDrawer = json => ({
    type: INIT_PRODUCTS_CART_DRAWER,
    json,
});

export const cartDrawerProductSearchSuccess = json => ({
    type: CART_DRAWER_PRODUCT_SEARCH_SUCCESS,
    products: json.products,
    title: json.title,
});

export const cartDrawerDeletingOffer = () => ({
    type: CART_DRAWER_DELETING_OFFER,
});

export const cartDrawerAddOffer = () => ({
    type: CART_DRAWER_ADD_OFFER,
});

export const cartDrawerCloseChooseProduct = () => ({
    type: CART_DRAWER_CLOSE_CHOOSE_PRODUCT,
});

export const cartDrawerSelectingOfferProduct = () => ({
    type: CART_DRAWER_SELECTING_OFFER_PRODUCT,
});

export const cartDrawerUpdateProductSearchText = text => ({
    type: CART_DRAWER_UPDATE_PRODUCT_SEARCH_TEXT,
    text,
});

export const moveCartDrawerItem = (direction, index) => ({
    type: MOVE_CART_DRAWER_ITEM,
    direction,
    index,
});

const moveCartDrawerItemInnerFunction = _.debounce(dispatch => dispatch(moveCartDrawerItemApi()), 2500);
export function cartDrawerMoveItem(direction, index) {
    return (dispatch, getState) => {
        dispatch(moveCartDrawerItem(direction, index));
        moveCartDrawerItemInnerFunction(dispatch);
    }
}

export function loadCartDrawer() {
    return async (dispatch, getState) => {
        const state = getState();
        if (!state.productsCartDrawer.ready) {
            const response = await fetch(`https://${host}/cart_drawer/get_init_data_for_admin?shop_name=${state.data.shopName}&token=${state.data.token}`);
            if (response.ok) {
                const json = await response.json();
                dispatch(initProductsCartDrawer(json));
            } else {
                console.log('loadCartDrawer failure');
            }
        }
    }
}

export function cartDrawerDeleteOffer(id) {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(cartDrawerDeletingOffer());
        const response = await fetch(`https://${host}/cart_drawer/delete_one_offer?shop_name=${state.data.shopName}&token=${state.data.token}&offer_id=${id}`);
        if (response.ok) {
            const json = await response.json();
            dispatch(initProductsCartDrawer(json));
        } else {
            console.log('cartDrawerDeleteOffer failure');
        }
    }
}

export function cartDrawerChooseOfferProduct(id) {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(cartDrawerSelectingOfferProduct());
        dispatch(cartDrawerCloseChooseProduct());
        const response = await fetch(`https://${host}/cart_drawer/add_offer?shop_name=${state.data.shopName}&token=${state.data.token}&product_id=${id}`);
        if (response.ok) {
            const json = await response.json();
            dispatch(initProductsCartDrawer(json));
        } else {
            console.log('cartDrawerChooseOfferProduct failure');
        }
    }
}

export function cartDrawerChangeProductSearchText(text) {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(cartDrawerUpdateProductSearchText(text));
        const response = await fetch(`https://${host}/data/search_products`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    title: text,
                    existing_ids: [],
                })
            });

        if (response.ok) {
            const json = await response.json();
            dispatch(cartDrawerProductSearchSuccess(json));
        } else {
            console.log('cartDrawerChangeProductSearchText failure');
        }
    }
}

export function moveCartDrawerItemApi() {
    return async (dispatch, getState) => {
        const state = getState();
        const response = await postPayload('cart_drawer/update_offers_positions',
            {
                    offers: state.productsCartDrawer.offers,
            }, state);

        if (response.ok) {
            const json = await response.json();
            dispatch(initProductsCartDrawer(json));
        } else {
            console.log('moveCartDrawerItemApi failure');
        }
    }
}

export function cartDrawerFocusProductSearchText() {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(cartDrawerChangeProductSearchText(state.productsCartDrawer.searchBoxText || ''));
    }
}
