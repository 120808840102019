import { connect } from 'react-redux'
import {
    stickyAtcChangeSaveCss,
    stickyAtcChangeSettingText
} from "../../actions/sticky-atc/stickyAtc";
import StickyAtcCssEditor from "../../components/sticky-atc/StickyAtcCssEditor";

const mapStateToProps = state => ({
    stickyAtc: state.stickyAtc,
});

const mapDispatchToProps = (dispatch) => ({
    save: () => dispatch(stickyAtcChangeSaveCss()),
    changeText: attribute => text => dispatch(stickyAtcChangeSettingText(attribute, text)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StickyAtcCssEditor);
