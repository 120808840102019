import { connect } from 'react-redux'
import StickyAtc from "../../components/sticky-atc/StickyAtc";
import {
    initStickyAtc,
    stickyAtcDismissToast,
    stickyAtcSelectTab,
    createStoreJsFromCrm,
    fetchUpdate
} from "../../actions/sticky-atc/stickyAtc";

const mapStateToProps = state => ({
    stickyAtc: state.stickyAtc,
});

const mapDispatchToProps = (dispatch) => ({
    init: params => dispatch(initStickyAtc(params)),
    handleTabSelect: index => dispatch(stickyAtcSelectTab(index)),
    onDismissToast: () => dispatch(stickyAtcDismissToast()),
    onLoadCreateStoreJS: params => dispatch(createStoreJsFromCrm(params)),
    fetchUpdate: params => dispatch(fetchUpdate(params)),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StickyAtc);
