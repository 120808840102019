import * as _ from "lodash";
import {
    DISMISS_TRANSLATIONS_SAVED_TOAST,
    LOAD_TRANSLATIONS_FAILED,
    LOCALE_CHANGE,
    RESTORE_SLIDER_TEXTS_DEFAULTS,
    SAVING_TRANSLATIONS,
    SAVING_TRANSLATIONS_FAILURE,
    SAVING_TRANSLATIONS_SUCCESS,
    SET_TRANSLATIONS,
    SLIDER_ADD_TO_CART_BUTTON_ADDED_TEXT_CHANGE,
    SLIDER_ADD_TO_CART_BUTTON_TITLE_CHANGE,
    SLIDER_FULL_PRODUCT_DETAILS_CHANGE,
    SLIDER_POPUP_JUST_ADDED_TEXT_CHANGE,
    SLIDER_QUICK_VIEW_TEXT_CHANGE,
    SLIDER_CONTINUE_SHOPPING_TEXT_CHANGE,
    SLIDER_SUB_TITLE_CHANGE,
    SLIDER_TITLE_CHANGE,
    SLIDER_POPUP_VIEW_CART_TEXT_CHANGE,
    RESTORE_BUNDLE_TEXTS_DEFAULTS,
    BUNDLE_ADD_SELECTED_TO_CART_TEXT_CHANGE,
    BUNDLE_TOTAL_PRICE_TEXT_CHANGE,
    SLIDER_TITLE_CART_SLIDER_CHANGE,
    SLIDER_TITLE_BOTTOM_SLIDER_CHANGE,
    SLIDER_TITLE_MAIN_SLIDER_CHANGE,
    BUNDLE_ADD_TO_CART_TEXT_CHANGE,
    BUNDLE_JUST_ADDED_TEXT_CHANGE,
    BUNDLE_DISCOUNT_GRANTED_TEXT_CHANGE,
    BUNDLE_CONTINUE_SHOPPING_TEXT_CHANGE,
    BUNDLE_QUICK_VIEW_FULL_DETAILS_TEXT_CHANGE,
    BUNDLE_POPUP_VIEW_CART_TEXT_CHANGE,
    RESTORE_WARRANTY_TEXTS_DEFAULTS,
    WARRANTY_TITLE_CHANGE,
    WARRANTY_CALL_TO_ACTION_CHANGE,
    WARRANTY_TOOLTIP_TEMPLATE_CHANGE,
    RESTORE_MINICART_TEXTS_DEFAULTS,
    MINICART_FREE_DISCOUNT_TITLE_CHANGE,
    MINICART_EMPTY_CART_TEXT_CHANGE,
    MINICART_CART_TOTAL_TEXT_CHANGE,
    MINICART_CHECKOUT_TEXT_CHANGE,
    MINICART_UNLOCK_DISCOUNT_TEXT_CHANGE,
    MINICART_FREE_DISCOUNT_UNLOCKED_TEXT_CHANGE,
    MINICART_CART_TITLE_CHANGE,
    MINICART_FREE_SHIPPING_TITLE_CHANGE,
    MINICART_UNLOCK_SHIPPING_TEXT_CHANGE,
    MINICART_FREE_SHIPPING_UNLOCKED_TEXT_CHANGE,
    MINICART_VIEW_CART_TEXT_CHANGE,
    SLIDER_NOTIF_POPUP_QTY_TEXT_CHANGE,
    BUNDLE_NOTIF_POPUP_QTY_TEXT_CHANGE,
    OPEN_ADD_LOCALE_MODAL,
    CLOSE_ADD_LOCALE_MODAL,
    NEW_LOCALE_CHANGE,
    COPY_FROM_CHANGE,
    ADDING_LOCALE,
    ADD_LOCALE_FAILURE,
    ADD_LOCALE_SUCCESS,
    OPEN_REMOVE_LOCALE_MODAL,
    CLOSE_REMOVE_LOCALE_MODAL,
    REMOVING_LOCALE,
    REMOVE_LOCALE_FAILURE,
    REMOVE_LOCALE_SUCCESS,
    SYNCING_PRODUCT_TRANSLATIONS,
    SYNC_PRODUCT_TRANSLATIONS_SUCCESS,
    SYNC_PRODUCT_TRANSLATIONS_FAILED,
    SYNC_PRODUCT_TRANS_STATUS,
    VOLUME_ADD_TO_CART_TEXT_CHANGE,
    RESTORE_VOLUME_TEXTS_DEFAULTS,
    VOLUME_POPULAR_TEXT_CHANGE,
    VOLUME_TOTAL_TEXT_CHANGE,
    VOLUME_SAVE_TEXT_CHANGE,
    VOLUME_EACH_ITEM_TEXT_CHANGE,
    VOLUME_JUST_ADDED_TEXT_CHANGE,
    VOLUME_QTY_TEXT_CHANGE,
    VOLUME_SAVE_PER_ITEM_CHANGE,
    VOLUME_OUT_OF_STOCK_TEXT_CHANGE,
    BUNDLE_SAVE_TEXT_CHANGE,
    VOLUME_TIERS_TABLE_QTY_TEXT_CHANGE,
    VOLUME_TIERS_TABLE_DISCOUNT_TEXT_CHANGE,
    BUNDLE_OUT_OF_STOCK_TEXT_CHANGE,
    MINICART_ADD_CART_NOTE_TEXT_CHANGE,
    MINICART_CART_NOTE_PLACEHOLDER_TEXT_CHANGE,
    MINICART_OFFER_CTA_TEXT_CHANGE,
    TEXTS_CHANGE_TEXT
} from "../actions/texts";

export function calcLocales(newState, translations) {
    newState.locales = _.map(_.keys(translations), locale => {
        const withHyphen = _.find(newState.availableLocales, {isoCode: locale});
        const withoutHyphen = _.find(newState.availableLocales, {isoCode: locale.split('-')[0]});
        const localeName = (withHyphen || withoutHyphen || {name: locale}).name;
        return {
            label: localeName,
            value: locale,
        }
    });
    newState.locale = newState.locales[0].value;
}

function fillMissingText(newState, locale, widget, attr) {
    if (newState.translations[locale][widget][attr] !== '' && !newState.translations[locale][widget][attr]) {
        newState.translations[locale][widget][attr] =  newState.defaults[widget][attr];
    }
}

const fillMissingTexts = newState => {
    Object.keys(newState.translations).forEach(locale => {
        fillMissingText(newState, locale, 'mini_cart', 'checkout_redirect_text');
        if (newState.translations[locale].mix_and_match) {
            fillMissingText(newState, locale, 'mix_and_match', 'item_just_added_text');
            fillMissingText(newState, locale, 'mix_and_match', 'add_item_text');
            fillMissingText(newState, locale, 'mix_and_match', 'tier_success_text');
            fillMissingText(newState, locale, 'mix_and_match', 'search_product_text');
            fillMissingText(newState, locale, 'mix_and_match', 'qty_added_template');
            fillMissingText(newState, locale, 'mix_and_match', 'total_offer_price_label');
            fillMissingText(newState, locale, 'mix_and_match', 'go_to_bundle_builder_text');
            fillMissingText(newState, locale, 'mix_and_match', 'out_of_stock_text');
            fillMissingText(newState, locale, 'mix_and_match', 'load_more_text');
        } else {
            newState.translations[locale].mix_and_match = _.cloneDeep(newState.defaults.mix_and_match);
        }
    })
}

const texts = (state = { }, action) => {
    let newState = null;
    switch (action.type) {
        case SET_TRANSLATIONS:
            newState = _.cloneDeep(state);
            newState.translations = _.cloneDeep(action.translations);
            newState.defaults = _.cloneDeep(action.defaults);
            fillMissingTexts(newState);
            newState.availableLocales = _.cloneDeep(action.availableLocales);
            newState.manuallyAddedLocales = _.cloneDeep(action.manuallyAddedLocales);
            calcLocales(newState, newState.translations);
            break;
        case ADD_LOCALE_SUCCESS:
            newState = _.cloneDeep(state);
            newState.translations = _.cloneDeep(action.translations);
            newState.manuallyAddedLocales = _.cloneDeep(action.manuallyAddedLocales);
            calcLocales(newState, newState.translations);
            newState.addingLocale = false;
            newState.addLocaleModalOpen = false;
            break;
        case REMOVE_LOCALE_SUCCESS:
            newState = _.cloneDeep(state);
            newState.translations = _.cloneDeep(action.translations);
            newState.manuallyAddedLocales = _.cloneDeep(action.manuallyAddedLocales);
            calcLocales(newState, newState.translations);
            newState.removingLocale = false;
            newState.removeLocaleModalOpen = false;
            break;
        case LOAD_TRANSLATIONS_FAILED:
            newState = _.cloneDeep(state);
            break;
        case SAVING_TRANSLATIONS:
            newState = _.cloneDeep(state);
            newState.saving = true;
            newState.savingError = false;
            break;
        case SYNCING_PRODUCT_TRANSLATIONS:
            newState = _.cloneDeep(state);
            newState.syncProductTranslationsProgress = 0;
            newState.syncProductTranslationsStatusTime = 0;
            break;
        case SYNC_PRODUCT_TRANSLATIONS_FAILED:
            newState = _.cloneDeep(state);
            newState.syncProductTranslationsProgress = 100;
            newState.savingError = true;
            break;
        case SYNC_PRODUCT_TRANSLATIONS_SUCCESS:
            newState = _.cloneDeep(state);
            newState.savingError = false;
            break;
        case SYNC_PRODUCT_TRANS_STATUS:
            newState = _.cloneDeep(state);
            if (action.data.time > newState.syncProductTranslationsStatusTime) {
                newState.syncProductTranslationsProgress = action.data.progress;
                newState.syncProductTranslationsStatusTime = action.data.time;
            }
            break;
        case SAVING_TRANSLATIONS_FAILURE:
            newState = _.cloneDeep(state);
            newState.saving = false;
            newState.savingError = true;
            break;
        case SAVING_TRANSLATIONS_SUCCESS:
            newState = _.cloneDeep(state);
            newState.saving = false;
            newState.translationsSavedToastIsActive = true;
            break;
        case DISMISS_TRANSLATIONS_SAVED_TOAST:
            newState = _.cloneDeep(state);
            newState.translationsSavedToastIsActive = false;
            break;
        case LOCALE_CHANGE:
            newState = _.cloneDeep(state);
            newState.locale = action.text;
            break;
        case NEW_LOCALE_CHANGE:
            newState = _.cloneDeep(state);
            newState.desiredNewLocale = action.text;
            break;
        case SLIDER_TITLE_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].slider.title = action.text;
            break;
        case SLIDER_SUB_TITLE_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].slider.sub_title = action.text;
            break;
        case SLIDER_ADD_TO_CART_BUTTON_TITLE_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].slider.add_to_cart_button_title = action.text;
            break;
        case SLIDER_ADD_TO_CART_BUTTON_ADDED_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].slider.add_to_cart_button_added_text = action.text;
            break;
        case SLIDER_QUICK_VIEW_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].slider.quick_view_text = action.text;
            break;
        case SLIDER_FULL_PRODUCT_DETAILS_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].slider.full_product_details = action.text;
            break;
        case SLIDER_CONTINUE_SHOPPING_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].slider.slider_continue_shopping_text = action.text;
            break;
        case SLIDER_POPUP_JUST_ADDED_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].slider.slider_popup_just_added_text = action.text;
            break;
        case SLIDER_POPUP_VIEW_CART_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].slider.slider_popup_view_cart_text = action.text;
            break;
        case SLIDER_TITLE_CART_SLIDER_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].slider.title_cart_slider = action.text;
            break;
        case SLIDER_TITLE_BOTTOM_SLIDER_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].slider.title_bottom_slider = action.text;
            break;
        case SLIDER_TITLE_MAIN_SLIDER_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].slider.title_main_slider = action.text;
            break;
        case SLIDER_NOTIF_POPUP_QTY_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].slider.slider_notif_popup_qty_text = action.text;
            break;
        case TEXTS_CHANGE_TEXT:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale][action.widget][action.attr] = action.text;
            break;
        case BUNDLE_ADD_SELECTED_TO_CART_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].bundle.add_selected_to_cart_text = action.text;
            break;
        case BUNDLE_TOTAL_PRICE_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].bundle.total_price_text = action.text;
            break;
        case BUNDLE_ADD_TO_CART_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].bundle.add_to_cart_text = action.text;
            break;
        case BUNDLE_JUST_ADDED_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].bundle.just_added_text = action.text;
            break;
        case BUNDLE_DISCOUNT_GRANTED_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].bundle.bundle_discount_granted_text = action.text;
            break;
        case BUNDLE_CONTINUE_SHOPPING_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].bundle.bundle_continue_shopping_text = action.text;
            break;
        case BUNDLE_QUICK_VIEW_FULL_DETAILS_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].bundle.bundle_quick_view_full_details_text = action.text;
            break;
        case BUNDLE_POPUP_VIEW_CART_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].bundle.bundle_popup_view_cart_text = action.text;
            break;
        case BUNDLE_NOTIF_POPUP_QTY_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].bundle.bundle_notif_popup_qty_text = action.text;
            break;
        case BUNDLE_SAVE_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].bundle.bundle_save_text = action.text;
            break;
        case BUNDLE_OUT_OF_STOCK_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].bundle.bundle_out_of_stock_text = action.text;
            break;
        case WARRANTY_TITLE_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].warranty.title = action.text;
            break;
        case WARRANTY_CALL_TO_ACTION_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].warranty.call_to_action = action.text;
            break;
        case WARRANTY_TOOLTIP_TEMPLATE_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].warranty.tooltip_template = action.text;
            break;
        case MINICART_FREE_DISCOUNT_TITLE_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].mini_cart.free_discount_title = action.text;
            break;
        case MINICART_EMPTY_CART_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].mini_cart.empty_cart_text = action.text;
            break;
        case MINICART_CART_TOTAL_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].mini_cart.cart_total_text = action.text;
            break;
        case MINICART_CHECKOUT_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].mini_cart.checkout_text = action.text;
            break;
        case MINICART_UNLOCK_DISCOUNT_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].mini_cart.unlock_discount_text = action.text;
            break;
        case MINICART_FREE_DISCOUNT_UNLOCKED_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].mini_cart.free_discount_unlocked_text = action.text;
            break;
        case MINICART_CART_TITLE_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].mini_cart.cart_title = action.text;
            break;
        case MINICART_FREE_SHIPPING_TITLE_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].mini_cart.free_shipping_title = action.text;
            break;
        case MINICART_UNLOCK_SHIPPING_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].mini_cart.unlock_shipping_text = action.text;
            break;
        case MINICART_FREE_SHIPPING_UNLOCKED_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].mini_cart.free_shipping_unlocked_text = action.text;
            break;
        case MINICART_VIEW_CART_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].mini_cart.mini_cart_view_cart_text = action.text;
            break;
        case MINICART_ADD_CART_NOTE_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].mini_cart.add_cart_note_text = action.text;
            break;
        case MINICART_CART_NOTE_PLACEHOLDER_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].mini_cart.cart_note_placeholder_text = action.text;
            break;
        case MINICART_OFFER_CTA_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].mini_cart.offer_cta_text = action.text;
            break;
        case VOLUME_ADD_TO_CART_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].volume.add_to_cart_text = action.text;
            break;
        case VOLUME_POPULAR_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].volume.popular_text = action.text;
            break;
        case VOLUME_TOTAL_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].volume.total_text = action.text;
            break;
        case VOLUME_SAVE_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].volume.save_text = action.text;
            break;
        case VOLUME_EACH_ITEM_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].volume.each_item_text = action.text;
            break;
        case VOLUME_JUST_ADDED_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].volume.just_added = action.text;
            break;
        case VOLUME_QTY_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].volume.qty = action.text;
            break;
        case VOLUME_SAVE_PER_ITEM_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].volume.save_per_item_text = action.text;
            break;
        case VOLUME_OUT_OF_STOCK_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].volume.out_of_stock_text = action.text;
            break;
        case VOLUME_TIERS_TABLE_QTY_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].volume.tiers_table_qty_text = action.text;
            break;
        case VOLUME_TIERS_TABLE_DISCOUNT_TEXT_CHANGE:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].volume.tiers_table_discount_text = action.text;
            break;
        case RESTORE_SLIDER_TEXTS_DEFAULTS:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].slider = _.cloneDeep(newState.defaults.slider);
            break;
        case RESTORE_BUNDLE_TEXTS_DEFAULTS:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].bundle = _.cloneDeep(newState.defaults.bundle);
            break;
        case RESTORE_WARRANTY_TEXTS_DEFAULTS:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].warranty = _.cloneDeep(newState.defaults.warranty);
            break;
        case RESTORE_MINICART_TEXTS_DEFAULTS:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].mini_cart = _.cloneDeep(newState.defaults.mini_cart);
            break;
        case RESTORE_VOLUME_TEXTS_DEFAULTS:
            newState = _.cloneDeep(state);
            newState.translations[newState.locale].volume = _.cloneDeep(newState.defaults.volume);
            break;
        case OPEN_REMOVE_LOCALE_MODAL:
            newState = _.cloneDeep(state);
            newState.removeLocaleModalOpen = true;
            break;
        case OPEN_ADD_LOCALE_MODAL:
            newState = _.cloneDeep(state);
            newState.addLocaleModalOpen = true;
            const allLocales = newState.availableLocales.map(l => ({label: l.name, value: l.isoCode}));
            const existingIsoCodes = _.keys(newState.translations);
            _.remove(allLocales, locale => existingIsoCodes.indexOf(locale.value) >= 0);
            newState.newLocaleOptions = _.cloneDeep(allLocales);
            newState.desiredNewLocale = newState.newLocaleOptions[0].value;
            newState.copyFromOptions = [{label: 'Default texts', value: 'default'}].concat(newState.locales);
            newState.copyFromLocale = 'default';
            break;
        case CLOSE_ADD_LOCALE_MODAL:
            newState = _.cloneDeep(state);
            newState.addLocaleModalOpen = false;
            break;
        case CLOSE_REMOVE_LOCALE_MODAL:
            newState = _.cloneDeep(state);
            newState.removeLocaleModalOpen = false;
            break;
        case COPY_FROM_CHANGE:
            newState = _.cloneDeep(state);
            newState.copyFromLocale = action.text;
            break;
        case ADDING_LOCALE:
            newState = _.cloneDeep(state);
            newState.addingLocale = true;
            break;
        case REMOVING_LOCALE:
            newState = _.cloneDeep(state);
            newState.removingLocale = true;
            break;
        case ADD_LOCALE_FAILURE:
            newState = _.cloneDeep(state);
            newState.addingLocale = false;
            break;
        case REMOVE_LOCALE_FAILURE:
            newState = _.cloneDeep(state);
            newState.removingLocale = false;
            break;
        default:
            return state;
    }
    return newState;
};

export default texts;
