import React from 'react';
import '../../css/sticky-atc/StickyAtc.scss';
import {
    Spinner,
    TextField,
    Button,
    Layout,
    Card,
    TextContainer,
    Heading,
    Stack
} from "@shopify/polaris";

const StickyAtcSupport = ({ stickyAtc, send, onTextChange }) => {
    return <div className="sticky-atc-support-form">
        {stickyAtc.storeData ? <div>
            <Layout>
                <Layout.AnnotatedSection
                    title="Get in touch"
                    description="We would be happy to help with every inquiry you may have, simply complete this form."
                >
                    <Card sectioned>
                        {stickyAtc.submittedSupport ?
                            <TextContainer>
                                <Heading>Thanks for submitting!</Heading>
                                <p>We'll try to get back to you in a few hours.</p>
                            </TextContainer>
                         :
                            <div>
                                <Stack distribution="fill">
                                    <Stack.Item>
                                        <TextField label="Your name *" value={stickyAtc.storeData.owner_name}
                                                   onChange={onTextChange('owner_name')} />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <TextField type="email" label="Your email *" value={stickyAtc.storeData.owner_email}
                                                   onChange={onTextChange('owner_email')} />
                                    </Stack.Item>
                                </Stack>
                                <TextField label="How can we help you? *" multiline={5} value={stickyAtc.storeData.support_text}
                                           onChange={onTextChange('support_text')} />
                                <div className="sticky-atc-ticket-submit">
                                    <Button primary onClick={send} loading={stickyAtc.submittingSupport}>Submit</Button>
                                </div>
                            </div>

                        }

                    </Card>
                </Layout.AnnotatedSection>
            </Layout>
        </div> : <div>
            <Spinner />
        </div>}
    </div>
};

export default StickyAtcSupport;
