import fetch from "cross-fetch";
import {host} from "./data";
import {refreshSyncStatus} from "./status";

export const RECOMMENDATION_WIDGET_INIT = 'RECOMMENDATION_WIDGET_INIT';
export const RECOMMENDATION_WIDGET_MAX_PRODUCTS = 'RECOMMENDATION_WIDGET_MAX_PRODUCTS';
export const AUTO_PLAY_INTERVAL = 'AUTO_PLAY_INTERVAL';
export const NUM_OF_SLIDES_MOBILE = 'NUM_OF_SLIDES_MOBILE';
export const NUM_OF_SLIDES_DESKTOP = 'NUM_OF_SLIDES_DESKTOP';
export const SLIDER_DISCOUNT_CHANGE = 'SLIDER_DISCOUNT_CHANGE';
export const SLIDER_CART_DRAWER_NUM_OF_SLIDES_CHANGE = 'SLIDER_CART_DRAWER_NUM_OF_SLIDES_CHANGE';
export const SAVE_SETTINGS_INIT = 'SAVE_SETTINGS_INIT';
export const SAVE_SETTINGS_ERROR = 'SAVE_SETTINGS_ERROR';
export const SAVE_SETTINGS_SUCCESS = 'SAVE_SETTINGS_SUCCESS';
export const ACTIVATE_TOAST = 'ACTIVATE_TOAST';
export const DISMISS_TOAST = 'DISMISS_TOAST';
export const CLOSE_SLIDER_INVENTORY_MODAL = 'CLOSE_SLIDER_INVENTORY_MODAL';
export const ABOVE_PRODUCT_MAIN_CHANGE = 'ABOVE_PRODUCT_MAIN_CHANGE';
export const ABOVE_PRODUCT_BOTTOM_CHANGE = 'ABOVE_PRODUCT_BOTTOM_CHANGE';
export const ABOVE_CART_TOP_CHANGE = 'ABOVE_CART_TOP_CHANGE';
export const ABOVE_CART_BOTTOM_CHANGE = 'ABOVE_CART_BOTTOM_CHANGE';
export const PRODUCT_MAIN_SELECTOR_CHANGE = 'PRODUCT_MAIN_SELECTOR_CHANGE';
export const PRODUCT_BOTTOM_SELECTOR_CHANGE = 'PRODUCT_BOTTOM_SELECTOR_CHANGE';
export const CART_TOP_SELECTOR_CHANGE = 'CART_TOP_SELECTOR_CHANGE';
export const CART_BOTTOM_SELECTOR_CHANGE = 'CART_BOTTOM_SELECTOR_CHANGE';
export const CART_TOTAL_SELECTOR_CHANGE = 'CART_TOTAL_SELECTOR_CHANGE';
export const CART_TOP_SELECT_LAST_CHANGE = 'CART_TOP_SELECT_LAST_CHANGE';
export const CART_BOTTOM_SELECT_LAST_CHANGE = 'CART_BOTTOM_SELECT_LAST_CHANGE';
export const ROUND_UP_CHANGE = 'ROUND_UP_CHANGE';
export const IMAGE_SHARPNESS_CHANGE = 'IMAGE_SHARPNESS_CHANGE';
export const FACEBOOK_PIXEL_CHANGE = 'FACEBOOK_PIXEL_CHANGE';
export const KLAVIYO_CHANGE = 'KLAVIYO_CHANGE';
export const APP_BLOCKS_CHANGE = 'APP_BLOCKS_CHANGE';
export const TITLE_COLOR = 'TITLE_COLOR';
export const TITLE_FONT_SIZE = 'TITLE_FONT_SIZE';
export const TITLE_FONT_WEIGHT = 'TITLE_FONT_WEIGHT';
export const SUB_TITLE_FONT_SIZE = 'SUB_TITLE_FONT_SIZE';
export const SUB_TITLE_FONT_WEIGHT = 'SUB_TITLE_FONT_WEIGHT';
export const SUB_TITLE_COLOR = 'SUB_TITLE_COLOR';
export const PRICE_FONT_SIZE = 'PRICE_FONT_SIZE';
export const PRICE_FONT_WEIGHT = 'PRICE_FONT_WEIGHT';
export const PRICE_COLOR = 'PRICE_COLOR';
export const COMPARE_PRICE_FONT_SIZE = 'COMPARE_PRICE_FONT_SIZE';
export const COMPARE_PRICE_FONT_WEIGHT = 'COMPARE_PRICE_FONT_WEIGHT';
export const COMPARE_PRICE_COLOR = 'COMPARE_PRICE_COLOR';
export const PRODUCT_LINK_FONT_SIZE = 'PRODUCT_LINK_FONT_SIZE';
export const PRODUCT_LINK_FONT_WEIGHT = 'PRODUCT_LINK_FONT_WEIGHT';
export const CURRENCY_POSITION = 'CURRENCY_POSITION';
export const TITLE_ALIGN = 'TITLE_ALIGN';
export const CART_POSITION_CHANGE = 'CART_POSITION_CHANGE';
export const PRODUCT_LOCATION_CHANGE = 'PRODUCT_LOCATION_CHANGE';
export const PRODUCT_LINK_UNDERLINE = 'PRODUCT_LINK_UNDERLINE';
export const PRODUCT_LINK_COLOR = 'PRODUCT_LINK_COLOR';
export const SELECTION_COLOR = 'SELECTION_COLOR';
export const NAV_COLOR = 'NAV_COLOR';
export const SHOW_QUANTITY_MODIFIER = 'SHOW_QUANTITY_MODIFIER';
export const CURRENCY_CUT_ZEROS = 'CURRENCY_CUT_ZEROS';
export const SHUFFLE_PRODUCTS = 'SHUFFLE_PRODUCTS';
export const HIDE_PRODUCT_LINK = 'HIDE_PRODUCT_LINK';
export const BORDER = 'BORDER';
export const NAV_CIRCLE = 'NAV_CIRCLE';
export const HIDE_COMPARE_TO = 'HIDE_COMPARE_TO';
export const QUICK_VIEW_ON_IMAGE_CLICKED = 'QUICK_VIEW_ON_IMAGE_CLICKED';
export const SHOW_SLIDER_VARIANTS = 'SHOW_SLIDER_VARIANTS';
export const AUTO_PLAY = 'AUTO_PLAY';
export const BELOW_ADD_TO_CART = 'BELOW_ADD_TO_CART';
export const PRODUCT_BOTTOM_ENABLED = 'PRODUCT_BOTTOM_ENABLED';
export const REFRESH_AFTER_ADD_TO_CART_CHANGE = 'REFRESH_AFTER_ADD_TO_CART_CHANGE';
export const PRODUCT_DESC_ON_QUICK_VIEW = 'PRODUCT_DESC_ON_QUICK_VIEW';
export const INFO_POPUP_SLIDER = 'INFO_POPUP_SLIDER';
export const RECOMMEND_WITH_ORDERS = 'RECOMMEND_WITH_ORDERS';
export const TAKE_ONLY_FIRST_MAPPING_CHANGE = 'TAKE_ONLY_FIRST_MAPPING_CHANGE';
export const CUSTOM_CURRENCY = 'CUSTOM_CURRENCY';
export const SELECTION_SHAPE = 'SELECTION_SHAPE';
export const SLIDER_FONT_FAMILY = 'SLIDER_FONT_FAMILY';
export const RESTORE_TO_DEFAULTS = 'RESTORE_TO_DEFAULTS';
export const ADD_TO_CART_BUTTON_FONT_SIZE = 'ADD_TO_CART_BUTTON_FONT_SIZE';
export const ADD_TO_CART_BUTTON_COLOR = 'ADD_TO_CART_BUTTON_COLOR';
export const ADD_TO_CART_BUTTON_TEXT_COLOR = 'ADD_TO_CART_BUTTON_TEXT_COLOR';
export const SLIDER_ITEM_BACKGROUND = 'SLIDER_ITEM_BACKGROUND';
export const ADD_TO_CART_BUTTON_MAIN_WIDGET = 'ADD_TO_CART_BUTTON_MAIN_WIDGET';
export const ADD_TO_CART_BUTTON_BOTTOM_WIDGET = 'ADD_TO_CART_BUTTON_BOTTOM_WIDGET';
export const ADD_TO_CART_BUTTON_CART_WIDGET = 'ADD_TO_CART_BUTTON_CART_WIDGET';
export const NAVIGATE_TO_PRODUCT_PAGE = 'NAVIGATE_TO_PRODUCT_PAGE';
export const SLIDER_SHOW_AFTER_ITEM_ADDED = 'SLIDER_SHOW_AFTER_ITEM_ADDED';
export const SLIDER_ONLY_ON_SOURCE_PRODUCTS_CHANGE = 'SLIDER_ONLY_ON_SOURCE_PRODUCTS_CHANGE';
export const SLIDER_SHOW_OTHERS_AFTER_GLOBALS_CHANGED = 'SLIDER_SHOW_OTHERS_AFTER_GLOBALS_CHANGED';
export const SLIDER_SETTINGS_BOOL_CHANGE = 'SLIDER_SETTINGS_BOOL_CHANGE';
export const QUICK_VIEW = 'QUICK_VIEW';
export const TITLE_ON_TOP = 'TITLE_ON_TOP';
export const SHOW_QUANTITY_MODAL = 'SHOW_QUANTITY_MODAL';
export const IMAGE_BACKGROUND = 'IMAGE_BACKGROUND';
export const SLIDER_NAVIGATE_TO_CHANGE = 'SLIDER_NAVIGATE_TO_CHANGE';
export const SLIDER_SHOW_DISCOUNTED_PRICES_CHANGE = 'SLIDER_SHOW_DISCOUNTED_PRICES_CHANGE';
export const SLIDER_CART_POPUP_CHANGE = 'SLIDER_CART_POPUP_CHANGE';
export const SLIDER_DISPLAY_OPTIONS_CHANGE = 'SLIDER_DISPLAY_OPTIONS_CHANGE';
export const SLIDER_CART_DRAWER_DISCOUNT_CHANGE = 'SLIDER_CART_DRAWER_DISCOUNT_CHANGE';
export const COLLAPSE_BACKGROUND_COLOR = 'COLLAPSE_BACKGROUND_COLOR';

export const restoreToDefaults = () => ({
    type: RESTORE_TO_DEFAULTS,
});

export const productLinkUnderline = bool => ({
    type: PRODUCT_LINK_UNDERLINE,
    bool,
});

export const showQuantityModifier = bool => ({
    type: SHOW_QUANTITY_MODIFIER,
    bool,
});

export const currencyCutZeros = bool => ({
    type: CURRENCY_CUT_ZEROS,
    bool,
});

export const shuffleProducts = bool => ({
    type: SHUFFLE_PRODUCTS,
    bool,
});

export const hideProductLink = bool => ({
    type: HIDE_PRODUCT_LINK,
    bool,
});

export const border = bool => ({
    type: BORDER,
    bool,
});

export const navCircle = bool => ({
    type: NAV_CIRCLE,
    bool,
});

export const hideCompareTo = bool => ({
    type: HIDE_COMPARE_TO,
    bool,
});

export const quickViewOnImageClicked = bool => ({
    type: QUICK_VIEW_ON_IMAGE_CLICKED,
    bool,
});

export const showSliderVariants = text => ({
    type: SHOW_SLIDER_VARIANTS,
    text,
});

export const autoPlay = bool => ({
    type: AUTO_PLAY,
    bool,
});

export const belowAddToCart = bool => ({
    type: BELOW_ADD_TO_CART,
    bool,
});

export const productBottomEnabled = bool => ({
    type: PRODUCT_BOTTOM_ENABLED,
    bool,
});

export const refreshAfterAddToCartChange = bool => ({
    type: REFRESH_AFTER_ADD_TO_CART_CHANGE,
    bool,
});

export const productDescOnQuickView = bool => ({
    type: PRODUCT_DESC_ON_QUICK_VIEW,
    bool,
});

export const infoPopupSlider = bool => ({
    type: INFO_POPUP_SLIDER,
    bool,
});

export const recommendWithOrders = bool => ({
    type: RECOMMEND_WITH_ORDERS,
    bool,
});

export const takeOnlyFirstMappingChange = text => ({
    type: TAKE_ONLY_FIRST_MAPPING_CHANGE,
    text,
});

export const addToCartButtonColor = hex => ({
    type: ADD_TO_CART_BUTTON_COLOR,
    hex,
});

export const addToCartButtonTextColor = hex => ({
    type: ADD_TO_CART_BUTTON_TEXT_COLOR,
    hex,
});

export const sliderItemBackground = hex => ({
    type: SLIDER_ITEM_BACKGROUND,
    hex,
});

export const addToCartButtonFontSize = size => ({
    type: ADD_TO_CART_BUTTON_FONT_SIZE,
    size,
});

export const addToCartButtonMainWidget = bool => ({
    type: ADD_TO_CART_BUTTON_MAIN_WIDGET,
    bool,
});

export const addToCartButtonBottomWidget = bool => ({
    type: ADD_TO_CART_BUTTON_BOTTOM_WIDGET,
    bool,
});

export const addToCartButtonCartWidget = bool => ({
    type: ADD_TO_CART_BUTTON_CART_WIDGET,
    bool,
});

export const navigateToProductPage = bool => ({
    type: NAVIGATE_TO_PRODUCT_PAGE,
    bool,
});

export const sliderShowAfterItemAdded = bool => ({
    type: SLIDER_SHOW_AFTER_ITEM_ADDED,
    bool,
});

export const sliderOnlyOnSourceProductsChange = bool => ({
    type: SLIDER_ONLY_ON_SOURCE_PRODUCTS_CHANGE,
    bool,
});

export const sliderShowOthersAfterGlobalsChanged = bool => ({
    type: SLIDER_SHOW_OTHERS_AFTER_GLOBALS_CHANGED,
    bool,
});

export const sliderSettingsBoolChange = (attr, bool) => ({
    type: SLIDER_SETTINGS_BOOL_CHANGE,
    attr,
    bool,
});

export const titleFontWeight = weight => ({
    type: TITLE_FONT_WEIGHT,
    weight,
});

export const subTitleFontWeight = weight => ({
    type: SUB_TITLE_FONT_WEIGHT,
    weight,
});

export const priceFontWeight = weight => ({
    type: PRICE_FONT_WEIGHT,
    weight,
});

export const comparePriceFontWeight = weight => ({
    type: COMPARE_PRICE_FONT_WEIGHT,
    weight,
});

export const productLinkFontWeight = weight => ({
    type: PRODUCT_LINK_FONT_WEIGHT,
    weight,
});

export const cartPositionChange = position => ({
    type: CART_POSITION_CHANGE,
    position,
});

export const productLocationChange = position => ({
    type: PRODUCT_LOCATION_CHANGE,
    position,
});

export const currencyPosition = position => ({
    type: CURRENCY_POSITION,
    position,
});

export const titleAlign = position => ({
    type: TITLE_ALIGN,
    position,
});

export const titleFontSize = size => ({
    type: TITLE_FONT_SIZE,
    size,
});

export const subTitleFontSize = size => ({
    type: SUB_TITLE_FONT_SIZE,
    size,
});

export const priceFontSize = size => ({
    type: PRICE_FONT_SIZE,
    size,
});

export const comparePriceFontSize = size => ({
    type: COMPARE_PRICE_FONT_SIZE,
    size,
});

export const productLinkFontSize = size => ({
    type: PRODUCT_LINK_FONT_SIZE,
    size,
});

export const titleColor = hex => ({
    type: TITLE_COLOR,
    hex,
});

export const subTitleColor = hex => ({
    type: SUB_TITLE_COLOR,
    hex,
});

export const priceColor = hex => ({
    type: PRICE_COLOR,
    hex,
});

export const comparePriceColor = hex => ({
    type: COMPARE_PRICE_COLOR,
    hex,
});

export const productLinkColor = hex => ({
    type: PRODUCT_LINK_COLOR,
    hex,
});

export const selectionColor = hex => ({
    type: SELECTION_COLOR,
    hex,
});

export const navColor = hex => ({
    type: NAV_COLOR,
    hex,
});

export const activateToast = () => ({
    type: ACTIVATE_TOAST,
});

export const dismissToast = () => ({
    type: DISMISS_TOAST,
});

export const closeSliderInventoryModal = () => ({
    type: CLOSE_SLIDER_INVENTORY_MODAL,
});

export const aboveProductMainChange = text => ({
    type: ABOVE_PRODUCT_MAIN_CHANGE,
    text,
});

export const aboveProductBottomChange = text => ({
    type: ABOVE_PRODUCT_BOTTOM_CHANGE,
    text,
});

export const aboveCartTopChange = text => ({
    type: ABOVE_CART_TOP_CHANGE,
    text,
});

export const aboveCartBottomChange = text => ({
    type: ABOVE_CART_BOTTOM_CHANGE,
    text,
});

export const productMainSelectorChange = text => ({
    type: PRODUCT_MAIN_SELECTOR_CHANGE,
    text,
});

export const productBottomSelectorChange = text => ({
    type: PRODUCT_BOTTOM_SELECTOR_CHANGE,
    text,
});

export const cartTopSelectorChange = text => ({
    type: CART_TOP_SELECTOR_CHANGE,
    text,
});

export const cartBottomSelectorChange = text => ({
    type: CART_BOTTOM_SELECTOR_CHANGE,
    text,
});

export const cartTotalSelectorChange = text => ({
    type: CART_TOTAL_SELECTOR_CHANGE,
    text,
});

export const cartTopSelectLastChange = text => ({
    type: CART_TOP_SELECT_LAST_CHANGE,
    text,
});

export const cartBottomSelectLastChange = text => ({
    type: CART_BOTTOM_SELECT_LAST_CHANGE,
    text,
});

export const roundUpChange = text => ({
    type: ROUND_UP_CHANGE,
    text,
});

export const imageSharpnessChange = text => ({
    type: IMAGE_SHARPNESS_CHANGE,
    text,
});

export const facebookPixelChange = text => ({
    type: FACEBOOK_PIXEL_CHANGE,
    text,
});

export const klaviyoChange = text => ({
    type: KLAVIYO_CHANGE,
    text,
});

export const appBlocksChange = text => ({
    type: APP_BLOCKS_CHANGE,
    text,
});

export const saveSettingsInit = () => ({
    type: SAVE_SETTINGS_INIT,
});

export const saveSettingsError = () => ({
    type: SAVE_SETTINGS_ERROR,
});

export const saveSettingsSuccess = () => ({
    type: SAVE_SETTINGS_SUCCESS,
});

export const initSettings = json => ({
    type: RECOMMENDATION_WIDGET_INIT,
    title: json.recommendationWigdetTitle,
    subTitle: json.recommendationWigdetSubTitle,
    maxProducts: json.recommendationWigdetMaxProducts,
    upsell: json.upsell,
    planName: json.plan_name,
});

export const customCurrency = text => ({
    type: CUSTOM_CURRENCY,
    text,
});

export const selectionShape = text => ({
    type: SELECTION_SHAPE,
    text,
});

export const sliderFontFamily = text => ({
    type: SLIDER_FONT_FAMILY,
    text,
});

export const maxProductsChange = num => ({
    type: RECOMMENDATION_WIDGET_MAX_PRODUCTS,
    num,
});

export const autoPlayInterval = num => ({
    type: AUTO_PLAY_INTERVAL,
    num,
});

export const numOfSlidesMobile = num => ({
    type: NUM_OF_SLIDES_MOBILE,
    num,
});

export const numOfSlidesDesktop = num => ({
    type: NUM_OF_SLIDES_DESKTOP,
    num,
});

export const sliderDiscountChange = num => ({
    type: SLIDER_DISCOUNT_CHANGE,
    num,
});

export const sliderCartDrawerNumOfSlidesChange = num => ({
    type: SLIDER_CART_DRAWER_NUM_OF_SLIDES_CHANGE,
    num,
});

export const quickView = bool => ({
    type: QUICK_VIEW,
    bool,
});

export const titleOnTop = bool => ({
    type: TITLE_ON_TOP,
    bool,
});

export const showQuantityModal = bool => ({
    type: SHOW_QUANTITY_MODAL,
    bool,
});

export const imageBackground = text => ({
    type: IMAGE_BACKGROUND,
    text,
});

export const sliderNavigateToChange = text => ({
    type: SLIDER_NAVIGATE_TO_CHANGE,
    text,
});

export const sliderShowDiscountedPricesChange = text => ({
    type: SLIDER_SHOW_DISCOUNTED_PRICES_CHANGE,
    text,
});

export const sliderCartPopupChange = text => ({
    type: SLIDER_CART_POPUP_CHANGE,
    text,
});

export const sliderDisplayOptionsChange = text => ({
    type: SLIDER_DISPLAY_OPTIONS_CHANGE,
    text,
});

export const sliderCartDrawerDiscountChange = text => ({
    type: SLIDER_CART_DRAWER_DISCOUNT_CHANGE,
    text,
});

export const collapseBackgroundColor = hex => ({
    type: COLLAPSE_BACKGROUND_COLOR,
    hex,
});

export function postMsgAndDispatchProductLinkUnderlineChange(dispatch, bool) {
    dispatch(productLinkUnderline(bool));
}

export function postMsgAndDispatchShowQuantityModifierChange(dispatch, bool) {
    dispatch(showQuantityModifier(bool));
}

export function postMsgAndDispatchCurrencyCutZerosChange(dispatch, bool) {
    dispatch(currencyCutZeros(bool));
}

export function postMsgAndDispatchShuffleProductsChange(dispatch, bool) {
    dispatch(shuffleProducts(bool));
}

export function postMsgAndDispatchHideProductLinkChange(dispatch, bool) {
    dispatch(hideProductLink(bool));
}

export function postMsgAndDispatchBorderChange(dispatch, bool) {
    dispatch(border(bool));
}

export function postMsgAndDispatchNavCircleChange(dispatch, bool) {
    dispatch(navCircle(bool));
}

export function postMsgAndDispatchHideCompareToChange(dispatch, bool) {
    dispatch(hideCompareTo(bool));
}

export function postMsgAndDispatchQuickViewOnImageClickedChange(dispatch, bool) {
    dispatch(quickViewOnImageClicked(bool));
}

export function postMsgAndDispatchShowSliderVariantsChange(dispatch, text) {
    dispatch(showSliderVariants(text));
}

export function postMsgAndDispatchAutoPlayChange(dispatch, bool) {
    dispatch(autoPlay(bool));
}

export function postMsgAndDispatchTitleFontWeightChange(dispatch, weight) {
    dispatch(titleFontWeight(weight));
}

export function postMsgAndDispatchSubTitleFontWeightChange(dispatch, weight) {
    dispatch(subTitleFontWeight(weight));
}

export function postMsgAndDispatchPriceFontWeightChange(dispatch, weight) {
    dispatch(priceFontWeight(weight));
}

export function postMsgAndDispatchComparePriceFontWeightChange(dispatch, weight) {
    dispatch(comparePriceFontWeight(weight));
}

export function postMsgAndDispatchProductLinkFontWeightChange(dispatch, weight) {
    dispatch(productLinkFontWeight(weight));
}

export function postMsgAndDispatchCurrencyPositionChange(dispatch, position) {
    dispatch(currencyPosition(position));
}

export function postMsgAndDispatchTitleAlignChange(dispatch, position) {
    dispatch(titleAlign(position));
}

export function postMsgAndDispatchTitleFontSizeChange(dispatch, size) {
    dispatch(titleFontSize(size));
}

export function postMsgAndDispatchSubTitleFontSizeChange(dispatch, size) {
    dispatch(subTitleFontSize(size));
}

export function postMsgAndDispatchPriceFontSizeChange(dispatch, size) {
    dispatch(priceFontSize(size));
}

export function postMsgAndDispatchComparePriceFontSizeChange(dispatch, size) {
    dispatch(comparePriceFontSize(size));
}

export function postMsgAndDispatchProductLinkFontSizeChange(dispatch, size) {
    dispatch(productLinkFontSize(size));
}

export function postMsgAndDispatchTitleColorChange(dispatch, hex) {
    dispatch(titleColor(hex));
}

export function postMsgAndDispatchSubTitleColorChange(dispatch, hex) {
    dispatch(subTitleColor(hex));
}

export function postMsgAndDispatchPriceColorChange(dispatch, hex) {
    dispatch(priceColor(hex));
}

export function postMsgAndDispatchComparePriceColorChange(dispatch, hex) {
    dispatch(comparePriceColor(hex));
}

export function postMsgAndDispatchProductLinkColorChange(dispatch, hex) {
    dispatch(productLinkColor(hex));
}

export function postMsgAndDispatchSelectionColorChange(dispatch, hex) {
    dispatch(selectionColor(hex));
}

export function postMsgAndDispatchNavColorChange(dispatch, hex) {
    dispatch(navColor(hex));
}

export function postMsgAndDispatchCustomCurrencyChange(dispatch, text) {
    dispatch(customCurrency(text));
}

export function postMsgAndDispatchSelectionShapeChange(dispatch, text) {
    dispatch(selectionShape(text));
}

export function postMsgAndDispatchFontFamilyChange(dispatch, text) {
    dispatch(sliderFontFamily(text));
}

export function postMsgAndDispatchMaxProductsChange(dispatch, num) {
    if (num === '') {
        // do nothing
    } else {
        if (num < 1) num = 1;
        if (num > 30) num = 30;
    }
    dispatch(maxProductsChange(num));
}

export function postMsgAndDispatchAutoPlayIntervalChange(dispatch, num) {
    dispatch(autoPlayInterval(num));
}

export function postMsgAndDispatchAddToCartButtonFontSizeChange(dispatch, size) {
    dispatch(addToCartButtonFontSize(size));
}

export function postMsgAndDispatchAddToCartButtonColorChange(dispatch, hex) {
    dispatch(addToCartButtonColor(hex));
}

export function postMsgAndDispatchAddToCartButtonTextColorChange(dispatch, hex) {
    dispatch(addToCartButtonTextColor(hex));
}

export function postMsgAndDispatchSliderItemBackgroundChange(dispatch, hex) {
    dispatch(sliderItemBackground(hex));
}

export function postMsgAndDispatchAddToCartButtonMainWidgetChange(dispatch, bool) {
    dispatch(addToCartButtonMainWidget(bool));
}

export function postMsgAndDispatchQuickViewChange(dispatch, bool) {
    dispatch(quickView(bool));
}

export function postMsgAndDispatchTitleOnTopChange(dispatch, bool) {
    dispatch(titleOnTop(bool));
}

export function postMsgAndDispatchShowQuantityModalChange(dispatch, bool) {
    dispatch(showQuantityModal(bool));
}

export function postMsgAndDispatchImageBackgroundChange(dispatch, text) {
    dispatch(imageBackground(text));
}

export function postMsgAndDispatchRestoreToDefaults() {
    return (dispatch, getState) => {
        dispatch(restoreToDefaults());
    }
}

export function submit() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(saveSettingsInit());
        return fetch(`https://${host}/modify_recommendation_widget`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    values: state.settings.values,
                    max_products: state.settings.maxProducts,
                    title_color: state.settings.titleColor,
                    title_font_size: state.settings.titleFontSize,
                    title_font_weight: state.settings.titleFontWeight,
                    sub_title_color: state.settings.subTitleColor,
                    sub_title_font_size: state.settings.subTitleFontSize,
                    sub_title_font_weight: state.settings.subTitleFontWeight,
                    price_color: state.settings.priceColor,
                    price_font_size: state.settings.priceFontSize,
                    price_font_weight: state.settings.priceFontWeight,
                    compare_price_color: state.settings.comparePriceColor,
                    compare_price_font_size: state.settings.comparePriceFontSize,
                    compare_price_font_weight: state.settings.comparePriceFontWeight,
                    selection_color: state.settings.selectionColor,
                    product_link_color: state.settings.productLinkColor,
                    product_link_font_size: state.settings.productLinkFontSize,
                    product_link_font_weight: state.settings.productLinkFontWeight,
                    product_link_underline: state.settings.productLinkUnderline,
                    show_quantity_modifier: state.settings.showQuantityModifier,
                    nav_circle: state.settings.navCircle,
                    nav_color: state.settings.navColor,
                    cart_top: state.settings.cartTop,
                    cart_bottom: state.settings.cartBottom,
                    shuffle_products: state.settings.shuffleProducts,
                    currency_position: state.settings.currencyPosition,
                    title_align: state.settings.titleAlign,
                    border: state.settings.border,
                    custom_currency: state.settings.customCurrency,
                    currency_cut_zeros: state.settings.currencyCutZeros,
                    auto_play: state.settings.autoPlay,
                    auto_play_interval: state.settings.autoPlayInterval * 1000,
                    hide_product_link: state.settings.hideProductLink,
                    above_add_to_cart: state.settings.aboveAddToCart,
                    below_add_to_cart: state.settings.belowAddToCart,
                    product_bottom_enabled: state.settings.productBottomEnabled,
                    refresh_after_add_to_cart: state.settings.refreshAfterAddToCart,
                    add_to_cart_button_color: state.settings.addToCartButtonColor,
                    add_to_cart_button_main_widget: state.settings.addToCartButtonMainWidget,
                    add_to_cart_button_bottom_widget: state.settings.addToCartButtonBottomWidget,
                    add_to_cart_button_cart_widget: state.settings.addToCartButtonCartWidget,
                    add_to_cart_button_text_color: state.settings.addToCartButtonTextColor,
                    add_to_cart_button_font_size: state.settings.addToCartButtonFontSize,
                    quick_view: state.settings.quickView,
                    image_background: state.settings.imageBackground,
                    nav_to_checkout: state.settings.navToCheckout,
                    product_desc_on_quick_view: state.settings.productDescOnQuickView,
                    num_of_slides_mobile: state.settings.numOfSlidesMobile,
                    num_of_slides_desktop: state.settings.numOfSlidesDesktop,
                    slider_item_background: state.settings.sliderItemBackground,
                    title_on_top: state.settings.titleOnTop,
                    hide_compare_to: state.settings.hideCompareTo,
                    quick_view_on_image_clicked: state.settings.quickViewOnImageClicked,
                    selection_shape: state.settings.selectionShape,
                    slider_font_family: state.settings.sliderFontFamily,
                    show_quantity_modal: state.settings.showQuantityModal,
                    info_popup_slider: state.settings.infoPopupSlider,
                    nav_to_cart: state.settings.navToCart,
                    recommend_with_orders: state.settings.recommendWithOrders,
                    show_slider_variants: state.settings.showSliderVariants,
                    slider_discount: state.settings.sliderDiscount,
                    collapse_main: state.settings.collapseMain,
                    collapse_background_color: state.settings.collapseBackgroundColor,
                    navigate_to_product_page: state.settings.navigateToProductPage,
                    slider_show_after_item_added: state.settings.sliderShowAfterItemAdded,
                    take_only_first_mapping: state.settings.takeOnlyFirstMapping,
                    show_discounted_prices: state.settings.showDiscountedPrices,
                    cart_popup: state.settings.cartPopup,
                    plain_list: state.settings.plainList,
                    cart_drawer_discount: state.settings.cartDrawerDiscount,
                    cart_drawer_num_of_slides: state.settings.cartDrawerNumOfSlides,
                    only_on_source_products: state.settings.onlyOnSourceProducts,
                    show_others_after_globals: state.settings.showOthersAfterGlobals,
                })
            })
            .then((response) => {
                if (response.ok) {
                    dispatch(saveSettingsSuccess());
                    dispatch(activateToast());
                    dispatch(refreshSyncStatus());
                } else {
                    dispatch(saveSettingsError());
                }
            });

    }
}


