import React from 'react';
import {
    Button,
    Frame,
    Form,
    FormLayout,
    TextStyle,
    Toast, Card, TextField, Select,
} from "@shopify/polaris";
import '../../css/Volume.css';
import {ChromePicker} from "react-color";
import {alignOptions} from "./SettingsSlider";

const saveAmountOptions = [
    { label: 'Show tier savings per offer', value: 'all' },
    { label: 'Show tier savings per item', value: 'item' },
    { label: 'Show tier savings percentage', value: 'percentage' },
];

const tiersTableOptions = [
    { label: 'Show both table and tiers', value: 'show_both' },
    { label: 'Show dynamic discounted tiers', value: 'hide_table' },
    { label: 'Show static table of discounts', value: 'show_only_table' },
];

const SettingsVolume = ({ toastIsActive, onDismissToast, saveError, save, settings, onAddToCartColorChange,
    onAddToCartBgColorChange, saveInProgress, restoreToDefaults, onTitleColorChange,
    onSubtitleColorChange, onTitleFontSizeChange, onSubtitleFontSizeChange,
    onTitleAlignChange, onSelectedBgColorChange, onSaveTextColorChange,
    onAddVariantBgColorChange, onAddVariantTextColorChange, onPopularBgColorChange,
    onPopularTextColorChange, onTotalOriginalPriceColorChange,
    onTotalDiscountedPriceColorChange, onSaveAmountChange, onShowTiersTableChange }) =>
    <Frame>
        <Form>
            <FormLayout>
                {saveError && <TextStyle variation="negative">There was an error sending your request. Please try
                    again in a few minutes</TextStyle>}
                <div className="zrx-tab-wrapper-settings">
                    <div className="zrx-tab-title">Volume Discount Settings</div>
                    <div className="zrx-tab-subtitle">Coniguration and customizations of the volume discount offers</div>
                </div>
                <div className="form-card">
                    <Card title={'Title & Subtitle'}>
                        <Select label="Alignment:" options={alignOptions} onChange={onTitleAlignChange}
                            value={settings.title_align} disabled={saveInProgress} />
                        <div className="color-title">Title color:</div>
                        <ChromePicker color={settings.title_color}
                            onChangeComplete={onTitleColorChange} />
                        <TextField label="Title font size:" value={settings.title_font_size}
                            onChange={onTitleFontSizeChange} disabled={saveInProgress} />
                        <div className="color-title">Subtitle color:</div>
                        <ChromePicker color={settings.subtitle_color}
                            onChangeComplete={onSubtitleColorChange} />
                        <TextField label="Subtitle font size:" value={settings.subtitle_font_size}
                            onChange={onSubtitleFontSizeChange} disabled={saveInProgress} />
                    </Card>
                </div>

                    <div className="form-card">
                        <Card title={'Title & Subtitle'}>
                            <Select label="Alignment:" options={alignOptions} onChange={onTitleAlignChange}
                                    value={settings.title_align} disabled={saveInProgress} />
                            <div className="color-title">Title color:</div>
                            <ChromePicker color={settings.title_color}
                                          onChangeComplete={onTitleColorChange} />
                            <TextField label="Title font size:" value={settings.title_font_size}
                                       onChange={onTitleFontSizeChange} disabled={saveInProgress} />
                            <div className="color-title">Subtitle color:</div>
                            <ChromePicker color={settings.subtitle_color}
                                          onChangeComplete={onSubtitleColorChange} />
                            <TextField label="Subtitle font size:" value={settings.subtitle_font_size}
                                       onChange={onSubtitleFontSizeChange} disabled={saveInProgress} />
                        </Card>
                    </div>

                    <div className="form-card">
                        <Card title={'Add to Cart'}>
                            <div className="color-title">Add to cart text color:</div>
                            <ChromePicker color={settings.add_to_cart_color}
                                          onChangeComplete={onAddToCartColorChange} />
                            <div className="color-title">Add to cart background color:</div>
                            <ChromePicker color={settings.add_to_cart_bg_color}
                                          onChangeComplete={onAddToCartBgColorChange} />
                        </Card>
                    </div>

                    <div className="form-card">
                        <Card title={'Display options'}>
                            <Select label={'Discounted tiers and table display:'} options={tiersTableOptions}
                                    onChange={onShowTiersTableChange} disabled={saveInProgress}
                                    value={settings.show_only_table ? 'show_only_table' : settings.show_tiers_table ? 'show_both' : 'hide_table'} />
                            <Select label="Tier savings display:" options={saveAmountOptions} onChange={onSaveAmountChange}
                                    value={settings.show_saved_percentage ? 'percentage' : settings.save_per_item ? 'item' : 'all'}
                                    disabled={saveInProgress} />
                        </Card>
                    </div>

                    <div className="form-card">
                        <Card title={'Misc.'}>
                            <div className="color-title">Selected background color:</div>
                            <ChromePicker color={settings.selected_bg_color}
                                          onChangeComplete={onSelectedBgColorChange} />
                            <div className="color-title">Save text color:</div>
                            <ChromePicker color={settings.save_text_color}
                                          onChangeComplete={onSaveTextColorChange} />
                            <div className="color-title">Add variant background color:</div>
                            <ChromePicker color={settings.add_variant_bg_color}
                                          onChangeComplete={onAddVariantBgColorChange} />
                            <div className="color-title">Add variant text color:</div>
                            <ChromePicker color={settings.add_variant_text_color}
                                          onChangeComplete={onAddVariantTextColorChange} />
                            <div className="color-title">Most popular background color:</div>
                            <ChromePicker color={settings.popular_bg_color}
                                          onChangeComplete={onPopularBgColorChange} />
                            <div className="color-title">Most popular text color:</div>
                            <ChromePicker color={settings.popular_text_color}
                                          onChangeComplete={onPopularTextColorChange} />
                            <div className="color-title">Total original price color:</div>
                            <ChromePicker color={settings.total_original_price_color}
                                          onChangeComplete={onTotalOriginalPriceColorChange} />
                            <div className="color-title">Total discounted price color:</div>
                            <ChromePicker color={settings.total_discounted_price_color}
                                          onChangeComplete={onTotalDiscountedPriceColorChange} />

                        </Card>
                    </div>

                    <span className={"save-button"}>
                        <Button id="save-settings" onClick={save} primary loading={saveInProgress}>Save</Button>
                    </span>
                    <Button onClick={restoreToDefaults}>Restore defaults</Button>
                    {toastIsActive && <Toast content="Settings saved" onDismiss={onDismissToast} />}
                </FormLayout>
            </Form>
        </Frame>;

export default SettingsVolume;
