import { connect } from 'react-redux';
import SettingsVolume from "../../components/settings/SettingsVolume";
import {addAlphaToColor} from "./VisibleSettingsMain";
import {
    saveWidget,
    volumeAddToCartBgColorChange,
    volumeAddToCartColorChange,
    volumeAddVariantBgColorChange,
    volumeAddVariantTextColorChange,
    volumePopularBgColorChange, volumePopularTextColorChange,
    volumeRestoreToDefaults, volumeSaveAmountChange,
    volumeSaveTextColorChange,
    volumeSelectedBgColorChange, volumeShowTiersTableChange,
    volumeSubtitleColorChange,
    volumeSubtitleFontSizeChange,
    volumeTitleAlignChange,
    volumeTitleColorChange,
    volumeTitleFontSizeChange, volumeTotalDiscountedPriceColorChange, volumeTotalOriginalPriceColorChange
} from "../../actions/settingsVolume";

const mapStateToProps = state => ({
    toastIsActive: state.settingsVolume.toastIsActive,
    saveError: state.settingsVolume.saveError,
    settings: state.settingsVolume.settings,
    saveInProgress: state.settingsVolume.saveInProgress,
});

const mapDispatchToProps = dispatch => ({
    onAddToCartColorChange: color => dispatch(volumeAddToCartColorChange(addAlphaToColor(color))),
    onAddToCartBgColorChange: color => dispatch(volumeAddToCartBgColorChange(addAlphaToColor(color))),
    onTitleColorChange: color => dispatch(volumeTitleColorChange(addAlphaToColor(color))),
    onSubtitleColorChange: color => dispatch(volumeSubtitleColorChange(addAlphaToColor(color))),
    onSelectedBgColorChange: color => dispatch(volumeSelectedBgColorChange(addAlphaToColor(color))),
    onSaveTextColorChange: color => dispatch(volumeSaveTextColorChange(addAlphaToColor(color))),
    onAddVariantBgColorChange: color => dispatch(volumeAddVariantBgColorChange(addAlphaToColor(color))),
    onAddVariantTextColorChange: color => dispatch(volumeAddVariantTextColorChange(addAlphaToColor(color))),
    onPopularBgColorChange: color => dispatch(volumePopularBgColorChange(addAlphaToColor(color))),
    onPopularTextColorChange: color => dispatch(volumePopularTextColorChange(addAlphaToColor(color))),
    onTotalOriginalPriceColorChange: color => dispatch(volumeTotalOriginalPriceColorChange(addAlphaToColor(color))),
    onTotalDiscountedPriceColorChange: color => dispatch(volumeTotalDiscountedPriceColorChange(addAlphaToColor(color))),
    onTitleAlignChange: text => dispatch(volumeTitleAlignChange(text)),
    onTitleFontSizeChange: text => dispatch(volumeTitleFontSizeChange(text)),
    onSubtitleFontSizeChange: text => dispatch(volumeSubtitleFontSizeChange(text)),
    onSaveAmountChange: text => dispatch(volumeSaveAmountChange(text)),
    onShowTiersTableChange: text => dispatch(volumeShowTiersTableChange(text)),
    save: () => dispatch(saveWidget()),
    restoreToDefaults: () => dispatch(volumeRestoreToDefaults()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettingsVolume);
