import * as _ from "lodash";
import {stickyAtcSetZoorixMPromoBadge} from "./sticky-atc/stickyAtc";

export const tabs = [
    {
        id: 'status',
        content: 'Dashboard',
    },
    {
        id: 'products',
        content: 'Offers',
    },
    {
        id: 'settings',
        content: 'Settings',
    },
    {
        id: 'texts',
        content: 'Texts',
    },
    {
        id: 'warranty-moderation',
        content: 'Warranty Mgmt',
    },
    {
        id: 'analytics',
        content: 'ROI',
    },
    {
        id: 'support',
        content: 'Support',
    },
    {
        id: 'manual-installation',
        content: "Installation",
    },
    {
        id: 'faq',
        content: "Tutorials & FAQ",
    },
    {
        id: 'css-editor',
        content: 'Code Editor',
    },
    {
        id: 'integrations',
        content: 'Integrations',
    },
    {
        id: 'plans',
        content: 'Plans',
    },

];

export const integrationTabs = [
    {
        id: 'all',
        content: 'All',
    },
    {
        id: 'page-builders',
        content: 'Page Builders',
    },
    {
        id: 'translations',
        content: 'Translations',
    },
];


export const HANDLE_TAB_CHANGE = 'HANDLE_TAB_CHANGE';
export const CHANGE_INTEGRATIONS_TAB = 'CHANGE_INTEGRATIONS_TAB';
export const ACTIVATE_GENERIC_TOAST = 'ACTIVATE_GENERIC_TOAST';
export const INIT_NAV = 'INIT_NAV';
export const SLIDER_CLICKED = 'SLIDER_CLICKED';
export const BUNDLE_CLICKED = 'BUNDLE_CLICKED';
export const WARRANTY_CLICKED = 'WARRANTY_CLICKED';
export const CART_DRAWER_CLICKED = 'CART_DRAWER_CLICKED';
export const VOLUME_DISCOUNT_CLICKED = 'VOLUME_DISCOUNT_CLICKED';
export const MIX_AND_MATCH_CLICKED = 'MIX_AND_MATCH_CLICKED';
export const DISMISS_GENERIC_TOAST = 'DISMISS_GENERIC_TOAST';
export const APP_CLICKED = 'APP_CLICKED';

export const handleTabChange = tab => ({
    type: HANDLE_TAB_CHANGE,
    tab,
});

export const changeIntegrationsTab = tab => ({
    type: CHANGE_INTEGRATIONS_TAB,
    tab,
});

export const activateGenericToast = content => ({
    type: ACTIVATE_GENERIC_TOAST,
    content,
});

export const initNav = (json, appType) => ({
    type: INIT_NAV,
    installation: json.installation,
    packageName: json.package_name,
    appType,
});

export const sliderClicked = () => ({
    type: SLIDER_CLICKED,
});

export const bundleClicked = () => ({
    type: BUNDLE_CLICKED,
});

export const warrantyClicked = () => ({
    type: WARRANTY_CLICKED,
});

export const appClicked = () => ({
    type: APP_CLICKED,
});

export const cartDrawerClicked = () => ({
    type: CART_DRAWER_CLICKED,
});

export const volumeDiscountClicked = () => ({
    type: VOLUME_DISCOUNT_CLICKED,
});

export const mixAndMatchClicked = () => ({
    type: MIX_AND_MATCH_CLICKED,
});

export const dismissGenericToast = () => ({
    type: DISMISS_GENERIC_TOAST,
});

export function changeTab(name) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(handleTabChange(getTabIndexByName(name, state.nav.tabs)));
    }
}

export function upgradeToZoorixM() {
    return (dispatch, getState) => {
        const state = getState();
        window.open(state.stickyAtc.storeData.upgrade_url, '_blank');
        dispatch(stickyAtcSetZoorixMPromoBadge(false));
    }
}

export function getTabIndexByName(name, tabs) {
    return _.findIndex(tabs, { id: name });
}
