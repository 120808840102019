import { connect } from 'react-redux'
import StickyAtcFaq from "../../components/sticky-atc/StickyAtcFaq";

const mapStateToProps = state => ({
    stickyAtc: state.stickyAtc,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StickyAtcFaq);
