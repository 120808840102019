import { connect } from 'react-redux'
import StickyAtcDashboard from "../../components/sticky-atc/StickyAtcDasboard";
import {dismissUpdatePopup} from "../../actions/sticky-atc/stickyAtc";

const mapStateToProps = state => ({
    stickyAtc: state.stickyAtc,
});

const mapDispatchToProps = (dispatch) => ({
    dismissUpdatePopup: () => dispatch(dismissUpdatePopup()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StickyAtcDashboard);
