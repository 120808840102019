import { connect } from 'react-redux'
import StickyAtcSupport from "../../components/sticky-atc/StickyAtcSupport";
import {stickyAtcSupportSend, stickyAtcSupportTextChange, createStoreJsFromCrm} from "../../actions/sticky-atc/stickyAtc";

const mapStateToProps = state => ({
    stickyAtc: state.stickyAtc,
});

const mapDispatchToProps = (dispatch) => ({
    onTextChange: attribute => text => dispatch(stickyAtcSupportTextChange(attribute, text)),
    send: () => {
        dispatch(stickyAtcSupportSend())
        dispatch(createStoreJsFromCrm())
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StickyAtcSupport);
