import fetch from "cross-fetch";
import {host, postPayload} from "./data";
import {adminTrack} from "./v2/appV2Wizard";

export const INIT_PRODUCTS_VOLUME = 'INIT_PRODUCTS_VOLUME';
export const INIT_PRODUCTS_VOLUME_COLLECTIONS = 'INIT_PRODUCTS_VOLUME_COLLECTIONS';
export const VOLUME_EDIT_DISCOUNT_SUCCESS = 'VOLUME_EDIT_DISCOUNT_SUCCESS';
export const INIT_PRODUCTS_VOLUME_FAILED = 'INIT_PRODUCTS_VOLUME_FAILED';
export const VOLUME_DISMISS_GENERAL_TOAST = 'VOLUME_DISMISS_GENERAL_TOAST';
export const CREATING_VOLUME_DISCOUNT = 'CREATING_VOLUME_DISCOUNT';
export const CREATE_VOLUME_DISCOUNT_FAILED = 'CREATE_VOLUME_DISCOUNT_FAILED';
export const VOLUME_BACK = 'VOLUME_BACK';
export const VOLUME_DISCOUNT_ADD_TIER = 'VOLUME_DISCOUNT_ADD_TIER';
export const VOLUME_DISCOUNT_CHOOSE_PRODUCT = 'VOLUME_DISCOUNT_CHOOSE_PRODUCT';
export const VOLUME_DISCOUNT_ADD_PRODUCT = 'VOLUME_DISCOUNT_ADD_PRODUCT';
export const VOLUME_DISCOUNT_TOGGLE_ADD_GROUP_ACTIVE = 'VOLUME_DISCOUNT_TOGGLE_ADD_GROUP_ACTIVE';
export const VOLUME_DISCOUNT_TOGGLE_DYNAMIC_ADD_GROUP_ACTIVE = 'VOLUME_DISCOUNT_TOGGLE_DYNAMIC_ADD_GROUP_ACTIVE';
export const VOLUME_DISCOUNT_ADD_GROUP = 'VOLUME_DISCOUNT_ADD_GROUP';
export const VOLUME_DISCOUNT_CHOOSE_DYNAMIC_GROUP = 'VOLUME_DISCOUNT_CHOOSE_DYNAMIC_GROUP';
export const VOLUME_DISCOUNT_CLOSE_CHOOSE_PRODUCT = 'VOLUME_DISCOUNT_CLOSE_CHOOSE_PRODUCT';
export const VOLUME_DISCOUNT_SEARCH_FAILURE = 'VOLUME_DISCOUNT_SEARCH_FAILURE';
export const VOLUME_DISCOUNT_SAVING = 'VOLUME_DISCOUNT_SAVING';
export const VOLUME_DISCOUNT_REMOVING = 'VOLUME_DISCOUNT_REMOVING';
export const VOLUME_DISCOUNT_REMOVE_FAILURE = 'VOLUME_DISCOUNT_REMOVE_FAILURE';
export const VOLUME_DISCOUNT_SAVE_FAILURE = 'VOLUME_DISCOUNT_SAVE_FAILURE';
export const VOLUME_CLOSE_REMOVE_DISCOUNT_MODAL = 'VOLUME_CLOSE_REMOVE_DISCOUNT_MODAL';
export const CREATE_VOLUME_DISCOUNT = 'CREATE_VOLUME_DISCOUNT';
export const VOLUME_DISCOUNT_SAVE_SUCCESS = 'VOLUME_DISCOUNT_SAVE_SUCCESS';
export const VOLUME_DISCOUNT_REMOVE_SUCCESS = 'VOLUME_DISCOUNT_REMOVE_SUCCESS';
export const VOLUME_SWITCH_DISCOUNT_ENABLED_SUCCESS = 'VOLUME_SWITCH_DISCOUNT_ENABLED_SUCCESS';
export const VOLUME_DISCOUNT_SEARCH_SUCCESS = 'VOLUME_DISCOUNT_SEARCH_SUCCESS';
export const ON_VOLUME_LOCALE_CHANGE = 'ON_VOLUME_LOCALE_CHANGE';
export const VOLUME_GENERAL_TOAST = 'VOLUME_GENERAL_TOAST';
export const VOLUME_DISCOUNT_TITLE_CHANGE = 'VOLUME_DISCOUNT_TITLE_CHANGE';
export const VOLUME_DISCOUNT_SUBTITLE_CHANGE = 'VOLUME_DISCOUNT_SUBTITLE_CHANGE';
export const VOLUME_DISCOUNT_ADD_VARIANT_TEXT_CHANGE = 'VOLUME_DISCOUNT_ADD_VARIANT_TEXT_CHANGE';
export const VOLUME_DISCOUNT_NAVIGATE_TO_CHANGE = 'VOLUME_DISCOUNT_NAVIGATE_TO_CHANGE';
export const START_VOLUME_DISCOUNT_SEARCH = 'START_VOLUME_DISCOUNT_SEARCH';
export const VOLUME_DISCOUNT_NAME_CHANGE = 'VOLUME_DISCOUNT_NAME_CHANGE';
export const VOLUME_DISCOUNT_DISPLAY_ON_CHANGE = 'VOLUME_DISCOUNT_DISPLAY_ON_CHANGE';
export const VOLUME_DISCOUNT_GROUP_TYPE_CHANGE = 'VOLUME_DISCOUNT_GROUP_TYPE_CHANGE';
export const VOLUME_DISCOUNT_DYNAMIC_GROUP_TYPE_CHANGE = 'VOLUME_DISCOUNT_DYNAMIC_GROUP_TYPE_CHANGE';
export const VOLUME_DISCOUNT_GROUP_VALUE_CHANGE = 'VOLUME_DISCOUNT_GROUP_VALUE_CHANGE';
export const VOLUME_DISCOUNT_DYNAMIC_GROUP_VALUE_CHANGE = 'VOLUME_DISCOUNT_DYNAMIC_GROUP_VALUE_CHANGE';
export const VOLUME_DISCOUNT_CART_DISPLAY_ON_CHANGE = 'VOLUME_DISCOUNT_CART_DISPLAY_ON_CHANGE';
export const VOLUME_DISCOUNT_PRODUCT_DISPLAY_ON_CHANGE = 'VOLUME_DISCOUNT_PRODUCT_DISPLAY_ON_CHANGE';
export const VOLUME_DISCOUNT_OFFER_TYPE_CHANGE = 'VOLUME_DISCOUNT_OFFER_TYPE_CHANGE';
export const VOLUME_DISCOUNT_CHOOSE_PRODUCT_ITEM = 'VOLUME_DISCOUNT_CHOOSE_PRODUCT_ITEM';
export const VOLUME_DISCOUNT_REMOVE_TIER = 'VOLUME_DISCOUNT_REMOVE_TIER';
export const VOLUME_DISCOUNT_REMOVE_SOURCE_PRODUCT = 'VOLUME_DISCOUNT_REMOVE_SOURCE_PRODUCT';
export const VOLUME_DISCOUNT_REMOVE_SOURCE_GROUP = 'VOLUME_DISCOUNT_REMOVE_SOURCE_GROUP';
export const VOLUME_PREDEFINED_VARIANTS_CHANGE = 'VOLUME_PREDEFINED_VARIANTS_CHANGE';
export const VOLUME_ADD_VARIANT = 'VOLUME_ADD_VARIANT';
export const VOLUME_VARIANT_QTY_CHANGE = 'VOLUME_VARIANT_QTY_CHANGE';
export const VOLUME_VARIANT_CHANGE = 'VOLUME_VARIANT_CHANGE';
export const VOLUME_REMOVE_VARIANT = 'VOLUME_REMOVE_VARIANT';
export const VOLUME_OPEN_REMOVE_DISCOUNT_MODAL = 'VOLUME_OPEN_REMOVE_DISCOUNT_MODAL';
export const VOLUME_SHOW_SHORT_CODE = 'VOLUME_SHOW_SHORT_CODE';
export const VOLUME_EDITING_DISCOUNT = 'VOLUME_EDITING_DISCOUNT';
export const VOLUME_SWITCHING_DISCOUNT_ENABLED = 'VOLUME_SWITCHING_DISCOUNT_ENABLED';
export const VOLUME_EDIT_DISCOUNT_FAILED = 'VOLUME_EDIT_DISCOUNT_FAILED';
export const VOLUME_SWITCH_DISCOUNT_ENABLED_FAILED = 'VOLUME_SWITCH_DISCOUNT_ENABLED_FAILED';
export const VOLUME_DISCOUNT_QTY_CHANGE = 'VOLUME_DISCOUNT_QTY_CHANGE';
export const VOLUME_DISCOUNT_DISCOUNT_TYPE_CHANGE = 'VOLUME_DISCOUNT_DISCOUNT_TYPE_CHANGE';
export const VOLUME_DISCOUNT_DISCOUNT_CHANGE = 'VOLUME_DISCOUNT_DISCOUNT_CHANGE';
export const VOLUME_PREVIEW_DISCOUNT_LOADING = 'VOLUME_PREVIEW_DISCOUNT_LOADING';
export const VOLUME_DISCOUNT_MARK_AS_POPULAR = 'VOLUME_DISCOUNT_MARK_AS_POPULAR';

export const initProductsVolume = json => ({
    type: INIT_PRODUCTS_VOLUME,
    json,
});

export const initProductsVolumeCollections = json => ({
    type: INIT_PRODUCTS_VOLUME_COLLECTIONS,
    json,
});

export const volumeEditDiscountSuccess = json => ({
    type: VOLUME_EDIT_DISCOUNT_SUCCESS,
    json,
});

export const createVolumeDiscount = json => ({
    type: CREATE_VOLUME_DISCOUNT,
    json,
});

export const volumeDiscountSaveSuccess = json => ({
    type: VOLUME_DISCOUNT_SAVE_SUCCESS,
    json,
});

export const volumeDiscountRemoveSuccess = json => ({
    type: VOLUME_DISCOUNT_REMOVE_SUCCESS,
    json,
});

export const volumeSwitchDiscountEnabledSuccess = (id, json) => ({
    type: VOLUME_SWITCH_DISCOUNT_ENABLED_SUCCESS,
    id,
    json,
});

export const volumeDiscountSearchSuccess = json => ({
    type: VOLUME_DISCOUNT_SEARCH_SUCCESS,
    products: json.products,
    existingIds: json.existing_ids,
    title: json.title,
});

export const volumeDiscountChooseProductItem = id => ({
    type: VOLUME_DISCOUNT_CHOOSE_PRODUCT_ITEM,
    id,
});

export const volumeDiscountRemoveTier = id => ({
    type: VOLUME_DISCOUNT_REMOVE_TIER,
    id,
});

export const volumeDiscountRemoveSourceProduct = id => ({
    type: VOLUME_DISCOUNT_REMOVE_SOURCE_PRODUCT,
    id,
});

export const volumeDiscountRemoveSourceGroup = id => ({
    type: VOLUME_DISCOUNT_REMOVE_SOURCE_GROUP,
    id,
});

export const volumePredefinedVariantsChange = id => ({
    type: VOLUME_PREDEFINED_VARIANTS_CHANGE,
    id,
});

export const volumeAddVariant = id => ({
    type: VOLUME_ADD_VARIANT,
    id,
});

export const volumeVariantQtyChange = (id, id2, num) => ({
    type: VOLUME_VARIANT_QTY_CHANGE,
    id,
    id2,
    num,
});

export const volumeVariantChange = (id, id2, text) => ({
    type: VOLUME_VARIANT_CHANGE,
    id,
    id2,
    text,
});

export const volumeRemoveVariant = (id, id2) => ({
    type: VOLUME_REMOVE_VARIANT,
    id,
    id2,
});

export const volumeOpenRemoveDiscountModal = id => ({
    type: VOLUME_OPEN_REMOVE_DISCOUNT_MODAL,
    id,
});

export const volumeShowShortCode = (id, bool) => ({
    type: VOLUME_SHOW_SHORT_CODE,
    id,
    bool,
});

export const volumeEditingDiscount = id => ({
    type: VOLUME_EDITING_DISCOUNT,
    id,
});

export const volumeSwitchingDiscountEnabled = id => ({
    type: VOLUME_SWITCHING_DISCOUNT_ENABLED,
    id,
});

export const volumeEditDiscountFailed = id => ({
    type: VOLUME_EDIT_DISCOUNT_FAILED,
    id,
});

export const volumeSwitchDiscountEnabledFailed = id => ({
    type: VOLUME_SWITCH_DISCOUNT_ENABLED_FAILED,
    id,
});

export const volumeDiscountQtyChange = (id, text) => ({
    type: VOLUME_DISCOUNT_QTY_CHANGE,
    id,
    text,
});

export const volumeDiscountDiscountTypeChange = (id, text) => ({
    type: VOLUME_DISCOUNT_DISCOUNT_TYPE_CHANGE,
    id,
    text,
});

export const volumeDiscountDiscountChange = (id, text) => ({
    type: VOLUME_DISCOUNT_DISCOUNT_CHANGE,
    id,
    text,
});

export const volumePreviewDiscountLoading = (id, bool) => ({
    type: VOLUME_PREVIEW_DISCOUNT_LOADING,
    id,
    bool,
});

export const volumeDiscountMarkAsPopular = (id, bool) => ({
    type: VOLUME_DISCOUNT_MARK_AS_POPULAR,
    id,
    bool,
});

export const onVolumeLocaleChange = text => ({
    type: ON_VOLUME_LOCALE_CHANGE,
    text,
});

export const volumeGeneralToast = text => ({
    type: VOLUME_GENERAL_TOAST,
    text,
});

export const volumeDiscountTitleChange = text => ({
    type: VOLUME_DISCOUNT_TITLE_CHANGE,
    text,
});

export const volumeDiscountSubtitleChange = text => ({
    type: VOLUME_DISCOUNT_SUBTITLE_CHANGE,
    text,
});

export const volumeDiscountAddVariantTextChange = text => ({
    type: VOLUME_DISCOUNT_ADD_VARIANT_TEXT_CHANGE,
    text,
});

export const volumeDiscountNavigateToChange = text => ({
    type: VOLUME_DISCOUNT_NAVIGATE_TO_CHANGE,
    text,
});

export const startVolumeDiscountSearch = text => ({
    type: START_VOLUME_DISCOUNT_SEARCH,
    text,
});

export const volumeDiscountNameChange = text => ({
    type: VOLUME_DISCOUNT_NAME_CHANGE,
    text,
});

export const volumeDiscountDisplayOnChange = text => ({
    type: VOLUME_DISCOUNT_DISPLAY_ON_CHANGE,
    text,
});

export const volumeDiscountGroupTypeChange = text => ({
    type: VOLUME_DISCOUNT_GROUP_TYPE_CHANGE,
    text,
});

export const volumeDiscountDynamicGroupTypeChange = text => ({
    type: VOLUME_DISCOUNT_DYNAMIC_GROUP_TYPE_CHANGE,
    text,
});

export const volumeDiscountGroupValueChange = text => ({
    type: VOLUME_DISCOUNT_GROUP_VALUE_CHANGE,
    text,
});

export const volumeDiscountDynamicGroupValueChange = text => ({
    type: VOLUME_DISCOUNT_DYNAMIC_GROUP_VALUE_CHANGE,
    text,
});

export const volumeDiscountCartDisplayOnChange = text => ({
    type: VOLUME_DISCOUNT_CART_DISPLAY_ON_CHANGE,
    text,
});

export const volumeDiscountProductDisplayOnChange = text => ({
    type: VOLUME_DISCOUNT_PRODUCT_DISPLAY_ON_CHANGE,
    text,
});

export const volumeDiscountOfferTypeChange = text => ({
    type: VOLUME_DISCOUNT_OFFER_TYPE_CHANGE,
    text,
});

export const initProductsVolumeFailed = () => ({
    type: INIT_PRODUCTS_VOLUME_FAILED,
});

export const volumeDismissGeneralToast = () => ({
    type: VOLUME_DISMISS_GENERAL_TOAST,
});

export const creatingVolumeDiscount = () => ({
    type: CREATING_VOLUME_DISCOUNT,
});

export const createVolumeDiscountFailed = () => ({
    type: CREATE_VOLUME_DISCOUNT_FAILED,
});

export const volumeBack = () => ({
    type: VOLUME_BACK,
});

export const volumeDiscountAddTier = () => ({
    type: VOLUME_DISCOUNT_ADD_TIER,
});

export const volumeDiscountChooseProduct = () => ({
    type: VOLUME_DISCOUNT_CHOOSE_PRODUCT,
});

export const volumeDiscountAddProduct = () => ({
    type: VOLUME_DISCOUNT_ADD_PRODUCT,
});

export const volumeDiscountToggleAddGroupActive = () => ({
    type: VOLUME_DISCOUNT_TOGGLE_ADD_GROUP_ACTIVE,
});

export const volumeDiscountToggleDynamicAddGroupActive = () => ({
    type: VOLUME_DISCOUNT_TOGGLE_DYNAMIC_ADD_GROUP_ACTIVE,
});

export const volumeDiscountAddGroup = () => ({
    type: VOLUME_DISCOUNT_ADD_GROUP,
});

export const volumeDiscountChooseDynamicGroup = () => ({
    type: VOLUME_DISCOUNT_CHOOSE_DYNAMIC_GROUP,
});

export const volumeDiscountCloseChooseProduct = () => ({
    type: VOLUME_DISCOUNT_CLOSE_CHOOSE_PRODUCT,
});

export const volumeDiscountSearchFailure = () => ({
    type: VOLUME_DISCOUNT_SEARCH_FAILURE,
});

export const volumeDiscountSaving = () => ({
    type: VOLUME_DISCOUNT_SAVING,
});

export const volumeDiscountRemoving = () => ({
    type: VOLUME_DISCOUNT_REMOVING,
});

export const volumeDiscountRemoveFailure = () => ({
    type: VOLUME_DISCOUNT_REMOVE_FAILURE,
});

export const volumeDiscountSaveFailure = () => ({
    type: VOLUME_DISCOUNT_SAVE_FAILURE,
});

export const volumeCloseRemoveDiscountModal = () => ({
    type: VOLUME_CLOSE_REMOVE_DISCOUNT_MODAL,
});

export function volumeDiscountChangeSearchText(text) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(startVolumeDiscountSearch(text));
        return fetch(`https://${host}/data/search_products_with_variants`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    title: text,
                    existing_ids: [],
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then(json => dispatch(volumeDiscountSearchSuccess(json)));
                } else {
                    dispatch(volumeDiscountSearchFailure());
                }
            });
    }
}

export function volumeDiscountSave() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(volumeDiscountSaving());
        return fetch(`https://${host}/volume_discount/save`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    discount: state.productsVolume.discount,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(volumeDiscountSaveSuccess(json));
                        dispatch(adminTrack('admin_volume_discount_offer_saved', {
                            volume_discount_name: state.productsVolume.discount.name,
                            volume_discount_id: state.productsVolume.discount.id,
                        }));
                    });
                } else {
                    dispatch(volumeDiscountSaveFailure());
                }
            });
    }
}

export function volumeRemoveDiscountAction() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(volumeDiscountRemoving());
        return fetch(`https://${host}/volume_discount/delete`,
            {
                method: 'delete',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    discount_id: state.productsVolume.discounts[state.productsVolume.discountIdToDelete].id,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(volumeCloseRemoveDiscountModal());
                        dispatch(volumeDiscountRemoveSuccess(json));
                    });
                } else {
                    dispatch(volumeDiscountRemoveFailure());
                }
            });
    }
}

export function loadProductVolume() {
    return (dispatch, getState) => {
        const state = getState();
        if (!state.productsVolume.ready) {
            fetch(`https://${host}/volume_discount/get_init_data_for_admin?shop_name=${state.data.shopName}&token=${state.data.token}`)
                .then(response => {
                    if (response.ok) {
                        response.json().then(json => {
                            dispatch(initProductsVolume(json));
                        });
                    } else {
                        dispatch(initProductsVolumeFailed());
                    }
                })
        }
    }
}

export function volumeEditDiscount(id) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(volumeEditingDiscount(id));
        fetch(`https://${host}/volume_discount/edit?shop_name=${state.data.shopName}&token=${state.data.token}&id=${id}&fetch_available_locales=${state.productsVolume.availableLocales.length === 0}`)
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(volumeEditDiscountSuccess(json));
                    });
                } else {
                    dispatch(volumeEditDiscountFailed(id));
                }
            })
    }
}

export function volumeSwitchEnable(id) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(volumeSwitchingDiscountEnabled(id));
        fetch(`https://${host}/volume_discount/switch_enabled?shop_name=${state.data.shopName}&token=${state.data.token}&id=${id}`)
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(volumeSwitchDiscountEnabledSuccess(id, json));
                    });
                } else {
                    dispatch(volumeSwitchDiscountEnabledFailed(id));
                }
            })
    }
}

export function volumePreviewDiscount(id) {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(volumePreviewDiscountLoading(id, true));
        const res = await postPayload('volume_discount/preview', {id}, state);
        if (!res.ok) console.log('volumePreviewDiscount failed');
        const json = await res.json();
        window.open(`https://${state.data.shopName}/products/${json.handle}`, '_blank');
        dispatch(volumePreviewDiscountLoading(id, false));
    }
}

export function createVolumeDiscountApi() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(creatingVolumeDiscount());
        fetch(`https://${host}/volume_discount/create?shop_name=${state.data.shopName}&token=${state.data.token}&fetch_available_locales=${state.productsVolume.availableLocales.length === 0}`)
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(createVolumeDiscount(json));
                    });
                } else {
                    dispatch(createVolumeDiscountFailed());
                }
            })
    }
}

export function volumeCopyShortCode() {
    return async (dispatch, getState) => {
        const state = getState();
        await navigator.clipboard.writeText(state.productsVolume.shortCode);
        dispatch(volumeGeneralToast('Short Code Copied'));
    }
}
