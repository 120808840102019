import React from 'react';
import {
    Button,
    Card,
    Frame,
    TextField,
    TextStyle,
    Toast,
    Form,
    FormLayout,
    Select,
    Checkbox,
} from '@shopify/polaris';
import '../../css/Settings.css';
import { ChromePicker } from "react-color";
import {currencyPositionOptions, fontOptions, fontWeightOptions} from "./SettingsSlider";

const alignOptions = [
    { label: 'Left', value: 'left' },
    { label: 'Center', value: 'center' },
    { label: 'Right', value: 'right' },
];

const navigateOptions = [
    { label: 'Checkout', value: 'checkout' },
    { label: 'Cart', value: 'cart' },
    { label: 'None', value: 'none' },
];

const deselectBundleCheckboxesOptions = [
    { label: 'Selected', value: 'selected' },
    { label: 'Unselected', value: 'unselected' },
];

const showSaveOptions = [
    { label: 'Enable', value: 'enable' },
    { label: 'Disable', value: 'disable' },
];

const SettingsBundle = ({ toastIsActive, onDismissToast, save, saveInProgress, saveError, settings,
    onAddSelectedToCartTextColorChange,
    onAddSelectedToCartButtonColorChange,
    restoreToDefaults, onTitleAlignChange,
    onTotalPriceTextColorChange, onBundleComparePriceColorChange,
    onBundleComparePriceSizeChange, onBundlePriceColorChange, onBundlePriceSizeChange, onHideBundleComparePriceChange,
    onSumComparePricesChange, onBundleTitleFontSizeChange, onBundleTitleColorChange, onFontFamilyChange,
    onNavigateToChange, onShowBundleItemCompareChange, onBundleMainEnabledChange, onBundleBottomEnabledChange,
    onInfoPopupBundleChange, onBundleItemPriceColorChange, onBundleItemNameColorChange,
    onTitleFontWeightChange, onDeselectBundleCheckboxesChange,
    onQuickViewEnabledChange, onProductDescOnQuickViewChange, planName,
    onCutZerosChange, onCurrencyPositionChange, onRefreshOnCartChange, onRegenerateAutoBundlesChange, onShowSaveChange
}) => (
    <Frame>
        <Form>
            <FormLayout>
                {saveError && <TextStyle variation="negative">There was an error sending your request. Please try again in a
                    few minutes</TextStyle>}
                <div className="zrx-tab-wrapper-settings">
                    <div className="zrx-tab-title">Bundle Settings</div>
                    <div className="zrx-tab-subtitle">Configuration and customizations of the bundle offers</div>
                </div>
                <div className="form-card">
                    <Card title="Location in product page">
                        <div>
                            <Checkbox label="Middle" checked={settings.bundle_main_enabled} onChange={onBundleMainEnabledChange} disabled={saveInProgress} />&nbsp;
                            </div>
                            <Checkbox label="Bottom" checked={settings.bundle_bottom_enabled} onChange={onBundleBottomEnabledChange} disabled={saveInProgress} /><br />
                        </Card>
                    </div>
                    <div className="form-card">
                        <Card title={'Title'}>
                            <TextField label="Font size:" value={settings.bundle_title_font_size}
                                       onChange={onBundleTitleFontSizeChange}
                                       disabled={saveInProgress} autoComplete={false} />
                            <Select label="Font weight:" options={fontWeightOptions} onChange={onTitleFontWeightChange}
                                    value={settings.title_font_weight} disabled={saveInProgress} />
                            <Select label="Alignment:" options={alignOptions} onChange={onTitleAlignChange}
                                    value={settings.title_align} disabled={saveInProgress} />
                            <div className="color-title">Title color:</div>
                            <ChromePicker color={settings.bundle_title_color}
                                          onChangeComplete={onBundleTitleColorChange} />
                            <div className="color-title">Item name color:</div>
                            <ChromePicker color={settings.bundle_item_name_color}
                                          onChangeComplete={onBundleItemNameColorChange} />
                        </Card>
                    </div>
                    <div className="form-card">
                        <Card title={'Navigation'}>
                            <Select label="Navigate after adding bundle:" options={navigateOptions}
                                    onChange={onNavigateToChange}
                                    value={settings.navigate_to} disabled={saveInProgress} />
                        </Card>
                    </div>
                    <div className="form-card">
                        <Card title={'Add Bundle'}>
                            <Checkbox label="Refresh on cart page after adding bundle"
                                      checked={settings.refresh_on_cart}
                                      onChange={onRefreshOnCartChange}
                                      disabled={saveInProgress} />
                            <div className="color-title">Text color:</div>
                            <ChromePicker color={settings.add_selected_to_cart_text_color}
                                onChangeComplete={onAddSelectedToCartTextColorChange} />
                            <div className="color-title">Button color:</div>
                            <ChromePicker color={settings.add_selected_to_cart_button_color}
                                onChangeComplete={onAddSelectedToCartButtonColorChange} />
                        </Card>
                    </div>
                    <div className="form-card">
                        <Card title={'Price'}>
                            <div style={{marginTop: '10px'}}>
                                <Checkbox label="Hide bundle 'compare to' price" checked={settings.hide_bundle_compare_price}
                                          onChange={onHideBundleComparePriceChange}
                                          disabled={saveInProgress} />
                            </div>
                            <div style={{marginTop: '3px'}}>
                                <Checkbox label="Sum 'compare to' prices" checked={settings.sum_compare_prices}
                                          onChange={onSumComparePricesChange}
                                          disabled={saveInProgress} />
                            </div>
                            <div style={{marginTop: '3px'}}>
                                <Checkbox label="Show bundle item 'compare to' price" checked={settings.show_bundle_item_compare}
                                          onChange={onShowBundleItemCompareChange}
                                          disabled={saveInProgress} />
                            </div>
                            <TextField label="Bundle price font size:" value={settings.bundle_price_size}
                                       onChange={onBundlePriceSizeChange}
                                       disabled={saveInProgress} autoComplete={false} />
                            <div className="color-title">Total price text color:</div>
                            <ChromePicker color={settings.total_price_text_color}
                                          onChangeComplete={onTotalPriceTextColorChange} />
                            <div className="color-title">Item price color:</div>
                            <ChromePicker color={settings.bundle_item_price_color}
                                          onChangeComplete={onBundleItemPriceColorChange} />
                            <TextField label="Bundle 'compare to' price font size:" value={settings.bundle_compare_price_size}
                                       onChange={onBundleComparePriceSizeChange}
                                       disabled={saveInProgress} autoComplete={false} />
                            <div className="color-title">Bundle price color:</div>
                            <ChromePicker color={settings.bundle_price_color}
                                          onChangeComplete={onBundlePriceColorChange} />
                            <div className="color-title">Bundle 'compare to' price color:</div>
                            <ChromePicker color={settings.bundle_compare_price_color}
                                          onChangeComplete={onBundleComparePriceColorChange} />
                        </Card>
                    </div>
                    <div className="form-card">
                        <Card title={'Currency'}>
                            <div style={{marginTop: '3px'}}>
                                <Checkbox label="Cut zeros" checked={settings.cut_zeros} onChange={onCutZerosChange}
                                          disabled={saveInProgress} />
                            </div>
                            <Select label="Currency side:" options={currencyPositionOptions} disabled={saveInProgress}
                                    onChange={onCurrencyPositionChange} value={settings.bundle_currency_position} />
                        </Card>
                    </div>
                    <div className="form-card">
                        <Card title={'Notification Popup'}>
                            <Checkbox label="Enabled" checked={settings.info_popup_bundle} onChange={onInfoPopupBundleChange} disabled={saveInProgress} />
                        </Card>
                    </div>
                    <div className="form-card">
                        <Card title={'Quick view'}>
                            <Checkbox label="Enabled" checked={settings.quick_view_enabled}
                                      onChange={onQuickViewEnabledChange} disabled={saveInProgress} /> <br/>
                            <Checkbox label="Show full product description" checked={settings.product_desc_on_quick_view}
                                      onChange={onProductDescOnQuickViewChange} disabled={saveInProgress} />
                        </Card>
                    </div>
                    <div className="form-card">
                        <Card title={'Bulk bundles'}>
                            <Checkbox label="Re-generate automatic bundles periodically" checked={settings.auto_generate_bundles}
                                      onChange={onRegenerateAutoBundlesChange} disabled={saveInProgress} />
                        </Card>
                    </div>
                    <div className="form-card">
                        <Card title={'Savings'}>
                            <Select label="Show total savings:" options={showSaveOptions} onChange={onShowSaveChange}
                                    value={settings.show_save ? 'enable' : 'disable'} disabled={saveInProgress} />
                        </Card>
                    </div>
                    <div className="form-card">
                        <Card title={'Advanced'}>
                            <Select label="Widget font:" options={fontOptions} onChange={onFontFamilyChange}
                                    value={settings.bundle_font_family} disabled={saveInProgress} />
                            <Select label="Initial checkboxes state:" options={deselectBundleCheckboxesOptions}
                                    onChange={onDeselectBundleCheckboxesChange}
                                    value={settings.deselect_bundle_checkboxes ? 'unselected' : 'selected'} disabled={saveInProgress} />
                        </Card>
                    </div>
                    <span className={"save-button " + (planName === 'trial' ? 'trial' : '')}>
                        <Button id="save-settings" onClick={save} primary loading={saveInProgress}>Save</Button>
                    </span>
                    <Button onClick={restoreToDefaults}>Restore defaults</Button>
                    {toastIsActive && <Toast content="Settings saved" onDismiss={onDismissToast} />}
                </FormLayout>
            </Form>
        </Frame>
    );

export default SettingsBundle;
