import fetch from "cross-fetch";
import {host, postPayload} from "./data";
import {refreshAutoBundlesStatus, refreshSyncStatus} from "./status";
import {adminTrack} from "./v2/appV2Wizard";

export const INIT_PRODUCTS_BUNDLE = 'INIT_PRODUCTS_BUNDLE';
export const AUTO_BUNDLES_STATUS = 'AUTO_BUNDLES_STATUS';
export const SWITCH_ENABLE_SUCCESS = 'SWITCH_ENABLE_SUCCESS';
export const CREATE_BUNDLE = 'CREATE_BUNDLE';
export const CREATE_PAGE = 'CREATE_PAGE';
export const BUNDLES_IMPORT_EXPORT = 'BUNDLES_IMPORT_EXPORT';
export const AUTO_BUNDLES_LOADED = 'AUTO_BUNDLES_LOADED';
export const IMPORTED_BUNDLES_LOADED = 'IMPORTED_BUNDLES_LOADED';
export const AUTO_BUNDLES_LOADING = 'AUTO_BUNDLES_LOADING';
export const IMPORTED_BUNDLES_LOADING = 'IMPORTED_BUNDLES_LOADING';
export const EDIT_PAGE = 'EDIT_PAGE';
export const MANAGE_PAGES = 'MANAGE_PAGES';
export const CREATE_GROUPED_BUNDLE = 'CREATE_GROUPED_BUNDLE';
export const GEN_AUTO_BUNDLES = 'GEN_AUTO_BUNDLES';
export const AUTO_BUNDLES = 'AUTO_BUNDLES';
export const IMPORTED_BUNDLES = 'IMPORTED_BUNDLES';
export const DEL_AUTO_BUNDLES = 'DEL_AUTO_BUNDLES';
export const CLOSE_REMOVE_AUTO_BUNDLE_MODAL = 'CLOSE_REMOVE_AUTO_BUNDLE_MODAL';
export const EDIT_BUNDLE = 'EDIT_BUNDLE';
export const REMOVE_BUNDLE = 'REMOVE_BUNDLE';
export const SHOW_SHORT_CODE = 'SHOW_SHORT_CODE';
export const DISMISS_TOAST = 'DISMISS_TOAST';
export const CLOSE_REMOVE_BUNDLE_MODAL = 'CLOSE_REMOVE_BUNDLE_MODAL';
export const BUNDLE_REMOVE_FAILURE = 'BUNDLE_REMOVE_FAILURE';
export const AUTO_BUNDLE_REMOVE_FAILURE = 'AUTO_BUNDLE_REMOVE_FAILURE';
export const SWITCH_ENABLE_FAILURE = 'SWITCH_ENABLE_FAILURE';
export const BACK = 'BACK';
export const OVERRIDE_EXISTING_BUNDLES_ON_IMPORT = 'OVERRIDE_EXISTING_BUNDLES_ON_IMPORT';
export const SAVING = 'SAVING';
export const SAVE_ERROR = 'SAVE_ERROR';
export const SETTINGS_SAVED = 'SETTINGS_SAVED';
export const GEN_AUTO_BUNDLES_SUCCESS = 'GEN_AUTO_BUNDLES_SUCCESS';
export const BUNDLE_NAME_CHANGE = 'BUNDLE_NAME_CHANGE';
export const AMOUNT_CHANGE = 'AMOUNT_CHANGE';
export const AMOUNT2_CHANGE = 'AMOUNT2_CHANGE';
export const TYPE_CHANGE = 'TYPE_CHANGE';
export const CHOOSE_PRODUCT = 'CHOOSE_PRODUCT';
export const CLOSE_CHOOSE_PRODUCT = 'CLOSE_CHOOSE_PRODUCT';
export const BUNDLE_START_ADDING_GROUP = 'BUNDLE_START_ADDING_GROUP';
export const PAGE_SAVING = 'PAGE_SAVING';
export const PAGE_DELETING = 'PAGE_DELETING';
export const EDITING_BUNDLE = 'EDITING_BUNDLE';
export const EDITING_BUNDLE_FAILED = 'EDITING_BUNDLE_FAILED';
export const BUNDLE_START_CHOOSING_GROUP = 'BUNDLE_START_CHOOSING_GROUP';
export const CREATING_BUNDLE = 'CREATING_BUNDLE';
export const CREATING_AUTO_BUNDLE = 'CREATING_AUTO_BUNDLE';
export const CREATING_GROUPED_BUNDLE = 'CREATING_GROUPED_BUNDLE';
export const CREATING_GROUPED_BUNDLE_FAILED = 'CREATING_GROUPED_BUNDLE_FAILED';
export const CREATING_AUTO_BUNDLE_FAILED = 'CREATING_AUTO_BUNDLE_FAILED';
export const CREATING_BUNDLE_FAILED = 'CREATING_BUNDLE_FAILED';
export const BUNDLE_ADD_GROUP_FAILURE = 'BUNDLE_ADD_GROUP_FAILURE';
export const BUNDLE_CHOOSE_GROUP_FAILURE = 'BUNDLE_CHOOSE_GROUP_FAILURE';
export const BUNDLE_ADD_GROUP_SUCCESS = 'BUNDLE_ADD_GROUP_SUCCESS';
export const PAGE_SAVE_SUCCESS = 'PAGE_SAVE_SUCCESS';
export const BUNDLE_LOAD_AUTO_BUNDLES_JSON_FOR_SEARCH = 'BUNDLE_LOAD_AUTO_BUNDLES_JSON_FOR_SEARCH';
export const PAGE_DELETE_SUCCESS = 'PAGE_DELETE_SUCCESS';
export const PAGE_SAVE_FAILURE = 'PAGE_SAVE_FAILURE';
export const PAGE_DELETE_FAILURE = 'PAGE_DELETE_FAILURE';
export const BUNDLE_CHOOSE_GROUP_SUCCESS = 'BUNDLE_CHOOSE_GROUP_SUCCESS';
export const START_BUNDLE_SEARCH = 'START_BUNDLE_SEARCH';
export const PAGE_TITLE_CHANGE = 'PAGE_TITLE_CHANGE';
export const MOVE_BUNDLE_UP = 'MOVE_BUNDLE_UP';
export const MOVE_BUNDLE_DOWN = 'MOVE_BUNDLE_DOWN';
export const REMOVE_BUNDLE_FROM_PAGE = 'REMOVE_BUNDLE_FROM_PAGE';
export const BUNDLE_SELECTED = 'BUNDLE_SELECTED';
export const BUNDLE_SEARCH_SUCCESS = 'BUNDLE_SEARCH_SUCCESS';
export const BUNDLE_SEARCH_FAILURE = 'BUNDLE_SEARCH_FAILURE';
export const START_BUNDLE_REMOVE = 'START_BUNDLE_REMOVE';
export const START_AUTO_BUNDLE_REMOVE = 'START_AUTO_BUNDLE_REMOVE';
export const CLOSE_SHORT_CODE_MODAL = 'CLOSE_SHORT_CODE_MODAL';
export const CLOSE_NO_AUTO_BUNDLES_MODAL = 'CLOSE_NO_AUTO_BUNDLES_MODAL';
export const DISMISS_DUPLICATE_BUNDLE_TOAST = 'DISMISS_DUPLICATE_BUNDLE_TOAST';
export const OPEN_NO_AUTO_BUNDLES_MODAL = 'OPEN_NO_AUTO_BUNDLES_MODAL';
export const CLOSE_CHOOSE_VARIANTS = 'CLOSE_CHOOSE_VARIANTS';
export const CHOOSE_VARIANTS_ACTION = 'CHOOSE_VARIANTS_ACTION';
export const OPEN_QUANTITATIVE_MODAL = 'OPEN_QUANTITATIVE_MODAL';
export const CLOSE_QUANTITATIVE_MODAL = 'CLOSE_QUANTITATIVE_MODAL';
export const TOGGLE_ADD_GROUP_ACTIVE = 'TOGGLE_ADD_GROUP_ACTIVE';
export const SHOWING_BUNDLES_SEMANTIC_NAMES = 'SHOWING_BUNDLES_SEMANTIC_NAMES';
export const SHOW_BUNDLES_SEMANTIC_NAMES_FAILURE = 'SHOW_BUNDLES_SEMANTIC_NAMES_FAILURE';
export const SHOW_BUNDLES_SEMANTIC_NAMES_SUCCESS = 'SHOW_BUNDLES_SEMANTIC_NAMES_SUCCESS';
export const CHOOSE_BUNDLE_ITEM = 'CHOOSE_BUNDLE_ITEM';
export const REMOVE_SOURCE_PRODUCT = 'REMOVE_SOURCE_PRODUCT';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const CHOOSE_VARIANTS = 'CHOOSE_VARIANTS';
export const REMOVE_SOURCE_GROUP = 'REMOVE_SOURCE_GROUP';
export const SWITCH_ENABLE_LOADING = 'SWITCH_ENABLE_LOADING';
export const LOAD_PAGES_SUCCESS = 'LOAD_PAGES_SUCCESS';
export const DUPLICATE_BUNDLE_STARTED = 'DUPLICATE_BUNDLE_STARTED';
export const DUPLICATE_BUNDLE_SUCCESS = 'DUPLICATE_BUNDLE_SUCCESS';
export const DUPLICATE_BUNDLE_FAILURE = 'DUPLICATE_BUNDLE_FAILURE';
export const PREVIEW_BUNDLE_LOADING = 'PREVIEW_BUNDLE_LOADING';
export const BUNDLE_CART_TOP_CHANGE = 'BUNDLE_CART_TOP_CHANGE';
export const OVERRIDE_EXISTING_BUNDLES_ON_IMPORT_CHANGE = 'OVERRIDE_EXISTING_BUNDLES_ON_IMPORT_CHANGE';
export const TOGGLE_OVERRIDE_EXISTING_BUNDLES_MODAL = 'TOGGLE_OVERRIDE_EXISTING_BUNDLES_MODAL';
export const BUNDLE_CART_BOTTOM_CHANGE = 'BUNDLE_CART_BOTTOM_CHANGE';
export const BUNDLE_ALWAYS_SHOWN_ON_CART_CHANGE = 'BUNDLE_ALWAYS_SHOWN_ON_CART_CHANGE';
export const BUNDLE_LOAD_AUTO_BUNDLES_FOR_SEARCH = 'BUNDLE_LOAD_AUTO_BUNDLES_FOR_SEARCH';
export const IN_BUNDLE_CHANGED = 'IN_BUNDLE_CHANGED';
export const BUNDLE_DISCOUNT_TYPE_CHANGED = 'BUNDLE_DISCOUNT_TYPE_CHANGED';
export const DISPLAY_CHANGE = 'DISPLAY_CHANGE';
export const SINGLE_BUNDLE_NAVIGATE_TO_CHANGE = 'SINGLE_BUNDLE_NAVIGATE_TO_CHANGE';
export const BUNDLE_SUBTITLE_CHANGE = 'BUNDLE_SUBTITLE_CHANGE';
export const BUNDLE_AUTO_GEN_CHANGE = 'BUNDLE_AUTO_GEN_CHANGE';
export const GROUP_TYPE_CHANGE = 'GROUP_TYPE_CHANGE';
export const GROUP_VALUE_CHANGE = 'GROUP_VALUE_CHANGE';
export const SHOW_ON_PRODUCT_PAGE_CHANGE = 'SHOW_ON_PRODUCT_PAGE_CHANGE';
export const BUNDLE_LOCALE_CHANGE = 'BUNDLE_LOCALE_CHANGE';
export const BUNDLE_QTY_MODIFIERS_CHANGE = 'BUNDLE_QTY_MODIFIERS_CHANGE';
export const BUNDLE_SEMANTIC_NAME_CHANGE = 'BUNDLE_SEMANTIC_NAME_CHANGE';
export const IMPORT_BUNDLE_STATE = 'IMPORT_BUNDLE_STATE';

export const initProductsBundle = json => ({
    type: INIT_PRODUCTS_BUNDLE,
    bundles: json.bundles,
    importedBundlesCount: json.imported_bundles_count,
    currencySymbol: json.currency_symbol,
    tags: json.tags,
    vendors: json.vendors,
    productTypes: json.product_types,
    customCollections: json.customCollections,
    smartCollections: json.smartCollections,
    adminSettings: json.admin_settings,
});

export const autoBundlesStatus = json => ({
    type: AUTO_BUNDLES_STATUS,
    bundles: json.bundles,
});

export const switchEnableSuccess = (json, id) => ({
    type: SWITCH_ENABLE_SUCCESS,
    bundles: json.bundles,
    id,
});

export const createBundle = json => ({
    type: CREATE_BUNDLE,
    json,
});

export const createPage = () => ({
    type: CREATE_PAGE,
});

export const bundlesImportExport = () => ({
    type: BUNDLES_IMPORT_EXPORT,
});

export const autoBundlesLoaded = () => ({
    type: AUTO_BUNDLES_LOADED,
});

export const importedBundlesLoaded = () => ({
    type: IMPORTED_BUNDLES_LOADED,
});

export const autoBundlesLoading = () => ({
    type: AUTO_BUNDLES_LOADING,
});

export const importedBundlesLoading = () => ({
    type: IMPORTED_BUNDLES_LOADING,
});

export const editPage = id => ({
    type: EDIT_PAGE,
    id,
});

export const managePages = () => ({
    type: MANAGE_PAGES,
});

export const createGroupedBundle = json => ({
    type: CREATE_GROUPED_BUNDLE,
    json,
});

export const genAutoBundles = json => ({
    type: GEN_AUTO_BUNDLES,
    json,
});

export const autoBundles = json => ({
    type: AUTO_BUNDLES,
    json,
});

export const importedBundles = json => ({
    type: IMPORTED_BUNDLES,
    json,
});

export const delAutoBundles = () => ({
    type: DEL_AUTO_BUNDLES,
});

export const closeRemoveAutoBundleModal = () => ({
    type: CLOSE_REMOVE_AUTO_BUNDLE_MODAL,
});

export const editBundle = (id, json) => ({
    type: EDIT_BUNDLE,
    id,
    json,
});

export const removeBundle = id => ({
    type: REMOVE_BUNDLE,
    id,
});

export const showShortCode = id => ({
    type: SHOW_SHORT_CODE,
    id,
});

export const dismissToast = () => ({
    type: DISMISS_TOAST,
});

export const closeRemoveBundleModal = () => ({
    type: CLOSE_REMOVE_BUNDLE_MODAL,
});

export const bundleRemoveFailure = () => ({
    type: BUNDLE_REMOVE_FAILURE,
});

export const autoBundleRemoveFailure = () => ({
    type: AUTO_BUNDLE_REMOVE_FAILURE,
});

export const switchEnableFailure = id => ({
    type: SWITCH_ENABLE_FAILURE,
    id,
});

export const back = () => ({
    type: BACK,
});

export const overrideExistingBundlesOnImport = () => ({
    type: OVERRIDE_EXISTING_BUNDLES_ON_IMPORT,
});

export const saving = () => ({
    type: SAVING,
});

export const saveError = () => ({
    type: SAVE_ERROR,
});

export const settingsSaved = () => ({
    type: SETTINGS_SAVED,
});

export const genAutoBundlesSuccess = () => ({
    type: GEN_AUTO_BUNDLES_SUCCESS,
});

export const bundleNameChange = name => ({
    type: BUNDLE_NAME_CHANGE,
    name,
});

export const amountChange = amount => ({
    type: AMOUNT_CHANGE,
    amount,
});

export const amount2Change = (i, amount) => ({
    type: AMOUNT2_CHANGE,
    amount,
    i,
});

export const typeChange = text => ({
    type: TYPE_CHANGE,
    text,
});

export const chooseProduct = id => ({
    type: CHOOSE_PRODUCT,
    id,
});

export const closeChooseProduct = () => ({
    type: CLOSE_CHOOSE_PRODUCT,
});

export const bundleStartAddingGroup = () => ({
    type: BUNDLE_START_ADDING_GROUP,
});

export const pageSaving = () => ({
    type: PAGE_SAVING,
});

export const pageDeleting = id => ({
    type: PAGE_DELETING,
    id,
});

export const editingBundle = id => ({
    type: EDITING_BUNDLE,
    id,
});

export const editingBundleFailed = id => ({
    type: EDITING_BUNDLE_FAILED,
    id,
});

export const bundleStartChoosingGroup = () => ({
    type: BUNDLE_START_CHOOSING_GROUP,
});

export const creatingBundle = () => ({
    type: CREATING_BUNDLE,
});

export const creatingAutoBundle = () => ({
    type: CREATING_AUTO_BUNDLE,
});

export const creatingGroupedBundle = () => ({
    type: CREATING_GROUPED_BUNDLE,
});

export const creatingGroupedBundleFailed = () => ({
    type: CREATING_GROUPED_BUNDLE_FAILED,
});

export const creatingAutoBundleFailed = () => ({
    type: CREATING_AUTO_BUNDLE_FAILED,
});

export const creatingBundleFailed = () => ({
    type: CREATING_BUNDLE_FAILED,
});

export const bundleAddGroupFailure = () => ({
    type: BUNDLE_ADD_GROUP_FAILURE,
});

export const bundleChooseGroupFailure = () => ({
    type: BUNDLE_CHOOSE_GROUP_FAILURE,
});

export const bundleAddGroupSuccess = json => ({
    type: BUNDLE_ADD_GROUP_SUCCESS,
    id: json.id,
    count: json.count,
    filter1_type: json.filter,
    filter1_value: json.text,
});

export const pageSaveSuccess = json => ({
    type: PAGE_SAVE_SUCCESS,
    json,
});

export const bundleLoadAutoBundlesJsonForSearch = json => ({
    type: BUNDLE_LOAD_AUTO_BUNDLES_JSON_FOR_SEARCH,
    json,
});

export const pageDeleteSuccess = json => ({
    type: PAGE_DELETE_SUCCESS,
    json,
});

export const pageSaveFailure = () => ({
    type: PAGE_SAVE_FAILURE,
});

export const pageDeleteFailure = () => ({
    type: PAGE_DELETE_FAILURE,
});

export const bundleChooseGroupSuccess = json => ({
    type: BUNDLE_CHOOSE_GROUP_SUCCESS,
    count: json.count,
    filter1_type: json.filter,
    filter1_value: json.text,
});

export const startBundleSearch = text => ({
    type: START_BUNDLE_SEARCH,
    text,
});

export const pageTitleChange = text => ({
    type: PAGE_TITLE_CHANGE,
    text,
});

export const moveBundleUp = index => ({
    type: MOVE_BUNDLE_UP,
    index,
});

export const moveBundleDown = index => ({
    type: MOVE_BUNDLE_DOWN,
    index,
});

export const removeBundleFromPage = index => ({
    type: REMOVE_BUNDLE_FROM_PAGE,
    index,
});

export const bundleSelected = item => ({
    type: BUNDLE_SELECTED,
    id: item.key,
});

export const bundleSearchSuccess = json => ({
    type: BUNDLE_SEARCH_SUCCESS,
    products: json.products,
    existingIds: json.existing_ids,
    title: json.title,
});

export const bundleSearchFailure = () => ({
    type: BUNDLE_SEARCH_FAILURE,
});

export const startBundleRemove = () => ({
    type: START_BUNDLE_REMOVE,
});

export const startAutoBundleRemove = () => ({
    type: START_AUTO_BUNDLE_REMOVE,
});

export const closeShortCodeModal = () => ({
    type: CLOSE_SHORT_CODE_MODAL,
});

export const closeNoAutoBundlesModal = () => ({
    type: CLOSE_NO_AUTO_BUNDLES_MODAL,
});

export const dismissDuplicateBundleToast = () => ({
    type: DISMISS_DUPLICATE_BUNDLE_TOAST,
});

export const openNoAutoBundlesModal = () => ({
    type: OPEN_NO_AUTO_BUNDLES_MODAL,
});

export const closeChooseVariants = () => ({
    type: CLOSE_CHOOSE_VARIANTS,
});

export const chooseVariantsAction = () => ({
    type: CHOOSE_VARIANTS_ACTION,
});

export const openQuantitativeModal = () => ({
    type: OPEN_QUANTITATIVE_MODAL,
});

export const closeQuantitativeModal = () => ({
    type: CLOSE_QUANTITATIVE_MODAL,
});

export const toggleAddGroupActive = () => ({
    type: TOGGLE_ADD_GROUP_ACTIVE,
});

export const showingBundlesSemanticNames = text => ({
    type: SHOWING_BUNDLES_SEMANTIC_NAMES,
    text,
});

export const showBundlesSemanticNamesFailure = text => ({
    type: SHOW_BUNDLES_SEMANTIC_NAMES_FAILURE,
    text,
});

export const showBundlesSemanticNamesSuccess = text => ({
    type: SHOW_BUNDLES_SEMANTIC_NAMES_SUCCESS,
    text,
});

export const chooseBundleItem = id => ({
    type: CHOOSE_BUNDLE_ITEM,
    id,
});

export const removeSourceProduct = id => ({
    type: REMOVE_SOURCE_PRODUCT,
    id,
});

export const removeProduct = id => ({
    type: REMOVE_PRODUCT,
    id,
});

export const chooseVariants = id => ({
    type: CHOOSE_VARIANTS,
    id,
});

export const removeSourceGroup = id => ({
    type: REMOVE_SOURCE_GROUP,
    id,
});

export const switchEnableLoading = id => ({
    type: SWITCH_ENABLE_LOADING,
    id,
});

export const loadPagesSuccess = json => ({
    type: LOAD_PAGES_SUCCESS,
    pages: json.pages,
});

export const duplicateBundleStarted = id => ({
    type: DUPLICATE_BUNDLE_STARTED,
    id,
});

export const duplicateBundleSuccess = (json, id) => ({
    type: DUPLICATE_BUNDLE_SUCCESS,
    bundles: json.bundles,
    id,
});

export const duplicateBundleFailure = id => ({
    type: DUPLICATE_BUNDLE_FAILURE,
    id,
});

export const previewBundleLoading = (id, bool) => ({
    type: PREVIEW_BUNDLE_LOADING,
    id,
    bool,
});

export const bundleCartTopChange = bool => ({
    type: BUNDLE_CART_TOP_CHANGE,
    bool,
});

export const overrideExistingBundlesOnImportChange = bool => ({
    type: OVERRIDE_EXISTING_BUNDLES_ON_IMPORT_CHANGE,
    bool,
});

export const toggleOverrideExistingBundlesModal = bool => ({
    type: TOGGLE_OVERRIDE_EXISTING_BUNDLES_MODAL,
    bool,
});

export const bundleCartBottomChange = bool => ({
    type: BUNDLE_CART_BOTTOM_CHANGE,
    bool,
});

export const bundleAlwaysShownOnCartChange = bool => ({
    type: BUNDLE_ALWAYS_SHOWN_ON_CART_CHANGE,
    bool,
});

export const bundleLoadAutoBundlesForSearch = bool => ({
    type: BUNDLE_LOAD_AUTO_BUNDLES_FOR_SEARCH,
    bool,
});

export const inBundleChanged = (id, bool) => ({
    type: IN_BUNDLE_CHANGED,
    id,
    bool,
});

export const bundleDiscountTypeChanged = (id, text) => ({
    type: BUNDLE_DISCOUNT_TYPE_CHANGED,
    id,
    text,
});

export const displayChange = text => ({
    type: DISPLAY_CHANGE,
    text,
});

export const singleBundleNavigateToChange = text => ({
    type: SINGLE_BUNDLE_NAVIGATE_TO_CHANGE,
    text,
});

export const bundleSubtitleChange = text => ({
    type: BUNDLE_SUBTITLE_CHANGE,
    text,
});

export const bundleAutoGenChange = text => ({
    type: BUNDLE_AUTO_GEN_CHANGE,
    text,
});

export const groupTypeChange = text => ({
    type: GROUP_TYPE_CHANGE,
    text,
});

export const groupValueChange = text => ({
    type: GROUP_VALUE_CHANGE,
    text,
});

export const showOnProductPageChange = text => ({
    type: SHOW_ON_PRODUCT_PAGE_CHANGE,
    text,
});

export const bundleLocaleChange = text => ({
    type: BUNDLE_LOCALE_CHANGE,
    text,
});

export const bundleQtyModifiersChange = text => ({
    type: BUNDLE_QTY_MODIFIERS_CHANGE,
    text,
});

export const bundleSemanticNameChange = text => ({
    type: BUNDLE_SEMANTIC_NAME_CHANGE,
    text,
});

export const importBundleState = json => ({
    type: IMPORT_BUNDLE_STATE,
    json,
});

export function bundleAddGroup() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(bundleStartAddingGroup());
        return fetch(`https://${host}/data/fetch_dynamic_count`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    app_type: state.data.appType,
                    filter: state.productsBundle.groupAddition.groupType,
                    text: state.productsBundle.groupAddition.groupValue,
                    second_filter_present: false,
                    get_variants: false,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => dispatch(bundleAddGroupSuccess(json)));
                } else {
                    dispatch(bundleAddGroupFailure());
                }
            });
    }
}

export function savePage() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(pageSaving());
        return fetch(`https://${host}/pages/save`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    app_type: state.data.appType,
                    page: state.productsBundle.page,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => dispatch(pageSaveSuccess(json)));
                } else {
                    dispatch(pageSaveFailure());
                }
            });
    }
}

export function editBundleApi(id) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(editingBundle(id));
        fetch(`https://${host}/bundle/edit?shop_name=${state.data.shopName}&token=${state.data.token}&id=${id}&fetch_available_locales=${state.productsBundle.availableLocales.length === 0}${state.data.appType ? `&app_type=${state.data.appType}` : ''}`)
            .then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(editBundle(id, json));
                    });
                } else {
                    dispatch(editingBundleFailed(id));
                }
            })
    }
}

export function createBundleApi() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(creatingBundle());
        fetch(`https://${host}/bundle/create?shop_name=${state.data.shopName}&token=${state.data.token}&fetch_available_locales=${state.productsBundle.availableLocales.length === 0}${state.data.appType ? `&app_type=${state.data.appType}` : ''}`)
            .then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(createBundle(json));
                    });
                } else {
                    dispatch(creatingBundleFailed());
                }
            })
    }
}

export function genAutoBundlesApi() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(creatingAutoBundle());
        fetch(`https://${host}/bundle/create?shop_name=${state.data.shopName}&token=${state.data.token}&fetch_available_locales=${state.productsBundle.availableLocales.length === 0}${state.data.appType ? `&app_type=${state.data.appType}` : ''}`)
            .then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(genAutoBundles(json));
                    });
                } else {
                    dispatch(creatingAutoBundleFailed());
                }
            })
    }
}

export function createGroupedBundleApi() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(creatingGroupedBundle());
        fetch(`https://${host}/bundle/create?shop_name=${state.data.shopName}&token=${state.data.token}&fetch_available_locales=${state.productsBundle.availableLocales.length === 0}${state.data.appType ? `&app_type=${state.data.appType}` : ''}`)
            .then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(createGroupedBundle(json));
                    });
                } else {
                    dispatch(creatingGroupedBundleFailed());
                }
            })
    }
}

export function deletePage(id) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(pageDeleting(id));
        return fetch(`https://${host}/pages/delete`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    app_type: state.data.appType,
                    id: id,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => dispatch(pageDeleteSuccess(json)));
                } else {
                    dispatch(pageDeleteFailure());
                }
            });
    }
}

export function bundleChooseGroup() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(bundleStartChoosingGroup());
        return fetch(`https://${host}/data/fetch_dynamic_count`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    app_type: state.data.appType,
                    filter: state.productsBundle.groupAddition.groupType,
                    text: state.productsBundle.groupAddition.groupValue,
                    second_filter_present: false,
                    get_variants: false,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => dispatch(bundleChooseGroupSuccess(json)));
                } else {
                    dispatch(bundleChooseGroupFailure());
                }
            });
    }
}

export function changeSearchText(text) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(startBundleSearch(text));
        return fetch(`https://${host}/data/search_products_with_variants`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    app_type: state.data.appType,
                    title: text,
                    existing_ids: [],
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => dispatch(bundleSearchSuccess(json)));
                } else {
                    dispatch(bundleSearchFailure());
                }
            });
    }
}

export function removeBundleAction() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(startBundleRemove());
        return fetch(`https://${host}/bundle/delete`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    app_type: state.data.appType,
                    id: state.productsBundle.destBundle,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        dispatch(initProductsBundle(json));
                        dispatch(closeRemoveBundleModal());
                        dispatch(refreshSyncStatus());
                        dispatch(loadPaginatedBundles());
                    });
                } else {
                    dispatch(bundleRemoveFailure());
                }
            });
    }
}

export function removeAutoBundlesAction() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(startAutoBundleRemove());
        return fetch(`https://${host}/bundle/delete_all_auto`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    app_type: state.data.appType,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        dispatch(initProductsBundle(json));
                        dispatch(closeRemoveAutoBundleModal());
                        dispatch(refreshSyncStatus());
                        dispatch(loadPaginatedBundles());
                    });
                } else {
                    dispatch(autoBundleRemoveFailure());
                }
            });
    }
}

export function switchEnable(id) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(switchEnableLoading(id));
        return fetch(`https://${host}/bundle/switch_enable`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    app_type: state.data.appType,
                    id: id,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        dispatch(switchEnableSuccess(json, id));
                        dispatch(refreshSyncStatus());
                        dispatch(loadPaginatedBundles())
                    });
                } else {
                    dispatch(switchEnableFailure(id));
                }
            });
    }
}

export function loadPages() {
    return (dispatch, getState) => {
        const state = getState();
        if (state.productsBundle.pages) {
            return;
        }
        return fetch(`https://${host}/pages/get_all?shop_name=${state.data.shopName}&token=${state.data.token}${state.data.appType ? `&app_type=${state.data.appType}` : ''}`)
            .then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(loadPagesSuccess(json));
                    });
                } else {
                    // do nothing
                }
            });
    }
}

export function createOrManagePages() {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(bundleLoadAutoBundlesForSearch(true));
        const res = await postPayload('bundle/get_auto_for_search', {}, state);
        if (!res.ok) {
            console.log('createOrManagePages failed to fetch auto bundles');
            return;
        }
        const json = await res.json();
        dispatch(bundleLoadAutoBundlesJsonForSearch(json));
        dispatch(bundleLoadAutoBundlesForSearch(false));
        if (!state.productsBundle.pages) {
            return;
        }
        if (state.productsBundle.pages.length === 0) {
            dispatch(createPage());
        } else {
            dispatch(managePages())
        }
    }
}

export function loadAutoBundles() {
    return (dispatch, getState) => {
        const state = getState();
        if (state.productsBundle.autoBundlesLoaded) {
            return;
        }
        dispatch(autoBundlesLoaded());
        fetch(`https://${host}/bundle/get_auto?shop_name=${state.data.shopName}&token=${state.data.token}&page=0${state.data.appType ? `&app_type=${state.data.appType}` : ''}`)
            .then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(autoBundles(json));
                    });
                } else {
                    // do nothing
                }
            })
    }
}

export function loadImportedBundles() {
    return async (dispatch, getState) => {
        const state = getState();
        if (state.productsBundle.importedBundlesLoaded) {
            return;
        }
        dispatch(importedBundlesLoaded());
        const response = await postPayload('bundle/get_imported', {page: 0}, state);
        if (response.ok) {
            const json = await response.json();
            dispatch(importedBundles(json));
        } else {
            // do nothing
        }
    }
}

export function loadPaginatedBundles() {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(autoBundlesLoaded());
        dispatch(autoBundlesLoading());
        dispatch(importedBundlesLoaded());
        dispatch(importedBundlesLoading());
        let response = await fetch(`https://${host}/bundle/get_auto?shop_name=${state.data.shopName}&token=${state.data.token}&page=${state.productsBundle.autoBundles.page || 0}${state.data.appType ? `&app_type=${state.data.appType}` : ''}`);
        if (response.ok) {
            const json = await response.json();
            dispatch(autoBundles(json));
        }
        response = await postPayload('bundle/get_imported', {page: state.productsBundle.importedBundles.page || 0}, state);
        if (response.ok) {
            const json = await response.json();
            dispatch(importedBundles(json));
        } else {
            // do nothing
        }
    }
}

export function nextAutoBundlePage() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(autoBundlesLoading());
        fetch(`https://${host}/bundle/get_auto?shop_name=${state.data.shopName}&token=${state.data.token}&page=${state.productsBundle.autoBundles.page + 1}${state.data.appType ? `&app_type=${state.data.appType}` : ''}`)
            .then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(autoBundles(json));
                    });
                } else {
                    // do nothing
                }
            })
    }
}

export function previousAutoBundlePage() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(autoBundlesLoading());
        fetch(`https://${host}/bundle/get_auto?shop_name=${state.data.shopName}&token=${state.data.token}&page=${state.productsBundle.autoBundles.page - 1}${state.data.appType ? `&app_type=${state.data.appType}` : ''}`)
            .then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        dispatch(autoBundles(json));
                    });
                } else {
                    // do nothing
                }
            })
    }
}

export function previousImportedBundlePage() {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(importedBundlesLoading());
        const response = await postPayload('bundle/get_imported', {page: state.productsBundle.importedBundles.page - 1}, state);
        if (response.ok) {
            const json = await response.json();
            dispatch(importedBundles(json));
        } else {
            // do nothing
        }
    }
}

export function nextImportedBundlePage() {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(importedBundlesLoading());
        const response = await postPayload('bundle/get_imported', {page: state.productsBundle.importedBundles.page + 1}, state);
        if (response.ok) {
            const json = await response.json();
            dispatch(importedBundles(json));
        } else {
            // do nothing
        }
    }
}

export function duplicateBundle(id) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(duplicateBundleStarted(id));
        return fetch(`https://${host}/bundle/duplicate`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    app_type: state.data.appType,
                    id: id,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        dispatch(duplicateBundleSuccess(json, id));
                        dispatch(refreshSyncStatus());
                        dispatch(loadPaginatedBundles())
                    });
                } else {
                    dispatch(duplicateBundleFailure(id));
                }
            });
    }
}

export function previewBundle(id) {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(previewBundleLoading(id, true));
        const res = await postPayload('bundle/preview', {id}, state);
        if (!res.ok) {
            console.log('previewBundle failure')
        }
        const json = await res.json();
        window.open(`https://${state.data.shopName}/products/${json.handle}`, '_blank');
        dispatch(previewBundleLoading(id, false));
    }
}

export function showBundlesSemanticNamesChange(text) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(showingBundlesSemanticNames(text));
        return fetch(`https://${host}/admin_settings/toggle_show_bundles_semantic_names`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    app_type: state.data.appType,
                    bool: text === 'name',
                })
            })
            .then((response) => {
                if (response.ok) {
                    dispatch(showBundlesSemanticNamesSuccess(text));
                } else {
                    dispatch(showBundlesSemanticNamesFailure(text === 'name' ? 'title' : 'name'));
                }
            });
    }
}

export function save() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(saving());
        return fetch(`https://${host}/bundle/save`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    app_type: state.data.appType,
                    bundle: state.productsBundle.bundle,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        dispatch(initProductsBundle(json));
                        dispatch(settingsSaved());
                        dispatch(refreshSyncStatus());
                        dispatch(loadPaginatedBundles());
                        dispatch(adminTrack('admin_bundle_offer_saved', {
                            bundle_name: state.productsBundle.bundle.semantic_name,
                        }));

                    });
                } else {
                    dispatch(saveError());
                }
            });

    }
}

export function saveGroupedBundle() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(saving());
        return fetch(`https://${host}/bundle/save_grouped`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    app_type: state.data.appType,
                    bundle: state.productsBundle.bundle,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        dispatch(initProductsBundle(json));
                        dispatch(settingsSaved());
                        dispatch(refreshSyncStatus());
                        dispatch(loadPaginatedBundles())
                    });
                } else {
                    dispatch(saveError());
                }
            });

    }
}

export function gen() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(saving());
        return fetch(`https://${host}/bundle/generate_auto_bundles`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    app_type: state.data.appType,
                    bundle: state.productsBundle.bundle,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        dispatch(settingsSaved());
                        dispatch(refreshAutoBundlesStatus());
                    });
                } else {
                    dispatch(saveError());
                }
            });

    }
}

export const bundlesUploadSuccess = (_, json) => async (dispatch, getState) => {
    const state = getState();
    const res = await postPayload('export_import_bundles/import', {
        file_name: json.file_name,
        override: state.productsBundle.overrideExistingBundlesOnImport || false,
    }, state);
    if (res.ok) {
        dispatch(pollForImportBundlesState(json));
    }
}

export const pollForImportBundlesState = (json) => async (dispatch, getState) => {
    const state = getState();
    const res = await postPayload('export_import_bundles/get_state', {
        file_name: json.file_name,
    }, state)
    if (res.ok) {
        const json2 = await res.json();
        dispatch(importBundleState(json2));
        if (json2.status !== 'success' && json2.status !== 'error') {
            setTimeout(() => dispatch(pollForImportBundlesState(json)), 5000);
        } else {
            dispatch(loadPaginatedBundles());
        }
    } else {
        dispatch(importBundleState({status: 'initializing'}));
        setTimeout(() => dispatch(pollForImportBundlesState(json)), 5000);
    }
}


export const bundlesGetSignedUrlCallback = async (data) => {
    const res = await postPayload('export_import_bundles/get_urls', {}, {
        data,
    });
    if (res.ok) {
        const json = await res.json();
        json.url = json.import_obj_url;
        return json;
    }
}
