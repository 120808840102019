import React from 'react';
import {Button} from "@shopify/polaris";
import {useToast} from "@shopify/app-bridge-react";

export default function Test() {

    const {show} = useToast();

    return <Button onClick={() => show('BLA!!!')}>Show</Button>;
}
