import React, {useCallback, useState} from 'react';
import {Banner, Button, Caption, DropZone, Icon, List, Stack, Thumbnail} from "@shopify/polaris";
import {CancelSmallMinor} from '@shopify/polaris-icons';
import fetch from 'cross-fetch';

const ImageUpload = ({presignedUrl, uploadSuccess, uploadFailure, onCancel}) => {
    const getSignedUrl = () => {
        setLoading(true);
        fetch(presignedUrl)
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        uploadFile(json);
                    });
                } else {
                    setLoading(false);
                    console.log(response);
                }
            });
    }

    const uploadFile = json => {
        fetch(json.url,
            {
                method: 'put',
                headers: {
                    'Content-Type': file.type,
                    'Cache-Control': 'max-age=0',
                },
                body: file,
            })
            .then((response) => {
                if (response.ok) {
                    uploadSuccess(response);
                    setLoading(false);
                } else {
                    uploadFailure(response);
                    setLoading(false);
                }
            })
    }

    const [file, setFile] = useState();
    const [loading, setLoading] = useState(false);
    const [rejectedFiles, setRejectedFiles] = useState([]);
    const hasError = rejectedFiles.length > 0;
    const handleDrop = useCallback(
        (_droppedFiles, acceptedFiles, rejectedFiles) => {
            setFile(acceptedFiles[0]);
            setRejectedFiles(rejectedFiles);
        },
        [],
    );
    const fileUpload = !file && <DropZone.FileUpload/>;
    const removeFile = (event) => {
        setFile();
        event.stopPropagation();
    };
    const uploadedFile = file && (
        <Stack alignment="center">
            <Thumbnail
                size="large"
                alt={file.name}
                source={window.URL.createObjectURL(file)}
            />
            <div>
                {file.name} <Caption>{file.size} bytes</Caption>
                <span style={{cursor: 'pointer'}} onClick={removeFile}><Icon source={CancelSmallMinor}/></span>
            </div>
        </Stack>

    );

    const errorMessage = hasError && (
        <Banner
            title="The following images couldn’t be uploaded:"
            status="critical"
        >
            <List type="bullet">
                {rejectedFiles.map((file, index) => (
                    <List.Item key={index}>
                        {`"${file.name}" is not supported. File type must be .gif, .jpg, .png or .svg.`}
                    </List.Item>
                ))}
            </List>
        </Banner>
    );

    return (
        <Stack vertical>
            {errorMessage}
            <DropZone accept="image/*" type="image" onDrop={handleDrop}>
                {uploadedFile}
                {fileUpload}
            </DropZone>
            <div>
            {onCancel && <Button onClick={onCancel}>Cancel</Button>}
            {file && <span style={{marginLeft:'10px'}}><Button primary onClick={getSignedUrl} loading={loading}>Upload</Button></span>}
            </div>
        </Stack>
    );
};

export default ImageUpload;
