import { connect } from 'react-redux';
import {
    belowAddToCart,
    cartPositionChange,
    dismissToast,
    postMsgAndDispatchAutoPlayChange,
    postMsgAndDispatchAutoPlayIntervalChange,
    postMsgAndDispatchBorderChange,
    postMsgAndDispatchComparePriceColorChange,
    postMsgAndDispatchComparePriceFontSizeChange,
    postMsgAndDispatchComparePriceFontWeightChange,
    postMsgAndDispatchCurrencyCutZerosChange,
    postMsgAndDispatchCurrencyPositionChange,
    postMsgAndDispatchCustomCurrencyChange,
    postMsgAndDispatchHideProductLinkChange,
    postMsgAndDispatchMaxProductsChange,
    postMsgAndDispatchNavCircleChange,
    postMsgAndDispatchNavColorChange,
    postMsgAndDispatchPriceColorChange,
    postMsgAndDispatchPriceFontSizeChange,
    postMsgAndDispatchPriceFontWeightChange,
    postMsgAndDispatchProductLinkColorChange,
    postMsgAndDispatchProductLinkFontSizeChange,
    postMsgAndDispatchProductLinkFontWeightChange,
    postMsgAndDispatchProductLinkUnderlineChange,
    postMsgAndDispatchRestoreToDefaults,
    postMsgAndDispatchSelectionColorChange,
    postMsgAndDispatchShowQuantityModifierChange,
    postMsgAndDispatchShuffleProductsChange,
    postMsgAndDispatchSubTitleColorChange,
    postMsgAndDispatchSubTitleFontSizeChange,
    postMsgAndDispatchSubTitleFontWeightChange,
    postMsgAndDispatchTitleAlignChange,
    postMsgAndDispatchTitleColorChange,
    postMsgAndDispatchTitleFontSizeChange,
    postMsgAndDispatchTitleFontWeightChange,
    productBottomEnabled,
    productLocationChange,
    refreshAfterAddToCartChange,
    addToCartButtonBottomWidget,
    addToCartButtonCartWidget,
    submit,
    postMsgAndDispatchAddToCartButtonFontSizeChange,
    postMsgAndDispatchAddToCartButtonColorChange,
    postMsgAndDispatchAddToCartButtonTextColorChange,
    postMsgAndDispatchAddToCartButtonMainWidgetChange,
    postMsgAndDispatchQuickViewChange,
    postMsgAndDispatchImageBackgroundChange,
    productDescOnQuickView,
    numOfSlidesMobile,
    numOfSlidesDesktop,
    postMsgAndDispatchSliderItemBackgroundChange,
    postMsgAndDispatchTitleOnTopChange,
    postMsgAndDispatchHideCompareToChange,
    postMsgAndDispatchQuickViewOnImageClickedChange,
    postMsgAndDispatchSelectionShapeChange,
    postMsgAndDispatchFontFamilyChange,
    postMsgAndDispatchShowQuantityModalChange,
    infoPopupSlider,
    sliderNavigateToChange,
    recommendWithOrders,
    postMsgAndDispatchShowSliderVariantsChange,
    sliderDiscountChange,
    collapseBackgroundColor,
    navigateToProductPage,
    sliderShowAfterItemAdded,
    takeOnlyFirstMappingChange,
    sliderShowDiscountedPricesChange,
    sliderCartPopupChange,
    sliderDisplayOptionsChange,
    sliderCartDrawerDiscountChange,
    sliderCartDrawerNumOfSlidesChange,
    sliderOnlyOnSourceProductsChange,
    sliderShowOthersAfterGlobalsChanged,
    sliderSettingsBoolChange,
} from "../../actions/settings";
import SettingsSlider from "../../components/settings/SettingsSlider";
import {addAlphaToColor} from "./VisibleSettingsMain";

const mapStateToProps = state => {
    return {
        maxProducts: state.settings.maxProducts,
        error: state.settings.submissionError,
        isSubmitting: state.settings.isSubmitting,
        toastIsActive: state.settings.toastIsActive,
        titleColor: state.settings.titleColor,
        titleFontSize: state.settings.titleFontSize,
        titleFontWeight: state.settings.titleFontWeight,
        subTitleFontSize: state.settings.subTitleFontSize,
        subTitleFontWeight: state.settings.subTitleFontWeight,
        subTitleColor: state.settings.subTitleColor,
        priceFontSize: state.settings.priceFontSize,
        priceFontWeight: state.settings.priceFontWeight,
        priceColor: state.settings.priceColor,
        comparePriceFontSize: state.settings.comparePriceFontSize,
        comparePriceFontWeight: state.settings.comparePriceFontWeight,
        comparePriceColor: state.settings.comparePriceColor,
        productLinkFontSize: state.settings.productLinkFontSize,
        productLinkFontWeight: state.settings.productLinkFontWeight,
        productLinkUnderline: state.settings.productLinkUnderline,
        productLinkColor: state.settings.productLinkColor,
        selectionColor: state.settings.selectionColor,
        showQuantityModifier: state.settings.showQuantityModifier,
        cartPosition: state.settings.cartPosition,
        shuffleProducts: state.settings.shuffleProducts,
        currencyPosition: state.settings.currencyPosition,
        customCurrency: state.settings.customCurrency,
        currencyCutZeros: state.settings.currencyCutZeros,
        autoPlay: state.settings.autoPlay,
        autoPlayInterval: state.settings.autoPlayInterval,
        hideProductLink: state.settings.hideProductLink,
        productLocation: state.settings.productLocation,
        belowAddToCart: state.settings.belowAddToCart,
        productBottomEnabled: state.settings.productBottomEnabled,
        refreshAfterAddToCart: state.settings.refreshAfterAddToCart,
        titleAlign: state.settings.titleAlign,
        border: state.settings.border,
        navCircle: state.settings.navCircle,
        navColor: state.settings.navColor,
        addToCartButtonFontSize: state.settings.addToCartButtonFontSize,
        addToCartButtonColor: state.settings.addToCartButtonColor,
        addToCartButtonTextColor: state.settings.addToCartButtonTextColor,
        addToCartButtonBottomWidget: state.settings.addToCartButtonBottomWidget,
        addToCartButtonCartWidget: state.settings.addToCartButtonCartWidget,
        addToCartButtonMainWidget: state.settings.addToCartButtonMainWidget,
        quickView: state.settings.quickView,
        imageBackground: state.settings.imageBackground,
        productDescOnQuickView: state.settings.productDescOnQuickView,
        numOfSlidesMobile: state.settings.numOfSlidesMobile,
        numOfSlidesDesktop: state.settings.numOfSlidesDesktop,
        sliderItemBackground: state.settings.sliderItemBackground,
        titleOnTop: state.settings.titleOnTop,
        hideCompareTo: state.settings.hideCompareTo,
        quickViewOnImageClicked: state.settings.quickViewOnImageClicked,
        selectionShape: state.settings.selectionShape,
        sliderFontFamily: state.settings.sliderFontFamily,
        showQuantityModal: state.settings.showQuantityModal,
        infoPopupSlider: state.settings.infoPopupSlider,
        navigateTo: state.settings.navigateTo,
        recommendWithOrders: state.settings.recommendWithOrders,
        showSliderVariants: state.settings.showSliderVariants,
        sliderDiscount: state.settings.sliderDiscount,
        collapseMain: state.settings.collapseMain,
        collapseBackgroundColor: state.settings.collapseBackgroundColor,
        planName: state.settings.planName,
        navigateToProductPage: state.settings.navigateToProductPage,
        sliderShowAfterItemAdded: state.settings.sliderShowAfterItemAdded,
        takeOnlyFirstMapping: state.settings.takeOnlyFirstMapping,
        showDiscountedPrices: state.settings.showDiscountedPrices,
        cartPopup: state.settings.cartPopup,
        plainList: state.settings.plainList,
        cartDrawerDiscount: state.settings.cartDrawerDiscount,
        cartDrawerNumOfSlides: state.settings.cartDrawerNumOfSlides,
        onlyOnSourceProducts: state.settings.onlyOnSourceProducts,
        showOthersAfterGlobals: state.settings.showOthersAfterGlobals,
        settings: state.settings,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onBoolChange: attr => bool => dispatch(sliderSettingsBoolChange(attr, bool)),
    onCustomCurrencyChange: text => postMsgAndDispatchCustomCurrencyChange(dispatch, text),
    onSelectionShapeChange: text => postMsgAndDispatchSelectionShapeChange(dispatch, text),
    onFontFamilyChange: text => postMsgAndDispatchFontFamilyChange(dispatch, text),
    onMaxProductsChange: num => postMsgAndDispatchMaxProductsChange(dispatch, num),
    onAutoPlayIntervalChange: num => postMsgAndDispatchAutoPlayIntervalChange(dispatch, num),
    onNumOfSlidesMobileChange: num => dispatch(numOfSlidesMobile(num)),
    onNumOfSlidesDesktopChange: num => dispatch(numOfSlidesDesktop(num)),
    onSliderDiscountChange: num => dispatch(sliderDiscountChange(num)),
    onCartDrawerNumOfSlidesChange: num => dispatch(sliderCartDrawerNumOfSlidesChange(num)),
    onSubmit: () => dispatch(submit()),
    onDismissToast: () => dispatch(dismissToast()),
    onTitleColorChange: color => postMsgAndDispatchTitleColorChange(dispatch, addAlphaToColor(color)),
    onSubTitleColorChange: color => postMsgAndDispatchSubTitleColorChange(dispatch, addAlphaToColor(color)),
    onPriceColorChange: color => postMsgAndDispatchPriceColorChange(dispatch, addAlphaToColor(color)),
    onComparePriceColorChange: color => postMsgAndDispatchComparePriceColorChange(dispatch, addAlphaToColor(color)),
    onProductLinkColorChange: color => postMsgAndDispatchProductLinkColorChange(dispatch, addAlphaToColor(color)),
    onSelectionColorChange: color => postMsgAndDispatchSelectionColorChange(dispatch, addAlphaToColor(color)),
    onNavColorChange: color => postMsgAndDispatchNavColorChange(dispatch, addAlphaToColor(color)),
    onCollapseBackgroundColorChange: color => dispatch(collapseBackgroundColor(addAlphaToColor(color))),
    onTitleFontSizeChange: size => postMsgAndDispatchTitleFontSizeChange(dispatch, size),
    onSubTitleFontSizeChange: size => postMsgAndDispatchSubTitleFontSizeChange(dispatch, size),
    onPriceFontSizeChange: size => postMsgAndDispatchPriceFontSizeChange(dispatch, size),
    onComparePriceFontSizeChange: size => postMsgAndDispatchComparePriceFontSizeChange(dispatch, size),
    onProductLinkFontSizeChange: size => postMsgAndDispatchProductLinkFontSizeChange(dispatch, size),
    onTitleFontWeightChange: weight => postMsgAndDispatchTitleFontWeightChange(dispatch, weight),
    onSubTitleFontWeightChange: weight => postMsgAndDispatchSubTitleFontWeightChange(dispatch, weight),
    onPriceFontWeightChange: weight => postMsgAndDispatchPriceFontWeightChange(dispatch, weight),
    onComparePriceFontWeightChange: weight => postMsgAndDispatchComparePriceFontWeightChange(dispatch, weight),
    onProductLinkFontWeightChange: weight => postMsgAndDispatchProductLinkFontWeightChange(dispatch, weight),
    onCartPositionChange: position => dispatch(cartPositionChange(position)),
    onProductLocationChange: position => dispatch(productLocationChange(position)),
    onCurrencyPositionChange: position => postMsgAndDispatchCurrencyPositionChange(dispatch, position),
    onTitleAlignChange: position => postMsgAndDispatchTitleAlignChange(dispatch, position),
    onProductLinkUnderlineChange: bool => postMsgAndDispatchProductLinkUnderlineChange(dispatch, bool),
    onShowQuantityModifierChange: bool => postMsgAndDispatchShowQuantityModifierChange(dispatch, bool),
    onCurrencyCutZerosChange: bool => postMsgAndDispatchCurrencyCutZerosChange(dispatch, bool),
    onShuffleProductsChange: bool => postMsgAndDispatchShuffleProductsChange(dispatch, bool),
    onAutoPlayChange: bool => postMsgAndDispatchAutoPlayChange(dispatch, bool),
    onHideProductLinkChange: bool => postMsgAndDispatchHideProductLinkChange(dispatch, bool),
    onBorderChange: bool => postMsgAndDispatchBorderChange(dispatch, bool),
    onNavCircleChange: bool => postMsgAndDispatchNavCircleChange(dispatch, bool),
    onHideCompareToChange: bool => postMsgAndDispatchHideCompareToChange(dispatch, bool),
    onQuickViewOnImageClickedChange: bool => postMsgAndDispatchQuickViewOnImageClickedChange(dispatch, bool),
    onShowSliderVariantsChange: text => postMsgAndDispatchShowSliderVariantsChange(dispatch, text),
    onBelowAddToCartChange: bool => dispatch(belowAddToCart(bool)),
    onProductBottomEnabledChange: bool => dispatch(productBottomEnabled(bool)),
    onRefreshAfterAddToCartChange: bool => dispatch(refreshAfterAddToCartChange(bool)),
    onProductDescOnQuickViewChange: bool => dispatch(productDescOnQuickView(bool)),
    onInfoPopupSliderChange: bool => dispatch(infoPopupSlider(bool)),
    onRecommendWithOrdersChange: bool => dispatch(recommendWithOrders(bool)),
    onTakeOnlyFirstMappingChange: text => dispatch(takeOnlyFirstMappingChange(text)),
    onRestoreToDefaults: () => dispatch(postMsgAndDispatchRestoreToDefaults()),
    onAddToCartButtonFontSizeChange: size => postMsgAndDispatchAddToCartButtonFontSizeChange(dispatch, size),
    onAddToCartButtonColorChange: color => postMsgAndDispatchAddToCartButtonColorChange(dispatch, addAlphaToColor(color)),
    onAddToCartButtonTextColorChange: color => postMsgAndDispatchAddToCartButtonTextColorChange(dispatch, addAlphaToColor(color)),
    onSliderItemBackgroundChange: color => postMsgAndDispatchSliderItemBackgroundChange(dispatch, addAlphaToColor(color)),
    onAddToCartButtonBottomWidgetChange: bool => dispatch(addToCartButtonBottomWidget(bool)),
    onAddToCartButtonCartWidgetChange: bool => dispatch(addToCartButtonCartWidget(bool)),
    onNavigateToProductPageChange: bool => dispatch(navigateToProductPage(bool)),
    onSliderShowAfterItemAddedChange: bool => dispatch(sliderShowAfterItemAdded(bool)),
    onOnlyOnSourceProductsChange: bool => dispatch(sliderOnlyOnSourceProductsChange(bool)),
    onShowOthersAfterGlobalsChanged: bool => dispatch(sliderShowOthersAfterGlobalsChanged(bool)),
    onAddToCartButtonMainWidgetChange: bool => postMsgAndDispatchAddToCartButtonMainWidgetChange(dispatch, bool),
    onQuickViewChange: bool => postMsgAndDispatchQuickViewChange(dispatch, bool),
    onTitleOnTopChange: bool => postMsgAndDispatchTitleOnTopChange(dispatch, bool),
    onShowQuantityModalChange: bool => postMsgAndDispatchShowQuantityModalChange(dispatch, bool),
    onImageBackgroundChange: text => postMsgAndDispatchImageBackgroundChange(dispatch, text),
    onNavigateToChange: text => dispatch(sliderNavigateToChange(text)),
    onShowDiscountedPricesChange: text => dispatch(sliderShowDiscountedPricesChange(text)),
    onCartPopupChange: text => dispatch(sliderCartPopupChange(text)),
    onDisplayOptionsChange: text => dispatch(sliderDisplayOptionsChange(text)),
    onCartDrawerDiscountChange: text => dispatch(sliderCartDrawerDiscountChange(text)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsSlider);
